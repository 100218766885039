import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
  },
  waiting: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  },
  failed: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
}));