import { Remote } from "service/remote";

export const SalesCounterUpdate = async ({
    id,
    data
}:
    {
        id: string;
        data: any;
    }) => {
    return await Remote({
        method: "put",
        urn: "/sales-counters/" + id,
        api: 'manager',
        data
    })

}

export default SalesCounterUpdate;