import { Remote } from "service/remote";

export const Proposals = async (indication?: boolean) => {
    return await Remote({
        method: "get",
        urn: `online/proposals/my${indication ? '/indication' : ''}`
    })

}

export default Proposals;