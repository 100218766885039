import { useEffect, useRef, useState } from "react"
import styles from './styles.module.css'
import { colors } from "theme"

export const SelectUI = ({
    options,
    value,
    identifier,
    onChange
}: {
    options: {
        label: string,
        value: any,
        icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
        color?: string
    }[]
    value: any,
    identifier: string | number,
    onChange: React.FormEventHandler<any>;
}) => {

    const [preValue, setPreValue] = useState(value)
    const openMenu = useRef(false)

    useEffect(() => {
        const selectBtn = document.querySelector(`#selectBtn-${identifier}`)
        const optionsAll = document.querySelectorAll(`#option-${identifier}`)
        const optionsIdentifier = document.querySelector<HTMLElement>(`#options-${identifier}`)
        const sBtnText = document.querySelector(`#sBtnText-${identifier}`)
        const arrow = document.querySelector<HTMLElement>(`#arrow-${identifier}`)

        function handleOpen() {
            optionsIdentifier!.style.display = openMenu.current ? 'none' : 'block'
            optionsIdentifier!.style.visibility = openMenu.current ? 'hidden' : 'visible'
            optionsIdentifier!.style.opacity = openMenu.current ? '0' : '1'
            arrow!.style.transform = `rotate(${openMenu.current ? 0 : 180}deg)`
            openMenu.current = !openMenu.current
        }

        selectBtn?.addEventListener("click", (() => {
            handleOpen()
        }))

        optionsAll.forEach((option) => {
            option.addEventListener("click", (() => {
                let selectedOption = option.querySelector(`#optionText-${identifier}`)?.innerHTML
                sBtnText!.innerHTML = selectedOption as string
                const selected = options.find((item) => item.label === selectedOption)?.value
                setPreValue(selected)
                handleOpen()
                onChange(selected)
            }))
        })
        return () => {
            selectBtn?.removeEventListener('click', () => {})
            optionsAll.forEach((option) => {
                option.removeEventListener("click", () => {})
            })
          }
    }, [identifier])

    useEffect(() => {
        const selectMenu = document.querySelector(`#selectMenu-${identifier}`)
        const optionsIdentifier = document.querySelector<HTMLElement>(`#options-${identifier}`)
        const arrow = document.querySelector<HTMLElement>(`#arrow-${identifier}`)

        function close() {
            optionsIdentifier!.style.display = 'none'
            optionsIdentifier!.style.visibility = 'hidden' 
            optionsIdentifier!.style.opacity = '0'
            arrow!.style.transform = `rotate(0deg)`
            openMenu.current = !openMenu.current
        }

        window.addEventListener('click', (e) => {
            if (!selectMenu?.contains(e.target as unknown as Node | null)) {
                close()
            }
        })
    
      return () => {
        window.removeEventListener('click', () => {})
      }
    }, [])
    
    return (
        <div key={identifier} className={styles.selectMenu} id={`selectMenu-${identifier}`}>
            <div className={styles.selectBtn} id={`selectBtn-${identifier}`}>
                {options.find((item) => item.value === preValue)?.icon && options.find((item) => item.value === preValue)?.icon}
                <span className={styles.sBtnText} id={`sBtnText-${identifier}`} style={{
                    color: options.find((item) => item.value === preValue)?.color ?? colors.black
                }}>{options.find((item) => item.value === preValue)?.label}</span>
                <i className={styles.arrow} id={`arrow-${identifier}`}>&#9207;</i>
            </div>
            <ul className={styles.options} id={`options-${identifier}`}>
                {
                    options.map((item) => {
                        return (
                            <li className={styles.option} id={`option-${identifier}`}>
                                {item.icon && item.icon}
                                <span className={styles.optionText} style={{
                                    color: item.color ?? colors.black
                                }} id={`optionText-${identifier}`}>{item.label}</span>
                            </li>)
                    })
                }
            </ul>
        </div>
    )
}
