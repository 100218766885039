import { useState, useEffect } from "react"
import {
    Button,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import React, { PureComponent } from 'react';
import { colors } from 'theme';
import Icons from 'utils/icons';
import RemoteServices from "service";
import { dataBR, formatCurrency, getLegibleDate } from "@utils/format";
import { ProposalsChart } from "./proposals";
import BarValueForTable from "./table";
import BarValueForUF from "./tableUF";
import Parcels from "./parcels";
import Gender from "./gender";
import Birthday from "./birthday";
import Card from "components/@ui/card";

import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import LoadingSkeletonStatiscs from "./skeleton";
import useMediasQuerys from "hooks/mediasQuery";
import useAuth from "context/auth";

moment().locale('pt-BR')

const now = new Date();
now.setHours(23);
now.setMinutes(59);
now.setSeconds(59);

const firstDay = new Date();
firstDay.setDate(firstDay.getDate() - 30);
firstDay.setHours(0);
firstDay.setMinutes(0);
firstDay.setSeconds(0);

const Statistics = () => {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        User,
        defaultUser
    } = useAuth()

    const [filters, _setFilter] = useState<{
        begin: Moment,
        end: Moment,
        uf: string,
        city: string,
        gender: string | null,
        salesCounter: string | null,
        status?: string,
        kind: string,
    }>({
        begin: moment(firstDay),
        end: moment(now),
        uf: '',
        city: '',
        gender: null,
        salesCounter: null,
        status: undefined,
        kind: '',
    });

    const [populated, setPopulated] = useState(false);

    const [loading, setLoading] = useState(false);

    const [indicators, setIndicators] = useState({
        countProposals: 0,
        netAmount: 0,
        proposals: [],
        commission: 0,
        cost: 0
    });

    const [ufs, setUFs] = useState([]);
    const [cities, setCities] = useState([]);
    const [salesCounters, setSalesCounters] = useState([]);

    const StatisticsAdmin = async () => {
        console.log(User, defaultUser)
        console.log(filters)
        RemoteServices.statistics.StatisticsAdmin({
            begin: filters.begin.toISOString(),
            end: filters.end.toISOString(),
            status: filters.status,
            uf: filters.uf !== '' ? filters.uf : undefined,
            city: filters.city !== '' ? filters.city : undefined,
            kind: filters.kind !== '' ? filters.kind : undefined,
            salesCounter: filters.salesCounter !== null ? filters.salesCounter : User?.space === "sales_counter" ? defaultUser?.id : undefined,
        }).then(({ response }) => {
            if (response) {
                setIndicators(response);
            }
        })
            .finally(() => setLoading(false))
    }

    function setFilter(filter: string, value: Moment | null | string) {
        const newFilters = { ...filters, [filter]: value !== "" ? value : undefined };

        if (filter === 'uf') {
            newFilters.city = '';
        }

        _setFilter(newFilters);
    }


    useEffect(() => {
        populate();
        document.title = "Ayude | Gestão"
    }, []);


    const populate = async () => {
        setLoading(true)
        RemoteServices.statistics.StatisticsPopulate()
            .then(({ response }) => {
                if (response) {
                    setCities(response.cities);
                    setUFs(response.uf);
                    setSalesCounters(response.salesCounters);
                    setPopulated(true);
                    if (!response?.salesCounters) {
                        setLoading(false)
                    }
                }
            })
            .catch(() => setLoading(false))
    }

    useEffect(() => {
        if (populated) {
            StatisticsAdmin()
        }
    }, [filters, populated])

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                    alignItems="center"
                >
                    <img
                        src={Icons.IconESTATÍSTICA}
                        alt="icon"
                        width={30}
                        style={{ filter: 'brightness(0)' }}
                    />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>
                        PAINEL DE DADOS
                    </Typography>
                </Grid>
            </Grid>
            <Grid my={1} container gap={2} justifyContent={"flex-start"} alignItems={"center"}>
                <Grid item mx={1} className={'column'}>
                    <DatePicker
                        label="Data inicial"
                        format="DD/MM/YYYY"
                        value={filters.begin ? moment(filters.begin) : null}
                        sx={{ maxWidth: 250, width: '100%' }}
                        maxDate={moment(new Date())}
                        onChange={(date) => {
                            if (date) {
                                setFilter('begin', moment());
                            }
                            setFilter('begin', date)
                        }}
                    />
                </Grid>
                <Grid item className={'column'}>
                    <DatePicker
                        label="Data final"
                        format="DD/MM/YYYY"
                        value={filters.end ? moment(filters.end) : null}
                        sx={{ maxWidth: 250, width: '100%', ml: 1 }}
                        maxDate={moment(new Date())}
                        onChange={(date) => {
                            if (date) {
                                setFilter('end', moment());
                            }
                            setFilter('end', date)
                        }}
                    />
                </Grid>
                <Grid item width={130} mx={1}>
                    <FormControl fullWidth>
                        <InputLabel id="bi-estado-select">Estado</InputLabel>
                        <Select
                            labelId="bi-estado-select"
                            value={filters.uf}
                            label="Estado"
                            onChange={(e) => setFilter('uf', e.target.value)}
                        >
                            <MenuItem value={''}>Todos</MenuItem>
                            {ufs?.map((c, i) => {
                                return <MenuItem key={`${c}-${i}`} value={c}>{c}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item width={200}>
                    <FormControl fullWidth>
                        <InputLabel id="bi-cidade-select">Cidade</InputLabel>
                        <Select
                            labelId="bi-cidade-select"
                            value={filters.city}
                            label="Cidade"
                            onChange={(e) => setFilter('city', e.target.value)}
                        >
                            <MenuItem value={''}>Todas</MenuItem>
                            {cities?.filter((c: any) => c.uf === filters.uf)?.map((c: any, i) => {
                                return <MenuItem key={`${c.city}-${i}`} value={c.city}>{c.city}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid my={1} container gap={2} justifyContent={"flex-start"} alignItems={"center"}>
                <Grid item width={200} mx={1}>
                    <FormControl fullWidth>
                        <InputLabel id="bi-status-select">Status da proposta</InputLabel>
                        <Select
                            labelId="bi-status-select"
                            value={filters.status}
                            label="Status da proposta"
                            onChange={(e) => setFilter('status', e.target.value)}
                        >
                            <MenuItem value={''}>Todas</MenuItem>
                            <MenuItem value={'aproved'}>Aprovadas</MenuItem>
                            <MenuItem value={'preValidationCard'}>Validação do cartão de crédito</MenuItem>
                            <MenuItem value={'transfeera'}>Validação da conta bancária</MenuItem>
                            <MenuItem value={'face_id'}>Validação das fotos</MenuItem>
                            <MenuItem value={'signature'}>Etapa de assinatura</MenuItem>
                            <MenuItem value={'failed'}>Não aprovadas</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item width={200} mx={1} className={'column'}>
                    <FormControl fullWidth>
                        <InputLabel id="bi-kind-selectt">Tipo de proposta</InputLabel>
                        <Select
                            labelId="bi-kind-select"
                            value={filters.kind}
                            label="Tipo da proposta"
                            onChange={(e) => setFilter('kind', e.target.value)}
                        >
                            <MenuItem value={''}>Todas</MenuItem>
                            <MenuItem value={'on'}>Via link</MenuItem>
                            <MenuItem value={'dig'}>Presencial</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {(User?.space === "pdv" || User?.space === "gestor") && <Grid item width={130} mx={1}>
                    <FormControl fullWidth>
                        <InputLabel id="bi-sales-counter-select">Filial</InputLabel>
                        <Select
                            labelId="bi-sales-counter-select"
                            value={filters.salesCounter}
                            label="Filial"
                            onChange={(e) => setFilter('salesCounter', e.target.value)}
                        >
                            <MenuItem value={''}>Todos</MenuItem>
                            {salesCounters?.map((c: any, i) => {
                                return <MenuItem key={`${c.id}-${i}`} value={c.id}>{c.responsibleName}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>}
            </Grid>

            {
                loading ?
                    <LoadingSkeletonStatiscs />
                    :
                    <>
                        <Grid
                            my={4}
                            container
                            gap={3}
                            display={"grid"}
                            sx={{ gridTemplateColumns: `repeat(auto-fill, minmax(21.875rem, 1fr))`, flexWrap: 'wrap' }}>
                            <Card
                                bgcolor="Secondary"
                            >
                                <div>
                                    <Typography variant="h5" fontWeight={"bold"}>{indicators?.netAmount ?? 0}</Typography>
                                    <Typography variant="subtitle1">Valor Total</Typography>
                                </div>
                            </Card>
                            <Card
                                bgcolor="primary"
                            >
                                <div>
                                    <Typography variant="h5" fontWeight={"bold"}>{indicators?.countProposals ?? 0}</Typography>
                                    <Typography variant="subtitle1">Propostas no período</Typography>
                                </div>
                            </Card>
                            {User?.space === "pdv" && <Card
                                bgcolor="warning"
                            >
                                <div>
                                    <Typography variant="h5" fontWeight={"bold"}>{indicators?.commission ?? 0}</Typography>
                                    <Typography variant="subtitle1">Comissões do período<br /></Typography>
                                </div>
                            </Card>}
                            {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("readCommission") && <Card
                                bgcolor="warning"
                            >
                                <div>
                                    <Typography variant="h5" fontWeight={"bold"}>{indicators?.commission ?? 0}</Typography>
                                    <Typography variant="subtitle1">Comissões do período<br /></Typography>
                                </div>
                            </Card>}
                        </Grid>
                        <Grid
                            border={'1px solid ' + colors.grey300}
                            bgcolor={colors.Background}
                            borderRadius={1}
                            p={4}>
                            <Typography
                                variant="h5"
                                sx={{ my: 4, textTransform: 'uppercase' }}
                                color={colors.black}
                                fontWeight={"bold"}>
                                VALOR TOTAL
                            </Typography>
                            {
                                indicators?.proposals?.length > 0 &&
                                <ProposalsChart
                                    data={indicators?.proposals
                                        // @ts-ignore
                                        ?.sort((a: any, b: any) => new Date(a?.createdAt) - new Date(b?.createdAt))
                                        ?.map((proposal: any) => {
                                            return {
                                                amount: proposal?.netAmount,
                                                date: proposal?.createdAt.slice(0, 10)
                                            }
                                        })}
                                />
                            }

                        </Grid>
                        <Grid container gap={2} xs={12} py={2}>
                            <Grid
                                xs={12}
                                md={5.9}
                                lg={5.9}
                                border={'1px solid ' + colors.grey300}
                                bgcolor={colors.Background}
                                borderRadius={1}
                                px={8}
                                p={4}>
                                <Typography
                                    variant="h5"
                                    sx={{ my: 4, textTransform: 'uppercase' }}
                                    color={colors.black}
                                    fontWeight={"bold"}>
                                    Valor por tabela
                                </Typography>
                                <BarValueForTable
                                    data={indicators?.proposals
                                        ?.map((proposal: any) => {
                                            return {
                                                tab: proposal?.saleTable,
                                                amount: proposal?.netAmount,
                                                date: proposal?.createdAt.slice(0, 10)
                                            }
                                        })}
                                />

                            </Grid>
                            <Grid
                                xs={12}
                                md={5.9}
                                lg={5.9}
                                border={'1px solid ' + colors.grey300}
                                bgcolor={colors.Background}
                                borderRadius={1}
                                p={4}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{ my: 4, textTransform: 'uppercase' }}
                                    color={colors.black}
                                    fontWeight={"bold"}>
                                    Valor por UF
                                </Typography>
                                <BarValueForUF
                                    data={indicators?.proposals
                                        ?.map((proposal: any) => {
                                            return {
                                                tab: proposal?.uf,
                                                amount: proposal?.netAmount
                                            }
                                        })}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            flexWrap={"wrap"}
                            container gap={2} xs={12} py={2}>
                            <Grid
                                xs={12}
                                md={6}
                                lg={3.8}
                                border={'1px solid ' + colors.grey300}
                                bgcolor={colors.Background}
                                borderRadius={1}
                                p={matchesMaxMobile ? 1 : 4}>
                                <Typography
                                    variant="h5"
                                    sx={{ my: 4, textTransform: 'uppercase' }}
                                    color={colors.black}
                                    fontWeight={"bold"}>
                                    VALORES POR PARCELAS
                                </Typography>
                                <Parcels data={indicators?.proposals} />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                                lg={3.8}
                                border={'1px solid ' + colors.grey300}
                                bgcolor={colors.Background}
                                borderRadius={1}
                                p={matchesMaxMobile ? 1 : 4}>
                                <Typography
                                    variant="h5"
                                    sx={{ my: 4, textTransform: 'uppercase' }}
                                    color={colors.black}
                                    fontWeight={"bold"}>
                                    VALORES POR GÊNERO
                                </Typography>
                                <Gender data={indicators?.proposals} />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                                lg={3.8}
                                border={'1px solid ' + colors.grey300}
                                bgcolor={colors.Background}
                                borderRadius={1}
                                p={matchesMaxMobile ? 1 : 4}>
                                <Typography
                                    variant="h5"
                                    sx={{ my: 4, textTransform: 'uppercase' }}
                                    color={colors.black}
                                    fontWeight={"bold"}>
                                    VALORES POR IDADE
                                </Typography>
                                <Birthday data={indicators?.proposals?.map((proposal: any) => {
                                    return {
                                        birthday: new Date(proposal?.birthday),
                                        amount: proposal?.netAmount ?? ''
                                    }
                                })} />
                            </Grid>
                        </Grid>
                    </>
            }
        </Grid>
    )
}

export default Statistics;
