import { Grid } from "@mui/material"
import { formatCurrency, getLegibleDate } from '@utils/format';
import { transformValue } from '@utils/transformValue';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { colors } from 'theme';


function BarValueForUF({ data }: { data: any }) {

    const [table, setTable] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    const toPercent = (decimal: number, fixed = 0) => `${formatCurrency(decimal)}`;


    useEffect(() => {
        if(data){
            const _tabs: any = [];
            const _data: any = [];
            for (let d of data) {
                if (!_tabs.includes(d.tab)) {
                    _tabs.push(d.tab);
                    _data.push({
                        tab: d.tab,
                        amount: data
                            .filter((a: any) => a.tab === d.tab)
                            .map((a: any) => a.amount)
                            .reduce((a: any, b: any) => a + b),
                    });
                }
            }
            _data.sort((a: any, b: any) => a.amount - b.amount);
    
            setTable(_data);
        }
        
    }, [data])




    const CustomTooltip = ({ active, payload, label }: { active: boolean | undefined, payload: any, label: string }) => {
        if (active && payload && payload.length) {
            return (
                <Grid
                    bgcolor={colors.primary + 20}
                    p={2}
                    border={'1px solid ' + colors.primary}
                    onClick={() => console.log(label)} className="custom-tooltip">
                    <p className="intro">{formatCurrency(payload[0].value)}</p>
                    {/* <p className="intro">{((payload[0].value / total) * 100).toFixed(2)}%</p> */}
                </Grid>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width={'100%'} height={400}>
            <BarChart
                width={500}
                height={400}
                data={table}
                margin={{
                    top: 10,
                    right: 10,
                    left: 50,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="tab" />
                <YAxis tickFormatter={toPercent} />
                <Tooltip
                    content={({ active, payload, label, }) =>
                        <CustomTooltip
                            active={active}
                            payload={payload}
                            label={label}
                        />
                    }
                />
                {/* <Legend /> */}
                <Bar
                    type="monotone"
                    dataKey="amount"
                    stroke={colors.primary}
                    fill={colors.primary + 25} />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarValueForUF