const defaultTablesOnline = [
    {
      table: "on-2",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-1",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-3",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-4",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-5",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-6",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-7",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-8",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-9",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-10",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-11",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-12",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-13",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
    {
      table: "on-14",
      commission_rate: 0.00,
      edit: false,
      max_commission_rate: 0.00
    },
  ]

  export default defaultTablesOnline