import { Box, SxProps, Theme } from "@mui/material"
import { colors } from "theme";

const Card = ({
    bgcolor,
    children,
    sx,
    sxBox
}: {
    bgcolor: 'primary' | 'Secondary' | 'warning' | 'third',
    children: JSX.Element,
    sx?: SxProps<Theme>,
    sxBox?: SxProps<Theme>
}) => {
    return (
        <Box sx={{ bgcolor: 'white', display: 'flex', ...(sx ?? {}) }}>
            <Box sx={{
                borderRadius: '4px',
                bgcolor: colors[bgcolor] + 10 ?? '',
                border: '1px solid ' + colors[bgcolor],
                // @ts-ignore
                p: sxBox?.p ?? 3,
                width: '100%',
                color: colors.black,
                ...(sxBox ?? {})
            }}>
                {children}
            </Box>
        </Box>
    )
}

export default Card;