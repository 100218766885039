import { WhatsApp } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material"
import ButtonUI from "components/@ui/button"
import { CEPMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from "components/@ui/masked"
import ModalUI from "components/@ui/modal"
import useAuth from "context/auth"
import { useState } from "react"
import { FiEye, FiEyeOff } from "react-icons/fi"
import RemoteServices from "service"

import estadosCidades from 'assets/json/estados_cidades.json'
import useMediasQuerys from "hooks/mediasQuery"
import { schemaSalesCounterCreate } from "@utils/validator"
import Toast from "components/@ui/toast"
import { colors } from "theme"
import URLSBases from "utils/urlBases"

export const UpdateSalesCounter = () => {
    const {
        openModalUpdate,
        setOpenModalUpdate,
        UserOperatorSales,
        canRefuseUpdate
    } = useAuth()

    const {
        matchesMaxMobile
    } = useMediasQuerys()
    const [openModal, setOpenModal] = useState(false)
    const [openModalWhatsApp, setOpenModalWhatsApp] = useState(false)


    const [type, setType] = useState<"update" | "create">("update")

    const [loading, setLoading] = useState(false);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const [tradeName, setTradeName] = useState('');
    const [statusTradeName, setStatusTradeName] = useState('');

    const [responsibleName, setResponsibleName] = useState('');
    const [statusResponsibleName, setStatusResponsibleName] = useState('');

    const [CPF, setCPF] = useState('');
    const [statusCPF, setStatusCPF] = useState('');


    const [cellphone, setCellphone] = useState('');
    const [statusCellphone, setStatusCellphone] = useState('');

    const [email, setEmail] = useState('');
    const [statusEmail, setStatusEmail] = useState('');

    const [password, setPassword] = useState('');
    const [statusPassword, setStatusPassword] = useState('');

    const [address, setAddress] = useState('');
    const [statusAddress, setStatusAddress] = useState('');

    const [addressNumber, setAddressNumber] = useState('');
    const [statusAddressNumber, setStatusAddressNumber] = useState('');

    const [district, setDistrict] = useState('');
    const [statusDistrict, setStatusDistrict] = useState('');

    const [addressComplement, setAddressComplement] = useState('');

    const [CEP, setCEP] = useState('');
    const [statusCEP, setStatusCEP] = useState('');

    const [state, setState] = useState({ nome: '', sigla: '' });
    const [statusState, setStatusState] = useState('');

    const [city, setCity] = useState('');
    const [statusCity, setStatusCity] = useState('');

    const [cities, setCities] = useState<any[]>([]);
    const [showForm, setShowForm] = useState(false)
    const [loadingCPF, setLoadingCPF] = useState(false)
    const [disabledByCEP, setDisabledByCEP] = useState(false)

    const handleStates = (event: any, newValue: any) => {
        const stateInitials = newValue.sigla || newValue;

        if (statusState) {
            setStatusState('');
        }
        if (city) {
            setCity('');
        }

        estadosCidades.forEach((element: { nome: string, sigla: string, cidades: any[] }) => {
            if (element.sigla === stateInitials) {
                setCities(element.cidades);
                setState({
                    nome: element.nome,
                    sigla: element.sigla,
                });
            }
        });
    };

    const submitForm = async () => {
        setLoading(true);
        schemaSalesCounterCreate
            .validate(
                {
                    tradeName,
                    responsibleName,
                    CPF,
                    cellphone,
                    email,
                    password: type === "create" ? password : password !== "" ? password : "123456",
                    address,
                    addressNumber,
                    district,
                    CEP,
                    city,
                    state,
                },
                { abortEarly: false },
            )
            .then(() => {
                (async () => {
                    let data: {
                        trade_name?: string,
                        responsible_name?: string,
                        responsible_cpf?: string,
                        cellphone: string,
                        email: string,
                        password?: string,
                        address: string,
                        address_number: string,
                        district: string,
                        city: string,
                        state: string,
                        postal_code: string,
                        address_complement?: string
                    } = {
                        trade_name: tradeName,
                        responsible_name: responsibleName,
                        responsible_cpf: CPF,
                        cellphone,
                        email,
                        password,
                        address,
                        address_number: addressNumber,
                        district,
                        city,
                        state: state.sigla,
                        postal_code: CEP,
                        address_complement: addressComplement
                    };

                    if (!addressComplement) {
                        delete data.address_complement
                    }

                    if (!tradeName) {
                        delete data.trade_name
                    }

                    if (type === "update") {
                        delete data.responsible_cpf
                    }

                    if (password === "") {
                        delete data.password
                    }

                    (type === "update" ? RemoteServices.filiais.UpdateBranch(data) : RemoteServices.filiais.UpdateNew(data))
                        .then(({ response, status }) => {
                            console.log(response, status)
                            if (status > 201) {
                                if (response.point === "validation") {
                                    handleValidationError({
                                        inner: response.message
                                    });
                                    return
                                } else {
                                    Toast({
                                        type: 'error',
                                    }, response?.message ?? response?.errors[0] ?? 'Não foi possível Criar nova filial. Verifique os dados e tente novamente.!');
                                    return
                                }

                            } else {
                                setOpenModal(false)
                                setOpenModalUpdate(false)
                                setOpenModalWhatsApp(true)

                            }

                        })
                        .catch((error) => {
                            console.log(error, 'aqui')
                            Toast({
                                type: 'warning',
                            }, error?.message ?? error?.errors[0] ?? 'Não foi possível Criar nova filial. Verifique os dados e tente novamente..');
                        })
                        .finally(() => setLoading(false))
                })();
            })
            .catch((error) => {
                setLoading(false);
                handleValidationError(error);
                Toast(
                    {
                        type: 'warning',
                    }, 'Não foi possível Criar nova filial. Verifique os dados e tente novamente.',
                );
            });
    };


    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'tradeName':
                    setStatusTradeName(element.message);
                    break;
                case 'responsibleName':
                    setStatusResponsibleName(element.message);
                    break;
                case 'CPF':
                    setStatusCPF(element.message);
                    break;
                case 'cellphone':
                    setStatusCellphone(element.message);
                    break;
                case 'email':
                    setStatusEmail(element.message);
                    break;
                case 'password':
                    setStatusPassword(element.message);
                    break;
                case 'address':
                    setStatusAddress(element.message);
                    break;
                case 'addressNumber':
                    setStatusAddressNumber(element.message);
                    break;
                case 'district':
                    setStatusDistrict(element.message);
                    break;
                case 'CEP':
                    setStatusCEP(element.message);
                    break;
                case 'city':
                    setStatusCity(element.message);
                    break;
                case 'state.sigla':
                    setStatusState(element.message);
                    break;
                default:
                    break;
            }
        });
    };

    const consultCPF = async (cpf: string, init: boolean = false) => {
        setLoadingCPF(true)
        await RemoteServices.proposals.ConsultLocalizeWithoutBirthday({
            cpf
        }).then((res) => {
            if (res.response.result) {
                let result = res.response.result
                setResponsibleName(result.name)
                setStatusResponsibleName("")
                setStatusCPF("")
                if (!init) {
                    setShowForm(true)
                }
            } else {
                if (!init) {
                    setStatusCPF(res.response.message.resposta)
                    setShowForm(false)
                }
            }
        }).finally(() => setLoadingCPF(false))
    }

    function getCep(cep: string) {
        const formatCep = cep.replace(/[^\d]/g, "")
        RemoteServices.external.GetCep({
            formatCep
        }).then(async ({ response }) => {
            if (!response?.erro) {
                const state = estadosCidades.find((item) => item.sigla === response?.uf)

                if (state && response?.localidade) {
                    setState({ nome: state?.nome ?? '', sigla: state?.sigla ?? '' })
                    setCity(response?.localidade)
                    setDisabledByCEP(true)
                }
                if (statusState) setStatusState('')
                if (statusCity) setStatusCity('')
                if (statusDistrict) setStatusDistrict('')
                if (statusAddress) setStatusAddress('')
            } else {
                setCity('')
                setState({ nome: '', sigla: '' })
                setDisabledByCEP(false)
            }
        })
    }

    const refuse = async () => {
        await RemoteServices.filiais.RefuseUpdate().then(() => {
            window.location.href = '/'
        })
    }

    const setData = async () => {
        console.log(UserOperatorSales)
        setCPF(UserOperatorSales?.responsible_cpf as string)
        setResponsibleName(UserOperatorSales?.responsible_name as string)
        getCep(UserOperatorSales?.postal_code as string)
        setCEP(UserOperatorSales?.postal_code.replace(".", "") as string)
        setEmail(UserOperatorSales?.email as string)
        setAddressNumber(UserOperatorSales?.address_number as string)
        setAddress(UserOperatorSales?.address as string)
        setDistrict(UserOperatorSales?.district as string)
        if (UserOperatorSales?.trade_name) setTradeName(UserOperatorSales?.trade_name as string)
        if (UserOperatorSales?.address_complement) setAddressComplement(UserOperatorSales?.address_complement as string)
        setPassword("")
        setType("update")
        setOpenModal(true)

        consultCPF(UserOperatorSales?.responsible_cpf as string, true)
    }

    return (
        <>
            {UserOperatorSales && <ModalUI open={openModalUpdate} handleClose={() => { }} hiddenButtonClose>
                <Grid container xs={12} mt={4} display={"flex"} alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
                    <Typography variant="h5" fontWeight={"bold"}>ATUALIZAÇÃO CADASTRAL 2024</Typography>
                    <Grid container xs={12} display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
                        <Typography variant="h6" mt={4}>Você é: {UserOperatorSales?.responsible_name?.toUpperCase()}?<br /> CPF: {UserOperatorSales?.responsible_cpf}?</Typography>

                        <ButtonUI variant="contained" onClick={setData}><p style={{
                            margin: '0px'
                        }}>SIM, sou {UserOperatorSales?.responsible_name?.toUpperCase()} <br />
                            Irei <u>ATUALIZAR MEU CADASTRO AGORA</u></p></ButtonUI>

                        {canRefuseUpdate && <ButtonUI variant="outlined" onClick={refuse}><p style={{
                            margin: '0px'
                        }}>SIM, sou {UserOperatorSales?.responsible_name?.toUpperCase()} <br />
                            Irei <u>ATUALIZAR MEU CADASTRO MAIS TARDE</u></p></ButtonUI>}

                        <ButtonUI variant="contained" color="warning" onClick={() => {
                            setOpenModal(true)
                            setType("create")
                        }}> <p style={{ margin: '0px' }}><u>NÃO SOU</u> <br /> {UserOperatorSales?.responsible_name?.toUpperCase()}
                            </p></ButtonUI>
                    </Grid>
                </Grid>
            </ModalUI>}

            <ModalUI open={openModal} handleClose={() => { }} hiddenButtonClose>
                <Grid container xs={12} mt={4} gap={2}>
                    {type === "create" && <Grid container xs={12} display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography
                            textTransform={"uppercase"}
                            color={colors.black}
                            variant="caption">Correspondente matriz - {UserOperatorSales?.owner_trade_name}</Typography>
                        <Typography
                            textTransform={"uppercase"}
                            color={colors.black}
                            variant="h5"
                            fontWeight={"bold"}>Cadastro de Filial</Typography>
                    </Grid>}
                    <Grid container xs={12} gap={2} justifyContent={"flex-start"}>
                        <Grid container md={5.8} lg={5.8} xs={12}>
                            <FormControl fullWidth error={!!statusCPF} variant="outlined">
                                <InputLabel htmlFor="counterCPF">
                                    CPF do responsável
                                </InputLabel>
                                <OutlinedInput
                                    disabled={showForm || type === "update"}
                                    id="counterCPF"
                                    label="CPF do responsável"
                                    inputComponent={CPFMaskCustom}
                                    value={CPF}
                                    onChange={(event) => {
                                        if (statusCPF) {
                                            setStatusCPF('');
                                        }
                                        setCPF(event.target.value);
                                        if (event.target.value.length === 14) {
                                            consultCPF(event.target.value)
                                        }
                                    }}
                                    aria-describedby="component-error-cpf"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {loadingCPF && <CircularProgress size={24} />}
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-cpf">
                                    {statusCPF}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container md={5.8} lg={5.8} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label="Nome do responsável"
                                variant="outlined"
                                value={responsibleName}
                                error={!!statusResponsibleName}
                                helperText={statusResponsibleName}
                                onChange={(event) => {
                                    if (statusResponsibleName) {
                                        setStatusResponsibleName('');
                                    }
                                    setResponsibleName(event.target.value);
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container md={5.8} lg={5.8} xs={12}>
                            <TextField
                                fullWidth
                                disabled={!showForm && type === "create"}
                                label="Nome fantasia"
                                variant="outlined"
                                value={tradeName}
                                error={!!statusTradeName}
                                helperText={statusTradeName !== "" ? statusTradeName : "(Não obrigatório)"}
                                onChange={(event) => {
                                    if (statusTradeName) {
                                        setStatusTradeName('');
                                    }
                                    setTradeName(event.target.value);
                                }} />
                        </Grid>
                        <Grid container md={5.8} lg={5.8} xs={12}>
                            <FormControl
                                fullWidth
                                error={!!statusCellphone}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="operatorCellphone">
                                    Whatsapp
                                </InputLabel>
                                <OutlinedInput
                                    autoComplete="off"
                                    disabled={!showForm && type === "create"}
                                    id="operatorCellphone"
                                    label="Whatsapp"
                                    inputComponent={CellphoneMaskCustom}
                                    value={cellphone}
                                    onChange={(event) => {
                                        if (statusCellphone) {
                                            setStatusCellphone('');
                                        }
                                        setCellphone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <WhatsApp color="success" />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-cellphone">
                                    {statusCellphone}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} md={2.70} lg={2.82}>
                            <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                <InputLabel htmlFor="cep">CEP</InputLabel>
                                <OutlinedInput
                                    id="cep"
                                    label="CEP"
                                    inputComponent={CEPMaskCustom}
                                    disabled={!showForm && type === "create"}
                                    value={CEP}
                                    onChange={(event) => {
                                        if (statusCEP) {
                                            setStatusCEP('');
                                        }
                                        setCEP(event.target.value);
                                        if (event.target.value.replace(/[^\d]/g, "").length === 8) {
                                            getCep(event.target.value)
                                        }
                                    }}
                                    aria-describedby="component-error-cep"
                                />
                                <FormHelperText>{statusCEP}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container md={2.70} lg={2.82} xs={12}>
                            <Autocomplete
                                fullWidth
                                // @ts-ignore
                                value={state}
                                disabled={(!showForm && type === "create") || disabledByCEP}
                                options={estadosCidades}
                                noOptionsText="Estado não encontrado"
                                onChange={handleStates}
                                disableClearable
                                getOptionLabel={(option: { nome: string; sigla: string; cidades: string[]; }) => option.nome || ""}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Estado"
                                        variant="outlined"
                                        error={!!statusState}
                                        helperText={statusState}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={12} md={2.70} lg={2.82}>
                            <Autocomplete
                                fullWidth
                                disabled={(!showForm && type === "create") || disabledByCEP}
                                options={cities}
                                noOptionsText="Cidade não encontrada"
                                value={city}
                                onChange={(event, newValue) => {
                                    if (statusCity) {
                                        setStatusCity('');
                                    }
                                    setCity(newValue);
                                }}
                                autoHighlight
                                disableClearable
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Cidade"
                                        variant="outlined"
                                        error={!!statusCity}
                                        helperText={statusCity}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container md={2.70} lg={2.82} xs={12}>
                            <TextField
                                fullWidth
                                disabled={!showForm && type === "create"}
                                label="Bairro"
                                variant="outlined"
                                value={district}
                                error={!!statusDistrict}
                                helperText={statusDistrict}
                                onChange={(event) => {
                                    if (statusDistrict) {
                                        setStatusDistrict('');
                                    }
                                    setDistrict(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} md={3.73} lg={3.81}>
                            <TextField
                                disabled={!showForm && type === "create"}
                                fullWidth
                                label="Logradouro"
                                variant="outlined"
                                value={address}
                                error={!!statusAddress}
                                helperText={statusAddress}
                                onChange={(event) => {
                                    if (statusAddress) {
                                        setStatusAddress('');
                                    }
                                    setAddress(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container xs={12} md={3.73} lg={3.81}>
                            <TextField
                                disabled={!showForm && type === "create"}
                                fullWidth
                                label="Número"
                                variant="outlined"
                                value={addressNumber}
                                error={!!statusAddressNumber}
                                helperText={statusAddressNumber}
                                onChange={(event) => {
                                    if (statusAddressNumber) {
                                        setStatusAddressNumber('');
                                    }
                                    setAddressNumber(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container md={3.73} lg={3.81} xs={12}>
                            <TextField
                                disabled={!showForm && type === "create"}
                                fullWidth
                                label="Complemento"
                                variant="outlined"
                                value={addressComplement}
                                helperText={"(Não obrigatório)"}
                                onChange={(event) => {
                                    setAddressComplement(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" color={colors.black}>DADOS PARA ACESSO</Typography>
                    <Grid container xs={12} gap={2}>
                        <Grid item md={5.8} lg={5.8} xs={12}>
                            <TextField
                                disabled={!showForm && type === "create"}
                                fullWidth
                                type="email"
                                label="E-mail"
                                variant="outlined"
                                value={email}
                                error={!!statusEmail}
                                helperText={statusEmail}
                                onChange={(event) => {
                                    if (statusEmail) {
                                        setStatusEmail('');
                                    }
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item md={5.8} lg={5.8} xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusPassword}
                            >
                                <InputLabel htmlFor="password">Senha</InputLabel>
                                <OutlinedInput
                                    disabled={!showForm && type === "create"}
                                    id="password"
                                    autoComplete="off"
                                    label="Senha"
                                    value={password}
                                    onChange={(e) => {
                                        if (statusPassword) {
                                            setStatusPassword('');
                                        }
                                        setPassword(e.target.value);
                                    }}
                                    type={passwordIsVisible ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setPasswordIsVisible(!passwordIsVisible);
                                                }}
                                                edge="end"
                                            >
                                                {passwordIsVisible ? (
                                                    <FiEye />
                                                ) : (
                                                    <FiEyeOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{type === "update" ? statusPassword !== "" ? statusPassword : "Só informe se desejar alterar" : statusPassword}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid xs={12} md={11.8} lg={11.8} mt={2} container justifyContent="flex-end" gap={2}>
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            onClick={() => {
                                setOpenModal(false)
                            }}
                            variant="outlined"
                            disableElevation
                            sx={{ p: 2, maxWidth: 240, width: '100%', justifySelf: "flex-end" }}>
                            Voltar
                        </LoadingButton>
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            onClick={submitForm}
                            variant="contained"
                            disableElevation
                            sx={{ p: 2, maxWidth: 240, width: '100%', justifySelf: "flex-end" }}>
                            {type === "update" ? "Atualizar" : "Cadastrar"}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </ModalUI>

            <ModalUI open={openModalWhatsApp} handleClose={() => { }} hiddenButtonClose>
                <Grid item xs={12} mt={matchesMaxMobile ? 7 : 3}>
                    <Typography textAlign={"center"} variant="h6" mb={2}>Estamos aguardando sua confirmação!</Typography>
                    <Typography textAlign={"center"}>Enviamos uma mensagem para seu WhatsApp. <br />
                        Verifique a mensagem e confirme sua identificação para autorizarmos o acesso à nossa plataforma.</Typography>
                    <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={matchesMaxMobile ? "center" : "flex-end"} mt={3}>
                        <ButtonUI variant="contained" onClick={() => {
                            window.open(`https://api.whatsapp.com/send?phone=554187227846`, '_blank')
                        }}>Confirmar no WhatsApp</ButtonUI>
                    </Grid>
                </Grid>
            </ModalUI>
        </>
    )
}