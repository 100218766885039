import { Grid, Skeleton } from "@mui/material"

const SalesTableSkeleton = () => {
    return (
        <Grid container gap={2} justifyContent={"center"} alignItems={"center"} mt={3}>
            <Grid container maxWidth={400} justifyContent={"center"} alignItems={"center"}>
                <Skeleton variant="rounded" width={200} height={30} />
                <Skeleton variant="rounded" width={400} height={300} />
            </Grid>
            <Grid container maxWidth={400} justifyContent={"center"} alignItems={"center"}>
                <Skeleton variant="rounded" width={200} height={30} />
                <Skeleton variant="rounded" width={400} height={300} />
            </Grid>
            <Grid container maxWidth={400} justifyContent={"center"} alignItems={"center"}>
                <Skeleton variant="rounded" width={200} height={30} />
                <Skeleton variant="rounded" width={400} height={300} />
            </Grid> <Grid container maxWidth={400} justifyContent={"center"} alignItems={"center"}>
                <Skeleton variant="rounded" width={200} height={30} />
                <Skeleton variant="rounded" width={400} height={300} />
            </Grid>
            <Grid container maxWidth={400} justifyContent={"center"} alignItems={"center"}>
                <Skeleton variant="rounded" width={200} height={30} />
                <Skeleton variant="rounded" width={400} height={300} />
            </Grid>
            <Grid container maxWidth={400} justifyContent={"center"} alignItems={"center"}>
                <Skeleton variant="rounded" width={200} height={30} />
                <Skeleton variant="rounded" width={400} height={300} />
            </Grid>
        </Grid>
    )
}

export default SalesTableSkeleton;