import { IconButton, TextField } from '@mui/material'
import { IPosts } from '..'
import style from './style.module.css'
import VaptIcon from 'assets/vaptpost/vaptpoint.png'
import { HiDownload, HiSearch } from "react-icons/hi";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { VaptPostCard } from '../card';


export const VaptPostGallery = ({
    posts,
    vaptpoints,
    link
}: {
    posts: IPosts[],
    vaptpoints: number,
    link: string
}) => {
    const [search, setSearch] = useState("")

    return (
        <>
            <div className={style.containerFilter}>
                <div className={style.containerTitle} id='gallery'>
                    <h1>Minha Galeria</h1>
                    <div className={style.containerVapt}>
                        <div>
                            <img src={VaptIcon} alt="VaptIcon" />
                        </div>
                        <p>{vaptpoints} VaptPoints</p>
                    </div>
                </div>
                <div className={style.search}>
                    <TextField
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder='Pesquisar'
                        InputProps={{
                            startAdornment: <HiSearch size={24} />,
                            sx: {
                                borderRadius: '6px',
                            }
                        }}
                        size='small'
                    />
                </div>
            </div>
            <div className={style.containerPosts}>
                {(search !== "" ? posts.filter((p) => p.description.toLowerCase().includes(search.toLowerCase())) : posts).map((post, index) => {
                    return (
                        <VaptPostCard description={post.description} download={true} image={post.image} index={index} key={index} />
                    )
                })}
                {(search !== "" ? posts.filter((p) => p.description.toLowerCase().includes(search.toLowerCase())) : posts).length === 0 &&
                    <p className={style.noContent}>{search !== "" ? "Nenhum post correspondente à pesquisa..." : "Você ainda não gerou um post..."}</p>}
                {(posts.length === 0 && search === "") &&
                    <div className={style.newPost}>
                        <Link to={link} target="_blank">
                            <button>Gerar Meu Primeiro Post</button>
                        </Link>
                    </div>
                }

            </div>
        </>
    )
}