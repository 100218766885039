import { Remote } from "service/remote";

export const StatisticsAdmin = async (data: any) => {
    return await Remote({
        method: "post",
        urn: "/data/dashboard/admin",
        api: 'data-api',
        data
    })

}

export default StatisticsAdmin;