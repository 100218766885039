import { Remote } from "service/remote";

export const CommissionTax = async (userId?:string) => {
    return await Remote({
        method: "get",
        urn: `online/commissions/tax/${userId ?? ''}`
    })

}

export default CommissionTax;