import { Remote } from "service/remote";

export const Update = async (data:any) => {
    return await Remote({
        method: "post",
        urn: "/online/branch/update",
        data
    })

}

export default Update;