import styled from "@emotion/styled";
import { Box, StepConnector, Stepper, stepConnectorClasses } from "@mui/material";
import { useEffect, useState } from "react";
import 'react-step-progress/dist/index.css';
import { StatuSteps } from "./status";
import { ProposalRR } from "types/proposal";
import useMediasQuerys from "hooks/mediasQuery";

const Content = <div></div>;

declare enum StepStates {
    NOT_STARTED = "not_started",
    CURRENT = "current",
    ERROR = "error",
    COMPLETED = "completed"
}


const StepState = {
    NOT_STARTED: "not_started",
    CURRENT: "current",
    ERROR: "error",
    COMPLETED: "completed"
}

const StepProgress = ({
    status: _s,
    complete,
    proposal: _p
}: {
    status: number,
    complete: boolean,
    proposal: ProposalRR
}) => {
    const { matchesMaxMobile } = useMediasQuerys()
    const propostasElementsSteps = [
        {
            label: 'Dados cadastrais (Anti-fraude)',
            subtitle: '',
            name: '',
            content: Content
        },
        {
            label: 'Cartão de Crédito',
            subtitle: '',
            name: '',
            content: Content
        },
        {
            label: 'Dados bancários',
            subtitle: '',
            name: '',
            content: Content
        },
        {
            label: 'Captura de fotos',
            subtitle: '',
            name: '',
            content: Content
        },
        {
            label: 'Assinatura CCB',
            subtitle: '',
            name: '',
            content: Content
        },
        {
            label: 'Pagamento',
            subtitle: '',
            name: '',
            content: Content,
            state: StepState.COMPLETED as StepStates
        },
    ]
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#4db193 !important',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#4db193',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: matchesMaxMobile ? 30 : 3,
            border: 0,
            width: matchesMaxMobile ? 2 : "auto",
            marginLeft: matchesMaxMobile ? 15 : 0,
            backgroundColor:
                //@ts-ignore
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
        [`& .${stepConnectorClasses.lineVertical}`]: {
           height: 50,
           bottom: -10
        },
    }));
    const [proposal, setProposal] = useState(_p)
    const [status, setStatus] = useState(_s)

    useEffect(() => {
        console.log(status)
        const element = document.getElementsByClassName('step-progress') as unknown as any;
        if (element && element[6]) {
            element[6].style.display = 'none'
        }
    }, [status, complete])

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={status} alternativeLabel={!matchesMaxMobile} connector={<ColorlibConnector />} orientation={matchesMaxMobile ? "vertical" : "horizontal"}>
                    {propostasElementsSteps.map((item, i) => <StatuSteps index={i} label={item.label} status={status} proposal={proposal} />)}
                </Stepper>
            </Box>
        </>
    )
}

export default StepProgress; 