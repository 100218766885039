import { Remote } from "service/remote";

export const GenerateToken = async () => {
    return await Remote({
        method: "post",
        urn: "/online/branch/token",
    })

}

export default GenerateToken;