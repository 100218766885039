import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import RemoteServices from "service";
import storageLocales from "utils/storageLocales";
import URLSBases from "utils/urlBases";
import UserStorage from "./storage/user";



const gateway = axios.create({
    baseURL: URLSBases.apisTc
});

export const gatewayMAneger = axios.create({
    baseURL: URLSBases.utils
});

export const gatewayDataApi = axios.create({
    baseURL: URLSBases.dataApi
});

export const gatewayAuthDataApi = axios.create({
    baseURL: URLSBases.authApi
});

async function intercepter(config: InternalAxiosRequestConfig<any>) {
    const token = localStorage.getItem(storageLocales.TCtoken);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}


gateway.interceptors.response.use(e => e, async reject => {
    const canRedirect = !window.location.href.includes('auto-cadastro')

    if (reject.response?.status === 401) {
        if (document.location.search?.slice(1, 3) === 'r=') {
            return
        }
        if (reject.response.data?.error === "Envie um header Authorization") {
            canRedirect && document.location.replace(URLSBases.LOGIN)
        }
        if (reject.response.data?.error === "Signature has expired" || reject.response.data?.status === "error" || reject.response.data?.error === "jwt expired") {
            const User = localStorage.getItem(storageLocales.TCUser)
            if (User) {
                RemoteServices.auth.Login(JSON.parse(User)?.refresh_token)
                    .then(({ response }) => {
                        if (response?.access_token) {
                            axios.defaults.headers.Authorization = `Bearer ${response?.access_token}`;
                            UserStorage.saveUserTc(response)
                            UserStorage.saveToken(response?.access_token)
                            document.location.reload()
                        } else {
                            canRedirect &&document.location.replace(URLSBases.LOGIN)
                        }

                    }).catch(err => {
                    })
            } else {
                canRedirect && document.location.replace(URLSBases.LOGIN)
            }
        }
    }
    return Promise.reject(reject);
})

gateway.interceptors.request.use(intercepter);
gatewayMAneger.interceptors.request.use(intercepter);
gatewayAuthDataApi.interceptors.request.use(intercepter);
gatewayDataApi.interceptors.request.use(intercepter);

export default gateway;