import { Remote } from "service/remote";

export const GetUpdate = async () => {
    return await Remote({
        method: "get",
        urn: "/online/branch/update",
    })

}

export default GetUpdate;