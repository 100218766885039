import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { colors } from 'theme';
import { Grid, IconButton, SxProps, Theme, Typography } from '@mui/material';

import { FiX } from "react-icons/fi"
import useMediasQuerys from 'hooks/mediasQuery';

export default function ModalUI({
    open,
    handleClose,
    children,
    sxPapper,
    hiddenButtonClose,
    isChat
}: {
    open: boolean,
    handleClose: () => void;
    children: JSX.Element,
    sxPapper?: SxProps<Theme>,
    hiddenButtonClose?: boolean,
    isChat?: boolean
}) {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    return (
        <Modal
            disableAutoFocus
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: colors.Background,
                        boxShadow: 24,
                        p: matchesMaxMobile ? 2 : 4,
                        borderRadius: matchesMaxMobile ? 0 : 5,
                        maxHeight: matchesMaxMobile ? '100vh' : '90vh',
                        overflowY: 'auto',
                        maxWidth: 1320,
                        ...(matchesMaxMobile && { width: '100vw', height: '100vh' })
                        , ...(sxPapper && sxPapper)
                    }}>
                    {
                        !hiddenButtonClose &&
                        <Grid sx={{ position: 'absolute', top: isChat ? 0 : 30, right: 32 }}>
                            <IconButton onClick={handleClose} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                {isChat && <Typography color={"red"} variant={"subtitle2"}>Fechar</Typography>}
                                <FiX color={colors.black} />
                            </IconButton>
                        </Grid>
                    }
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
}