import { Grid, Typography } from "@mui/material";
import styles from './styles.module.css'

export function TableListGestorsUI({
    data
}: {
    data?: Array<{
        "id": number,
        "name": string,
        "phone": string,
        "email": string,
        "active": boolean
    }>
}) {
    return (
        <Grid sx={{
            width: '200px',
            maxHeight: '80px',
            overflow: 'auto',
        }}
        className={styles.container}
        >
            {data ? data.map((item) => {
                return <Typography fontSize={14}>{item.id} - {item.name}</Typography>
            }) : <Typography fontSize={14}>Nenhum gestor foi atribuído</Typography>}
        </Grid>
    )
}