import { LoadingButton, LoadingButtonProps } from "@mui/lab";

const ButtonUI = (props: LoadingButtonProps) => (
    <LoadingButton 
    {...props}
    disableElevation
    sx={{
        minWidth: 180,
        p: 2,
        ...(props?.sx && props.sx )
    }}>
        {props.children}
    </LoadingButton>
)

export default ButtonUI;