import { useEffect, useState } from "react";
import RemoteServices from "service";
import style from './styles.module.css'
import logo from 'assets/vaptpost/logo.png'
import { VaptPostCarrousel } from "./carrousel";
import { VaptPostCarrouselNewPosts } from "./carrouselNewPosts";
import { VaptPostGallery } from "./gallery";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from "@mui/material";
import ModalUI from "components/@ui/modal";
import { LoadingButton } from "@mui/lab";
import ButtonUI from "components/@ui/button";
import { Link, useNavigate } from "react-router-dom";
import Toast from "components/@ui/toast";

export interface IPosts {
    user: string,
    created_date: string,
    image: string,
    status: string,
    description: string,
}

const Advertising = () => {
    const [myPosts, setMyPosts] = useState<IPosts[]>([])
    const [postOptions, setpostOptions] = useState<IPosts[]>([])
    const [vaptPoints, setVaptPoints] = useState(0)
    const [terms, setTerms] = useState(false)
    const [openTerms, setOpenTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const nagivate = useNavigate()

    const [link, setLink] = useState("ttps://app.vaptpost.com.br/")

    const getPosts = () => {
        RemoteServices.marketing.getMyPosts().then(({ response }) => {
            console.log(response)
            if (response?.result) {
                setMyPosts(response?.result?.posts)
                setVaptPoints(response?.result?.vaptpoints)
                getLoginLink()
            } else if (response?.point === "notRegistered") {
                setOpenTerms(true)
            } else {
                Toast({
                    type: "error"
                }, response?.message ?? "Falha ao buscar posts do usuário!")
            }
        }).catch((err) => {
            console.log(err)
        })

        RemoteServices.marketing.getOptionsPost().then(({ response }) => {
            console.log(response)
            setpostOptions(response?.result?.posts)
        })
    }

    const getLoginLink = () => {
        RemoteServices.marketing.getLoginLink().then(({ response }) => {
            console.log(response)
            setLink(response.result)
        })
    }

    const createUser = () => {
        setLoading(true)
        RemoteServices.marketing.createUser()
            .then(({
                response
            }) => {
                if (response?.success) {
                    getPosts()
                    setOpenTerms(false)
                }
            })
            .catch((err) => {
                Toast({
                    type: "error"
                }, "Falha ao criar usuário!")
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getPosts()
    }, [])


    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
            }}>
                <div className={style.contentAyude}>
                    <div className={style.logo} >
                        <img src={logo} alt="logo vaptpost" />
                    </div>

                    <div className={style.description}>
                        <p className={style.title}>
                            Gere e Agende posts para seu Instagram <span className={style.emphasis}>com um Clique!</span>
                        </p>
                        <p className={style.subtitle}><strong>Conteúdos profissionais e personalizados</strong> com sua logo, <strong>em poucos segundos</strong>, sem precisar perder tempo com edição.</p>
                    </div>

                    <h1 className={style.contentTitle}>CONTEÚDOS AYUDE</h1>
                    <VaptPostCarrousel />
                    <div className={style.containerButtons}>
                        <a href={"#new-post"} >
                            <button>Gerar Conteúdos com a Minha Marca</button>
                        </a>
                        <a href={"#gallery"}>
                            <button>Meus Posts Gerados</button>
                        </a>

                    </div>
                </div>
                <div className={style.containerNewPost} id="new-post">
                    <p className={style.newPostTitle}>Gere Novos Posts</p>
                    <VaptPostCarrouselNewPosts posts={postOptions} link={link}/>
                </div>
                <VaptPostGallery posts={myPosts} vaptpoints={vaptPoints} link={link}/>
            </div>
            <ModalUI open={openTerms} handleClose={() => { nagivate(-1) }}>
                <Grid p={4} container gap={2}>
                    <Typography variant="h4" fontWeight="bold">TERMOS DE USO</Typography>
                    <Grid xs={12} sx={{
                        height: 400
                    }}>
                        <iframe src="https://vaptpost.com.br/termos-de-uso/" style={{
                            width: '100%',
                            height: '100%'
                        }} />
                    </Grid>
                    <FormControlLabel control={<Checkbox checked={terms} onChange={(e) => setTerms(e.target.checked)} />} label="Concordo que li e estou de acordo com os termos de uso." />
                    <Grid container justifyContent={"flex-end"}>
                        <ButtonUI variant="contained" loading={loading} disabled={!terms} onClick={createUser}>Continuar</ButtonUI>
                    </Grid>
                </Grid>
            </ModalUI>
        </>
    )
}

export default Advertising;