import { Remote } from "service/remote";

export const GetAccountPIX = async () => {
    return await Remote({
        method: "get",
        urn: `online/commissions/clerk-account`
    })

}

export const SaveAccountPIX = async (data: {
    type_key_pix: string,
    key_pix: string,
    cellphone: string
}) => {
    return await Remote({
        method: "post",
        urn: `online/commissions/clerk-account`,
        data
    })
}

export const UpdateAccountForBanner = async (data: {
    address: string,
    address_number: string,
    district: string,
    cep: string,
    city: string,
    state: string,
    address_complement?: string
}) => {
    return await Remote({
        method: "post",
        urn: `online/commissions/update-address`,
        data
    })

}

export default GetAccountPIX;