import { Remote } from "service/remote";

export const List = async (data?: {
    page: number,
    limit: number,
}) => {
    return await Remote({
        method: "get",
        urn: "/sales-counters",
        params:{
            ...data,
            page: data?.page ?? 1,
            limit: data?.limit ?? 10
        },
        api: 'manager'
    })

}

export default List;