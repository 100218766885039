import { LoadingButton } from "@mui/lab";
import { Autocomplete, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material"
import TooltipUI from "components/@ui/Tooltip";
import { CEPMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from "components/@ui/masked";
import Breadcrumb from "components/breadcrumbs"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import RemoteServices from "service";
import { colors } from "theme";
import estadosCidades from 'assets/json/estados_cidades.json'
import { schemaSalesCounter, schemaSalesCounterCreate, schemaSalesCounterUpdate } from "@utils/validator";
import Toast from "components/@ui/toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { awaitTime } from "utils/awaitTime";

const EditFilial = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [awatingData, setAwatingData] = useState(true);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const [counterIdentification, setCounterIdentification] = useState('');
    const [tradeName, setTradeName] = useState('');
    const [statusTradeName, setStatusTradeName] = useState('');

    const [responsibleName, setResponsibleName] = useState('');
    const [statusResponsibleName, setStatusResponsibleName] = useState('');

    const [CPF, setCPF] = useState('');
    const [statusCPF, setStatusCPF] = useState('');

    const [CNPJ, setCNPJ] = useState('');
    const [statusCNPJ, setStatusCNPJ] = useState('');

    const [cellphone, setCellphone] = useState('');
    const [statusCellphone, setStatusCellphone] = useState('');

    const [email, setEmail] = useState('');
    const [statusEmail, setStatusEmail] = useState('');

    const [password, setPassword] = useState('');
    const [statusPassword, setStatusPassword] = useState('');

    const [address, setAddress] = useState('');
    const [statusAddress, setStatusAddress] = useState('');

    const [addressNumber, setAddressNumber] = useState('');
    const [statusAddressNumber, setStatusAddressNumber] = useState('');

    const [district, setDistrict] = useState('');
    const [statusDistrict, setStatusDistrict] = useState('');

    const [addressComplement, setAddressComplement] = useState('');

    const [CEP, setCEP] = useState('');
    const [statusCEP, setStatusCEP] = useState('');

    const [state, setState] = useState({ nome: '', sigla: '' });
    const [statusState, setStatusState] = useState('');

    const [city, setCity] = useState('');
    const [statusCity, setStatusCity] = useState('');

    const [cities, setCities] = useState<any[]>([]);

    const [disabledCNPJ, setDisabledCNPJ] = useState(true)
    const [loadingCNPJ, setLoadingCNPJ] = useState(false)
    const [disableTradeName, setDisableTradeName] = useState(true)


    const handleStates = (event: any, newValue: any) => {
        const stateInitials = newValue.sigla || newValue;

        if (statusState) {
            setStatusState('');
        }
        if (city) {
            setCity('');
        }

        estadosCidades.forEach((element: { nome: string, sigla: string, cidades: any[] }) => {
            if (element.sigla === stateInitials) {
                setCities(element.cidades);
                setState({
                    nome: element.nome,
                    sigla: element.sigla,
                });
            }
        });
    };


    useEffect(() => {
        RemoteServices.filiais.SalesCounter({ id: location.state?.sales_counter?.id })
            .then(({ response }) => {
                const { sales_counter: salesCounter } = response.data;
                setCounterIdentification(salesCounter.identification_name);
                setTradeName(salesCounter.trade_name);
                setResponsibleName(salesCounter.responsible_name);
                setCPF(salesCounter.responsible_cpf);
                setCNPJ(salesCounter.cnpj);
                setCellphone(salesCounter.cellphone);
                setEmail(salesCounter.email);
                setAddress(salesCounter.address);
                setAddressNumber(salesCounter.address_number);
                setDistrict(salesCounter.district);
                setCEP(salesCounter.postal_code.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2'));
                handleStates({}, salesCounter.state);
                setCity(salesCounter.city);
                setAwatingData(false);

                if (salesCounter.cnpj === null) {
                    setDisabledCNPJ(false)
                }
            })
    }, [])


    const consultCNPJ = async (cnpj: string) => {
        setLoadingCNPJ(true)
        await RemoteServices.proposals.ConsultLocalizeWithoutBirthday({
            cnpj
        }).then((res) => {
            if (!res.response.result?.error) {
                let result = res.response.result
                let value = result?.razao ?? result?.fantasia
                if (value) {
                    setTradeName(value)
                    setDisableTradeName(true)
                } else {
                    setDisableTradeName(false)
                }
            } else {
                setDisableTradeName(false)
                setTradeName("")
            }
        }).catch(() => {
            setDisableTradeName(false)
            setTradeName("")
        }).finally(() => setLoadingCNPJ(false))
    }


    const submitForm = async () => {
        setLoading(true);
        schemaSalesCounterUpdate
            .validate(
                {
                    tradeName,
                    responsibleName,
                    CPF,
                    CNPJ: CNPJ || null,
                    cellphone,
                    email,
                    ...(password && {
                        password
                    }),
                    address,
                    addressNumber,
                    district,
                    CEP,
                    city,
                    state,
                },
                { abortEarly: false },
            )
            .then(() => {
                (async () => {
                    const data: any = {
                        trade_name: tradeName,
                        responsible_name: responsibleName,
                        responsible_cpf: CPF,
                        cellphone,
                        email,
                        address,
                        address_number: addressNumber,
                        district,
                        city,
                        state: state.sigla,
                        postal_code: CEP,
                        password: undefined,
                        cnpj: '',
                        address_complement: '',
                        update_data: true
                    };

                    if (password) {
                        data.password = password as unknown as undefined;
                    }
                    if (CNPJ && CNPJ !== "") {
                        data.cnpj = CNPJ;
                    } else {
                        delete data.cnpj
                    }
                    if (addressComplement) {
                        data.address_complement = addressComplement;
                    }

                    RemoteServices.filiais.SalesCounterUpdate({
                        id: location.state?.sales_counter?.id,
                        data
                    })
                        .then(({ response, status }) => {
                            if (status > 201) {
                                Toast({
                                    type: 'error',
                                }, response?.message ?? 'Não foi possível atualizar os dados informados. Verifique-os e tente novamente.!');
                                return
                            }
                            Toast({
                                type: 'success',
                            }, response?.message ?? 'Dados atualizados com successo!');
                            navigate('/app/filiais')
                        })
                        .catch((error) => {
                            Toast({
                                type: 'warning',
                            }, error?.message ?? 'Não foi possível atualizar os dados informados. Verifique-os e tente novamente.');
                        })
                        .finally(() => setLoading(false))
                })();
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                handleValidationError(error);
                Toast(
                    {
                        type: 'warning',
                    }, 'Não foi possível atualizar os dados informados. Verifique-os e tente novamente.',
                );
            });
    };


    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'tradeName':
                    setStatusTradeName(element.message);
                    break;
                case 'responsibleName':
                    setStatusResponsibleName(element.message);
                    break;
                case 'CPF':
                    setStatusCPF(element.message);
                    break;
                case 'CNPJ':
                    setStatusCNPJ(element.message);
                    break;
                case 'cellphone':
                    setStatusCellphone(element.message);
                    break;
                case 'email':
                    setStatusEmail(element.message);
                    break;
                case 'password':
                    setStatusPassword(element.message);
                    break;
                case 'address':
                    setStatusAddress(element.message);
                    break;
                case 'addressNumber':
                    setStatusAddressNumber(element.message);
                    break;
                case 'district':
                    setStatusDistrict(element.message);
                    break;
                case 'CEP':
                    setStatusCEP(element.message);
                    break;
                case 'city':
                    setStatusCity(element.message);
                    break;
                case 'state.sigla':
                    setStatusState(element.message);
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid px={1} py={2} mt={2}>
                <Typography
                    textTransform={"uppercase"}
                    color={colors.black}
                    variant="h5"
                    fontWeight={"bold"}>Editar filial</Typography>
                <Grid container xs={12} mt={4} gap={2} maxWidth={1280}>
                    <Grid container xs={12} gap={2}>
                        <Grid container md={5} lg={5} xs={12}>
                            <TextField
                                fullWidth
                                disabled={disableTradeName}
                                label="Nome fantasia"
                                variant="outlined"
                                value={tradeName}
                                error={!!statusTradeName}
                                helperText={statusTradeName}
                                onChange={(event) => {
                                    if (statusTradeName) {
                                        setStatusTradeName('');
                                    }
                                    setTradeName(event.target.value);
                                }} />
                        </Grid>
                        <Grid container md={5} lg={5} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label="Nome do responsável"
                                variant="outlined"
                                value={responsibleName}
                                error={!!statusResponsibleName}
                                helperText={statusResponsibleName}
                                onChange={(event) => {
                                    if (statusResponsibleName) {
                                        setStatusResponsibleName('');
                                    }
                                    setResponsibleName(event.target.value);
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container md={3.3} lg={3.3} xs={12}>
                            <TooltipUI title="O campo não é editável">
                                <FormControl fullWidth error={!!statusCPF} variant="outlined">
                                    <InputLabel htmlFor="counterCPF">
                                        CPF do responsável
                                    </InputLabel>
                                    <OutlinedInput
                                        disabled
                                        id="counterCPF"
                                        label="CPF do responsável"
                                        inputComponent={CPFMaskCustom}
                                        value={CPF}
                                        onChange={(event) => {
                                            if (statusCPF) {
                                                setStatusCPF('');
                                            }
                                            setCPF(event.target.value);
                                        }}
                                        aria-describedby="component-error-cpf"
                                    />
                                    <FormHelperText id="component-error-cpf">
                                        {statusCPF}
                                    </FormHelperText>
                                </FormControl>
                            </TooltipUI>
                        </Grid>
                        <Grid container md={3.3} lg={3.3} xs={12}>
                            <FormControl fullWidth error={!!statusCNPJ} variant="outlined">
                                <InputLabel htmlFor="CNPJ">
                                    CNPJ
                                </InputLabel>
                                <OutlinedInput
                                    id="CNPJ"
                                    fullWidth
                                    disabled={disabledCNPJ}
                                    label="CNPJ"
                                    value={CNPJ}
                                    inputComponent={CNPJMaskCustom}
                                    error={!!statusCNPJ}
                                    onChange={(event) => {
                                        if (statusCNPJ) {
                                            setStatusCNPJ('');
                                        }
                                        setCNPJ(event.target.value);
                                        console.log(event.target.value.length)
                                        if (event.target.value.length === 18) {
                                            consultCNPJ(event.target.value)
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {loadingCNPJ && <CircularProgress size={24} />}
                                        </InputAdornment>
                                    }
                                ></OutlinedInput>
                            </FormControl>
                        </Grid>
                        <Grid container md={3.3} lg={3.3} xs={12}>
                            <FormControl
                                fullWidth
                                error={!!statusCellphone}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="operatorCellphone">
                                    Whatsapp
                                </InputLabel>
                                <OutlinedInput
                                    id="operatorCellphone"
                                    label="Whatsapp"
                                    inputComponent={CellphoneMaskCustom}
                                    value={cellphone}
                                    onChange={(event) => {
                                        if (statusCellphone) {
                                            setStatusCellphone('');
                                        }
                                        setCellphone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                />
                                <FormHelperText id="component-error-cellphone">
                                    {statusCellphone !== "" ? statusCellphone : "Se o número for alterado, a filial precisará confirmar via Whatsapp"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} md={3} lg={3}>
                            <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                <InputLabel htmlFor="cep">CEP</InputLabel>
                                <OutlinedInput
                                    id="cep"
                                    label="CEP"
                                    inputComponent={CEPMaskCustom}
                                    value={CEP}
                                    onChange={(event) => {
                                        if (statusCEP) {
                                            setStatusCEP('');
                                        }
                                        setCEP(event.target.value);
                                    }}
                                    aria-describedby="component-error-cep"
                                />
                                <FormHelperText>{statusCEP}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container md={1} lg={1} xs={12}>
                            <Autocomplete
                                fullWidth
                                // @ts-ignore
                                value={state}
                                options={estadosCidades}
                                noOptionsText="Estado não encontrado"
                                onChange={handleStates}
                                disableClearable
                                getOptionLabel={(option: { nome: string; sigla: string; cidades: string[]; }) => option.nome || ""}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Estado"
                                        variant="outlined"
                                        error={!!statusState}
                                        helperText={statusState}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={12} md={3} lg={3}>
                            <Autocomplete
                                fullWidth
                                options={cities}
                                noOptionsText="Cidade não encontrada"
                                value={city}
                                onChange={(event, newValue) => {
                                    if (statusCity) {
                                        setStatusCity('');
                                    }
                                    setCity(newValue);
                                }}
                                autoHighlight
                                disableClearable
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Cidade"
                                        variant="outlined"
                                        error={!!statusCity}
                                        helperText={statusCity}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container md={2.8} lg={2.8} xs={12}>
                            <TextField
                                fullWidth
                                label="Bairro"
                                variant="outlined"
                                value={district}
                                error={!!statusDistrict}
                                helperText={statusDistrict}
                                onChange={(event) => {
                                    if (statusDistrict) {
                                        setStatusDistrict('');
                                    }
                                    setDistrict(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} md={4} lg={4}>
                            <TextField
                                fullWidth
                                label="Logradouro"
                                variant="outlined"
                                value={address}
                                error={!!statusAddress}
                                helperText={statusAddress}
                                onChange={(event) => {
                                    if (statusAddress) {
                                        setStatusAddress('');
                                    }
                                    setAddress(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container xs={12} md={3} lg={3}>
                            <TextField
                                fullWidth
                                label="Número"
                                variant="outlined"
                                value={addressNumber}
                                error={!!statusAddressNumber}
                                helperText={statusAddressNumber}
                                onChange={(event) => {
                                    if (statusAddressNumber) {
                                        setStatusAddressNumber('');
                                    }
                                    setAddressNumber(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container md={2.9} lg={2.9} xs={12}>
                            <TextField
                                fullWidth
                                label="Complemento"
                                variant="outlined"
                                value={addressComplement}
                                onChange={(event) => {
                                    setAddressComplement(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" color={colors.black}>ACESSO</Typography>
                    <Grid container xs={12} gap={2}>
                        <Grid item md={5} lg={5} xs={12}>
                            <TextField
                                fullWidth
                                type="email"
                                label="E-mail"
                                variant="outlined"
                                value={email}
                                error={!!statusEmail}
                                helperText={statusEmail}
                                onChange={(event) => {
                                    if (statusEmail) {
                                        setStatusEmail('');
                                    }
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item md={5} lg={5} xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusPassword}
                            >
                                <InputLabel htmlFor="password">Senha de acesso</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    autoComplete="off"
                                    label="Senha de acesso"
                                    value={password}
                                    onChange={(e) => {
                                        if (statusPassword) {
                                            setStatusPassword('');
                                        }
                                        setPassword(e.target.value);
                                    }}
                                    type={passwordIsVisible ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setPasswordIsVisible(!passwordIsVisible);
                                                }}
                                                edge="end"
                                            >
                                                {passwordIsVisible ? (
                                                    <FiEye />
                                                ) : (
                                                    <FiEyeOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{statusPassword !== "" ? statusPassword : "Informe apenas se desejar alterar"}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid xs={12} md={10.15} lg={10.15} mt={2} container justifyContent="flex-end">
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            onClick={submitForm}
                            variant="contained"
                            disableElevation
                            sx={{ p: 2, maxWidth: 240, width: '100%', justifySelf: "flex-end" }}>
                            Continuar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EditFilial;