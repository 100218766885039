import { Remote } from "service/remote";

export const RefuseUpdate = async () => {
    return await Remote({
        method: "post",
        urn: "/online/branch/update/refuse",
    })

}

export default RefuseUpdate;