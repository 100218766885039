import { Grid } from "@mui/material"
import { formatCurrency, getLegibleDate, toCurrency } from '@utils/format';
import { transformValue } from '@utils/transformValue';
import React, { useCallback, useEffect, useState } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';


import { colors } from 'theme';
import { Cell, Pie, Sector } from "recharts";
import { PieChart } from "@mui/x-charts";
import useMediasQuerys from "hooks/mediasQuery";

const COLORS = [
    "#60009B",
    "#2E96FF",
    "#1542ff",
    "#02B2AF",
    "#2731C8",
    "#03008D",
    "#9c00d5"
];

const baseState = [
    { name: "18 a 25", value: `R$ 0,00`, amount: 0 },
    { name: "26 a 31", value: `R$ 0,00`, amount: 0 },
    { name: "32 a 41", value: `R$ 0,00`, amount: 0 },
    { name: "42 a 51", value: `R$ 0,00`, amount: 0 },
    { name: "52 a 61", value: `R$ 0,00`, amount: 0 },
    { name: "62 a 71", value: `R$ 0,00`, amount: 0 },
    { name: "71 acima", value: `R$ 0,00`, amount: 0 },
];


function Birthday({
    data
}: {
    data: { amount: number, birthday: Date }[]
}) {

    const { matchesMaxMobile } = useMediasQuerys()

    const [realData, setData] = useState<{ name: string, value: string, amount: number }[] | null>(baseState);

    const [activeIndex, setActiveIndex] = useState(-1);

    const onPieEnter = useCallback(
        (_: any, index: any) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    useEffect(() => {
        if (data?.length > 0) {
            const presentYears = (new Date()).getFullYear()
            const ages = [
                data.filter(a => presentYears - a.birthday.getFullYear() <= 25),
                data.filter(a => presentYears - a.birthday.getFullYear() >= 26 && presentYears - a.birthday.getFullYear() <= 31),
                data.filter(a => presentYears - a.birthday.getFullYear() >= 32 && presentYears - a.birthday.getFullYear() <= 41),
                data.filter(a => presentYears - a.birthday.getFullYear() >= 42 && presentYears - a.birthday.getFullYear() <= 51),
                data.filter(a => presentYears - a.birthday.getFullYear() >= 52 && presentYears - a.birthday.getFullYear() <= 61),
                data.filter(a => presentYears - a.birthday.getFullYear() >= 62 && presentYears - a.birthday.getFullYear() <= 71),
                data.filter(a => presentYears - a.birthday.getFullYear() >= 71),
            ]

            console.log(
                baseState?.map((v, i) => ({
                    name: v.name,
                    value: toCurrency(ages[i].length > 0 ?
                        ages[i]
                            .map((a: any) => a.amount)
                            .reduce((a, b) => a + b)
                        : 0),
                    amount: ages[i].length
                }))
            )

            setData(
                baseState?.map((v, i) => ({
                    name: v.name,
                    value: toCurrency(ages[i].length > 0 ?
                        ages[i]
                            .map((a: any) => a.amount)
                            .reduce((a, b) => a + b)
                        : 0),
                    amount: ages[i].length
                }))
            );
        }
    }, [data]);

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 12,
    }));

    function PieCenterLabel({ children }: { children: React.ReactNode }) {
        const { width, height, left, top } = useDrawingArea();
        return (
            <StyledText x={left + width / 2} y={top + height / 2.5}>
                {children}
            </StyledText>
        );
    }

    if (!realData) {
        return <></>
    }

    const round = (value: number, precision = 2) => {
        const power = 10 ** precision;
        return Math.round(parseFloat((value * power).toPrecision(15))) / power;
    };

    const toPercent = (decimal: number) => `${round(decimal * 100).toFixed(2)}%`

    return (
        <PieChart
            width={matchesMaxMobile ? 365 : 400}
            height={matchesMaxMobile ? 365: 400}
            tooltip={{
                trigger: 'item'
            }}
            series={[
                {
                    data: realData?.map((real) => {
                        return {
                            id: real.value,
                            label: real.name,
                            value: real.amount
                        }
                    }),
                    innerRadius: 70,
                    outerRadius: 100,
                    paddingAngle: 3,
                    cornerRadius: 5,
                    startAngle: -180,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                    valueFormatter: ({ id, value, label }) => id as unknown as string
                },
            ]}
        >
            {/* <PieCenterLabel>Center label</PieCenterLabel> */}
        </PieChart>
    )
}

export default Birthday;