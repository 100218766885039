import { Remote } from "service/remote";

export const GetLinks = async ({
    net_amount,
    parcels,
    sale_table
}: {
    net_amount: number,
    parcels: number,
    sale_table?: string
}) => {
    return await Remote({
        method: "post",
        urn: "online/proposals/simulator",
        data: {net_amount, parcels, ...(sale_table && {sale_table})}
    })

}

export default GetLinks;