import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import TabPanel from 'components/@ui/tab';
import lendingquota from 'utils/lendingquota';
import { colors } from 'theme';
import TableLending from 'components/tableLending';
import useAuth from 'context/auth';
import { useNavigate } from 'react-router-dom';
import { SalesTableProps } from 'types/proposals';
import useProposal from 'context/proposal';
import { transformValue } from '@utils/transformValue';
import { moneyMask } from '@utils/moneymask';
import SalesTableSkeleton from 'components/@ui/skeleton/SaleTables';
import TooltipUI from 'components/@ui/Tooltip';
import { FiLock } from 'react-icons/fi';
import { color } from 'html2canvas/dist/types/css/types/color';
import useMediasQuerys from 'hooks/mediasQuery';
import Icons from 'utils/icons';

const SimulatorMaxValue = ({
    value,
    showTablesSimulator,
    saleTables,
    parcels,
    changeParcel,
    LoadingSaleTables,
    error
}: {
    value: number,
    showTablesSimulator: boolean,
    saleTables: SalesTableProps[],
    parcels: number | null,
    LoadingSaleTables: boolean;
    changeParcel: (a: number) => void;
    error: boolean

}) => {

    const {
        valuesList,
        cardLimit,
        setValueProposal,
        valueProposal,
        preApproved
    } = useProposal();

    const navigate = useNavigate();

    const [valueProposalError, setValueProposalError] = useState<string>('')
    const [valueProp, setvalueProp] = useState<string>('')


    const ValidatePropInput = () => {
        if (transformValue(valueProp) > preApproved) {
            setValueProposalError(`Valor digitado é maior que o valor pré aprovado de ${moneyMask(preApproved.toFixed(2).toString())}.`)
            return false
        }
        if (!valuesList.includes(moneyMask(valueProp).replace(' ', ' '))) {
            setValueProposalError('O valor inserido não é válido. Insira um valor divisível por 10. exemplo: R$ 550')
            return false
        }
        setValueProposalError('')
        return true
    }

    useEffect(() => {
        if (valueProposal && valueProposal !== '0') {
            setvalueProp(valueProposal)
        }
    }, [valueProposal])

    return (
        <Grid container flexDirection={"column"} gap={2}>
            <Grid container xs={12} gap={2} alignItems={"flex-end"}>
                {
                    value === 1 &&
                    <Grid xs={12} md={5} lg={5}>
                        <TextField
                            fullWidth
                            value={moneyMask(valueProp)}
                            onChange={(e) => setvalueProp(e.target.value)}
                            error={!!valueProposalError}
                            inputProps={{
                                sx: {
                                    color: (valueProp && valueProp !== 'R$ 0,0') ? colors.black : colors.grey500
                                }
                            }}
                            helperText={valueProposalError}
                            label="Digite o valor desejado pelo cliente"
                            onBlur={(r) => {
                                if (ValidatePropInput()) {
                                    setValueProposal(r.target.value)
                                }
                            }}
                        />
                    </Grid>
                }
                <Grid container gap={2} xs={12} md={value === 1 ? 5 : 12} lg={value === 1 ? 5 : 12}>
                    <Typography minWidth={"100%"} fontWeight="400" variant="h6" textTransform="uppercase">
                        Selecione a quantidade de parcelas para o empréstimo
                    </Typography>
                    {
                        lendingquota.map((_quota) => (
                            <Button
                                disabled={value === 1 && (!!valueProposalError || valueProposal.length === 0)}
                                color="primary"
                                key={_quota.value}
                                onClick={() => changeParcel(_quota.value)}
                                sx={{
                                    p: 2,
                                    backgroundColor: (parcels ? (parcels === _quota.value ? colors.primary : colors.paper) : colors.paper),
                                    color: (parcels ? (parcels === _quota.value ? colors.paper : colors.primary) : colors.primary),
                                    borderColor: (value === 1 && (!!valueProposalError || valueProposal.length === 0) ? 'transparent' : colors.primary),
                                    borderWidth: '1px',
                                    borderStyle: 'solid'

                                }}
                                disableElevation
                                variant="contained">{_quota.title}</Button>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid>
                {
                    LoadingSaleTables && saleTables.length === 0 && <SalesTableSkeleton />
                }
            </Grid>
            {
                showTablesSimulator && saleTables.length > 0 &&
                <Grid mt={3}>
                    <Typography fontWeight="500" variant="h4" textTransform="uppercase" id="tables-on">Tabelas</Typography>
                    <Typography fontWeight="400" variant="subtitle2" sx={{ textDecoration: 'underline' }}>
                        Selecione a tabela desejada para iniciar o empréstimo
                    </Typography>
                    <Grid container gap={2} mt={5} alignItems={"center"} justifyContent="center">
                        {
                            saleTables?.sort((a, b) =>
                                Number(a.sale_table.replace('on-', '')) - Number(b.sale_table.replace('on-', ''))
                            )?.filter((_s) => Number(_s.sale_table.split('on-')[1]) < 15)?.map((sale_table) => (
                                <TableLending
                                    onClick={() => {
                                        navigate('/app/emprestar/tabela/', {
                                            state: sale_table
                                        })
                                    }}
                                    key={sale_table.id}
                                    sx={{ mt: 5 }}
                                    color={
                                        Number(sale_table.sale_table.replace('on-', '')) <= 3 ?
                                            colors.greenTable : (Number(sale_table.sale_table.replace('on-', '')) <= 6
                                                ? colors.blueTable : (Number(sale_table.sale_table.replace('on-', '')) <= 9
                                                ? colors.yellowTable : (Number(sale_table.sale_table.replace('on-', '')) <= 12
                                                ? colors.orangeTable : colors.redTable)))
                                    }
                                    item={sale_table} />
                            ))
                        }

                    </Grid>
                </Grid>
            }
            {
                !LoadingSaleTables && showTablesSimulator && saleTables.length === 0 && parcels && !error &&
                <Stack direction={"row"} gap={2}>
                    <img src={Icons.IconAlert} alt="alert" />

                    <Typography color={"red"}>Não há tabelas disponíveis para empréstimo devido ao saldo disponível.</Typography>
                </Stack>
            }
            {
                !LoadingSaleTables && showTablesSimulator && saleTables.length === 0 && parcels && error &&
                <Stack direction={"row"} gap={2}>
                    <img src={Icons.IconAlert} alt="alert" />

                    <Typography color={"red"}>Houve um problema ao buscar tabelas disponíveis. Por favor, tente novamente.</Typography>
                </Stack>
            }
            {
                !LoadingSaleTables && showTablesSimulator && saleTables.length > 0 && parcels && error &&
                <Stack direction={"row"} gap={2}>
                    <img src={Icons.IconAlert} alt="alert" />

                    <Typography color={"red"}>Houve um problema ao buscar algumas tabelas disponíveis. Por favor, tente novamente.</Typography>
                </Stack>
            }
        </Grid>
    )
}



const TablesSimulatos = ({
    showTablesSimulator,
    saleTables,
    parcels,
    changeParcel,
    LoadingSaleTables,
    changeTypeSimulador,
    typeSimulator,
    error
}: {
    showTablesSimulator: boolean,
    saleTables: SalesTableProps[]
    parcels: number | null,
    LoadingSaleTables: boolean;
    changeParcel: (a: number) => void;
    typeSimulator: "perTable" | "perValue";
    changeTypeSimulador: (a: 'perTable' | 'perValue') => void;
    error: boolean
}) => {

    const { matchesMaxMobile } = useMediasQuerys()

    const { setParcels } = useProposal()

    const handleChange = (newValue: 'perTable' | 'perValue') => {
        changeTypeSimulador(newValue)
    };

    const Simulator = () => <SimulatorMaxValue
        value={typeSimulator === 'perTable' ? 0 : 1}
        LoadingSaleTables={LoadingSaleTables}
        showTablesSimulator={showTablesSimulator}
        saleTables={saleTables}
        parcels={parcels}
        changeParcel={changeParcel}
        error={error}
    />

    return (
        <Box sx={{ width: '100%' }} id="parcels">
            {matchesMaxMobile && <Typography sx={{ width: '100%' }}>Selecionar tabela por:</Typography>}
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Grid container sx={{ width: '100%' }} >

                    <Grid
                        xs={5.5}
                        onClick={() => handleChange('perTable')}
                        sx={{
                            fontSize: '1em',
                            fontWeight: 'bold',
                            m: 1,
                            border: '1px solid ' + colors.grey300,
                            borderRadius: 2
                        }}
                        color={typeSimulator === 'perTable' ? colors.primary : colors.grey500}
                        component={Button}
                    >
                        {matchesMaxMobile ? 'valor máximo' : 'Simulação por valor máximo'}
                    </Grid>

                    <TooltipUI title={!parcels ? "Selecione a quantidade de parcelas antes de simular por valor desejado pelo cliente" : ''}>
                        <Grid xs={5.5}>
                            <Grid
                                xs={12}
                                disabled={!parcels && typeSimulator === 'perTable'}
                                color={typeSimulator === 'perValue' ? colors.primary : colors.grey500}
                                component={Button}
                                onClick={() => {
                                    handleChange('perValue')
                                    setParcels(null)
                                }}
                                sx={{
                                    fontSize: '1em',
                                    fontWeight: 'bold',
                                    m: 1,
                                    border: '1px solid ' + colors.grey300,
                                    borderRadius: 2,
                                    width: '100%'
                                }}
                            >
                                {
                                    !parcels && typeSimulator === 'perTable' && <FiLock size={22} />
                                }
                                {matchesMaxMobile ? 'valor desejado pelo cliente' : 'Simulação por valor desejado pelo cliente'}

                            </Grid>
                        </Grid>
                    </TooltipUI>
                </Grid>
            </Box>
            <TabPanel
                value={typeSimulator === 'perTable' ? 0 : 1} index={0}>
                <Simulator />
            </TabPanel>
            <TabPanel value={typeSimulator === 'perTable' ? 0 : 1} index={1}>
                <Simulator />
            </TabPanel>
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default TablesSimulatos;