import { Remote } from "service/remote";

export const Me = async () => {
    return await Remote({
        method: "get",
        urn: `owners/me`,
        api: 'manager'
    })

}

export default Me;