import { Remote } from "service/remote";

const ConsultAccount = async (id:string) => {
    return await Remote({
        method: "get",
        urn: `online/validate/consult/${id}`
    })

}

export default ConsultAccount;