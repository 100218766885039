import { Remote } from "service/remote";

export const GetCep = async ({
    formatCep
}: {
    formatCep: string;
}) => {
    return await Remote({
        method: "get",
        urn: `https://viacep.com.br/ws/${formatCep}/json/`
    })

}

export default GetCep;