import { Remote } from "service/remote";
import { ProposalProps } from "types/proposal";

export const CreateProposal = async (data: ProposalProps) => {
    return await Remote({
        method: "post",
        urn: "online/proposals/owner",
        data
    })

}

export default CreateProposal;