import { Remote } from "service/remote";

export const SalesTable = async (data?: {
    userid: number,
    sale_table?: any,
    active?: boolean
}) => {
    return await Remote({
        method: data ? (data?.sale_table ? 'patch' : 'get') : "get",
        urn: `online/sale-table${data ? data?.userid ? `/user-id/${data.userid}` : '' : ''}`,
        ...(data?.sale_table ? {
            data: {
                ...data
            }
        } : {})
    })

}

export default SalesTable;