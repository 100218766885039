import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import ModalUI from 'components/@ui/modal';
import { RiWhatsappFill } from 'react-icons/ri';
import { colors } from 'theme';
import RemoteServices from 'service';
import { ProposalRR } from 'types/proposal';

import { transformValue } from '@utils/transformValue';
import { dataBR, formatCurrency, formatDate, formatDateWithTime, phoneMask } from "@utils/format"
import StepProgress from 'components/@ui/stepProgressBar';
import Toast from 'components/@ui/toast';
import ButtonUI from 'components/@ui/button';
import ReportsOnline from './premio';
import useAuth from 'context/auth';

import CCB from 'assets/png/CCB.png';
import CCBBlue from 'assets/png/CCB BLUE.png';
import { saveAs } from 'file-saver';
import useGeneral from 'context/general';
import useMediasQuerys from 'hooks/mediasQuery';
import PixAccount from './pixAccount';

export default function ProposalDetails({
    open,
    handleClose,
    proposalId,
    proposalDetail
}: {
    open: boolean,
    handleClose: (pix?: boolean) => void;
    proposalId: number | string;
    proposalDetail?: ProposalRR | null;
}) {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        User
    } = useAuth()
    const {
        setGetStatus,
        setProposal: setGeneralProposal
    } = useGeneral()

    const [proposal, setProposal] = useState<ProposalRR | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const [openDialogCommission, setOpenDialogCommission] = useState(false)
    const [openDialogPixAccount, setOpenDialogPixAccount] = useState(false)

    const [commissionValue, setCommissionValue] = useState(0)
    const [PixHidden, setPixHidden] = useState(false)
    const [canSendBanner, setCanSendBanner] = useState(false)
    const [alreadyRequestBanner, setAlreadyRequestBanner] = useState(false)
    const [otherRequests, setOtherRequests] = useState([])
    const [account, setAccount] = useState(null)

    useEffect(() => {
        setPixHidden(false)
        // id: proposalId as unknown as number
        GetProposal()
    }, [proposalId, proposalDetail])

    const GetProposal = () => {
        if (proposalId && !proposalDetail) {
            RemoteServices.proposals.Proposals({
                id: proposalId as unknown as number
            })
                .then(({
                    response
                }) => {
                    if (response?.result[0]) {
                        setProposal(response?.result[0])
                    }
                })
        } else {
            if (proposalDetail) {
                setProposal(proposalDetail)
            }
        }
    }

    const statusProposal = (proposal: string) => {
        let status = 0
        switch (proposal) {
            case 'started':
                status = 0
                break;
            case 'preValidationCard':
                status = 1
                break;
            case 'transfeera':
                status = 2
                break;
            case 'face_id':
                status = 3
                break;
            case 'signature':
                status = 4
                break;
            case 'aproved':
                status = 5
                break;
            default:
                break;
        }
        return status
    }

    const getAccount = async () => {
        setLoading(true)
        await RemoteServices.commissions.GetAccountPIX().then(({ response }) => {
            if (response.result === null) {
                setOpenDialogPixAccount(true)
                setLoading(false)
            } else {
                getAlreadyRequest()
                setAccount(response.result)
            }
        }).catch(() => {
            Toast({
                type: "error"
            }, "Falha ao verificar se tem conta pix cadastrada!")
            setLoading(false)
        })
    }

    const getAlreadyRequest = async () => {
        setLoading(true)
        RemoteServices.commissions.PixAwardConsult(proposal?.id ?? '')
            .then(({ response }) => {
                if (!response.alreadyRequested) {
                    setCanSendBanner(!response.alreadySendBanner)
                    setAlreadyRequestBanner(response.alreadyRequestBanner)
                    setOpenDialogCommission(true)
                    setCommissionValue(response?.commission)
                    setOtherRequests(response?.other_requests)
                } else {
                    Toast({
                        type: "warning"
                    }, "GRATIFICAÇÃO POR ATENDIMENTO já solicitado!",)
                }
            }).catch(() => {
                Toast({
                    type: "error"
                }, "Falha ao verificar se a solicitação já foi realizada!",)
            })
            .finally(() => setLoading(false))
    }

    return (
        <ModalUI
            open={open}
            handleClose={() => { handleClose(); setProposal(null); setGetStatus(false); setGeneralProposal(null) }}
        >
            <Grid sx={{ maxWidth: 1000, minHeight: '50vh' }}>
                <Grid py={matchesMaxMobile ? 4 : 2}>
                    <Typography variant={matchesMaxMobile ? "h6" : "h4"} fontWeight="bold" color="black" textTransform={"uppercase"}>Detalhes da proposta</Typography>
                </Grid>
                {
                    proposal &&
                    <>
                        {matchesMaxMobile ?
                            <ProposalInfosMobileView proposal={proposal} /> :
                            <PropsalInfosView proposal={proposal} />}

                        {
                            matchesMaxMobile &&
                            <Grid gap={2} display={"flex"} alignItems={"center"}>
                                {
                                    proposal && proposal?.proposal_status === 'aproved' && proposal?.canRequestPix && !PixHidden &&
                                    <ButtonUI
                                        variant="contained"
                                        disableElevation
                                        sx={{ mt: 2, py: 2, width: 20 }}
                                        loading={loading}
                                        onClick={() => {
                                            getAccount()
                                            // handleClose(true); setProposal(null) 
                                        }}
                                        autoFocus>
                                        Solicitar GRATIFICAÇÃO POR ATENDIMENTO
                                    </ButtonUI>
                                }
                                {
                                    proposal?.ccb &&
                                    <Tooltip title="Baixar CCB">
                                        <Button
                                            sx={{ mt: 2, py: 1.8 }}
                                            variant='outlined'
                                            onClick={() => {
                                                saveAs(proposal.ccb as string, `CCB Proposta ${proposal.id}`)
                                            }}>
                                            <img alt="ccb" width={20} src={CCBBlue} />
                                            <div style={{ marginLeft: 10, fontWeight: 'bold' }}>
                                                Baixar CCB
                                            </div>
                                        </Button>
                                    </Tooltip>
                                }
                            </Grid>
                        }

                        <StepProgress
                            complete={proposal?.ted_status === 'paid' ? true : false}
                            status={statusProposal(proposal?.proposal_status ?? '')}
                            proposal={proposal}
                        />
                    </>
                }
                {
                    proposal && !matchesMaxMobile && proposal?.proposal_status === 'aproved' && proposal?.canRequestPix && !PixHidden &&
                    <ButtonUI
                        variant="contained"
                        disableElevation
                        sx={{ mt: 2 }}
                        loading={loading}
                        onClick={() => {
                            getAccount()
                            // handleClose(true); setProposal(null) 
                        }}
                        autoFocus>
                        Solicitar GRATIFICAÇÃO POR ATENDIMENTO
                    </ButtonUI>
                }
                {
                    proposal && (proposal?.proposal_status === "preValidationCard" || proposal?.proposal_status === "transfeera" || proposal?.proposal_status === "face_id") && <Typography sx={{
                        fontSize: '0.745rem',
                        padding: '20px 20px 0px 20px',
                        textAlign: 'center',
                        color: '#042f35 !important'

                    }}>Obs: Enviamos uma mensagem via WhatsApp para o cliente.
                        <br />
                        Solicite ao cliente que CLIQUE NO LINK DE EMPRÉSTIMO.para concluir a proposta.</Typography>
                }
                {
                    proposal && (proposal?.proposal_status === "signature") && <Typography sx={{
                        fontSize: '0.745rem',
                        padding: '20px 20px 0px 20px',
                        textAlign: 'center',
                        color: '#042f35 !important'

                    }}>CLICKSING enviou uma mensagem via WhatsApp para o cliente assinar o contrato, <br />
                        solicite que CLIQUE NO LINK para assinatura.</Typography>
                }

                <PixAccount
                    open={openDialogPixAccount}
                    handleClose={(success) => {
                        if (success) {
                            getAlreadyRequest()
                        }
                        setOpenDialogPixAccount(false)
                    }}
                    account={account}

                />
                <ReportsOnline
                    openDialogCommission={openDialogCommission}
                    handleClose={(success) => {
                        if (success === true) {
                            setPixHidden(true)
                            GetProposal()
                        }
                        setOpenDialogCommission(false)
                    }}
                    proposalId={proposal?.id ?? ''}
                    commissionValue={commissionValue}
                    canSendBanner={canSendBanner}
                    alreadyRequestBanner={alreadyRequestBanner}
                    otherRequests={otherRequests}
                />
            </Grid>

        </ModalUI>
    );
}

interface ProposalViewProps {
    proposal: ProposalRR | null
}

const ProposalInfosMobileView = (props: ProposalViewProps) => {

    const { proposal } = props

    const fSize = 14

    const {
        User
    } = useAuth()

    return (
        <Grid>
            <Grid container justifyContent={"space-between"} alignItems={"start"}>
                <Grid xs={7} fontWeight={"bold"}>
                    {proposal?.name}
                </Grid>
                <Grid xs={5} container flexDirection={"row"} justifyContent={"flex-end"}>
                    <Typography fontWeight={"bold"}>
                        ID:
                    </Typography>
                    {proposal?.id}
                </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={2}>
                <Grid xs={6} color={colors.grey400}>
                    <Typography fontWeight={"bold"} fontSize={fSize}>
                        Iniciada em:
                    </Typography>
                    <Typography fontSize={fSize}>
                        {formatDateWithTime(proposal?.created_at ?? '') ?? '00/00/0000'}
                    </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent={"flex-end"}
                    xs={6}
                    color={colors.grey400}>
                    <div>
                        <Typography fontWeight={"bold"} fontSize={fSize}>Finalizada em:</Typography>
                        <Typography fontSize={fSize}>{proposal?.proposal_status === "aproved" ?
                            formatDateWithTime(proposal?.updated_at ?? '')
                            ?? "00/00/0000" : 'NÃO FINALIZADA'}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={2}>
                <Grid xs={6} color={colors.grey400}>
                    <Typography fontWeight={"bold"} fontSize={fSize}>
                        Tabela:
                    </Typography>
                    <Typography fontSize={fSize}>
                        {proposal?.sale_table?.replaceAll('on-', '')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent={"flex-end"}
                    xs={6}
                    color={colors.grey400}>
                    <div>
                        <Typography fontWeight={"bold"} fontSize={fSize}>
                            Parcelas:
                        </Typography>
                        <Typography fontSize={fSize}>
                            {proposal?.parcels}x
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={2}>
                <Grid xs={6}>
                    <Typography fontWeight={"bold"} fontSize={fSize}>
                        Valor emprestado:
                    </Typography>
                    <Typography fontSize={fSize}>
                        {formatCurrency(proposal?.net_amount ?? 0)}
                    </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent={"flex-end"}
                    xs={6}
                >
                    <div>
                        <Typography fontWeight={"bold"} fontSize={fSize}>
                            Valor do contrato:
                        </Typography>
                        <Typography fontSize={fSize}>
                            {formatCurrency(proposal?.gross_amount ?? 0)}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={2}>
                <Grid xs={6} color={colors.grey400}>
                    <Typography fontWeight={"bold"} fontSize={fSize}>
                        Cliente WhatsApp:
                    </Typography>
                    <Grid
                        component={Button}
                        maxWidth={150}
                        p={0}
                        color={"inherit"}
                        variant='text'
                        sx={{ textDecoration: 'underline' }}
                        onClick={() => {
                            window.open(`https://api.whatsapp.com/send/?phone=55${proposal?.cellphone}`, '_blank');
                        }}
                        container
                        gap={1}
                        alignItems="center">
                        {phoneMask(proposal?.cellphone ?? '')} <RiWhatsappFill color={colors.success} size={22} />
                    </Grid>
                </Grid>
                {
                    User?.space === 'pdv' && proposal?.responsible &&
                    <Grid
                        container
                        justifyContent={"flex-end"}
                        xs={6}
                        color={colors.grey400}>
                        <div>
                            <Typography fontWeight={"bold"} fontSize={fSize}>
                                Filial responsável:
                            </Typography>
                            <Typography fontSize={fSize}>
                                {proposal?.responsible}
                            </Typography>
                        </div>
                    </Grid>
                }

            </Grid>
        </Grid>
    )
}


const PropsalInfosView = (props: ProposalViewProps) => {

    const { proposal } = props

    const {
        User
    } = useAuth()

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, color: 'balck' }} aria-label="simple table">
                <TableHead>
                    <CustomTableRow>
                        <>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">CLIENTE</TableCell>
                            <TableCell align="left">INICIADA EM</TableCell>
                            <TableCell align="left">FINALIZADA EM</TableCell>
                            <TableCell align="left">VALOR EMPRESTADO</TableCell>
                        </>
                    </CustomTableRow>
                </TableHead>
                <TableBody>
                    <CustomTableRow >
                        <>
                            <TableCell align="left">
                                {proposal?.id}
                            </TableCell>
                            <TableCell align="left">{proposal?.name}</TableCell>
                            <TableCell align="left">{formatDateWithTime(proposal?.created_at ?? '') ?? '00/00/0000'}</TableCell>
                            <TableCell align="left">{proposal?.proposal_status === "aproved" ?
                                formatDateWithTime(proposal?.updated_at ?? '') ?? "00/00/0000" : 'NÃO FINALIZADA'}</TableCell>
                            <TableCell align="left">{formatCurrency(proposal?.net_amount ?? 0)}</TableCell>
                        </>
                    </CustomTableRow>
                </TableBody>
            </Table>
            <Table sx={{ minWidth: 650, color: 'balck' }} aria-label="simple table">
                <TableHead>
                    <CustomTableRow>
                        <>
                            <TableCell>TABELA</TableCell>
                            <TableCell align="left">PARCELAS</TableCell>
                            {User?.space === 'pdv' && proposal?.responsible && <TableCell align="left">filial responsável</TableCell>}
                            <TableCell align="left">Valor do contrato</TableCell>
                        </>
                    </CustomTableRow>
                </TableHead>
                <TableBody>
                    <CustomTableRow >
                        <>
                            <TableCell align="left">
                                {proposal?.sale_table?.replaceAll('on-', '')}
                            </TableCell>
                            <TableCell align="left">{proposal?.parcels}</TableCell>

                            {User?.space === 'pdv' && proposal?.responsible &&
                                <TableCell align="left">{proposal?.responsible}</TableCell>}
                            <TableCell align="left">{formatCurrency(proposal?.gross_amount ?? 0)}</TableCell>
                        </>
                    </CustomTableRow>
                </TableBody>
            </Table>
            <Table sx={{ minWidth: 650, color: 'balck' }} aria-label="simple table">
                <TableHead>
                    <CustomTableRow>
                        < >
                            <TableCell>
                                <Grid >
                                    Cliente whatsapp
                                </Grid>
                            </TableCell>
                            {

                                proposal?.ccb &&
                                <TableCell>
                                    <Grid >
                                        CCB
                                    </Grid>
                                </TableCell>

                            }

                            <TableCell>
                                <Grid sx={{ width: 400 }}></Grid>
                            </TableCell>

                        </>
                    </CustomTableRow>
                </TableHead>
                <TableBody>
                    <CustomTableRow >
                        <>
                            <TableCell align="left">
                                <Grid
                                    component={Button}
                                    maxWidth={150}
                                    p={0}
                                    color={"inherit"}
                                    variant='text'
                                    sx={{ textDecoration: 'underline' }}
                                    onClick={() => {
                                        window.open(`https://api.whatsapp.com/send/?phone=55${proposal?.cellphone}`, '_blank');
                                    }}
                                    container
                                    gap={1}
                                    alignItems="center">
                                    {phoneMask(proposal?.cellphone ?? '')} <RiWhatsappFill color={colors.success} size={22} />
                                </Grid>
                            </TableCell>
                            {
                                proposal?.ccb &&
                                <TableCell align='center'>
                                    <Grid container justifyContent={"center"} alignItems={"center"}>
                                        <Tooltip title="Baixar CCB">
                                            <IconButton onClick={() => {
                                                saveAs(proposal.ccb as string, `CCB Proposta ${proposal.id}`)
                                            }}>
                                                <img alt="ccb" width={20} src={CCB} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </TableCell>
                            }

                        </>
                    </CustomTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}


const CustomTableRow = ({
    children
}: {
    children: JSX.Element
}) => {
    return (
        <TableRow
            sx={{
                border: 0, '& td': {
                    borderBottom: 0,
                    color: 'black !important',
                    fontWeight: '400',
                    fontSize: '14px',
                    textTransform: 'uppercase'
                },
                '& th': {
                    borderBottom: 0,
                    color: 'black !important',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textTransform: 'uppercase'

                },
            }}
        >
            {children}
        </TableRow>
    )
}