import { Grid, Skeleton } from "@mui/material"

const TableSkeleton = () => {
    return (
        <Grid width="100%" container gap={2}>
                    <Skeleton variant="rounded" width={'100%'} height={75} />
                    <Skeleton variant="rounded" width={'100%'} height={75} />
                    <Skeleton variant="rounded" width={'100%'} height={75} />
                    <Skeleton variant="rounded" width={'100%'} height={75} />
                    <Skeleton variant="rounded" width={'100%'} height={75} />
    
        </Grid>
    )
}

export default TableSkeleton;