import { Remote } from "service/remote";

export const ConsultCpf = async ({
    CPF
}: {
    CPF: string;
}) => {
    return await Remote({
        method: "get",
        urn: `online/proposals/consult-cpf/${CPF}`
    })

}

export default ConsultCpf;