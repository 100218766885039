import { IconButton } from '@mui/material'
import style from './styles.module.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useState } from 'react'
import axios from 'axios'
import { HiSearch } from "react-icons/hi";
import { IPosts } from '..'
import { Link } from 'react-router-dom'
import { VaptPostCard } from '../card'

export const VaptPostCarrouselNewPosts = ({
    posts,
    link
}: {
    posts: IPosts[],
    link: string
}) => {
    const [scrollPosition, setScrollPosition] = useState(0)

    const scrollToElement = (index: number) => {
        setScrollPosition(index)
        const element = document.getElementById(`slide-new-post-${index}`)
        element?.scrollIntoView({
            behavior: 'smooth',
            block: "center",
            inline: "center"
        })
    }

    return (
        <>
            <div className={style.container}>
                <div className={style.arrows}>
                    <IconButton onClick={() => {
                        if (scrollPosition > 0) {
                            scrollToElement(scrollPosition - 1)
                        }
                    }} disabled={scrollPosition === 0}>
                        <IoIosArrowBack color={scrollPosition === 0 ? 'gray' : 'black'} fontSize={25} />
                    </IconButton>
                </div>
                <div id="slide-new-post" className={style.cardContainer} >
                    {posts?.map((post, index) => {
                        return <VaptPostCard keyName={"slide-new-post"} description={post.description} image={post.image} index={index} key={index} scaleReverse={scrollPosition !== index}/>
                    })}
                </div>
                <div className={style.arrows}>
                    <IconButton onClick={() => {
                        if (scrollPosition < posts?.length - 1) {
                            scrollToElement(scrollPosition + 1)
                        }
                    }} disabled={scrollPosition >= posts?.length - 1}>
                        <IoIosArrowForward color={scrollPosition >= posts?.length - 1 ? 'gray' : 'black'} fontSize={25} />
                    </IconButton>
                </div>
            </div>
            <div className={style.dotsContainer}>
                {posts?.map((_, index) => {
                    return (
                        <button className={index === scrollPosition ? style.dotSelected : style.dot} onClick={() => {
                            scrollToElement(index)
                        }}>
                            <a href={`#slide-new-post-${index}`}></a>
                        </button>
                    )
                })}
            </div>

            <div className={style.containerButtonNew}>
                <Link to={link} target="_blank">
                    <button>Gerar Novo Post</button>
                </Link>
            </div>
        </>
    )

}