import { dataBR } from "@utils/format";
import { Remote } from "service/remote";

export const ConsultLocalize = async ({
    cpf,
    birthday
}: {
    cpf: string;
    birthday: string;
}) => {
    return await Remote({
        method: "get",
        urn: `online/proposals/consult-localize`,
        params: {cpf, birthday}
    })

}

export default ConsultLocalize;