import {
    Grid,
    IconButton,
    TableCell,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    CircularProgress,
    Stack,
    TextField
} from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import { colors } from 'theme';
import { useEffect, useState } from 'react';
import RemoteServices from 'service';
import { useLocation } from 'react-router';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { formatCPF, formatDateOnly, formatDateWithTime, phoneMask } from '@utils/format';
import TooltipUI from 'components/@ui/Tooltip';
import ExcelIcon from 'assets/png/EXCEL.png';
import Axios from 'axios';
import URLSBases from 'utils/urlBases';
import storageLocales from 'utils/storageLocales';
import useMediasQuerys from 'hooks/mediasQuery';
import { TablePagination } from '@material-ui/core';
import { CalendarMonth, Close, Search } from '@mui/icons-material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import useAuth from 'context/auth';
import { MdLeaderboard } from 'react-icons/md';
import { ILead } from 'types/leads';


export default function Remarketing() {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        User,
    } = useAuth()


    const [leads, setLeads] = useState<ILead[] | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const [loading, setLoading] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [startData, setStartData] = useState<Moment | null>(null)
    const [startHours, setStartHours] = useState<any>(moment().hours(0).minutes(0))

    const [endData, setEndData] = useState<Moment | null>(null)
    const [endHours, setEndHours] = useState<any>(moment().hours(23).minutes(59))

    const [type, setType] = useState<"none" | "registered" | "not-registered">("none")

    const [name, setName] = useState("")

    const [openSearchData, setOpenSearchData] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        getLeads()
    }, [page, rowsPerPage, type])

    const getLeads = async (clear: boolean = false) => {
        await RemoteServices.marketing.getLeads({
            page: clear ? 1 : page,
            limit: clear ? 10 : rowsPerPage,
            type: clear ? "none" : type,
            ... (startData && !clear && {
                start_data: returnStringData(startData as Moment, startHours)
            }),
            ... (endData && !clear && {
                end_data: returnStringData(endData as Moment, endHours)
            }),
            ... (name && !clear && {
                name
            }),
        })
            .then(({
                response
            }) => {

                setLeads(response.result)
                setTotal(response.total)
            })
            .finally(() => setLoading(false))


    }

    const returnStringData = (data: Moment, time: Moment) => {
        return new Date(new Date(new Date(data.toISOString() as string).setUTCHours(time?.get("hour"))).setUTCMinutes(time?.get("minute"))).toISOString()
    }

    const download = () => {
        setLoadingDownload(true)
        Axios({
            url: URLSBases.apisTc + "/online/remarketing/",
            params: {
                page: 1,
                limit: total,
                export: true,
                ... (startData && {
                    start_data: returnStringData(startData as Moment, startHours)
                }),
                ... (endData && {
                    end_data: returnStringData(endData as Moment, endHours)
                }),
                type
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
            },
            responseType: 'blob'
        }).then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            const tempLink = document.createElement('a');
            tempLink.href = fileUrl;
            tempLink.setAttribute('download', 'lead.xlsx');
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(fileUrl);
        }).finally(() => setLoadingDownload(false))
    }

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                >
                    <MdLeaderboard size={30} />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>Leads</Typography>
                </Grid>
                <Grid
                    container
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    py={2}
                >
                    <Grid container justifyContent={"flex-end"} gap={2}>
                        {!openSearchData ?
                            <TooltipUI title='Filtrar por data'>
                                <IconButton
                                    sx={{
                                        width: 55,
                                        height: 55,
                                        ":hover": {
                                            color: colors.primary
                                        }
                                    }}
                                    onClick={() => setOpenSearchData(!openSearchData)}
                                >
                                    <CalendarMonth />
                                </IconButton >
                            </TooltipUI>
                            :

                            <Grid container xs={12} display={"flex"} gap={2} flexDirection={"row"} justifyContent={"flex-end"} alignItems={"flex-end"} >
                                <Stack direction={"row"} gap={2}>
                                    <DatePicker
                                        label="Data Inicial"
                                        value={startData ? moment(startData) : null}
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        maxDate={endData ? endData : moment()}
                                        format='DD/MM/YYYY'
                                        onChange={(date) => {
                                            setStartData(date);
                                        }} />
                                    <TimePicker
                                        label="Hora inicial"
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        value={startHours}
                                        onChange={(e) => {
                                            setStartHours(e)
                                        }}
                                        views={['hours', 'minutes']}
                                    />
                                </Stack>
                                <Stack direction={"row"} gap={2}>
                                    <DatePicker
                                        label="Data Final"
                                        value={endData ? moment(endData) : null}
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        maxDate={moment()}
                                        minDate={startData && startData}
                                        format='DD/MM/YYYY'
                                        onChange={(date) => {
                                            setEndData(date);
                                        }} />
                                    <TimePicker
                                        label="Hora Final"
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        value={endHours}
                                        onChange={(e) => setEndHours(e as string)}
                                        views={['hours', 'minutes']}
                                    />
                                </Stack>
                                <Stack direction={"row"} gap={matchesMaxMobile ? 1 : 2}>
                                    <TooltipUI title='Aplicar filtros'>
                                        <IconButton
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                ":hover": {
                                                    color: colors.primary
                                                }
                                            }}
                                            onClick={() => {
                                                getLeads()
                                            }}
                                        >
                                            <Search />
                                        </IconButton >
                                    </TooltipUI>
                                    <TooltipUI title='Limpar pesquisa'>
                                        <IconButton
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                ":hover": {
                                                    color: colors.primary
                                                }
                                            }}
                                            onClick={() => {
                                                setStartHours(moment().hours(0).minutes(0))
                                                setEndHours(moment().hours(23).minutes(59))
                                                setEndData(null)
                                                setStartData(null)
                                                setOpenSearchData(!openSearchData)
                                                setRowsPerPage(10)
                                                setType("none")
                                                setPage(1)
                                                getLeads(true)
                                            }}
                                        >
                                            <Close />
                                        </IconButton >
                                    </TooltipUI>
                                </Stack>
                            </Grid>
                        }

                        <Grid item width={250}>
                            <FormControl fullWidth>
                                <InputLabel id="bi-type-select">Tipo de Lead</InputLabel>
                                <Select
                                    labelId="bi-type-select"
                                    value={type}
                                    label="Tipo de Lead"
                                    onChange={(e) => {
                                        setType(e.target.value as any)
                                    }}
                                >
                                    <MenuItem value={'none'}>Todos</MenuItem>
                                    <MenuItem value={'registered'}>Convertidos</MenuItem>
                                    <MenuItem value={'not-registered'}>Não convertidos</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item width={250}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Nome do cliente"
                                    placeholder="Digite o nome do cliente"
                                    value={name}
                                    onChange={(e) => {
                                        if (e.target.value.length === 0) {
                                            getLeads()
                                        }
                                        setName(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" || e.keyCode === 13) {
                                            getLeads()
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item width={loadingDownload ? 224 : 200}>
                            <Button
                                startIcon={<img src={ExcelIcon} />}
                                sx={{
                                    bgcolor: "#158249",
                                    color: "white",
                                    p: 1.8,
                                    ":hover": {
                                        bgcolor: 'rgba(21, 130, 73, 0.9)',
                                    },
                                    ":disabled": {
                                        color: 'white'
                                    }
                                }}
                                disabled={loadingDownload}
                                onClick={download}
                                endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
                            >Exportar para Excel</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    matchesMaxMobile ? leads && leads.map((row) => (<>
                        <Grid
                            p={1}
                            maxWidth="100%"
                            border={`1px solid ${colors.grey400}`}
                            my={2}
                            borderRadius={2}
                            bgcolor="white">
                            <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`}>
                                <Grid item xs={7} fontWeight="bold">
                                    {row.name}
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography fontWeight="bold">Data do registro: </Typography>{formatDateWithTime(row.created_at)}
                                </Grid>
                            </Grid>
                            <Grid pt={1} container fontSize={12} color={colors.grey600}>
                                <Grid item xs={8} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold" >
                                        Gênero:
                                    </Typography>
                                    {row.gender}
                                </Grid>
                                <Grid item xs={4} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold">
                                        Tabela:
                                    </Typography>
                                    {row.link_info.sale_table.split("on-")[1]}
                                </Grid>
                            </Grid>
                            <Grid container fontSize={12} color={colors.grey600}>
                                <Grid item xs={8} container flexDirection={"row"} gap={.5} >
                                    <Typography fontSize={12} fontWeight="bold">
                                        Data de nascimento:
                                    </Typography>
                                    {formatDateOnly(row.birthday)}
                                </Grid>
                                <Grid item xs={4} container flexDirection={"row"} gap={.5} >
                                    <Typography fontSize={12} fontWeight="bold">
                                        E-mail:
                                    </Typography>
                                    {row.email}
                                </Grid>
                                <Grid item xs={8} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold">
                                        WhatsApp:
                                    </Typography>
                                    <Grid
                                        component={Button}
                                        maxWidth={150}
                                        fontSize={12}
                                        p={0}
                                        color={"inherit"}
                                        variant='text'
                                        sx={{ textDecoration: 'underline' }}
                                        onClick={() => {
                                            window.open(`https://api.whatsapp.com/send/?phone=55${row?.whatsapp}`, '_blank');
                                        }}
                                        container
                                        gap={1}
                                        alignItems="center">
                                        {phoneMask(row?.whatsapp ?? '')}
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold" >
                                        Cidade - Estado:
                                    </Typography>
                                    {`${row.address.city} - ${row.address.state}`}
                                </Grid>
                                <Grid item xs={8} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold">
                                        CEP:
                                    </Typography>
                                    {row.address.cep}
                                </Grid>
                                {User?.space !== "sales_counter" &&
                                    <Grid item xs={4} container flexDirection={"row"} gap={.5}>
                                        <Typography fontSize={12} fontWeight="bold">
                                            Filial:
                                        </Typography>
                                        {row.link_info.sales_counter ? `${row.link_info.sales_counter.id} - ${row.link_info.sales_counter.responsible}` as string : ""}
                                    </Grid>}


                            </Grid>

                            <Grid container fontSize={12} color={colors.grey600}>

                            </Grid>
                        </Grid >

                    </>
                    ))
                        :
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            loading={loading}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            HeaderComponent={
                                <CustomTableRow>
                                    <TableCell align="left">Data do registro</TableCell>
                                    <TableCell align="left">Nome</TableCell>
                                    <TableCell align="left">CPF</TableCell>
                                    <TableCell align="left">Gênero</TableCell>
                                    <TableCell align="left">Data de nascimento</TableCell>
                                    <TableCell align="left">E-mail</TableCell>
                                    <TableCell align="left">WhatsApp</TableCell>
                                    <TableCell align="left">Tabela</TableCell>
                                    <TableCell align="left">Cidade - Estado</TableCell>
                                    <TableCell align="left">CEP</TableCell>
                                    {User?.space !== "sales_counter" && <TableCell align="left">Filial</TableCell>}
                                </CustomTableRow>
                            }
                            RowComponent={
                                leads && leads.map((row) => (
                                    <CustomTableRow type="body">
                                        <TableCellCusttom title={formatDateWithTime(row.created_at)} />
                                        <TableCellCusttom title={row.name}>
                                            <Typography sx={{ maxWidth: 200 }}>{row.name}</Typography>
                                        </TableCellCusttom>
                                        <TableCellCusttom title={formatCPF(row.cpf)} />
                                        <TableCellCusttom title={row.gender} />
                                        <TableCellCusttom title={row.birthday ? formatDateOnly(row.birthday) : 'Não informado'} />
                                        <TableCellCusttom title={row.email} sx={{
                                            textTransform: 'none !important'
                                        }} />
                                        <TableCellCusttom title={row.whatsapp}>
                                            <Grid
                                                component={Button}
                                                maxWidth={150}
                                                p={0}
                                                color={"inherit"}
                                                variant='text'
                                                sx={{ textDecoration: 'underline' }}
                                                onClick={() => {
                                                    window.open(`https://api.whatsapp.com/send/?phone=55${row?.whatsapp}`, '_blank');
                                                }}
                                                container
                                                gap={1}
                                                alignItems="center">
                                                {phoneMask(row?.whatsapp ?? '')}
                                            </Grid>
                                        </TableCellCusttom>
                                        <TableCellCusttom title={row.link_info.sale_table.split("on-")[1]} />
                                        <TableCellCusttom title={`${row.address.city} - ${row.address.state}`} />
                                        <TableCellCusttom title={row.address.cep} />
                                        {User?.space !== "sales_counter" && <TableCellCusttom title={row.link_info.sales_counter ? `${row.link_info.sales_counter.id} - ${row.link_info.sales_counter.responsible}` as string : ""} />}
                                    </CustomTableRow>
                                ))
                            }

                        />
                }
                {matchesMaxMobile &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={total}
                        translate='yes'
                        labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                        labelRowsPerPage={`páginas:`}
                        rowsPerPage={rowsPerPage ?? 10}
                        page={page - 1}
                        onPageChange={(event, page) => setPage(page + 1)}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPage(Number(rows.target.value))
                        }}
                        backIconButtonProps={
                            page === 1
                                ? {
                                    disabled: true
                                }
                                : undefined
                        }

                    />
                }
            </Grid>
        </Grid >
    );
}
