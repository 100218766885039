import { Remote } from "service/remote";

export const getAccount = async (data?: any) => {
    return await Remote({
        method: data ? 'patch' : "get",
        urn: `online/commissions/request/account`,
        ...(data && { data })
    })

}

export default getAccount;