import { Button, Grid, IconButton, TableCell, Typography, Select, FormControl, TextField, MenuItem, InputLabel, InputAdornment, CircularProgress, OutlinedInput, Stack } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { useEffect, useRef, useState } from 'react';
import RemoteServices from 'service';
import { MdEdit, } from 'react-icons/md';
import { useNavigate } from 'react-router';
import useMediasQuerys from 'hooks/mediasQuery';

import { TablePagination } from '@material-ui/core';
import TooltipUI from 'components/@ui/Tooltip';

import ExcelIcon from 'assets/png/EXCEL.png';
import Axios from 'axios';
import URLSBases from 'utils/urlBases';
import storageLocales from 'utils/storageLocales';

import { GestorsProps } from 'types/gestor';
import { Utils } from 'utils/formatter';
import { ImBlocked } from "react-icons/im";
import { IoMdEye } from 'react-icons/io';
import { BsCheck2Circle } from 'react-icons/bs';
import { SelectUI } from 'components/@ui/select';
import Toast from 'components/@ui/toast';
import ModalUI from 'components/@ui/modal';
import ButtonUI from 'components/@ui/button';
import useGeneral from 'context/general';

export default function Gestor() {

    const { matchesMaxMobile } = useMediasQuerys()
    const { setChatOpen } = useGeneral()

    const navigate = useNavigate()

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const [listGestors, setlistGestors] = useState<GestorsProps[] | null>(null)


    const [search, setSearch] = useState("")
    const [filterParam, setFilterParam] = useState("responsible_name")
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)

    const [canCreateGestor, setCanCreateGestor] = useState(false)
    const [openAlertCannotCreateGestor, setOpenAlertCannotCreateGestor] = useState(false)

    useEffect(() => {
        getGestors()
    }, [page, rowsPerPage])

    useEffect(() => {
        RemoteServices.auth.Me().then(({ response, status }: any) => {
            if (status === 200) {
                setCanCreateGestor(response.data.owner.can_create_gestor)
            }
        })
    }, [])

    const getGestors = async (clear: boolean = false) => {
        let params: {
            limit: number,
            page: number,
            name?: string,
            cpf?: string,
            phone?: string,

        } = {
            limit: rowsPerPage,
            page
        }
        if (!clear && search) {
            //@ts-ignore
            params[filterParam] = search
            setLoading(true)
        }
        if (clear) {
            params.page = 1
            params.limit = 10
            setRowsPerPage(10)
            setPage(1)
        }
        await RemoteServices.gestor.getGestor(params)
            .then(({ response }) => {
                console.log(response)
                if (response?.gestors) {
                    setlistGestors(response.gestors)
                    setTotal(response?.meta?.total_items)
                }
            }).finally(() => setLoading(false))
    }

    const download = async () => {
        setLoadingDownload(true)
        Axios({
            url: URLSBases.utils + "/gestors",
            params: {
                page: 1,
                limit: total,
                export: true,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
            },
            responseType: 'blob'
        }).then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            const tempLink = document.createElement('a');
            tempLink.href = fileUrl;
            tempLink.setAttribute('download', 'usuários Matriz.xlsx');
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(fileUrl);
        }).finally(() => setLoadingDownload(false))
    }

    const handleActive = async (active: boolean, id: number) => {
        RemoteServices.gestor.updateGestor({
            active: active
        }, id).then(({ response, status }) => {
            if (status === 200) {
                Toast({
                    type: 'success',
                }, `Usuário Matriz ${active ? 'ativado' : 'desativado'}!`)
            }
        })
    }

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                    justifyContent={"space-between"}
                >
                    <div>
                        <Grid container gap={2}>
                            <img
                                src={Icons.IconFiliais}
                                alt="icon"
                                width={25}
                                style={{ filter: 'brightness(0)' }}
                            />
                            <Typography
                                textTransform={"uppercase"}
                                color={colors.black}
                                variant="h5"
                                fontWeight={"bold"}>Usuários Matriz</Typography>
                        </Grid>
                    </div>
                    <Grid
                        item
                        display={"flex"}
                        gap={2}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        flexWrap={matchesMaxMobile ? "wrap" : "nowrap"}
                    >
                        <Grid
                            container
                            display={"flex"}
                            gap={2}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={"row"}
                        >
                            <Grid item minWidth={loadingDownload ? 224 : 200}>
                                <Button
                                    startIcon={<img src={ExcelIcon} />}
                                    sx={{
                                        bgcolor: "#158249",
                                        color: "white",
                                        p: 1.8,
                                        ":hover": {
                                            bgcolor: 'rgba(21, 130, 73, 0.9)',
                                        },
                                        ":disabled": {
                                            color: 'white'
                                        }
                                    }}
                                    disabled={loadingDownload || total === 0}
                                    onClick={download}
                                    endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
                                >Exportar para Excel</Button>
                            </Grid>
                            <Button
                                disableElevation
                                onClick={() => canCreateGestor ? navigate('/app/usuarios-matriz/novo-usuario-matriz') : setOpenAlertCannotCreateGestor(true)}
                                sx={{ p: 2, minWidth: 200, textTransform: 'none', height: 56.5 }}
                                variant="contained">
                                Criar novo usuário Matriz
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
                {
                    matchesMaxMobile ? listGestors && listGestors.map((row, i) => (
                        <Grid
                            p={1}
                            width="100%"
                            border={`1px solid ${colors.grey400}`}
                            my={2}
                            borderRadius={2}
                            bgcolor="white">
                            <Grid pt={1} container fontSize={12} color={colors.grey600} direction="column">
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">ID: </Typography>{row.id}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">NOME: </Typography>{row.name}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">E-mail: </Typography>{row.email}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">WhatsApp: </Typography>{Utils.formatCellphone(row.phone)}
                                </Grid>
                            </Grid>
                            <Grid pt={2} container borderTop={`1px solid ${colors.grey400}`}>
                                <Grid xs={7}>
                                    <SelectUI
                                        key={row.id}
                                        identifier={row.id}
                                        value={row.active}
                                        options={[
                                            {
                                                color: colors.success800,
                                                label: 'ATIVO',
                                                value: true,
                                                icon: <BsCheck2Circle color={colors.success800} size={22} style={{
                                                    minWidth: 22
                                                }} />
                                            },
                                            {
                                                color: colors.error,
                                                label: 'INATIVO',
                                                value: false,
                                                icon: <ImBlocked color={colors.error} size={20} style={{
                                                    minWidth: 20
                                                }} />
                                            },
                                        ]}
                                        onChange={(value: any) => {
                                            handleActive(value, row.id)
                                        }}
                                    />
                                </Grid>
                                <Grid container gap={1} xs={5} justifyContent={"flex-end"}>
                                    <TooltipUI title='Ver detalhes do Usuário Matriz'>
                                        <IconButton onClick={() => {
                                            navigate(`/app/usuarios-matriz/detalhes/${row.id}`, {
                                                state: row
                                            })
                                        }}>
                                            <IoMdEye color={colors.black} />
                                        </IconButton>
                                    </TooltipUI>
                                </Grid>
                            </Grid>
                        </Grid>
                    )) :
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            HeaderComponent={
                                <CustomTableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>NOME</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>EMAIL</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>WHATSAPP</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>STATUS</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>DETALHES</TableCell>

                                </CustomTableRow>
                            }
                            RowComponent={
                                listGestors && listGestors.map((row, i) => (
                                    <CustomTableRow type={'body'} key={i}>
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.id} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.name} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.email} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={Utils.formatCellphone(row.phone)} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.active === true ? 'true' : 'falae'} >
                                            <SelectUI
                                                key={row.id}
                                                identifier={row.id}
                                                value={row.active}
                                                options={[
                                                    {
                                                        color: colors.success800,
                                                        label: 'ATIVO',
                                                        value: true,
                                                        icon: <BsCheck2Circle color={colors.success800} size={22} style={{
                                                            minWidth: 22
                                                        }} />
                                                    },
                                                    {
                                                        color: colors.error,
                                                        label: 'INATIVO',
                                                        value: false,
                                                        icon: <ImBlocked color={colors.error} size={20} style={{
                                                            minWidth: 20
                                                        }} />
                                                    },
                                                ]}
                                                onChange={(value: any) => {
                                                    handleActive(value, row.id)
                                                }}
                                            />
                                        </TableCellCusttom >
                                        <TableCellCusttom sx={{ textTransform: 'none !important', maxWidth: 125 }} title={row.id} align='justify'>
                                            <Grid container gap={1}>
                                                <TooltipUI title='Ver detalhes do Usuário Matriz'>
                                                    <IconButton onClick={() => {
                                                        navigate(`/app/usuarios-matriz/detalhes/${row.id}`, {
                                                            state: row
                                                        })
                                                    }}>
                                                        <IoMdEye color={colors.black} />
                                                    </IconButton>
                                                </TooltipUI>
                                            </Grid>
                                        </TableCellCusttom>
                                    </CustomTableRow>
                                ))
                            }
                        />
                }

                {matchesMaxMobile &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={total}
                        translate='yes'
                        labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                        labelRowsPerPage={`páginas:`}
                        rowsPerPage={rowsPerPage ?? 10}
                        page={page - 1}
                        onPageChange={(event, page) => setPage(page + 1)}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPage(Number(rows.target.value))
                        }}
                        backIconButtonProps={
                            page === 1
                                ? {
                                    disabled: true
                                }
                                : undefined
                        }

                    />
                }
            </Grid>
            <ModalUI open={openAlertCannotCreateGestor} handleClose={() => setOpenAlertCannotCreateGestor(false)}>
                <Grid p={4} mt={2} container gap={5} justifyContent={'center'}>
                    <Typography fontWeight={'bold'} textAlign={'center'}>Solicite a ativação da função CRIAR GESTOR através do atendimento</Typography>
                    <Grid container justifyContent={'center'} gap={3}>
                        <ButtonUI
                            type="submit"

                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setChatOpen(true)
                                setOpenAlertCannotCreateGestor(false)
                            }}
                        >
                            Atendimento
                        </ButtonUI>
                    </Grid>
                </Grid>
            </ModalUI>
        </Grid>
    );
}