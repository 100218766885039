import moment from 'moment';

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export const formatPercent = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(value);
};


export const formatDateOnly = (date: Date | string) => {
  const dateToFormat = moment(date);
  return dateToFormat.format('DD/MM/YYYY');
};

export const formatDateWithTime = (date: Date | string) => {
  const dateToFormat = moment(date);
  return dateToFormat.format('DD/MM/YYYY HH:mm:ss');
};

export const dataBR = (newData: Date | string, onlymonth?: boolean) => {
  let data = new Date(newData);
  let day = data.getDate().toString().padStart(2, '0')
  let month = (data.getMonth() + 1).toString().padStart(2, '0')
  let year = data.getFullYear()

  if (onlymonth) {
    return month
  }
  return day + "/" + month + "/" + year;
}

export function getLegibleDate(date: Date, noyaer?: boolean) {
  moment().locale('pt-BR')
  const dateToFormat = moment(date);
  return dateToFormat.format("DD MMM");
}

export const monthsMin = [
  'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago',
  'set', 'out', 'nov', 'dez'
]


export function formatDate(newData: string) {
  const split = newData?.split('-')
  return split[0] + "/" + split[1] + "/" + split[2].split('T')[0];
}

export const phoneMask = (value: string) => {
  if (!value) return ""
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d)(\d{4})$/, "$1-$2")
  return value
}

export const toCurrency = (value: number, withSign: boolean = true) => {
  return `${withSign && 'R$'} ${(value ?? 0).toLocaleString('pt-BR', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 2,
  })}`;
}

export function formatCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]/g, "");

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}