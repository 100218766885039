import { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import { colors } from 'theme';
import { useLocation } from 'react-router';
import { dataBR, formatCurrency, formatDateWithTime } from '@utils/format';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { RequestCommissionProps, proposalCommission } from 'types/commission';
import { getStatusCommission } from '.';
import useAuth from 'context/auth';
import useMediasQuerys from 'hooks/mediasQuery';
import { FiArrowUpRight } from 'react-icons/fi';
import RemoteServices from 'service';
import ExcelIcon from 'assets/png/EXCEL.png';
import Axios from 'axios';
import URLSBases from 'utils/urlBases';
import storageLocales from 'utils/storageLocales';


export default function HistoricProposal() {
    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        User
    } = useAuth();

    const location = useLocation()

    const [commission, setproposalCommission] = useState<RequestCommissionProps | null>(null)

    const [commissionsProposal, setcommissionsProposal] = useState<proposalCommission[] | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const [rowsPerPageProposal, setRowsPerPageProposal] = useState<number>(10)
    const [pageProposal, setPageProposal] = useState<number>(1)
    const [totalProposal, setTotalProposal] = useState<number>(0)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)


    useEffect(() => {
        if (location?.state) {
            console.log(location.state)
            setproposalCommission(location.state)
            setcommissionsProposal(location.state?.proposals ?? [])
            setTotalProposal(location.state?.proposals.filter((item: any) => item.indication === null).length ?? 0)
            setTotal(location.state?.proposals.filter((item: any) => item.indication === true).length ?? 0)
        } else {
            let id = location.pathname.split('/')[4]
            GetRequestCommissions(Number(id))
        }
    }, [])

    const download = async () => {
        const id = location.state?.id ?? commission?.id
        setLoadingDownload(true)
        Axios({
            url: URLSBases.apisTc + "/online/commissions/request/proposals/" + id,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
            },
            responseType: 'blob'
        }).then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            const tempLink = document.createElement('a');
            tempLink.href = fileUrl;
            tempLink.setAttribute('download', `Detalhes da solicitação ${id}.xlsx`);
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(fileUrl);
        }).finally(() => setLoadingDownload(false))
    }

    const GetRequestCommissions = (id: number) => {
        RemoteServices.commissions.ListRequestCommission({
            limit: 1000,
            page: 1
        })
            .then(({ response }) => {
                console.log(response.result)
                let request = response.result.find((item: any) => item.id === id)
                setproposalCommission(request)
                setcommissionsProposal(request?.proposals ?? [])
                setTotalProposal(request?.proposals.filter((item: any) => item.indication === null).length ?? 0)
                setTotal(request?.proposals.filter((item: any) => item.indication === true).length ?? 0)
            })
    }

    const StatusRender = ({
        isMobile
    }: {
        isMobile?: boolean
    }) => <td style={{
        padding: 20, ...(!isMobile && {
            display: 'flex', flexDirection: 'row', gap: 50, alignItems: 'flex-end'
        })
    }}>
            <Grid>
                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                    Status
                </Typography>
                <Typography color={colors.grey600} variant="subtitle1">
                    <Grid
                        container
                        width={170}
                        gap={1}
                        borderRadius={4}
                        bgcolor={getStatusCommission(commission?.status ?? 'reproved').color + 15}
                        color={getStatusCommission(commission?.status ?? 'reproved').color}
                        py={.5}
                        p={1}
                        textAlign="center"
                    >
                        {getStatusCommission(commission?.status ?? 'reproved').Icon}
                        <Typography sx={{ textTransform: 'none' }}>
                            {getStatusCommission(commission?.status ?? 'reproved').statuscommission}
                        </Typography>
                    </Grid>
                </Typography></Grid>

            {!isMobile && <Button
                startIcon={<img src={ExcelIcon} />}
                sx={{
                    bgcolor: "#158249",
                    color: "white",

                    p: 1.8,
                    ":hover": {
                        bgcolor: 'rgba(21, 130, 73, 0.9)',
                    },
                    ":disabled": {
                        color: 'white'
                    }
                }}
                disabled={loadingDownload}
                onClick={download}
                endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
            >Exportar para Excel</Button>}
        </td>

    const NotaFiscalRender = () => <td style={{ padding: 20 }}>
        <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
            Nota fiscal
        </Typography>
        <Typography color={colors.grey600} variant="subtitle1">
            {
                matchesMaxMobile ?
                    <Grid
                        target='_black'
                        component="a"
                        href={commission?.invoice_url}
                        sx={{ textDecoration: 'underline' }}>
                        Abrir nota fiscal <FiArrowUpRight />
                    </Grid>
                    :
                    <Grid
                        sx={{ whiteSpace: 'nowrap' }}
                        maxWidth={500}
                        overflow="hidden"
                        textOverflow={"ellipsis"}
                    >
                        {
                            commission?.invoice_url ?
                                <a href={commission.invoice_url} target='_blank'>
                                    {commission.invoice_url}
                                </a>
                                :
                                "Não enviado"
                        }
                    </Grid>
            }


        </Typography>
    </td>

    return (
        <Grid xs={12} p={2}>
            <Breadcrumb />
            <Grid xs={12} container gap={2} px={2}>
                <Grid container mt={4} mb={2}>
                    <Typography color={colors.black} variant="h6" textTransform={"uppercase"} fontWeight={"bold"}>
                        Detalhes da solicitação id: {location.state?.id ?? commission?.id ?? '0'}
                    </Typography>
                </Grid>
                <Grid container>
                    <table>
                        <tr>
                            <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Solicitado em {' '}
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {dataBR(commission?.created_at ?? '00/00/0000')}
                                </Typography>
                            </td>
                            <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Descontado
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {formatCurrency(commission?.discounted ?? 0)}
                                </Typography>
                            </td>
                            {!matchesMaxMobile && <NotaFiscalRender />}
                        </tr>
                        <tr>
                            <td style={{ padding: 20 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Atualizado em
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {dataBR(commission?.updated_at ?? '00/00/0000')}
                                </Typography>
                            </td>
                            <td style={{ padding: 20 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Valor da solicitação
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {formatCurrency(commission?.commission_amount ?? 0)}
                                </Typography>
                            </td>
                            {!matchesMaxMobile && <StatusRender isMobile={false} />}
                        </tr>
                        {matchesMaxMobile &&
                            <>
                                <tr>
                                    <NotaFiscalRender />
                                    <StatusRender isMobile={true} />
                                </tr>
                                <tr>
                                    <Button
                                        startIcon={<img src={ExcelIcon} />}
                                        sx={{
                                            bgcolor: "#158249",
                                            color: "white",

                                            p: 1.8,
                                            ":hover": {
                                                bgcolor: 'rgba(21, 130, 73, 0.9)',
                                            },
                                            ":disabled": {
                                                color: 'white'
                                            }
                                        }}
                                        disabled={loadingDownload}
                                        onClick={download}
                                        endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
                                    >Exportar para Excel</Button>
                                </tr>
                            </>
                        }
                    </table>
                </Grid>
                <Grid item my={4} md={12} lg={7.9} xs={12}>
                    <Typography color={colors.black} variant="h6" fontWeight={"bold"}>
                        Propostas que compõem a comissão
                    </Typography>
                    <Grid height={50} />
                    <Grid maxHeight={600} sx={{ overflowY: 'auto' }}>
                        {
                            matchesMaxMobile ?
                                commissionsProposal?.map((row) => {
                                    if (row.indication === null) {
                                        return (
                                            <Grid
                                                p={1}
                                                width="100%"
                                                border={`1px solid ${colors.grey400}`}
                                                my={2}
                                                borderRadius={2}
                                                bgcolor="white">
                                                <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`} alignItems="center">
                                                    <Grid item xs={6} container gap={2}>
                                                        <Typography fontWeight="bold">Comissão </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} textAlign="end">
                                                        <Typography fontWeight={"bold"} color={colors.primary}>
                                                            {formatCurrency(row?.commission_amount)}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                                <Grid pt={1} container fontSize={14} color={colors.grey600}>
                                                    <Grid xs={6} item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                                        <Typography fontWeight="bold">Proposta: </Typography>{row?.proposal_id}
                                                    </Grid>
                                                    <Grid xs={6} item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                                        <Typography fontWeight="bold">Criada em: </Typography>{formatDateWithTime(row.created_at)}
                                                    </Grid>
                                                    <Grid xs={6} item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                                        <Typography fontWeight="bold">Tabela:  </Typography>{row.sale_table}
                                                    </Grid>
                                                    <Grid xs={6} item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                                        <Typography fontWeight="bold">Valor da proposta: </Typography>{formatCurrency(row.net_amount)}
                                                    </Grid>
                                                    <Grid xs={6} item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                                        <Typography fontWeight="bold">Parcelas: </Typography>{row.parcels}x
                                                    </Grid>
                                                    <Grid xs={6} item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                                        <Typography fontWeight="bold">Valor do contrato: </Typography>{formatCurrency(row.gross_amount)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                })
                                :
                                <TableUI
                                    page={pageProposal - 1}
                                    onPageChange={(event, page) => setPageProposal(page + 1)}
                                    total={totalProposal}
                                    rowsPerPage={rowsPerPageProposal}
                                    onRowsPerPageChange={(rows) => {
                                        setRowsPerPageProposal(Number(rows.target.value))
                                    }}
                                    propsTable={{
                                        sx: {
                                            maxHeight: 500
                                        }
                                    }}
                                    HeaderComponent={<></>}
                                    propsTableBody={{
                                        sx: {
                                            maxHeight: 500
                                        }
                                    }}
                                    RowComponent={
                                        commissionsProposal?.map((_commission) => {
                                            if (_commission?.indication === null) {
                                                return (
                                                    <CustomTableRow type={'body'} key={_commission?.id}>
                                                        <TableCellCusttom title={'row.id'}>
                                                            <Grid>
                                                                <Grid container justifyContent={"space-between"}>
                                                                    <span>
                                                                        <strong>PROPOSTA:</strong> <br />
                                                                        {_commission?.proposal_id ?? '0'}
                                                                    </span>
                                                                    <Grid>
                                                                        <strong>Valor da proposta:</strong> <br />
                                                                        {formatCurrency(_commission?.net_amount) ?? 'R$ 0,00'}
                                                                    </Grid>
                                                                    <Grid>
                                                                        <strong>Valor do contrato:</strong> <br />
                                                                        {formatCurrency(_commission?.gross_amount) ?? 'R$ 0,00'}
                                                                    </Grid>
                                                                    <Grid px={3}>
                                                                        <strong>Comissão:</strong> <br />
                                                                        {formatCurrency(_commission?.commission_amount) ?? 'R$ 0,00'}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container mt={2} gap={5}>
                                                                    <Grid>
                                                                        <Typography sx={{ textTransform: 'none' }} fontSize={13} color={colors.grey400}>
                                                                            <strong>Criado em:</strong> {dataBR(_commission?.created_at) ?? '00/00/0000'}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography sx={{ textTransform: 'none' }} fontSize={13} color={colors.grey400}>
                                                                            <strong>Parcelas:</strong> {_commission?.parcels ?? '0'}x
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography sx={{ textTransform: 'none' }} fontSize={13} color={colors.grey400}>
                                                                            <strong>Tabela: </strong> {_commission?.sale_table.replace('on-', ' ') ?? ''}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCellCusttom>
                                                    </CustomTableRow>
                                                )
                                            }
                                        })
                                    }
                                />
                        }

                    </Grid>
                </Grid>
                {User?.space !== "sales_counter" && <Grid item my={4} md={12} lg={3.9} xs={12}>
                    <Typography color={colors.black} variant="h6" fontWeight={"bold"}>
                        Remunerações sobre as comissões dos correspondentes indicados
                    </Typography>
                    <Grid height={18} />
                    <Grid maxHeight={600} sx={{ overflowY: 'auto' }}>
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            propsTable={{
                                sx: { minWidth: 300, }
                            }}
                            HeaderComponent={<></>}
                            RowComponent={
                                commissionsProposal?.map((_commission) => {
                                    if (_commission?.indication) {
                                        let proposal = _commission?.part_of_commission.find((item) => item.profile === "pdv" && item.indication === null)
                                        return (
                                            <Grid
                                                p={2}
                                                bgcolor={colors.primary200 + 20}>
                                                <Grid>
                                                    <Grid container justifyContent={"space-between"}>
                                                        <span>
                                                            <strong>{proposal?.id} - {proposal?.trade_name}</strong>
                                                        </span>
                                                        <span>
                                                            <Typography color={colors.primary} fontWeight={"bold"}>
                                                                <strong>{formatCurrency(_commission?.commission_amount) ?? 'R$ 0,00'}</strong>
                                                            </Typography>
                                                        </span>
                                                    </Grid>
                                                    <Grid container mt={2} gap={5}>
                                                        <Grid>
                                                            <Typography sx={{ textTransform: 'none' }} fontSize={13} color={colors.grey400}>
                                                                <strong>PROPOSTA:</strong> {proposal?.proposal_id ?? '0'}
                                                            </Typography>
                                                        </Grid>
                                                        {/* <Grid>
                                                    <Typography sx={{ textTransform: 'none' }} fontSize={13} color={colors.grey400}>
                                                        <strong>Criado em:</strong> {dataBR(_commission?.created_at) ?? '00/00/0000'}
                                                    </Typography>
                                                </Grid> */}
                                                    </Grid>
                                                </Grid>
                                                <Grid height={'1px'} width="calc(100% - 16px)" bgcolor="#00000080" mt={2} />
                                            </Grid>
                                        )
                                    }
                                })
                            }
                        />
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    );
}
