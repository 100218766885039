import { Remote } from "service/remote";

export const StatisticsPopulate = async () => {
    return await Remote({
        method: "post",
        urn: "/data/dashboard/populate",
        api: 'data-api',
        data: {
            salesCounters: -1
        }
    })

}

export default StatisticsPopulate;