import useMediaQuery from '@mui/material/useMediaQuery';

const useMediasQuerys = () => {
    const matchesMaxMobile = useMediaQuery('(max-width:1001px)');
    const matchesOtherBanner = useMediaQuery('(max-width:1400px)');
    const matchesMinMaxTablet = useMediaQuery('(min-width:768px) and (max-width: 1023px)');
    const matchesMinDesktop = useMediaQuery('(max-width:1024px)');


    return {
        matchesMaxMobile,
        matchesMinMaxTablet,
        matchesMinDesktop,
        matchesOtherBanner
    }

}

export default useMediasQuerys