import { Remote } from "service/remote";

export const PixAwardConsult = async (id: string | number) => {
    return await Remote({
        method: "get",
        urn: `online/commissions/clerk/${id}`
    })

}

export default PixAwardConsult;