import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { colors } from "theme";

import { useEffect, useState } from "react"
import { formatCurrency, formatPercent } from "@utils/format";
import { SalesTableProps } from "types/proposals";
import useAuth from "context/auth";
import useProposal from "context/proposal";
import { moneyMask } from "@utils/moneymask";
import useMediasQuerys from "hooks/mediasQuery";
import TooltipUI from "components/@ui/Tooltip";

const TableLending = ({
    color,
    item,
    sx,
    onClick,
    notableNameComplete
}: {
    color?: string;
    sx?: SxProps<Theme>,
    item?: SalesTableProps | null,
    onClick?: (item: SalesTableProps | null) => void;
    notableNameComplete?: boolean
}) => {

    const { matchesMaxMobile } = useMediasQuerys()

    const { myTax, User, defaultUser } = useAuth()

    const { cardLimit } = useProposal()

    const [saleTable, setSaleTable] = useState<SalesTableProps | null>(null)


    useEffect(() => {
        if (item) {
            setSaleTable(item)
        }
    }, [item])

    const TableName = (notableNameComplete ? '' : 'TADIG-') + saleTable?.sale_table?.replace('on-', '*')

    const renderTax = (): number => {
        if (User?.space === "sales_counter") {
            return myTax?.find((i: any) => i?.sale_table === saleTable?.sale_table)?.commission_rate as number
        } else if (User?.space === "pdv") {
            return myTax?.find((i: any) => i?.sale_table === saleTable?.sale_table)?.commission_tax as number
        } else {
            if (defaultUser?.permissions?.permissions.includes('readCommisionOnGenerateProposal')) {
                return myTax?.find((i: any) => i?.sale_table === saleTable?.sale_table)?.commission_tax as number
            } else {
                return 0
            }
        }
    }

    return (
        <Box
            sx={{
                px: matchesMaxMobile ? 1 : 2,
                py: 2,
                border: '2px solid ' + color ?? colors.primary,
                borderRadius: '4px',
                position: 'relative',
                cursor: 'pointer',
                bgcolor: colors.Background,
                ":before": {
                    position: 'absolute',
                    content: `"Tabela ${saleTable?.sale_table?.replace('on-', '')}"`,
                    top: -40,
                    left: '50%',
                    bgcolor: color ?? colors.primary,
                    color: 'white',
                    height: 40,
                    zIndex: -1,
                    padding: 1,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    transform: 'translate(-50%)',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '1.2em'
                },
                ":hover": {
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                },
                ...(sx ?? {})
            }}
        >
            <TooltipUI title={User?.space === "gestor" ? defaultUser?.permissions?.permissions.includes("generateProposal") ? "" : "Você não possui permissão para gerar proposta" : ""} followCursor>
                <Grid onClick={() => { 
                    if (User?.space === "gestor") {
                        if (defaultUser?.permissions?.permissions.includes("generateProposal")) onClick && onClick(item ?? null);
                    } else {
                        onClick && onClick(item ?? null);
                    }
                 }} container gap={2} flexDirection={"column"} alignItems="center">
                    <Grid textAlign="center">
                        <Typography fontSize={14} fontWeight="bold" color={colors.fourth}>Valor do empréstimo (na conta do cliente)</Typography>
                        <Typography variant="h5" fontWeight="bold" color={colors.fourth}>{formatCurrency(saleTable?.net_amount ?? 0)}</Typography>
                    </Grid>
                    <div>
                        <Typography variant="subtitle2" fontWeight="400">Parcelado em {saleTable?.parcels}x de</Typography>
                        <Typography textAlign={"center"} variant="h6" fontWeight="bold">{formatCurrency(saleTable?.parcels_value ?? 0)}</Typography>
                    </div>
                    <Grid container flexDirection={"column"} alignItems="center">
                        {
                            matchesMaxMobile ?
                                <Grid
                                    border={`solid 1px ${colors.grey300}`}
                                    borderRadius={3}>
                                    <Grid
                                        container
                                        alignItems={'center'}
                                        p={1}
                                        borderBottom={`solid 1px ${colors.grey300}`}
                                        justifyContent="center"
                                        fontSize={13}
                                        color={colors.grey600}
                                    >
                                        Saldo do cartão: {formatCurrency(saleTable?.gross_amount ?? 0)}
                                    </Grid>
                                    <Grid container alignItems={'center'} justifyContent={"space-between"} p={1}>
                                        <Typography p={.5} fontSize={10} color={colors.grey600}>
                                            Taxa total: {formatPercent((((saleTable?.gross_amount ?? 0) - (saleTable?.net_amount ?? 0)) / (saleTable?.net_amount ?? 0)))}
                                        </Typography>
                                        <Typography py={.5} px={.5} fontSize={10} color={colors.grey600} fontWeight={"600"} borderLeft={`solid 1px ${colors.grey300}`} borderRight={`solid 1px ${colors.grey300}`}>
                                            Taxa ao mês: {formatPercent(((((saleTable?.gross_amount ?? 0) - (saleTable?.net_amount ?? 0) ?? 0) / (saleTable?.net_amount ?? 0) ?? 0) / (saleTable?.parcels ?? 0)))}
                                        </Typography>
                                        <Typography p={.5} fontSize={10} color={colors.grey600} >
                                            IOF: {formatCurrency((saleTable?.iof ?? 0))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                <table id="table-simulator">
                                    <thead>
                                        <tr>
                                            <th>Saldo do cartão</th>
                                            <th>Taxa total</th>
                                            <th style={{ color: colors.black }}>Taxa ao mês</th>
                                            <th>IOF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{formatCurrency(saleTable?.gross_amount ?? 0)}</td>
                                            <td>{formatPercent((((saleTable?.gross_amount ?? 0) - (saleTable?.net_amount ?? 0)) / (saleTable?.net_amount ?? 0)))}</td>
                                            <td style={{ color: colors.black }}>{formatPercent(((((saleTable?.gross_amount ?? 0) - (saleTable?.net_amount ?? 0) ?? 0) / (saleTable?.net_amount ?? 0) ?? 0) / (saleTable?.parcels ?? 0)))}</td>
                                            <td>{formatCurrency((saleTable?.iof ?? 0))}</td>
                                        </tr>
                                    </tbody>

                                </table>
                        }
                    </Grid>
                    <Grid textAlign="center">
                        {
                            renderTax() > 0 &&
                            <>
                                <Typography
                                    fontSize={14}
                                    fontWeight="bold"
                                    color={colors.fourth}>
                                    Receba a comissão de {formatPercent(renderTax() ?? 0)}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    fontWeight="bold"
                                    color={colors.fourth}>
                                    {formatCurrency((saleTable?.net_amount ?? 0) * (renderTax() ?? 0))}
                                </Typography>
                            </>
                        }
                    </Grid>
                    {User?.space === "sales_counter" &&
                        <Grid textAlign="center">
                            <Typography
                                fontSize={12}
                                fontWeight="bold"
                                color={colors.grey500}>+ GRATIFICAÇÃO POR ATENDIMENTO</Typography>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                color={colors.grey500}>
                                {formatCurrency(((saleTable?.net_amount ?? 0) * (saleTable?.commission_tax ?? 0)) * 0.1)}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </TooltipUI>
        </Box>
    )
}

export default TableLending;