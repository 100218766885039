import ModalUI from "components/@ui/modal"

import { Grid, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, FormHelperText, Typography } from "@mui/material"
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import ButtonUI from "components/@ui/button";
import schemaChangeOperatorPassword from "@utils/validator";
import RemoteServices from "service";
import useAuth from "context/auth";
import Toast from "components/@ui/toast";
import useMediasQuerys from "hooks/mediasQuery";

const ChangePassword = ({
    open,
    handleClose
}: {
    open: boolean,
    handleClose: () => void
}) => {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        logout,
        User,
        defaultUser
    } = useAuth()

    const [oldPasswordIsVisible, setOldPasswordIsVisible] = useState(false);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [passwordConfirmationIsVisible, setPasswordConfirmationIsVisible] =
        useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [statusOldPassword, setStatusOldPassword] = useState('');

    const [password, setPassword] = useState('');
    const [statusPassword, setStatusPassword] = useState('');

    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [statusPasswordConfirmation, setStatusPasswordConfirmation] = useState('');

    const [loading, setLoading] = useState(false);

    const clearForm = () => {
        setPassword('');
        setStatusPassword('');

        setOldPassword('');
        setStatusOldPassword('');

        setPasswordConfirmation('');
        setStatusPasswordConfirmation('');
    };

    const sendForm = () => {
        setLoading(true);
        schemaChangeOperatorPassword
            .validate(
                { oldPassword, password, passwordConfirmation },
                { abortEarly: false },
            )
            .then(() => {
                
                const dataGestor = {
                    password,
                    oldPassword
                };

                (async () => {
                    if (User?.space === "gestor") {
                        RemoteServices.gestor.updateGestor(dataGestor, defaultUser?.id as number)
                            .then(({ response , status}) => {
                                if(status === 200){
                                    Toast({
                                        type: 'success',
                                    }, 'Senha atualizada!')
                                    logout();
                                    return
                                }
                                Toast({
                                    type: 'error',
                                }, response?.error ?? response?.message ?? 'Não foi possível alterar a senha. tente novamente!')
                            })
                            .catch((error) => {
                                Toast({
                                    type: 'error',
                                }, 'Não foi possível alterar a senha. tente novamente!')
                            }).finally(() => setLoading(false))

                    } else {
                        RemoteServices.auth.RedefinePassword({
                            new_password: password,
                            old_password: oldPassword
                        })
                            .then(({ response }) => {
                                if(response?.success){
                                    Toast({
                                        type: 'success',
                                    }, 'Senha atualizada!')
                                    logout();
                                    return
                                }
                                Toast({
                                    type: 'error',
                                }, response?.error ?? 'Não foi possível alterar a senha. tente novamente!')
                            })
                            .catch((error) => {
                                Toast({
                                    type: 'error',
                                }, 'Não foi possível alterar a senha. tente novamente!')
                            }).finally(() => setLoading(false))
                    }
                })();
            })
            .catch((err) => {
                handleValidationError(err);
                setLoading(false);
                Toast({
                    type: 'error',
                }, 'Não foi possível alterar a senha. Verifique os dados informados e tente novamente.')
            });
    };

    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'oldPassword':
                    setStatusOldPassword(element.message);
                    break;
                case 'password':
                    setStatusPassword(element.message);
                    break;
                case 'passwordConfirmation':
                    setStatusPasswordConfirmation(element.message);
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <ModalUI
            handleClose={handleClose}
            open={open}>
            <Grid p={4} container gap={5}>
                <Typography variant="h4" fontWeight="bold">ALTERAR SENHA</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!statusOldPassword}
                        >
                            <InputLabel htmlFor="password">Senha atual</InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                label="Senha atual"
                                value={oldPassword}
                                onChange={(e) => {
                                    if (statusOldPassword) {
                                        setStatusOldPassword('');
                                    }
                                    setOldPassword(e.target.value);
                                }}
                                type={oldPasswordIsVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setOldPasswordIsVisible(
                                                    !oldPasswordIsVisible,
                                                );
                                            }}
                                            edge="end"
                                        >
                                            {oldPasswordIsVisible ? (
                                                <FiEye />
                                            ) : (
                                                <FiEyeOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{statusOldPassword}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!statusPassword}
                        >
                            <InputLabel htmlFor="password">Nova senha</InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                label="Nova senha"
                                value={password}
                                onChange={(e) => {
                                    if (statusPassword) {
                                        setStatusPassword('');
                                    }
                                    setPassword(e.target.value);
                                }}
                                type={passwordIsVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setPasswordIsVisible(!passwordIsVisible);
                                            }}
                                            edge="end"
                                        >
                                            {passwordIsVisible ? (
                                                <FiEye />
                                            ) : (
                                                <FiEyeOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{statusPassword}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!statusPasswordConfirmation}
                        >
                            <InputLabel htmlFor="password">
                                Confirmar nova senha
                            </InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                label="Confirmar nova senha"
                                value={passwordConfirmation}
                                onChange={(e) => {
                                    if (statusPasswordConfirmation) {
                                        setStatusPasswordConfirmation('');
                                    }
                                    setPasswordConfirmation(e.target.value);
                                }}
                                type={
                                    passwordConfirmationIsVisible ? 'text' : 'password'
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setPasswordConfirmationIsVisible(
                                                    !passwordConfirmationIsVisible,
                                                );
                                            }}
                                            edge="end"
                                        >
                                            {passwordConfirmationIsVisible ? (
                                                <FiEye />
                                            ) : (
                                                <FiEyeOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>
                                {statusPasswordConfirmation}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justifyContent={matchesMaxMobile ? 'center' :"flex-end"} gap={3}>
                    <ButtonUI
                        disabled={loading}
                        onClick={() => {
                            clearForm();
                            handleClose()
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Fechar
                    </ButtonUI>
                    <ButtonUI
                        type="submit"
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        variant="contained"
                        onClick={sendForm}
                    >
                        Alterar senha
                    </ButtonUI>
                </Grid>
            </Grid>
        </ModalUI>
    )
}

export default ChangePassword;