import { Grid, Typography, Button } from "@mui/material"
import { formatCurrency } from "@utils/format";
import ButtonUI from "components/@ui/button";
import ModalUI from "components/@ui/modal"
import { useNavigate } from "react-router";
import RemoteServices from "service";
import { SalesTableProps } from "types/proposals";

const NoLimiteAmount = ({
    open,
    handleClose,
    amountAlreadyRequested,
    saleTable,
    availableAmount,
    setSaleTable
}: {
    open: boolean,
    handleClose: (a?: SalesTableProps) => void;
    amountAlreadyRequested: number;
    saleTable: SalesTableProps | null;
    availableAmount: number;
    setSaleTable(a: SalesTableProps):void
}) => {

    const navigate = useNavigate()

    const getLink = () => {
        RemoteServices.proposals.GetLinks({
            net_amount: availableAmount,
            parcels: saleTable?.parcels ?? 0,
            sale_table: saleTable?.sale_table ?? ''
        })
        .then(({ response }) => {
            if(response && response?.saleTables){
                // navigate('/pdv/emprestar/tabela', {
                //     state: response?.saleTables[0]
                // })
                handleClose(response?.saleTables[0])
            }
        })
    }

    return (
        <ModalUI
            handleClose={handleClose}
            open={open}>
            <Grid p={2} px={4} container flexDirection={"column"} gap={3}>
                <Typography variant="h5" fontWeight="bold">O LIMITE MENSAL DE EMPRÉSTIMO É DE R$ 5.000,00 POR CLIENTE</Typography>
                <Typography>
                    O cliente informado já solicitou {formatCurrency(amountAlreadyRequested)} em empréstimos nesse mês.
                    Podemos dar continuidade para solicitação de <strong>{formatCurrency(availableAmount)}</strong>?
                </Typography>
                <Grid container justifyContent="flex-end" p={2} mt={2} gap={3}>
                    <ButtonUI onClick={() => navigate('/app')} variant="contained">
                        Não continuar
                    </ButtonUI>
                    <ButtonUI onClick={() => getLink()} variant="contained">
                        Continuar
                    </ButtonUI>
                </Grid>
            </Grid>
        </ModalUI>
    )
}

export default NoLimiteAmount;