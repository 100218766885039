import { Remote } from "service/remote";

export const getKeys = async () => {
    return await Remote({
        method: "get",
        urn: "/online/marketing",
        api: 'default'
    })

}

export const editKeys = async (data: any) => {
    return await Remote({
        method: "patch",
        urn: "/online/marketing",
        api: 'default',
        data
    })

}

export const getMyPosts = async () => {
    return await Remote({
        method: "get",
        urn: "/online/marketing/vaptpost/my-posts"
    })
}

export const getOptionsPost = async () => {
    return await Remote({
        method: "get",
        urn: "/online/marketing/vaptpost/options-posts"
    })
}

export const createUser = async () => {
    return await Remote({
        method: "get",
        urn: "/online/marketing/vaptpost/create-user"
    })
}

export const getLoginLink = async () => {
    return await Remote({
        method: "get",
        urn: "/online/marketing/vaptpost/login-link"
    })
}

export const getLeads = async (params: {
    type?: "none" | "registered" | "not-registered",
    page: number,
    limit: number
}) => {
    return await Remote({
        method: "get",
        urn: "/online/remarketing",
        params
    })
}