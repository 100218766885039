import { colors } from "theme";

const Questions = [
    {
        title: 'Como funciona o empréstimo?',
        content: `É um empréstimo exclusivo para portadores de cartão de crédito, para maiores de 18 e menores de 85 anos de idade; 
        A margem do empréstimo é baseada a partir do saldo disponível no cartão de crédito do cliente, por isso é necessário que você saiba qual é o 
        saldo disponível para compras antes de iniciar a simulação.`
    },
    {
        title: 'Qual é o valor que o cliente pode solicitar de empréstimo?',
        content: `O cliente pode solicitar de R$ 300,00 a R$ 5.000,00 por proposta; O valor máximo de empréstimo por mês para cada cliente é de R$ 5.000,00.`
    },
    {
        title: 'Quais taxas compõe o empréstimo?',
        content: `Nosso empréstimo é da categoria “empréstimo pessoal ao consumidor ou crédito direto ao consumidor”, 
        ele é composto por diversas taxas operacionais, tributárias e administrativas, assim como: IOF – Imposto Sobre Operações 
        Financeiras; MDR - Taxas de Processamento do Cartão de Crédito; Taxa de Ágio Securitizado; 
        Taxa Operacional Administrativa; Tarifa Bancária; PIS/COFINS e Imposto de Renda; Contribuição Social sobre Lucro Líquido.`
    },
    {
        title: 'Qual valor devo simular para iniciar o empréstimo?',
        content: `Deverá simular o valor real do saldo disponível no cartão de crédito do cliente, evitando tanto o bloqueio 
        do cartão de crédito como o seu retrabalho na digitação de nova proposta no sistema. `
    },
    {
        title: 'Porque o antifraude barrou o cadastro?',
        content: `Nosso sistema é integrado a alguns motores de créditos, isso faz com que tenhamos uma resposta imediata do cadastro do cliente, existem alguns motivos em que o antifraudes não aprova o cadastro, por exemplo: 
        <br />
        - Número de telefone recente ou cadastrado em outro CPF;<br />
        - E-mail criado recentemente ou sem atividade recente;<br />
        - Endereço errado ou mudou-se recentemente; <br />
        - Documentos emitidos ou extraviados recentemente;<br />
        - E-mail, telefone ou CPF envolvidos em fraudes ou em outras ocorrências.
        `
    },
    {
        title: 'Qual é o horário de funcionamento para solicitar empréstimo?',
        content: `
            Funcionamos 24 horas por dia, durante os 7 dias da semana, analisamos e aprovamos os empréstimos de segunda-feira a domingo, 
            incluindo feriados, porém o valor é creditado na conta do cliente em horário comercial 
            (verifique os horários abaixo: Em quanto tempo o valor do empréstimo entra na conta do cliente?)
        `
    },
    {
        title: 'Em quanto tempo o valor do empréstimo entra na conta do cliente?',
        content: `
        Geralmente em até 01 (uma) hora o valor do empréstimo será creditado na conta bancária informada,
         para contrato bancário (CCB – Cédula de Crédito Bancário) assinado digitalmente até as 16:30 horas 
         de dias úteis (segunda a sexta-feira); Para contratos assinados após as 16:30 horas, sábados,
         domingos e feriados, o valor do empréstimo será creditado no próximo dia útil até as 09:30 horas.
        `
    },
    {
        title: 'Qual é o principal motivo para reprovação do cartão de crédito?',
        content: `
        O principal motivo é cartão declinado, significa que o cliente não tem costume de utilizar o cartão para compras na internet, 
        mas para solucionar basta solicitar ao cliente que entre em contato com o cartão para autorizar compras na internet.
        `
    },
    {
        title: 'Pode ser utilizado o cartão de crédito de parentes e outras pessoas?',
        content: `
        Não, o cartão de crédito por lei só pode ser utilizado pelo proprietário, 
        evitando incorrer em crime de falsidade ideológica, posse e uso de documentos de terceiros.
        `
    },
    {
        title: 'O celular do cliente não suporta o link para envio das fotos, o que fazer?',
        content: `
        Basta orientar ao cliente que aguarde entre 1 e 2 minutos que abrirá uma outra opção para envio das fotos.
        `
    },
    {
        title: 'Quais fotos o cliente deve capturar durante a conclusão do empréstimo?',
        content: `
        Após o cliente informar os dados bancários, o próprio sistema solicitará automaticamente que o cliente capture 
        “tire as seguintes fotos”, 1 foto de rosto natural (sério), 1 foto de rosto sorrindo (prova de vida), 1 foto da frente do RG ou CNH e 1 foto do verso do RG ou CNH.
        `
    },
    {
        title: 'Após digitar a proposta quais dados e informações o cliente deverá preencher sozinho? ',
        content: `Após inserido os dados do empréstimo no sistema, o cliente receberá um link da AYUDE, 
        para que possa informar seus dados sensíveis “confidenciais” <br />
        - Dados do próprio cartão de crédito; <br />
        - Dados da sua própria conta bancária (onde deseja receber o valor do empréstimo);<br />
        - Fotos do próprio Rosto;<br />
        - Fotos dos documentos pessoais (RG ou CNH);<br />
        - Coleta da própria assinatura eletrônica.
        `
    },
    {
        title: 'Cliente não alfabetizado consegue fazer empréstimo AYUDE?',
        content: `
        Não, clientes não alfabetizados não conseguem fazer empréstimo AYUDE, devido a questões éticas, entendemos que o cliente não alfabetizado não estará ciente do conteúdo a ser "assinado, rubricado" no contrato bancário / CCB – Cédula de Crédito Bancário.
        `
    },
    {
        title: 'Quando posso solicitar a comissão e/ou GRATIFICAÇÃO POR ATENDIMENTO?',
        content: `
        Em apenas 1 minuto após a aprovação do empréstimo você já pode solicitar sua comissão, os empréstimos efetuados através do acesso FILIAL terão sua GRATIFICAÇÃO POR ATENDIMENTO pagas automaticamente na chave pix pré-cadastrada.
        `
    },
    {
        title: 'Preciso emitir nota fiscal para receber a comissão e/ou GRATIFICAÇÃO POR ATENDIMENTO?',
        content: `
        Para receber a comissão é necessário a emissão da nota fiscal de prestação de serviços, 
        conforme normativa da Receita Federal do Brasil – RFB; Para receber a 
        GRATIFICAÇÃO POR ATENDIMENTO não é necessário a emissão da nota fiscal, por se tratar de uma gratificação.
        `
    },
    {
        title: 'O cliente pode cancelar o empréstimo?',
        content: `
        Sim, conforme o Código de Defesa do Consumidor, o cliente pode solicitar o cancelamento do empréstimo em até 07 
        dias após a contratação, basta enviar um e-mail para <a style="color: ${colors.primary};"href="mailto:contato@ayude.com.br">contato@ayude.com.br</a>, o e-mail deve ser originado e enviado através do 
        mesmo e-mail informado e cadastrado junto ao empréstimo; Após recebermos o e-mail, 
        nosso time entrará em contato com o cliente para tratar do cancelamento, iniciando todo procedimento financeiro e jurídico necessário.
        `
    },
    {
        title: 'A Ayude disponibiliza API para integração?',
        content: `
        Sim, disponibilizamos através destes links
        <br/>
        <ul>
            <li><a href="https://tc-docs.s3.sa-east-1.amazonaws.com/matriz/Swagger+UI.html#/" target="_blank">Documentação API Matriz</a></li>
            <li><a href="https://tc-docs.s3.sa-east-1.amazonaws.com/filial/Swagger+UI.html#/" target="_blank">Documentação API Filial</a></li>
        </ul>
        `
    }
]

export default Questions;