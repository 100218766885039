import { useState, useEffect } from "react";
import { Button } from "@mui/material"
import TooltipUI from "components/@ui/Tooltip"
import RemoteServices from "service"
import CommissionRequest from "./request";
import useGeneral from "context/general";
import useAuth from "context/auth";

const GetRequestCommission = ({
    getProposals,
    handleAvailable
}: {
    getProposals: () => void;
    handleAvailable: (availableCommission: number) => void;
}) => {
    const {
        defaultUser,
        User
    } = useAuth()
    const {
        setOpenDialogAccount,
        account,
        setAccount,
        setRedirectToRequest,
        openDialogCommissionRequest,
        setOpenDialogCommissionRequest
    } = useGeneral()

    const [availableCommission, setAvailableCommission] = useState<number>(0)

    const getAvailableCommissions = () => {
        RemoteServices.commissions.Available()
            .then(({
                response
            }) => {
                if (response?.amount) {
                    setAvailableCommission(response.amount)
                    handleAvailable(response.amount)
                }
            })
    }

    const getAccount = async () => {
        RemoteServices.commissions.getAccount().then(({ response }) => {
            if (response?.result?.account === null) {
                setOpenDialogAccount(true)
                setRedirectToRequest(true)
            } else {
                setAccount(response?.result?.account)
                setOpenDialogCommissionRequest(true)
                getProposals()
            }
        })
    }

    useEffect(() => {
        getAvailableCommissions()
    }, [])

    return (
        <div>
            <TooltipUI title={availableCommission === 0 ? 'Não há comissões para receber' : ''}>
                <div>
                    {User?.space !== "gestor" ?
                        <Button
                            disabled={availableCommission <= 0}
                            onClick={() => {
                                getAccount()
                            }}
                            variant="text"
                            color="inherit"
                            sx={{ p: 0, textDecoration: 'underline' }}>
                            Solicitar comissão agora
                        </Button>
                        :
                        defaultUser?.permissions?.permissions.includes("requestCommission") && <Button
                            disabled={availableCommission <= 0}
                            onClick={() => {
                                getAccount()
                            }}
                            variant="text"
                            color="inherit"
                            sx={{ p: 0, textDecoration: 'underline' }}>
                            Solicitar comissão agora
                        </Button>
                    }
                </div>
            </TooltipUI>
            <CommissionRequest
                open={openDialogCommissionRequest}
                getAccount={getAccount}
                commissionValue={availableCommission}
                handleClose={() => setOpenDialogCommissionRequest(false)}
                getProposals={getProposals}
                getAvailableCommissions={() => {
                    getAvailableCommissions()
                    setAvailableCommission(0)
                }}
            />
        </div>
    )
}


export default GetRequestCommission;