import { Remote } from "service/remote";

export const SalesCounter = async ({
    id
}:
    {
        id?: string
    }) => {
    return await Remote({
        method: "get",
        urn: "/sales-counters" + (id ? `/${id}` : ''),
        api: 'manager'
    })

}

export default SalesCounter;