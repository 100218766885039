import axios from "axios";
import { gatewayAuthDataApi } from "service/gateway";
import { Remote } from "service/remote";
import UserStorage from "service/storage/user";

export const Logout = async () => {
    return gatewayAuthDataApi.delete(`/revoke`, {
        data: { refresh_token: UserStorage.getUserTc()?.refresh_token ?? '' },
    });

}

export default Logout;