import { Remote } from "service/remote";

export const Contract = async () => {
    return await Remote({
        method: "get",
        urn: "online/contract"
    })

}

export default Contract;