import React, { useState, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Tooltip,
    Typography,
    withStyles,
    Autocomplete
} from '@mui/material';

import contaBancaria from 'assets/json/bancos.json'
import { mask, unMask } from 'remask';
import ButtonUI from 'components/@ui/button';
import RemoteServices from 'service';
import Toast from 'components/@ui/toast';
import { schemaAddress, schemaClerk } from '@utils/validator';
import { formatCurrency } from '@utils/format';
import { AgencyNumberInputMaskCustom, CEPMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom, KeyPixRandomMaskCustom } from 'components/@ui/masked';
import useMediasQuerys from 'hooks/mediasQuery';
import ModalUI from 'components/@ui/modal';
import { MdErrorOutline, MdOutlineUploadFile } from 'react-icons/md';
import { colors } from 'theme';
import { PiCheckFat } from 'react-icons/pi';
import { DropzoneArea } from 'react-mui-dropzone';
import estadosCidades from 'assets/json/estados_cidades.json'
import { awaitTime } from 'utils/awaitTime';
import ImageBanner from 'assets/png/GANHE + 5 REAIS.png';
import useAuth from 'context/auth';

const masks = [
    '9999-9',
    '99999-9',
    '999999-9',
    '9999999-9',
    '99999999-9',
    '999999999-9',
    '9999999999-9',
    '99999999999-9',
];

function ReportsOnline({
    openDialogCommission,
    proposalId,
    handleClose,
    commissionValue,
    canSendBanner,
    alreadyRequestBanner,
    otherRequests
}: {
    openDialogCommission: boolean;
    proposalId: number | string;
    handleClose: (success?: boolean) => void;
    commissionValue: number;
    canSendBanner: boolean;
    alreadyRequestBanner: boolean;
    otherRequests: Array<{
        proposal: number,
        value: number
    }>
}) {

    const [loadingAccount, setLoadingAccount] = useState(false)
    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        User
    } = useAuth()

    const [invoice, setInvoice] = useState<File[]>([]);
    const [statusInvoice, setStatusInvoice] = useState('');


    const [address, setAddress] = useState('');
    const [statusAddress, setStatusAddress] = useState('');

    const [addressNumber, setAddressNumber] = useState('');
    const [statusAddressNumber, setStatusAddressNumber] = useState('');

    const [district, setDistrict] = useState('');
    const [statusDistrict, setStatusDistrict] = useState('');

    const [addressComplement, setAddressComplement] = useState('');

    const [CEP, setCEP] = useState('');
    const [statusCEP, setStatusCEP] = useState('');

    const [state, setState] = useState({ nome: '', sigla: '' });
    const [statusState, setStatusState] = useState('');

    const [city, setCity] = useState('');
    const [statusCity, setStatusCity] = useState('');

    const [cities, setCities] = useState<any[]>([]);

    const [disabledByCEP, setDisabledByCEP] = useState(false)

    const [openModalAddress, setOpenModalAddress] = useState(false)
    const [hiddenButtonAddress, setHiddenButtonAddress] = useState(false)

    const [loadingAddress, setLoadingAddress] = useState(false)

    async function requestCommission() {
        setLoadingAccount(true)
        const data = new FormData();
        data.append('invoice', invoice[0]);
        console.log(data, 'chamou awui')
        await RemoteServices.commissions.PixAward(proposalId, data).then(({ data }) => {
            console.log(data)
            //@ts-ignore
            console.log(data?.id)
            //@ts-ignore
            if (data?.id) {
                Toast({
                    type: "success",
                }, "Gratificação por atendimento solicitada com sucesso!")
                handleClose(true)
            }
        }).catch((err) => {
            console.log(err)
            Toast({
                type: "error"
            }, err.response?.message ?? "Falha ao solicitar gratificação por atendimento!")
        }).finally(() => {
            setLoadingAccount(false)
        })
    }

    function getCep(cep: string) {
        const formatCep = cep.replace(/[^\d]/g, "")
        RemoteServices.external.GetCep({
            formatCep
        }).then(async ({ response }) => {
            if (!response?.erro) {
                const state = estadosCidades.find((item) => item.sigla === response?.uf)

                if (state && response?.localidade) {
                    setState({ nome: state?.nome ?? '', sigla: state?.sigla ?? '' })
                    setCity(response?.localidade)
                    setDisabledByCEP(true)
                }
                if (statusState) setStatusState('')
                if (statusCity) setStatusCity('')
                if (statusDistrict) setStatusDistrict('')
                if (statusAddress) setStatusAddress('')
            } else {
                setCity('')
                setState({ nome: '', sigla: '' })
                setDisabledByCEP(false)
            }
        })
    }

    const handleStates = (event: any, newValue: any) => {
        const stateInitials = newValue.sigla || newValue;

        if (statusState) {
            setStatusState('');
        }
        if (city) {
            setCity('');
        }

        estadosCidades.forEach((element: { nome: string, sigla: string, cidades: any[] }) => {
            if (element.sigla === stateInitials) {
                setCities(element.cidades);
                setState({
                    nome: element.nome,
                    sigla: element.sigla,
                });
            }
        });
    };

    const updateAddress = async () => {
        setLoadingAddress(true)
        schemaAddress.validate({
            address,
            addressNumber,
            district,
            CEP,
            city,
            state,
        }, {
            abortEarly: false
        }).then(() => {
            const data: {
                address: string,
                address_number: string,
                district: string,
                cep: string,
                city: string,
                state: string,
                address_complement?: string
            } = {
                address,
                address_number: addressNumber,
                district,
                cep: CEP,
                city,
                state: state.sigla
            }
            if (addressComplement) {
                data.address_complement = addressComplement
            }
            RemoteServices.commissions.UpdateAccountForBanner(data).then(({ response }) => {
                console.log(response)
                if (response?.success) {
                    Toast({
                        type: "success",
                    }, "Solicitação realizada. Em breve você receberá a correspondência no novo endereço cadastrado!")
                    setOpenModalAddress(false)
                    setHiddenButtonAddress(true)
                } else {
                    Toast({
                        type: "error",
                    }, response?.message ?? "Falha ao realizar solicitação!")
                }
            }).catch((err) => {
                Toast({
                    type: "error",
                }, err?.response?.message ?? "Falha ao realizar solicitação!")
            }).finally(() => {
                setLoadingAddress(false)
            })
        }).catch((err) => {
            setLoadingAddress(false)
            handleValidationError(err)
        })
    }

    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'address':
                    setStatusAddress(element.message);
                    break;
                case 'addressNumber':
                    setStatusAddressNumber(element.message);
                    break;
                case 'district':
                    setStatusDistrict(element.message);
                    break;
                case 'CEP':
                    setStatusCEP(element.message);
                    break;
                case 'city':
                    setStatusCity(element.message);
                    break;
                case 'state.sigla':
                    setStatusState(element.message);
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <>
            <ModalUI open={openDialogCommission} handleClose={handleClose}>
                <>
                    <DialogTitle style={{ textAlign: 'center' }}>Parabéns! <br /> A proposta {proposalId} foi aprovada</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12} md={12} style={{ margin: `10px 0 ${canSendBanner ? '30' : '10'}px 0px`, textAlign: 'center' }}>
                            <Typography variant='subtitle1'>Atendente, você ganhou uma GRATIFICAÇÃO POR ATENDIMENTO de <strong>{formatCurrency(commissionValue)}</strong></Typography>
                            {otherRequests.length > 0 &&
                                <>
                                    <Typography mt={3}>Estamos lhe enviando também a GRATIFICAÇÃO POR ATENDIMENTO das propostas abaixo:</Typography>
                                    <ul>
                                        {otherRequests.map((item) => {
                                            return (
                                                <li>Proposta {item.proposal} no valor de <strong>{formatCurrency(item.value)}</strong></li>
                                            )
                                        })}
                                    </ul>
                                </>}
                        </Grid>
                        {/* {
                            canSendBanner && User?.space === "sales_counter" &&
                            <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <Grid item xs={12} md={12}>
                                    <img src={ImageBanner} style={{
                                        width: '100%',
                                        height: 'auto',
                                        marginBottom: '10px'
                                    }} />
                                </Grid>
                                {
                                    invoice.length > 0 ?
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            borderRadius={2}
                                            height={250}
                                            width={'100%'}
                                            className="succcess-import-att-content"
                                            bgcolor={colors.primary}>
                                            <PiCheckFat size={50} color={'white'} />
                                        </Grid>
                                        : <DropzoneArea
                                            acceptedFiles={['image/*', 'application/pdf']}
                                            filesLimit={1}
                                            alertSnackbarProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                            }}
                                            maxFileSize={5000000}
                                            Icon={() => <MdOutlineUploadFile color={colors.grey600} size={65} />}
                                            dropzoneClass="drop-class-file-att"
                                            dropzoneText='Anexe a foto do adesivo "AQUI TEM AYUDE" colado em local visível ao público dentro da empresa'
                                            getFileAddedMessage={(fileName) =>
                                                `O arquivo ${fileName} foi adicionado com sucesso.`
                                            }
                                            getFileRemovedMessage={(fileName) =>
                                                `O arquivo ${fileName} foi removido com sucesso.`
                                            }
                                            getDropRejectMessage={(rejectedFile) =>
                                                `O arquivo ${rejectedFile.name} foi rejeitado. O formato do arquivo é inválido ou excede o tamanho máximo permitido de 5 MB`
                                            }
                                            getFileLimitExceedMessage={(filesLimit) =>
                                                `Número máximo permitido de arquivo/s excedido. Somente ${filesLimit} arquivos são permitidos.`
                                            }
                                            onChange={(file) => {
                                                if (statusInvoice) {
                                                    setStatusInvoice('');
                                                }
                                                setInvoice(file);
                                            }}
                                        />
                                }
                                {!hiddenButtonAddress && !alreadyRequestBanner && <ButtonUI
                                    variant='outlined'
                                    sx={{
                                        mt: 5,
                                        alignSelf: 'center'
                                    }}
                                    onClick={() => setOpenModalAddress(true)}
                                >
                                    Ainda não recebi o adesivo “AQUI TEM AYUDE!”<br />
                                    Quero atualizar meu endereço comercial para receber o adesivo
                                </ButtonUI>}
                            </Grid>
                        } */}
                    </DialogContent>
                    <DialogActions sx={{
                        display: 'flex',
                        gap: 2,
                        flexDirection: matchesMaxMobile ? "column" : "row"
                    }}>
                        <ButtonUI
                            variant='outlined'
                            color='primary'
                            onClick={() => handleClose()}
                        >Fechar</ButtonUI>
                        <ButtonUI
                            loading={loadingAccount}
                            variant='contained'
                            color='primary'
                            onClick={requestCommission}>Solicitar</ButtonUI>
                    </DialogActions>
                </>
            </ModalUI>

            <ModalUI open={openModalAddress} handleClose={() => setOpenModalAddress(false)}>
                <>
                    <DialogTitle textAlign={"center"} mt={2}>Atualizar endereço comercial</DialogTitle>
                    <DialogContent>
                        <Grid container xs={12} mt={4} gap={2}>
                            <Grid container xs={12} gap={2}>
                                <Grid container xs={12} md={3} lg={3}>
                                    <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                        <InputLabel htmlFor="cep">CEP</InputLabel>
                                        <OutlinedInput
                                            id="cep"
                                            label="CEP"
                                            inputComponent={CEPMaskCustom}
                                            value={CEP}
                                            onChange={(event) => {
                                                if (statusCEP) {
                                                    setStatusCEP('');
                                                }
                                                setCEP(event.target.value);
                                                if (event.target.value.replace(/[^\d]/g, "").length === 8) {
                                                    getCep(event.target.value)
                                                }
                                            }}
                                            aria-describedby="component-error-cep"
                                        />
                                        <FormHelperText>{statusCEP}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid container md={3} lg={3} xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        // @ts-ignore
                                        value={state}
                                        disabled={disabledByCEP}
                                        options={estadosCidades}
                                        noOptionsText="Estado não encontrado"
                                        onChange={handleStates}
                                        disableClearable
                                        getOptionLabel={(option: { nome: string; sigla: string; cidades: string[]; }) => option.nome || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                label="Estado"
                                                variant="outlined"
                                                error={!!statusState}
                                                helperText={statusState}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid container xs={12} md={3} lg={3}>
                                    <Autocomplete
                                        fullWidth
                                        disabled={disabledByCEP}
                                        options={cities}
                                        noOptionsText="Cidade não encontrada"
                                        value={city}
                                        onChange={(event, newValue) => {
                                            if (statusCity) {
                                                setStatusCity('');
                                            }
                                            setCity(newValue);
                                        }}
                                        autoHighlight
                                        disableClearable
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                label="Cidade"
                                                variant="outlined"
                                                error={!!statusCity}
                                                helperText={statusCity}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12} gap={2}>
                                <Grid container md={2.8} lg={2.8} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Bairro"
                                        variant="outlined"
                                        value={district}
                                        error={!!statusDistrict}
                                        helperText={statusDistrict}
                                        onChange={(event) => {
                                            if (statusDistrict) {
                                                setStatusDistrict('');
                                            }
                                            setDistrict(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid container xs={12} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="Logradouro"
                                        variant="outlined"
                                        value={address}
                                        error={!!statusAddress}
                                        helperText={statusAddress}
                                        onChange={(event) => {
                                            if (statusAddress) {
                                                setStatusAddress('');
                                            }
                                            setAddress(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid container xs={12} md={3} lg={3}>
                                    <TextField
                                        fullWidth
                                        label="Número"
                                        variant="outlined"
                                        value={addressNumber}
                                        error={!!statusAddressNumber}
                                        helperText={statusAddressNumber}
                                        onChange={(event) => {
                                            if (statusAddressNumber) {
                                                setStatusAddressNumber('');
                                            }
                                            setAddressNumber(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid container md={2.9} lg={2.9} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Complemento"
                                        variant="outlined"
                                        value={addressComplement}
                                        onChange={(event) => {
                                            setAddressComplement(event.target.value);
                                        }}
                                        helperText="Não obrigatório"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{
                        display: 'flex',
                        gap: 2,
                        flexDirection: matchesMaxMobile ? "column" : "row"
                    }}>
                        <ButtonUI
                            variant='outlined'
                            color='primary'
                            onClick={() => setOpenModalAddress(false)}
                        >Fechar</ButtonUI>
                        <ButtonUI
                            loading={loadingAddress}
                            variant='contained'
                            color='primary'
                            onClick={updateAddress}>Atualizar</ButtonUI>
                    </DialogActions>
                </>
            </ModalUI>
        </>
    )
}

export default ReportsOnline;