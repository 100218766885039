import { Grid, Typography } from "@mui/material";
import ModalUI from "components/@ui/modal";
import ImgLoading from "assets/png/Img loading.jpg";

export function MainteningModal({
    handleClose,
    open
}: {
    open: boolean,
    handleClose: () => void;
}) {
    return <ModalUI open={open} handleClose={handleClose}>
        <img
            src={ImgLoading}
            alt="Melhorias"
            style={{
                maxWidth: '700px',
                width: '100%',
                marginTop: '20px'
            }}
        />
        {/* <Grid sx={{
            textAlign: "center"
        }}>
            <Typography variant="h4" color={"red"}>AVISO DE ATUALIZAÇÃO!</Typography>
            <Typography variant="h6" mt={8} color={"blue"}>Parceiro(a), para melhor experiência do cliente e maior produtividade estaremos em manutenção técnica até 06/05/2024, período de  implementação para pagamentos dos empréstimos via PIX.</Typography>
        </Grid> */}
    </ModalUI>
}