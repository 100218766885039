import { INewGestor, IUpdateGestor } from "pages/app/gestor/interfaces"
import { Remote } from "service/remote"

export const getGestor = (params: {
    limit: number,
    page: number,
    name?: string,
    cpf?: string,
    phone?: string,
}) => {
    return Remote({
        method: "get",
        urn: "/gestors",
        api: "manager",
        params
    })
}

export const newGestor = (data: INewGestor) => {
    return Remote({
        method: "post",
        urn: "/gestors",
        api: "manager",
        data
    })
}

export const updateGestor = (data: IUpdateGestor, id: number) => {
    return Remote({
        method: "put",
        urn: "/gestors/" + id,
        api: "manager",
        data
    })
}

export const deleteGestor = (id: number) => {
    return Remote({
        method: "delete",
        urn: "/gestors/" + id,
        api: "manager",
    })
}