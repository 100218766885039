import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Popover, Typography } from "@mui/material";
import useStyles from './styles';
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdErrorOutline, MdWarningAmber } from "react-icons/md";
import { colors } from "theme";
import { ProposalRR } from "types/proposal";
import useMediasQuerys from "hooks/mediasQuery";

export function Status({
    proposal
}: {
    proposal: ProposalRR
}) {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles()

    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const statusProposal = (proposal: string) => {
        let status = ''
        switch (proposal) {
            case 'started':
                status = 'Iniciada'
                break;
            case 'preValidationCard':
                status = 'cartão de crédito'
                break;
            case 'transfeera':
                status = 'conta bancária'
                break;
            case 'face_id':
                status = 'fotos'
                break;
            case 'signature':
                status = 'assinatura CCB'
                break;
            case 'aproved':
                status = 'aprovada'
                break;
            case 'failed':
                status = 'Não aprovada'
                break;
            default:
                break;
        }
        return status
    }

    useEffect(() => {

        if (proposal.proposal_status === "failed") {
            if (proposal.antifraude_desc === "Não aprovado") {
                setTitle("Falha na validação antifraudes")
                setSubtitle("Os dados enviados não foram aprovados na validação.")
                return
            } else if (proposal.checkout_status === "failed" && proposal.checkout_desc !== "00 | Cartão de crédito não aprovado") {
                setTitle("Cartão de crédito não aprovado")
                setSubtitle(proposal.checkout_desc)
                return
            } else if (proposal.checkout_desc === "00 | Cartão de crédito não aprovado") {
                setTitle("Cartão de crédito não aprovado")
                setSubtitle("O cartão não pertence ao CPF do cliente cadastrado.")
                return
            } else if (proposal.checkout_desc.includes("3ds")) {
                setTitle("Cartão de crédito não aprovado")
                setSubtitle("O cliente não confirmou a compra na PGW*STREETJOB")
                return
            } else if (proposal.faceid_status === "failed") {
                setTitle("Falha na validação das fotos")
                setSubtitle("As imagens enviadas não foram aprovados na validação.")
                return
            } else if (proposal.sign_status === "canceled") {
                setTitle("Falha na assinatura do contrato")
                setSubtitle("Assinatura cancelada manualmente ou tempo limite esgotado.")
                return
            } else {
                setTitle("Proposta não aprovada")
                setSubtitle("A proposta foi cancelada.")
                return
            }
        } else if (proposal.proposal_status === "aproved") {
            setSubtitle("Proposta aprovada.")
        } else if (proposal.proposal_status === "preValidationCard") {
            if (proposal.checkout_status === "challenge") {
                setSubtitle("Aguardando resposta do desafio.")
            } else if (proposal.checkout_status === "challenge_3ds") {
                setSubtitle("Aguardando confirmação da compra.")
            } else {
                setSubtitle("Aguardando os dados do cartão de crédito.")
            }
        } else if (proposal.proposal_status === "transfeera") {
            setSubtitle("Aguardando o preenchimento dos dados bancários.")
        } else if (proposal.proposal_status === "face_id") {
            if (proposal.faceid_status === "waiting") {
                setSubtitle("Aguardando as fotos do cliente serem validadas (tempo estimado de 3 minutos a 1 hora). Caso as fotos sejam recusadas, o cliente pode reenviar as fotos.")
            } else {
                setSubtitle("Aguardando a coleta de fotos.")
            }
        } else if (proposal.proposal_status === "signature") {
            setSubtitle("Aguardando o cliente assinar a CCB(Cédula de crédito bancário).")
        }
    }, [proposal])


    const open = Boolean(anchorEl);
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            gap={1}
            borderRadius={4}
            bgcolor={
                proposal.proposal_status === 'aproved' ?
                    colors.successLight + 80 :
                    proposal.proposal_status === 'failed' ?
                        colors.errorLight + 80 :
                        colors.warningLight + 90
            }
            color={
                proposal.proposal_status === 'aproved' ?
                    colors.success :
                    proposal.proposal_status === 'failed' ?
                        colors.error :
                        colors.warningMain
            }
            py={.5}

            padding={matchesMaxMobile ? .5 : 2}
            textAlign={"center"}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >

            {proposal.proposal_status === 'aproved' ?
                <IoCheckmarkCircleOutline size={20} /> :
                proposal.proposal_status === 'failed' ?
                    <MdErrorOutline size={20} /> :
                    <MdWarningAmber size={20} />}
            {statusProposal(proposal.proposal_status)}
            <Popover
                elevation={3}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Card>
                    {proposal.proposal_status === "failed" && <CardHeader
                        title={title}
                    />}

                    <CardContent>
                        <Typography variant="body2">{subtitle}</Typography>
                    </CardContent>
                </Card>
            </Popover>
        </Grid>

    )
}