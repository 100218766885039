import { split } from "./split"

export function transformValue(value: string) {
    const newValue = value.replace('.', '').replace(',', '').replace(/\D/g, '')
    if (split(newValue, newValue.length - 2)[1] === '00') {
      return Number(split(newValue, newValue.length - 2)[0])
    } else {
      const array = split(newValue, newValue.length - 2)
      return Number(array[0] + '.' + array[1])
    }
  }


  export function getReadableFileSizeString(fileSizeInBytes: number) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);
  
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
  