import {
    Grid,
    IconButton,
    TableCell,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    CircularProgress,
    Stack
} from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import { useEffect, useState } from 'react';
import RemoteServices from 'service';
import { ProposalRR } from 'types/proposal';
import ProposalDetails from 'components/proposalDetais';
import { useLocation } from 'react-router';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { formatCurrency, formatDateWithTime } from '@utils/format';
import { AiFillEye } from 'react-icons/ai';
import TooltipUI from 'components/@ui/Tooltip';
import { Status } from './status';
import useGeneral from 'context/general';
import ExcelIcon from 'assets/png/EXCEL.png';
import Axios from 'axios';
import URLSBases from 'utils/urlBases';
import storageLocales from 'utils/storageLocales';
import useMediasQuerys from 'hooks/mediasQuery';
import { TablePagination } from '@material-ui/core';
import { CalendarMonth, Close, Search } from '@mui/icons-material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import useAuth from 'context/auth';
import { TableListGestorsUI } from 'components/@ui/tableList';

type StatusProps = "aproved" | "preValidationCard" | "face_id" | "failed" | "transfeera" | "signature" | 'pending' | 'Todas'
type StatusPedingProps = "preValidationCard" | "face_id" | "transfeera" | "signature" | 'Todas'

const availableStatus = ['pending', "aproved", "failed"]

export default function Proposals() {

    const location = useLocation()

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        setProposal: setGeneralProposal,
        setGetStatus
    } = useGeneral()

    const {
        User,
        defaultUser
    } = useAuth()

    const getstatusProposal = location?.search?.slice(location?.search.indexOf('status') + 7, location?.search.length)

    const [proposals, setProposals] = useState<ProposalRR[] | null>(null)
    const [proposalDetails, setProposalDetails] = useState<ProposalRR | null>(null)

    const [proposalID, setProposalId] = useState<string | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const [status, setStatus] = useState<StatusProps | null>('Todas')
    const [statusPeding, setStatusPeding] = useState<StatusPedingProps | null>('Todas')
    const [first, setFirst] = useState<boolean>(true)

    const [loading, setLoading] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [openSearchData, setOpenSearchData] = useState<boolean>(false)
    const [startData, setStartData] = useState<Moment | null>(null)
    const [startHours, setStartHours] = useState<any>(moment().hours(0).minutes(0))

    const [endData, setEndData] = useState<Moment | null>(null)
    const [endHours, setEndHours] = useState<any>(moment().hours(23).minutes(59))
    const [filiais, setFiliais] = useState<Array<{
        name: string,
        user_id: string
    }>>([])

    const [responsible, setResponsible] = useState<string>('all')

    useEffect(() => {
        setLoading(true)
        if (first && availableStatus.includes(getstatusProposal)) {
            return
        }
        getProposals()
    }, [page, rowsPerPage, status, statusPeding, responsible])


    useEffect(() => {
        if (location?.state?.proposalId) {
            setProposalId(location?.state?.proposalId)
            setGetStatus(true)
            //@ts-ignore
            setGeneralProposal({
                id: location?.state?.proposalId
            })
        }
        if (availableStatus.includes(getstatusProposal)) {
            setFirst(false)
            setStatus(getstatusProposal as StatusProps)
        }
    }, [location])

    useEffect(() => {
        RemoteServices.filiais.List({
            limit: 1000,
            page: 1
        })
            .then(({ response }) => {
                if (response?.data) {
                    let array: Array<{
                        name: string,
                        user_id: string
                    }> = []
                    for (let _r of response.data) {
                        array.push({
                            name: _r.sales_counter.responsible_name,
                            user_id: _r.sales_counter.user_id
                        })
                    }
                    setFiliais(array)
                }
            })
    }, [])

    const getProposals = async (clear: boolean = false) => {
        await RemoteServices.proposals.Proposals({
            page: clear ? 1 : page,
            limit: clear ? 10 : rowsPerPage,
            ...(status && {
                status: (status === 'pending' && statusPeding) ?
                    (statusPeding === 'Todas' ? 'pending' : statusPeding) : status
            }),
            ... (startData && !clear && {
                start_data: returnStringData(startData as Moment, startHours)
            }),
            ... (endData && !clear && {
                end_data: returnStringData(endData as Moment, endHours)
            }),
            ... (responsible !== "all" && {
                user_id: responsible
            })
        })
            .then(({
                response
            }) => {
                if (response?.result) {
                    setProposals(response?.result)
                    setTotal(response?.total ?? 0)
                }
            })
            .finally(() => setLoading(false))


    }

    const returnStringData = (data: Moment, time: Moment) => {
        return new Date(new Date(new Date(data.toISOString() as string).setUTCHours(time?.get("hour"))).setUTCMinutes(time?.get("minute"))).toISOString()
    }

    const download = () => {
        setLoadingDownload(true)
        let _s = status !== "pending" ? status === "Todas" ? "" : status : statusPeding === "Todas" ? "pending" : statusPeding
        Axios({
            url: URLSBases.apisTc + "/online/proposals/report",
            params: {
                page: 1,
                limit: total,
                status: _s,
                export: true,
                ... (startData && {
                    start_data: returnStringData(startData as Moment, startHours)
                }),
                ... (endData && {
                    end_data: returnStringData(endData as Moment, endHours)
                }),
                ... (responsible !== "all" && {
                    user_id: responsible
                })
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
            },
            responseType: 'blob'
        }).then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            const tempLink = document.createElement('a');
            tempLink.href = fileUrl;
            tempLink.setAttribute('download', renderExcelName({
                status: _s as string
            }));
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(fileUrl);
        }).finally(() => setLoadingDownload(false))
    }

    const renderExcelName = (data: {
        status: string,
    }): string => {
        let _s = {
            'aproved': " aprovadas",
            'face_id': " na etapa de fotos",
            'signature': " na etapa de assinatura",
            'failed': " não aprovadas",
            'preValidationCard': " na etapa do cartão de crédito",
            'transfeera': " na etapa da conta bancária",
            'pending': " pendentes"
        }
        let format = ".xlsx"
        //@ts-ignore
        let filters = !data.status ? "" : _s[data.status] ?? ""
        let name = `Relatório de Propostas${filters}`

        return name + format
    }

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                >
                    <img
                        src={Icons.IconPROPOSTAS}
                        alt="icon"
                        width={25}
                        style={{ filter: 'brightness(0)' }}
                    />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>Propostas</Typography>
                </Grid>
                <Grid
                    container
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    py={2}
                >
                    <Grid container justifyContent={"flex-end"} gap={2}>
                        {!openSearchData ?
                            <TooltipUI title='Filtrar por data'>
                                <IconButton
                                    sx={{
                                        width: 55,
                                        height: 55,
                                        ":hover": {
                                            color: colors.primary
                                        }
                                    }}
                                    onClick={() => setOpenSearchData(!openSearchData)}
                                >
                                    <CalendarMonth />
                                </IconButton >
                            </TooltipUI>
                            :

                            <Grid container xs={12} display={"flex"} gap={2} flexDirection={"row"} justifyContent={"flex-end"} alignItems={"flex-end"} >
                                <Stack direction={"row"} gap={2}>
                                    <DatePicker
                                        label="Data Inicial"
                                        value={startData ? moment(startData) : null}
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        maxDate={endData ? endData : moment()}
                                        format='DD/MM/YYYY'
                                        onChange={(date) => {
                                            setStartData(date);
                                        }} />
                                    <TimePicker
                                        label="Hora inicial"
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        value={startHours}
                                        onChange={(e) => {
                                            setStartHours(e)
                                        }}
                                        views={['hours', 'minutes']}
                                    />
                                </Stack>
                                <Stack direction={"row"} gap={2}>
                                    <DatePicker
                                        label="Data Final"
                                        value={endData ? moment(endData) : null}
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        maxDate={moment()}
                                        minDate={startData && startData}
                                        format='DD/MM/YYYY'
                                        onChange={(date) => {
                                            setEndData(date);
                                        }} />
                                    <TimePicker
                                        label="Hora Final"
                                        sx={{ maxWidth: 300, width: '100%' }}
                                        value={endHours}
                                        onChange={(e) => setEndHours(e as string)}
                                        views={['hours', 'minutes']}
                                    />
                                </Stack>
                                <Stack direction={"row"} gap={matchesMaxMobile ? 1 : 2}>
                                    <TooltipUI title='Aplicar filtros'>
                                        <IconButton
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                ":hover": {
                                                    color: colors.primary
                                                }
                                            }}
                                            onClick={() => {
                                                getProposals()
                                            }}
                                        >
                                            <Search />
                                        </IconButton >
                                    </TooltipUI>
                                    <TooltipUI title='Limpar pesquisa'>
                                        <IconButton
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                ":hover": {
                                                    color: colors.primary
                                                }
                                            }}
                                            onClick={() => {
                                                setStartHours(moment().hours(0).minutes(0))
                                                setEndHours(moment().hours(23).minutes(59))
                                                setEndData(null)
                                                setStartData(null)
                                                setOpenSearchData(!openSearchData)
                                                setRowsPerPage(10)
                                                setPage(1)
                                                getProposals(true)
                                            }}
                                        >
                                            <Close />
                                        </IconButton >
                                    </TooltipUI>
                                </Stack>
                            </Grid>
                        }
                        {User?.space !== "sales_counter" && <Grid item width={250}>
                            <FormControl fullWidth>
                                <InputLabel id="responsible">Responsável</InputLabel>
                                <Select
                                    labelId="responsible"
                                    value={responsible}
                                    label="Responsável"
                                    onChange={(e) => {
                                        setResponsible(e.target.value)
                                        setPage(1)
                                    }}
                                >
                                    <MenuItem value={'all'}>Todos</MenuItem>
                                    {User?.space === "pdv" && <MenuItem value={User?.sub}>Matriz</MenuItem>}
                                    {User?.space === "gestor" && (defaultUser?.permissions?.targets === null || defaultUser?.permissions?.targets.includes("0" as any)) && <MenuItem value={defaultUser?.owner?.user_id}>Matriz</MenuItem>}
                                    {filiais.sort((a, b) => {
                                        var textA = a.name.toUpperCase();
                                        var textB = b.name.toUpperCase();
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    }).map((item) => {
                                        return (
                                            <MenuItem value={item.user_id}>{item.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>}
                        <Grid item width={250}>
                            <FormControl fullWidth>
                                <InputLabel id="bi-status-select">Status da proposta</InputLabel>
                                <Select
                                    labelId="bi-status-select"
                                    value={status}
                                    label="Status da proposta"
                                    onChange={(e) => {
                                        setStatus(e.target.value as StatusProps)
                                        setPage(1)
                                    }}
                                >
                                    <MenuItem value={'Todas'}>Todas</MenuItem>
                                    <MenuItem value={'aproved'}>Aprovadas</MenuItem>
                                    <MenuItem value={'pending'}>Pendentes</MenuItem>
                                    <MenuItem value={'failed'}>Não aprovadas</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            status !== null && status === 'pending' &&
                            <Grid item width={300}>
                                <FormControl fullWidth>
                                    <InputLabel id="bi-status-select">Status da proposta (pendentes)</InputLabel>
                                    <Select
                                        labelId="bi-status-select"
                                        value={statusPeding}
                                        label="Status da proposta (pendentes)"
                                        onChange={(e) => {
                                            setStatusPeding(e.target.value as StatusPedingProps)
                                            setPage(1)
                                        }}
                                    >
                                        <MenuItem value={'Todas'}>Todas</MenuItem>
                                        <MenuItem value={'preValidationCard'}>Validação do cartão</MenuItem>
                                        <MenuItem value={'transfeera'}>Validação da conta bancária</MenuItem>
                                        <MenuItem value={'face_id'}>Validação das fotos</MenuItem>
                                        <MenuItem value={'signature'}>Etapa de assinatura</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item width={loadingDownload ? 224 : 200}>
                            <Button
                                startIcon={<img src={ExcelIcon} />}
                                sx={{
                                    bgcolor: "#158249",
                                    color: "white",
                                    p: 1.8,
                                    ":hover": {
                                        bgcolor: 'rgba(21, 130, 73, 0.9)',
                                    },
                                    ":disabled": {
                                        color: 'white'
                                    }
                                }}
                                disabled={loadingDownload}
                                onClick={download}
                                endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
                            >Exportar para Excel</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    matchesMaxMobile ? proposals && proposals.map((row) => (<>
                        <Grid
                            onClick={() => {
                                setProposalDetails(row)
                                setGeneralProposal(row)
                                setGetStatus(true)
                            }}
                            p={1}
                            width="100%"
                            border={`1px solid ${colors.grey400}`}
                            my={2}
                            borderRadius={2}
                            bgcolor="white">
                            <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`}>
                                <Grid item xs={7} fontWeight="bold">
                                    {row.name}
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography fontWeight="bold">ID: </Typography>{row.id}
                                </Grid>
                            </Grid>
                            <Grid pt={1} container fontSize={12} color={colors.grey600}>
                                <Grid item xs={8} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold" >
                                        Valor do empréstimo:
                                    </Typography>
                                    {formatCurrency(row.net_amount ?? '0')}
                                </Grid>
                                <Grid item xs={4} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold">
                                        Tabela:
                                    </Typography>
                                    {row.sale_table?.replaceAll('on-', row.owner_typing ? 'TADIG-' : 'TAON-')}
                                </Grid>
                            </Grid>
                            <Grid container fontSize={12} color={colors.grey600}>
                                <Grid item xs={8} container flexDirection={"row"} gap={.5} >
                                    <Typography fontSize={12} fontWeight="bold">
                                        Valor do contrato:
                                    </Typography>
                                    {formatCurrency(row.gross_amount)}
                                </Grid>
                                <Grid item xs={4} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold">
                                        Parcelas:
                                    </Typography>
                                    {row.parcels}x
                                </Grid>
                            </Grid>
                            <Grid py={1} container gap={1} fontSize={12} color={colors.grey600} alignItems={"center"}>
                                <Grid item xs={5} fontWeight="bold">
                                    <Status proposal={row} key={row.id} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container flexDirection={"row"} flexWrap={"nowrap"} gap={.5} fontSize={11}>
                                        <Typography fontSize={12} fontWeight="bold">Iniciada: </Typography> {formatDateWithTime(row.created_at)}
                                    </Grid>
                                    <Grid container flexDirection={"row"} flexWrap={"nowrap"} gap={.5} fontSize={12}  >
                                        <Typography fontSize={12} fontWeight="bold">Finalizada:{' '}</Typography>
                                        {row.updated_at ? formatDateWithTime(row.updated_at) : 'Não finalizada'}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid >

                    </>
                    ))
                        :
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            loading={loading}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            HeaderComponent={
                                <CustomTableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="left">Cliente</TableCell>
                                    <TableCell align="left">Iniciada em</TableCell>
                                    <TableCell align="left">Finalizada em</TableCell>
                                    <TableCell align="left">Valor empréstimo</TableCell>
                                    <TableCell align="left">Tabela</TableCell>
                                    <TableCell align="left">Parcelas</TableCell>
                                    <TableCell align="left">Valor contrato</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    {User?.space !== "sales_counter" && <TableCell align="left">Filial</TableCell>}
                                    {User?.space === "pdv" && <TableCell align="left">Gestores</TableCell>}
                                    
                                    <TableCell align="left">Detalhes</TableCell>
                                </CustomTableRow>
                            }
                            RowComponent={
                                proposals && proposals.map((row) => (
                                    <CustomTableRow type="body">
                                        <TableCellCusttom title={row.id} />
                                        <TableCellCusttom title={row.name}>
                                            <Typography sx={{ maxWidth: 200 }}>{row.name}</Typography>
                                        </TableCellCusttom>
                                        <TableCellCusttom title={formatDateWithTime(row.created_at)} />
                                        <TableCellCusttom title={row.updated_at ? formatDateWithTime(row.updated_at) : 'Não finalizada'} />
                                        <TableCellCusttom title={formatCurrency(row.net_amount ?? '0')} />
                                        <TableCellCusttom title={row.sale_table?.replaceAll('on-', row.owner_typing ? 'tadig-' : 'taon-')} />
                                        <TableCellCusttom title={`${row.parcels}x`} />
                                        <TableCellCusttom title={formatCurrency(row.gross_amount)} />
                                        <TableCellCusttom title={row.proposal_status}>
                                            <Status proposal={row} key={row.id} />
                                        </TableCellCusttom>
                                        {User?.space !== "sales_counter" && <TableCellCusttom title={row.sales_counter_id ? row.responsible as string : ""} />}
                                        {User?.space === "pdv" && <TableCellCusttom sx={{ textTransform: 'none !important' }} title={"gestors"}><TableListGestorsUI data={row.gestors}/></TableCellCusttom>}
                                        <TableCellCusttom align='center' title={row.id}>
                                            <TooltipUI title='Ver detalhes da proposta'>
                                                <IconButton onClick={() => {
                                                    setProposalDetails(row)
                                                    setGeneralProposal(row)
                                                    setGetStatus(true)
                                                }}>
                                                    <AiFillEye color={colors.black} />
                                                </IconButton>
                                            </TooltipUI>
                                        </TableCellCusttom>
                                    </CustomTableRow>
                                ))
                            }

                        />
                }
                {matchesMaxMobile &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={total}
                        translate='yes'
                        labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                        labelRowsPerPage={`páginas:`}
                        rowsPerPage={rowsPerPage ?? 10}
                        page={page - 1}
                        onPageChange={(event, page) => setPage(page + 1)}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPage(Number(rows.target.value))
                        }}
                        backIconButtonProps={
                            page === 1
                                ? {
                                    disabled: true
                                }
                                : undefined
                        }

                    />
                }

                <ProposalDetails
                    open={!!proposalDetails || !!proposalID}
                    proposalId={proposalID ?? ''}
                    proposalDetail={proposalDetails}
                    handleClose={() => {
                        setProposalDetails(null);
                        setProposalId(null);
                        setGeneralProposal(null);
                        setGetStatus(false)
                    }}
                />
            </Grid>
        </Grid >
    );
}


const proposalStatus = (status: string) => {

    let proposal = ''

    switch (status) {
        case 'aproved':
            proposal = 'Aprovada'
            break
        case 'failed':
            proposal = 'Não aprovada'
            break
        case 'preValidationCard':
            proposal = 'pendente'
            break
        case 'transfeera':
            proposal = 'pendente'
            break
        case 'face_id':
            proposal = 'pendente'
            break
        case 'signature':
            proposal = 'pendente'
            break
        default:
            proposal = 'Não aprovada'
            break
    }

    return proposal
}