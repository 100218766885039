import { Remote } from "service/remote";

export const newFilial = async ({
        data
}:
    {
        data: any
    }) => {
    return await Remote({
        method: "post",
        urn: "/sales-counters",
        api: 'manager',
        data
    })

}

export default newFilial;