import { Button, Grid, IconButton, Typography } from "@mui/material"
import { formatPercent } from "@utils/format";
import ModalUI from "components/@ui/modal"
import Toast from "components/@ui/toast";
import useMediasQuerys from "hooks/mediasQuery";
import { useEffect } from "react";
import { MdAdd, MdCheck, MdEdit, MdRemove } from "react-icons/md";
import RemoteServices from "service";
import { colors } from "theme";
import { FiliaisProps } from "types/filiais";

const CommissionOnline = ({
    open,
    handleClose,
    salesCounter,
    tablesOnline,
    setTablesOnline,
    userID
}: {
    open: boolean,
    handleClose: () => void;
    salesCounter: FiliaisProps['data'] | null;
    tablesOnline: {
        table: string,
        commission_rate: number,
        edit: false,
        max_commission_rate: number
    }[] | null,
    setTablesOnline: any;
    userID: string;
}) => {

    
    const {
        matchesMaxMobile
    } = useMediasQuerys()


    function Round(value: number, precision = 2) {
        const power = 10 ** precision;
        return Math.round(parseFloat((value * power).toPrecision(15))) / power;
    }



    function formatPercentNumber(value: number) {
        let result
        if ((value * 100) % 1 !== 0) {
            result = (value * 100).toFixed(2)
        } else {
            result = value * 100
        }
        const array = result.toString().split(".")
        if (array.length === 1) {
            return result
        } else {
            if (Number(array[1]) > 0) {
                return result
            } else {
                return array[0]
            }
        }
    }

    async function saveCommissionOnline(param: any) {
        const data = {
            userId: userID,
            table: param.table,
            tax: param.commission_rate
        }
        RemoteServices.proposals.commissionTaxUpdate(data).then(({ response, status }) => {
            if (status <= 201) {
                Toast({
                    type: 'success'
                }, "Taxa atualizada com sucesso!")
            } else {
                Toast({
                    type: 'error'
                }, response?.error ?? "Erro ao atualizar taxa")
            }
        }).catch((err) => {
            console.log(err)
            Toast({
                type: 'error'
            }, "Erro ao atualizar taxa")
        })

    }


    const Table = ({ item }: {
        item: {
            table: string,
            commission_rate: number,
            edit: false,
            max_commission_rate: number
        }
    }) => (
        <Grid>
            <Grid
                minWidth={"17em"}
                container
                height={"7.5em"}
                position="relative"
                flexDirection={"row"}
                alignItems="center"
                border={"2px solid " + colors.primary}
                bgcolor={colors.primary + 10}>
                <Grid p={2}>
                    <Typography>Comissão de</Typography>
                    <Grid container gap={1}>
                        {item.edit &&
                            <IconButton
                                disabled={!(item.commission_rate < item.max_commission_rate)}
                                onClick={() => {
                                    setTablesOnline(tablesOnline?.map((i: any) => {
                                        if (i.table === item.table) {
                                            if (((parseFloat(i.commission_rate.toFixed(2)) + 0.01) * 100) / 100 > i.max_commission_rate) {
                                                return { ...i, commission_rate: i.max_commission_rate }
                                            }
                                            return { ...i, commission_rate: i.commission_rate + 0.01 }
                                        } else {
                                            return i
                                        }

                                    }))
                                }}>
                                <MdAdd />
                            </IconButton>}
                        {item.edit ?
                            <input
                                style={{ width: '57px' }}
                                type="number"
                                min={0}
                                step={0.01}
                                max={item.max_commission_rate * 100}
                                value={formatPercentNumber(item.commission_rate)}
                                onChange={(e) => {
                                    // @ts-ignore
                                    if (parseFloat(Number(e.target.value) / 100) >= 0.00) {
                                        setTablesOnline(tablesOnline?.map((i: any) => {
                                            if (i.table === item.table) {
                                                return { ...i, commission_rate: Round(Number(e.target.value)) / 100 }
                                            } else {
                                                return i
                                            }
                                        }))
                                    }
                                }}
                            />
                            : <Typography variant="h4" color={colors.black} fontWeight="bold">
                                {formatPercent(item.commission_rate)}
                            </Typography>
                        }
                        {item.edit &&
                            <IconButton
                                disabled={!(item.commission_rate > 0.00)}
                                onClick={() => {
                                    setTablesOnline(tablesOnline?.map((i: any) => {
                                        if (i.table === item.table) {
                                            return { ...i, commission_rate: i.commission_rate - 0.01 < 0 ? 0 : i.commission_rate - 0.01 }
                                        } else {
                                            return i
                                        }

                                    }))
                                }} >
                                <MdRemove />
                            </IconButton>}
                        <IconButton
                            disabled={!(item.commission_rate <= item.max_commission_rate)}
                            onClick={() => {
                                setTablesOnline(tablesOnline?.map((i: any) => {
                                    if (i.table === item.table) {
                                        return { ...i, edit: !i.edit }
                                    } else {
                                        return i
                                    }
                                }))
                                if (item.edit) {
                                    saveCommissionOnline(item)
                                }
                            }}
                        >
                            {!item.edit ? <MdEdit color={colors.black} /> : <MdCheck color={colors.black} />}
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid width={'3.75em'} />
                <Grid
                    height={"7.5em"}
                    container
                    width={'7.5em'}
                    color="white"
                    borderRadius={"50%"}
                    bgcolor={colors.primary}
                    position={"absolute"}
                    right={'calc(-7.5em)'}
                    sx={{ transform: 'translateX(-50%)' }}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection={"column"}
                >
                    <Typography>{item.table?.toUpperCase()?.replaceAll('ON-', 'TABELA-')}</Typography>
                    {/* <Typography>1</Typography> */}
                </Grid>
            </Grid>
        </Grid>
    )


    return (
        <ModalUI
            open={open}
            hiddenButtonClose
            handleClose={handleClose}
        >
            <Grid container gap={2} flexDirection={"column"} width={"100%"} height={`calc(${matchesMaxMobile ? '100vh' : '90vh'} - 64px)`} flexWrap={"nowrap"}>
                <Typography
                    textTransform="uppercase"
                    fontWeight="bold"
                    sx={{ width: '100%' }}
                    variant={'h5'}
                    color={colors.black}>Comissão da filial</Typography>
                <Grid container xs={12} height={`calc(90vh - 300px)`} sx={{ overflowY: 'auto' }}>
                    <Grid container gap={10} my={2}>
                        <Grid item>
                            <Typography
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.black}>Nome</Typography>
                            <Typography
                                color={colors.black}>{salesCounter?.sales_counter.responsible_name}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.black}>Email</Typography>
                            <Typography
                                color={colors.black}>{salesCounter?.sales_counter.email}</Typography>
                        </Grid>
                    </Grid>
                    <Typography color={colors.black} variant="h5">TABELAS</Typography>
                    <Grid container gap={4} height={"100%"} mt={2} justifyContent="center">
                        {
                            tablesOnline && tablesOnline
                                .sort((a, b) => Number(a.table.split('on-')[1]) > Number(b.table.split('on-')[1]) ? 1 : -1)
                                .map((item) => (
                                    <>
                                        <Table
                                            item={item} />
                                        <Grid width={'3.75em'} />
                                    </>
                                ))
                        }
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" gap={2} alignItems={"center"}>
                    <Button
                        onClick={handleClose}
                        disableElevation
                        sx={{ p: 2, minWidth: 200 }}
                        variant="outlined">
                        Fechar
                    </Button>
                    {/* <Button variant="contained" disableElevation sx={{ p: 2, minWidth: 200 }}>
                        Salvar alterações
                    </Button> */}
                </Grid>
            </Grid>
        </ModalUI>
    )
}

export default CommissionOnline;
