import { Button, Grid, Typography } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import { useEffect, useState } from 'react';
import RemoteServices from 'service';
import { useNavigate } from 'react-router';
import Card from 'components/@ui/card';
import { formatCurrency } from '@utils/format';
import ProposalCommissions from './proposals';
import Toast from 'components/@ui/toast';
import CommissionRequest from './request';
import TooltipUI from 'components/@ui/Tooltip';
import useAuth from 'context/auth';
import useGeneral from 'context/general';
import GetRequestCommission from './getRequest';
import IndicatorsCommissions from './indicators';
import useMediasQuerys from 'hooks/mediasQuery';
import { sendToShow } from '../lend';

export default function Commissions() {

    const navigate = useNavigate()

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        User
    } = useAuth();

    const [availableCommission, setAvailableCommission] = useState<number>(0)
    const [periodCommissions, setPeriodCommissions] = useState<number>(0)
    const [remunerationofnominees, setRemunerationofnominees] = useState<number>(0)
    const [totalcommissionsreceived, setTotalcommissionsreceived] = useState<number>(0)

    const [mobileView, setmobileView] = useState<'commissions' | 'indicators'>('commissions')

    const getCommissionsAnalitics = () => {
        RemoteServices.commissions.CommissionlBI()
            .then(({ response }) => {
                if (response?.period_commissions) {
                    setPeriodCommissions(response?.period_commissions?.month)
                }
                if (response?.remuneration_of_nominees) {
                    setRemunerationofnominees(response?.remuneration_of_nominees?.month)
                }
                if (response?.total_commissions_received) {
                    setTotalcommissionsreceived(response?.total_commissions_received?.ever?.total)
                }
            })
    }

    useEffect(() => {
        getCommissionsAnalitics()
    }, [])


    useEffect(() => {
        if(matchesMaxMobile){
            sendToShow("tables-commissions-mobile", "center")
        }
    }, [mobileView])

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid container gap={2} py={2}>
                    <img
                        src={Icons.IconCOMISSÕES}
                        alt="icon"
                        width={30}
                        style={{ filter: 'brightness(0)' }}
                    />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>Comissões</Typography>
                </Grid>
                <Grid
                    my={4}
                    container
                    gap={3}
                    display={"grid"}
                    sx={{ gridTemplateColumns: `repeat(auto-fill, minmax(21.875rem, 1fr))`, flexWrap: 'wrap' }}>
                    <Card
                        bgcolor="Secondary"
                    >
                        <div>
                            <Typography variant="h5" fontWeight={"bold"} color={availableCommission < 0 ? 'red' : 'inherit'}>{formatCurrency(availableCommission)}</Typography>
                            <Typography variant="subtitle1">Comissões a receber <br />
                                <GetRequestCommission handleAvailable={(amount) => setAvailableCommission(amount)} getProposals={() => { }} />
                            </Typography>
                        </div>
                    </Card>
                    <Card bgcolor="warning">
                        <div>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {formatCurrency(periodCommissions)}<br />
                            </Typography>
                            <Typography variant="subtitle1">Comissões do período</Typography>
                        </div>
                    </Card>
                    {
                        User?.space !== 'sales_counter' &&
                        <Card bgcolor="primary">
                            <div>
                                <Typography variant="h5" fontWeight={"bold"}>{formatCurrency(remunerationofnominees)}</Typography>
                                <Typography variant="subtitle1">Remuneração do período sobre correspondentes indicados<br />
                                </Typography>
                            </div>
                        </Card>
                    }
                    <Card bgcolor="third">
                        <div>
                            <Typography variant="h5" fontWeight={"bold"}>{formatCurrency(totalcommissionsreceived)}</Typography>
                            <Typography variant="subtitle1">Total de comissões recebidas<br />
                                <Button
                                    onClick={() => navigate('/app/comissoes/historico')}
                                    variant="text"
                                    color="inherit"
                                    sx={{ p: 0, textDecoration: 'underline' }}>
                                    Histórico
                                </Button>
                            </Typography>
                        </div>
                    </Card>
                </Grid>
                {
                    matchesMaxMobile ?
                        <Grid container border={`1px solid ${colors.grey400}`} borderRadius={2} overflow="hidden">
                            <Grid
                                xs={User?.space === "pdv" ? 6 : 12}
                                bgcolor={mobileView === 'commissions' ? colors.primary + 30 : 'white'}
                                color={mobileView === 'commissions' ? colors.primary : colors.grey400}
                                fontWeight={mobileView === 'commissions' ? 'bold' : '400'}
                                onClick={() => setmobileView('commissions')}
                                container
                                alignItems={"ceter"}
                                justifyContent={"center"}
                                p={2}>
                                Minhas comissões
                            </Grid>
                            {User?.space === "pdv" && <Grid
                                xs={6}
                                bgcolor={mobileView === 'indicators' ? colors.primary + 30 : 'white'}
                                fontWeight={mobileView === 'indicators' ? 'bold' : '400'}
                                color={mobileView === 'indicators' ? colors.primary : colors.grey400}
                                onClick={() => setmobileView('indicators')}
                                container
                                alignItems={"ceter"}
                                justifyContent={"center"}
                                p={2}>
                                Remunerações de indicados
                            </Grid>}
                        </Grid>
                        :
                        <>
                            <Typography color={colors.black} variant="h6" fontWeight={"bold"}>
                                Minhas comissões
                            </Typography>
                            <ProposalCommissions />
                            {
                                User?.space === "pdv" &&
                                <>
                                    <Typography sx={{ mt: 10 }} color={colors.black} variant="h6" fontWeight={"bold"}>
                                        Remunerações de indicados
                                    </Typography>
                                    <IndicatorsCommissions />
                                </>
                            }
                        </>
                }
                <Grid id="tables-commissions-mobile" width="100%" minHeight={100}>
                    {matchesMaxMobile &&
                        (mobileView === 'indicators' && User?.space === "pdv" ? <IndicatorsCommissions /> : <ProposalCommissions />)
                    }
                </Grid>
            </Grid>
            
            
        </Grid>
    );
}