import * as React from 'react';
import Table, { TableProps } from '@mui/material/Table';
import TableBody, { TableBodyProps } from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { colors } from 'theme';
import { Grid, SxProps, TablePagination, Theme, Typography } from '@mui/material';
import TableSkeleton from '../tableSkeleton';
import TooltipUI from '../Tooltip';

export default function TableUI({
    page,
    rowsPerPage,
    onPageChange,
    total,
    onRowsPerPageChange,
    HeaderComponent,
    RowComponent,
    loading,
    propsTable,
    propsTableBody
}: {
    page: number;
    rowsPerPage?: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
    total: number;
    onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    HeaderComponent: React.ReactNode;
    RowComponent: React.ReactNode;
    loading?: boolean;
    propsTable?: TableProps;
    propsTableBody?: TableBodyProps;
}) {

    return (
        <TableContainer component={Paper}>
            <Table
                {...propsTable ?? {}}
                sx={{
                    minWidth: 650,
                    borderCollapse: 'separate',
                    borderSpacing: '0 1em',
                    ...propsTable?.sx ?? {}
                }} aria-label="simple table">
                <TableHead>
                    {HeaderComponent}
                </TableHead>
                <TableBody {...propsTableBody} sx={{ height: 100 }}>
                    {RowComponent}
                </TableBody>
            </Table>
            {!loading && total === 0 &&
                <Grid py={3} container justifyContent={'center'}>
                    <Typography>
                        Nenhum registro encontrado.
                    </Typography>
                </Grid>
            }
            {loading && total === 0 &&
                <TableSkeleton />
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={total}
                translate='yes'
                labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                labelRowsPerPage={`Resultados por página:`}
                rowsPerPage={rowsPerPage ?? 10}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </TableContainer>
    );
}



export const CustomTableRow = ({
    children,
    type,
    sx
}: {
    children: React.ReactNode,
    type?: string,
    sx?: SxProps<Theme>
}) => {
    return (
        <TableRow
            sx={{
                border: 0,
                '& td': {
                    color: 'black',
                    fontWeight: '400',
                    fontSize: '14px',
                    textTransform: 'uppercase',
                },
                '& th': {
                    borderBottom: 0,
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textTransform: 'uppercase',
                    p: 2
                },
                ...(type === 'body' && {
                    ':hover': {
                        backgroundColor: colors.grey100
                    },
                    backgroundColor: 'white'
                }),
                ...(sx ?? {})
            }}
        >
            {children}
        </TableRow>
    )
}

export const TableCellCusttom = ({
    children,
    title,
    align,
    sx,
    negative,
    tooltip
}: {
    children?: JSX.Element
    title: string | number,
    align?: 'left' | 'right' | 'center' | 'justify',
    sx?: SxProps<Theme>,
    negative?: boolean,
    tooltip?: string
}) => (
    <TooltipUI title={tooltip ?? ""} followCursor color={negative ? 'red' : colors.black}>
        <TableCell
            align={align ?? "left"}
            sx={{
                borderWidth: `1px 0 1px 0`,
                borderStyle: 'solid',
                borderColor: colors.grey300,
                '&:last-child': {
                    borderRight: '1px solid ' + colors.grey300,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5
                },
                '&:first-child': {
                    borderLeft: '1px solid ' + colors.grey300,
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5
                },
                ...(sx ?? {})
            }}>
            <Grid sx={{
                color: negative ? 'red' : colors.black,
                fontWeight: '500'
            }} py={1}>
                {children ? children : title}
            </Grid>
        </TableCell>
    </TooltipUI>

)