import { Button, Grid, IconButton, TableCell, Typography } from '@mui/material';
import Breadcrumb, { localeURLS } from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { AiFillEye } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import RemoteServices from 'service';
import { IndicatorProps, IndicatorsProps } from 'types/indicators';
import { useLocation, useNavigate } from 'react-router';
import { formatCurrency } from '@utils/format';
import { TablePagination } from '@material-ui/core';
import useMediasQuerys from 'hooks/mediasQuery';

export default function Indicator() {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const { state, pathname } = useLocation();
    const navigate = useNavigate()


    const [indicated, setIndicated] = useState<IndicatorProps[] | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    useEffect(() => {
        ListIndicators()
    }, [page, rowsPerPage])

    const ListIndicators = () => {
        if (state?.id) {
            RemoteServices.indicators.ListIndicators({ id: state.id as unknown as number, page, limit: rowsPerPage})
                .then(({ response }) => {
                    if (response?.result) {
                        setIndicated(response.result)
                        setTotal(response?.total)
                    }
                })
        } else {
            navigate('/app/correspondentes')
        }

    }

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                >
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>
                        Propostas do parceiro indicado {' '}
                        {decodeURIComponent(pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length)?.replaceAll('-', ' ').replaceAll('_', '/'))}
                    </Typography>
                </Grid>
                {
                    matchesMaxMobile ?
                        indicated && indicated.map((row) => (
                            <Grid
                                p={1}
                                width="100%"
                                border={`1px solid ${colors.grey400}`}
                                my={2}
                                borderRadius={2}
                                bgcolor="white">
                                <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`}>
                                    <Grid item fontWeight="bold">
                                        ID da proposta: {row.proposal_id}
                                    </Grid>
                                </Grid>
                                <Grid pt={1} container fontSize={14} color={colors.grey600} direction="column">
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">ID: </Typography>{row.id}
                                    </Grid>
                                    <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">Sua Comissão: </Typography>{formatCurrency(row.commission_amount)}
                                    </Grid>
                                    <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">Comissão Solicitada:</Typography>
                                        <Grid>
                                            {
                                                row.commission_request_id ?
                                                    <Grid>
                                                        <Button
                                                            onClick={() => navigate(localeURLS.historico + "/" + row.commission_request_id)}
                                                        >Ver solicitação</Button>
                                                    </Grid>
                                                    :
                                                    'NÃO FOI SOLICITADA'
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                        :
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            HeaderComponent={
                                <CustomTableRow>
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="left">Proposta</TableCell>
                                        <TableCell align="left">Sua Comissão</TableCell>
                                        <TableCell align="center">Comissão Solicitada</TableCell>
                                        {/* <TableCell align="center">Propostas</TableCell> */}
                                    </>
                                </CustomTableRow>
                            }
                            RowComponent={
                                indicated && indicated.map((row) => (
                                    <CustomTableRow type="body">
                                        <>
                                            <TableCellCusttom title={row.id} />
                                            <TableCellCusttom title={row.proposal_id} />
                                            <TableCellCusttom title={formatCurrency(row.commission_amount)} />
                                            <TableCellCusttom align="center" title={''}>
                                                <Grid>
                                                    {
                                                        row.commission_request_id ?
                                                            <Grid>
                                                                <Button
                                                                    onClick={() => navigate(localeURLS.comissoes)}
                                                                >Ver solicitação</Button>
                                                            </Grid>
                                                            :
                                                            'COMISSÃO NÃO FOI SOLICITADA'
                                                    }
                                                </Grid>
                                            </TableCellCusttom>
                                        </>
                                    </CustomTableRow>
                                ))
                            }
                        />
                }

            </Grid>
            {matchesMaxMobile &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={total}
                    translate='yes'
                    labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                    labelRowsPerPage={`páginas:`}
                    rowsPerPage={rowsPerPage ?? 10}
                    page={page - 1}
                    onPageChange={(event, page) => setPage(page + 1)}
                    onRowsPerPageChange={(rows) => {
                        setRowsPerPage(Number(rows.target.value))
                    }}
                />
            }
        </Grid>
    );
}