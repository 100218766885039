import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediasQuerys from 'hooks/mediasQuery';

interface TabPanelProps {
    children?: JSX.Element;
    index: number;
    value: number;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    const { matchesMaxMobile } = useMediasQuerys()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: matchesMaxMobile ? 0 : 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}