import { Avatar, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, List, ListItemText, TextField, Tooltip, Typography } from "@mui/material"
import { Backspace, Facebook } from '@mui/icons-material';
import { useEffect, useState } from "react";
import Toast from "components/@ui/toast";

import RemoteServices from "service";
export const FacebookPixel = ({
    openDialogPixel,
    onClickButton
}: {
    openDialogPixel: boolean;
    onClickButton: () => void;
}) => {
    const [pixel, setPixel] = useState('')
    const [edit, setEdit] = useState(true)

    const marketing = {
        0: '0: Solicitar empréstimo',
        1: '1: Pré cadastro & simulação',
        2: '2: Cadastro',
        3: '3: Cartão de crédito',
        4: '4: Conta bancária',
        5: '5: Fotos',
        6: '6: Assinatura',
        7: '7: Empréstimo concluído'
    }

    useEffect(() => {
        getKeys()
    }, [])


    const getKeys = async () => {
        RemoteServices.marketing.getKeys().then(({ response }) => {
            if (response.keys.pixel) {
                setPixel(response.keys.pixel.key)
            }
        }).catch(() => {
            Toast({
                type: "error"
            }, "Falha ao buscar suas ferramentas de marketing!")
        })
    }

    const changePixel = async () => {
        const data = {
            pixel: {
                key: pixel
            }
        }
        //@ts-ignore
        if (pixel === "") delete data.pixel

        RemoteServices.marketing.editKeys(data).then(() => {
            getKeys()
            Toast({
                type: "success"
            }, "Alteração salva!")
        }).catch(() => {
            Toast({
                type: "error"
            }, "Falha ao salvar chave PIXEL!")
        })
    }

    const renderKeys = () => {
        const keys = Object.keys(marketing)
        const result: any = []
        keys.forEach((pos) => {
            result.push(<div style={{
                minWidth: '200px',
            }}>
                <Avatar sx={{
                    color: 'white'
                }}>{pos}</Avatar>
                <ListItemText
                    //@ts-ignore
                    primary={marketing[pos]}
                /></div>)
        })
        return result


    }
    return (
        <Dialog open={openDialogPixel} fullWidth maxWidth={"md"} scroll="body" onClose={onClickButton}>
            <DialogTitle>Gerenciar ferramentas de marketing</DialogTitle>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Typography variant='overline'>Eventos emitidos:</Typography>
                        <List sx={{ display: 'flex', alignItems: "flex-start", justifyContent: 'flex-start', gap: '50px', flexWrap: 'wrap' }}>
                            {renderKeys()}
                        </List>

                        <Grid>
                            <List sx={{ display: 'flex' }}>
                                <TextField
                                    label="Facebook Pixel"
                                    placeholder="Facebook Pixel"
                                    size='small'
                                    variant='outlined'
                                    value={pixel}
                                    disabled={!edit}
                                    onChange={(e) => setPixel(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <Tooltip
                                                title="Facebook Pixel">
                                                <InputAdornment position="start">
                                                    <Facebook />
                                                </InputAdornment>
                                            </Tooltip>
                                        ),
                                    }}
                                >
                                </TextField>
                                {edit && <Tooltip title="Zerar campo">
                                    <IconButton
                                        onClick={() => setPixel("")}
                                    >
                                        <Backspace />
                                    </IconButton>
                                </Tooltip>}
                            </List>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ p: 2, minWidth: 125 }}
                    variant='contained'
                    color='primary'
                    disableElevation
                    onClick={() => {
                        onClickButton()
                    }}
                >Cancelar</Button>
                <Button
                    sx={{ p: 2, minWidth: 125 }}
                    variant='contained'
                    color='primary'
                    disableElevation
                    onClick={() => {
                        onClickButton()
                        changePixel()
                    }}
                >Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}