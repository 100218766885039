import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from 'theme';
import { Button } from '@mui/material';

type localeURLSProps = 'tabela' | 'emprestar' | 'proposta' | 'propostas' | 'filiais' | 'editar-filial' | 'correspondentes' | 'comissoes' | 'historico' | 'autoemprestimo' | 'suporte';

export const localeURLS = {
    'tabela': '/app/emprestar/tabela/',
    'emprestar': '/app/emprestar',
    'proposta': '/app/emprestar/tabela/proposta',
    'propostas': '/app/propostas',
    'filiais': '/app/filiais',
    'usuarios-matriz': '/app/usuarios-matriz',
    'editar-filial': '/app/filiais/editar-filial',
    'correspondentes': '/app/correspondentes',
    'comissoes': '/app/comissoes',
    'historico': '/app/comissoes/historico',
    'autoemprestimo': '/app/autoemprestimo',
    'suporte': '/app/perguntas-frequentes'
}

export default function Breadcrumb() {

    const navigate = useNavigate();


    const [breadlocation, setBreadlocation] = useState<localeURLSProps[]>([])
    const [currentlocation, setcurrentlocation] = useState<localeURLSProps>('emprestar')

    const { pathname, state } = useLocation()

    useEffect(() => {
        setBreadlocation(pathname.slice(pathname.indexOf('app/') + 3, pathname.length)
            .split("/")?.filter((b) => b.length > 0) as localeURLSProps[] ?? [])
        setcurrentlocation(pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length) as localeURLSProps)
    }, [pathname])



    const labels = (value: localeURLSProps): string => {
        switch (value) {
            case 'autoemprestimo':
                return 'link/qr code'
            case 'correspondentes': 
                return "Parceiros Matriz Cadastrados"
            default:
                return value
        }
    }

    return (
        <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {
                    breadlocation?.map((bl: localeURLSProps, index) =>
                        <Button
                            sx={{
                                color: currentlocation === bl ? colors.black : 'inherit',
                                fontWeight: 'bold',
                                textTransform: 'uppercase'
                            }}
                            key={bl + index}
                            color="inherit"
                            onClick={() => {
                                navigate(localeURLS[bl], {
                                    state: state
                                })
                            }}>
                            {decodeURIComponent(labels(bl).replaceAll('-', ' '))}
                        </Button>)
                }
            </Breadcrumbs>
        </Stack>
    );
}