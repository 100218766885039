import { Grid, CircularProgress } from "@mui/material"

import { UpdateSalesCounter } from "pages/app/updateSalesCounter";

const Login = () => {
    return (
        <Grid container height={'100vh'} xs={12} justifyContent={"center"} alignItems={"center"}>
            <UpdateSalesCounter/>
            <CircularProgress size={65} />
        </Grid>
    )
}

export default Login;