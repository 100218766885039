import { useState, useEffect } from 'react';
import { Button, Grid, IconButton, TableCell, Typography } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import { colors } from 'theme';
import Card from 'components/@ui/card';
import { formatCurrency, formatDateWithTime } from '@utils/format';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import RemoteServices from 'service';
import { RequestCommissionProps } from 'types/commission';

import {
    IoMdCheckmarkCircleOutline
} from "react-icons/io"
import {
    MdWarningAmber,
    MdErrorOutline
} from "react-icons/md"

import { FiArrowUpRight, FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import useMediasQuerys from 'hooks/mediasQuery';
import { AiFillEye } from 'react-icons/ai';
import { TablePagination } from '@material-ui/core';
export default function Historic() {

    const {
        matchesMaxMobile
    } = useMediasQuerys()


    const navigate = useNavigate()

    const [totalcommissionsreceived, setTotalcommissionsreceived] = useState<number>(0)
    const [commissionsRequests, setCommissionsRequests] = useState<RequestCommissionProps[] | null>(null)

    const [rowsPerPageRequest, setRowsPerPageRequest] = useState<number>(10)
    const [pageRequest, setPageRequest] = useState<number>(1)
    const [totalRequest, setTotalRequest] = useState<number>(0)
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)


    useEffect(() => {
        GetRequestCommissions()
    }, [pageRequest, rowsPerPageRequest])

    useEffect(() => {
        getCommissionsAnalitics()
    }, [])

    const getCommissionsAnalitics = () => {
        RemoteServices.commissions.CommissionlBI()
            .then(({ response }) => {
                if (response?.total_commissions_received) {
                    setTotalcommissionsreceived(response?.total_commissions_received?.ever?.total)
                }
            })
    }


    const GetRequestCommissions = () => {
        setLoadingRequest(true)
        RemoteServices.commissions.ListRequestCommission({
            limit: rowsPerPageRequest,
            page: pageRequest
        })
            .then(({ response }) => {
                if (response?.result) {
                    setCommissionsRequests(response.result)
                    setTotalRequest(response.total)
                }
            })
            .finally(() => setLoadingRequest(false))
    }


    return (
        <Grid xs={12} p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid container gap={2} justifyContent="space-between" py={2}>
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>histórico</Typography>
                    <Card bgcolor="primary">
                        <Grid container gap={2} flexDirection={"column"}>
                            <Typography variant="h6">
                                Total de comissões recebidas:<br />
                            </Typography>
                            <Grid container alignItems={"flex-end"} gap={5} >
                                <Typography variant="h5" fontWeight={"bold"}>{formatCurrency(totalcommissionsreceived)}</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Typography color={colors.black} mb={5} variant="h6" fontWeight={"bold"}>
                Solicitações
            </Typography>
            {
                matchesMaxMobile ?
                    commissionsRequests?.map((row) => (
                        <Grid
                            p={1}
                            width="100%"
                            border={`1px solid ${colors.grey400}`}
                            my={2}
                            borderRadius={2}
                            bgcolor="white">
                            <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`} alignItems="center">
                                <Grid item xs={6} container gap={2}>
                                    <Typography fontWeight="bold">ID: </Typography>{row.id}
                                </Grid>
                                <Grid item xs={6} fontWeight="bold">
                                    <Grid container
                                        // justifyContent="center"
                                        alignItems="center"
                                        gap={1}
                                        borderRadius={4}
                                        bgcolor={getStatusCommission(row.status).color + 15}
                                        color={getStatusCommission(row.status).color}
                                        py={.5}
                                        p={1}
                                        textAlign={"center"}
                                        justifyContent={"center"}
                                    >
                                        {getStatusCommission(row.status).Icon}
                                        <Typography sx={{ textTransform: 'none' }}>
                                            {getStatusCommission(row.status).statuscommission}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid pt={1} container fontSize={14} color={colors.grey600} direction="column">
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">Solicitado em: </Typography>{formatDateWithTime(row.updated_at ?? '')}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">Criado em: </Typography>{formatDateWithTime(row.created_at)}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">Valor da solicitação: </Typography>{formatCurrency(row.commission_amount)}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">Desconto: </Typography>{formatCurrency(row.discounted)}
                                </Grid>
                            </Grid>
                            <Grid
                                pt={1}
                                container
                                borderTop={`1px solid ${colors.grey400}`}
                                alignItems="center"
                                justifyContent="space-between">
                                <Grid>
                                    <Typography fontWeight="bold">nota fiscal</Typography>
                                    {
                                        row.invoice_url ?
                                            <Grid
                                                target='_black'
                                                component="a"
                                                href={row.invoice_url}
                                                sx={{ textDecoration: 'underline' }}>
                                                Ver nota fiscal <FiArrowUpRight />
                                            </Grid>
                                            :
                                            "Não enviado"
                                    }
                                </Grid>
                                <div>
                                    <Button
                                        sx={{
                                            bgcolor: colors.third + 50,
                                            color: colors.third,
                                            borderRadius: 12,
                                            p: .5
                                        }}
                                        onClick={() => navigate(`/app/comissoes/historico/${row.id}`, {
                                            state: row
                                        })}>
                                        <AiFillEye color={colors.third} size={22} style={{ marginRight: 4 }} />
                                        <Typography fontSize={12} fontWeight="bold">Ver detalhes</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    ))
                    :
                    <TableUI
                        page={pageRequest - 1}
                        onPageChange={(event, page) => setPageRequest(page + 1)}
                        total={totalRequest}
                        loading={loadingRequest}
                        rowsPerPage={rowsPerPageRequest}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPageRequest(Number(rows.target.value))
                        }}
                        HeaderComponent={
                            <CustomTableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="left">Solicitado em:</TableCell>
                                <TableCell align="left">Criada em:</TableCell>
                                <TableCell align="left">Valor da solicitação</TableCell>
                                <TableCell align="left">Desconto</TableCell>
                                <TableCell align="center">Nota fiscal</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Detalhes</TableCell>
                            </CustomTableRow>
                        }
                        RowComponent={
                            commissionsRequests?.map((row) => (
                                <CustomTableRow type={'body'}>
                                    <TableCellCusttom title={row.id} />
                                    <TableCellCusttom title={formatDateWithTime(row.created_at)} />
                                    <TableCellCusttom title={formatDateWithTime(row.updated_at ?? '')} />
                                    <TableCellCusttom title={formatCurrency(row.commission_amount)} />
                                    <TableCellCusttom title={formatCurrency(row.discounted)} />
                                    <TableCellCusttom align='center' title={row.status}>
                                        <Grid
                                            textTransform={'none'}
                                            p={1}>
                                            {
                                                row.invoice_url ?
                                                    <Button
                                                        target='_black'
                                                        component="a"
                                                        href={row.invoice_url}
                                                    >NF anexada</Button> :
                                                    'Não enviada'
                                            }

                                        </Grid>
                                    </TableCellCusttom>
                                    <TableCellCusttom title={row.status}>
                                        <Grid container
                                            // justifyContent="center"
                                            alignItems="center"
                                            gap={1}
                                            borderRadius={4}
                                            bgcolor={getStatusCommission(row.status).color + 15}
                                            color={getStatusCommission(row.status).color}
                                            py={.5}
                                            p={1}
                                            textAlign={"center"}
                                            justifyContent={"center"}
                                        >
                                            {getStatusCommission(row.status).Icon}
                                            <Typography sx={{ textTransform: 'none' }}>
                                                {getStatusCommission(row.status).statuscommission}
                                            </Typography>
                                        </Grid>
                                    </TableCellCusttom>
                                    <TableCellCusttom align='center' title={row.status}>
                                        <IconButton onClick={() => navigate(`/app/comissoes/historico/${row.id}`, {
                                            state: row
                                        })}>
                                            <FiEye />
                                        </IconButton>
                                    </TableCellCusttom>
                                </CustomTableRow>
                            ))
                        }
                    />
            }
            {matchesMaxMobile &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalRequest}
                    translate='yes'
                    labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                    labelRowsPerPage={`páginas:`}
                    rowsPerPage={rowsPerPageRequest ?? 10}
                    page={pageRequest - 1}
                    onPageChange={(event, page) => setPageRequest(page + 1)}
                    onRowsPerPageChange={(rows) => {
                        setRowsPerPageRequest(Number(rows.target.value))
                    }}
                />
            }
        </Grid>
    );
}


export const getStatusCommission = (status: 'paid' | 'reproved' | 'pending') => {
    let statuscommission = ''
    let Icon: JSX.Element = <></>
    let color = ''
    switch (status) {
        case 'paid':
            statuscommission = 'Paga'
            Icon = <IoMdCheckmarkCircleOutline size={20} />
            color = colors.primary
            break;
        case 'reproved':
            statuscommission = 'Reprovada'
            Icon = <MdErrorOutline size={20} />
            color = colors.error
            break
        case 'pending':
            statuscommission = 'Solicitada'
            Icon = <MdWarningAmber size={20} />
            color = colors.warningMain

            break
        default:
            break;
    }

    return {
        statuscommission,
        Icon,
        color
    }
}