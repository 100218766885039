import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'

import WithLinkImagem from '../../../assets/png/GERAR LINK.png'
import WithoutLinkImagem from '../../../assets/png/PRESENCIAL.png'
import { Button, Container, Typography } from '@mui/material'

const Tutorial = () => {
    const { search, state } = useLocation()
    const navigate = useNavigate()

    const videoList = [
        {
            url: "https://front-source-assets.s3.sa-east-1.amazonaws.com/acesso/assets/videos/PRESENCIAL.mp4",
            title: "Aprenda a emprestar presencialmente",
            thumbnail: WithoutLinkImagem
        },
        {
            url: "https://front-source-assets.s3.sa-east-1.amazonaws.com/acesso/assets/videos/GERAR+LINK.mp4",
            title: "Aprenda a emprestar via Link",
            thumbnail: WithLinkImagem
        },
        {
            url: "https://youtu.be/2H0zpQmoDZw",
            title: "Aprensentação VaptPost | Parceiros Ayude",
            thumbnail: WithLinkImagem
        },

    ]
    const [video, setVideo] = useState(videoList[0])

    useEffect(() => {
        setVideo(videoList[Number(search.split('?id=')[1])])
    }, [])

    return (
        <Container
            maxWidth={"xl"}
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '50px',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
            }}
        >
            <Typography variant='h4' mt={3}>{video.title}</Typography>
            <ReactPlayer
                controls={true}
                width="100%"
                height="70%"
                url={video.url}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload',
                            preload: "metadata",
                            autoplay: "true"
                        }
                    }
                }}
                light={<img src={video.thumbnail} alt='Thumbnail' style={{
                    width: '100%',
                    height: '100%'
                }} />}
            />
            <Button
                sx={{
                    p: 1,
                    width: 120,
                    textDecoration: 'underline',
                }}
                color="inherit"
                onClick={() => window.close()}
                variant="text">
                Voltar
            </Button>
        </Container>
    )
}

export default Tutorial