import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionUI({
    title,
    children,
    defaultExpanded
}: {
    title: string;
    children: JSX.Element,
    defaultExpanded?: boolean
}) {
  return (
    <div>
      <Accordion
      defaultExpanded={defaultExpanded ?? false}
      sx={{ maxWidth: 700, width: '100%', boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 12px', p: 2 }} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontSize={25} fontWeight="bold" >{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}