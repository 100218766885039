import { Box, Button, Grid, Switch, Typography } from "@mui/material"
import TooltipUI from "components/@ui/Tooltip";
import ModalUI from "components/@ui/modal"
import Toast from "components/@ui/toast";
import useMediasQuerys from "hooks/mediasQuery";
import RemoteServices from "service";
import { colors } from "theme"
import { FiliaisProps } from "types/filiais";

interface saleTablesSalesCounterProps {
    sale_table: string,
    active: boolean
}

const Managetables = ({
    open,
    handleClose,
    salesCounter,
    saleTablesSalesCounter,
    getSalesTables,
    allowedTables
}: {
    open: boolean,
    handleClose: () => void;
    salesCounter: FiliaisProps['data'] | null;
    saleTablesSalesCounter: saleTablesSalesCounterProps[] | null;
    getSalesTables: (sales_counter_id: number) => void;
    allowedTables: { sale_table: string }[] | null
}) => {

    const { matchesMaxMobile } = useMediasQuerys()


    const changeTable = async (active: boolean, sale_table: any, sales_counter_id: number) => {
        RemoteServices.proposals.SalesTable({
            userid: sales_counter_id,
            sale_table,
            active
        }).then(async ({ response, status }) => {
            console.log(response, status)
            if (status === 200) {
                getSalesTables(sales_counter_id)
                Toast({
                    type: "success"
                }, `Tabela ${sale_table.toUpperCase()} ${active ? 'ativada!' : 'desativada!'}`)
            } else {
                Toast({
                    type: "error"
                }, response?.message ?? response?.error ?? "Falha ao ativar/desativar tabela")
            }
        })
    }


    const Table = ({ item }: { item: saleTablesSalesCounterProps }) => (
        <Box>
            <TooltipUI title={allowedTables?.filter((i) => i.sale_table === item.sale_table).length === 0 ?
                'Tabela não autorizada para este usuário.' : ''}>
                <Grid
                    container
                    flexDirection={"column"}
                    gap={1}
                    alignItems={"center"}>
                    <Grid
                        container
                        height="6.75em"
                        width="6.75em"
                        color={item.active ? 'white' : 'black'}
                        bgcolor={item.active ? colors.primary : colors.grey300}
                        borderRadius={'50%'}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ transition: 'all .3s ease-in-out' }}
                    >
                        <Typography>{item?.sale_table?.toUpperCase()?.replaceAll('ON-', 'TABELA-')}</Typography>
                    </Grid>
                    <Switch
                        sx={{ mt: 1 }}
                        checked={item.active === true ? true : false}
                        disabled={allowedTables?.filter((i) => i.sale_table === item.sale_table).length === 0}
                        onChange={(e) => {
                            changeTable(e.target.checked, item.sale_table, salesCounter?.sales_counter?.id ?? 0)
                        }}
                    />
                </Grid>
            </TooltipUI>
        </Box>
    )


    return (
        <ModalUI
            open={open}
            // hiddenButtonClose
            handleClose={handleClose}
            sxPapper={{ width: '100%' }}
        >
            <Grid
                container
                gap={2}
                flexDirection={"column"}
                width={"100%"}
                height={`calc(${matchesMaxMobile ? '100vh - 32px' : '90vh - 64px'})`}
                flexWrap={"nowrap"}>
                <Typography
                    textTransform="uppercase"
                    fontWeight="bold"
                    sx={{ width: '100%' }}
                    variant={'h5'}
                    color={colors.black}>
                    Gerenciar tabelas
                </Typography>
                <Grid
                    container
                    xs={12}
                    alignContent={"flex-start"}
                    gap={5}
                    height={'calc(90vh - 400px)'}
                    sx={{ overflowY: 'auto' }}>
                    <Grid container gap={10} my={2}>
                        <Grid item>
                            <Typography
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.black}>Nome</Typography>
                            <Typography color={colors.black}>
                                {salesCounter?.sales_counter.responsible_name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.black}>
                                Email
                            </Typography>
                            <Typography color={colors.black}>{salesCounter?.sales_counter.email}</Typography>
                        </Grid>
                    </Grid>
                    <Typography color={colors.black} variant="h5">TABELAS</Typography>
                    <Grid
                        alignContent="flex-start"
                        container
                        gap={matchesMaxMobile ? 2 : 4}>
                        {
                            saleTablesSalesCounter?.map((item) => (
                                <Table item={item} />
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" gap={2} alignItems={"center"}>
                    <Button
                        onClick={handleClose}
                        disableElevation
                        sx={{ p: 2, minWidth: 200 }}
                        variant="outlined">
                        Fechar
                    </Button>
                    {/* <Button variant="contained" disableElevation sx={{ p: 2, minWidth: 200 }}>
                        Salvar alterações
                    </Button> */}
                </Grid>
            </Grid>
        </ModalUI>
    )
}

export default Managetables;