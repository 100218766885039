import { Remote } from "service/remote";

export const MeGestor = async () => {
    return await Remote({
        method: "get",
        urn: `gestors/me`,
        api: 'manager'
    })

}

export default MeGestor;