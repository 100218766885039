import React, { createContext, useContext, useEffect, useState } from "react";
import { Proposals } from "service/online/proposals";
import { io } from "socket.io-client";
import { ProposalRR } from "types/proposal";
import { awaitTime } from "utils/awaitTime";
import URLSBases from "utils/urlBases";
import useAuth from "./auth";
import RemoteServices from "service";
import { RequestCommissionProps } from "types/commission";


interface ContextProvider {
    openDialogAccount: boolean;
    setOpenDialogAccount: (value: boolean) => void;
    proposal: ProposalRR | null;
    setProposal: (value: ProposalRR | null) => void;
    getStatus: boolean;
    setGetStatus: (value: boolean) => void;
    notifications: RequestCommissionProps[];
    setNotifications: (value: RequestCommissionProps[]) => void;
    getRequestsReproved: () => void;
    redirectToRequest: boolean;
    setRedirectToRequest: (value: boolean) => void; 
    redirectToResendRequest: boolean;
    setRedirectToResendRequest: (value: boolean) => void; 
    openDialogCommissionRequest: boolean;
    setOpenDialogCommissionRequest: (value: boolean) => void; 
    openDialogResendRequest: boolean;
    setOpenDialogResendRequest: (value: boolean) => void; 
    account: any;
    setAccount: (value: any) => void; 
    chatOpen: boolean;
    setChatOpen: (value: boolean) => void; 

}

export const General = createContext<ContextProvider>({} as ContextProvider);
const useGeneral = () => useContext(General);

export const GeneralProvider = ({ children }: { children: JSX.Element }) => {
    const { User } = useAuth()
    const [openDialogAccount, setOpenDialogAccount] = useState(false)
    const [proposal, setProposal] = useState<ProposalRR | null>(null)
    const [getStatus, setGetStatus] = useState(false)
    const [notifications, setNotifications] = useState<Array<RequestCommissionProps>>([])
    const [redirectToRequest, setRedirectToRequest] = useState(false)
    const [redirectToResendRequest, setRedirectToResendRequest] = useState(false)

    const [openDialogCommissionRequest, setOpenDialogCommissionRequest] = useState(false)
    const [openDialogResendRequest, setOpenDialogResendRequest] = useState(false)
    const [chatOpen, setChatOpen] = useState(false)
    const [account, setAccount] = useState({})

    const getProposal = async () => {
        await Proposals({
            id: proposal?.id
        }).then(({ response }) => {
            if (response?.result[0]) {
                setProposal(response.result[0])
            }
        }).finally(async () => {
            await awaitTime(5)
            if (sessionStorage.getItem("getStatus" + "-" + proposal?.id) === "true") {
                return getProposal()
            } else {
                setProposal(null)
                return
            }
        })
    }

    const getRequestsReproved = async (total: number = 10) => {
        await RemoteServices.commissions.ListRequestCommission({
            status: "reproved",
            limit: total,
            page: 1
        })
            .then(({ response }) => {
                if (response?.result) {
                    setNotifications(response.result)
                    if (response.result?.total >  total) {
                        return getRequestsReproved(response.result?.total ?? total)
                    }
                }
            })
    }

    useEffect(() => {
        sessionStorage.setItem("getStatus" + "-" + proposal?.id, getStatus.toString())
        if (getStatus && proposal) {
            getProposal()
        } else {
            sessionStorage.clear()
        }
    }, [getStatus])


    useEffect(() => {
        const socket = io(URLSBases.wsTc);

        socket.on(User?.sub as string,  (...args) => {
            console.log(args[0])
            if (args[0] && args[0].point === 'request-reproved') {
                getRequestsReproved()
            }
            if (args[0] && args[0].point === 'access-blocked') {
                document.location.replace(URLSBases.LOGIN)
            }
        })

        getRequestsReproved()
    }, [])

    return (
        <General.Provider value={{
            openDialogAccount,
            setOpenDialogAccount,
            proposal,
            setProposal,
            getStatus,
            setGetStatus,
            notifications,
            setNotifications,
            getRequestsReproved,
            redirectToRequest,
            setRedirectToRequest,
            openDialogCommissionRequest,
            setOpenDialogCommissionRequest,
            account,
            setAccount,
            redirectToResendRequest,
            setRedirectToResendRequest,
            openDialogResendRequest,
            setOpenDialogResendRequest,
            chatOpen,
            setChatOpen
        }}>
            {children}
        </General.Provider>
    )
}


export default useGeneral;