import { Grid } from "@mui/material"
import { formatCurrency, getLegibleDate, toCurrency } from '@utils/format';
import { transformValue } from '@utils/transformValue';
import React, { useEffect, useState } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';

import { colors } from 'theme';
import useMediasQuerys from "hooks/mediasQuery";


function Gender({
    data
}: {
    data: { parcels: number, netAmount: number, gender: string }[]
}) {

    const { matchesMaxMobile } = useMediasQuerys()

    const [realData, setData] = useState<{ name: string, value: string, amount: number }[] | null>(null);

    useEffect(() => {
		if (data?.length > 0) {
			const men = data.filter(a => a.gender === 'M').map(a => a.netAmount);
			const women = data.filter(a => a.gender === 'F').map(a => a.netAmount);
			setData([
				{ name: "Homens", value: toCurrency(men.length > 0 ? men.reduce((a, b) => a+b) : 0), amount: men.length },
				{ name: "Mulheres", value:toCurrency(women.length > 0 ? women.reduce((a, b) => a+b) : 0), amount: women.length },
			]);
		} else {
			setData([
				{ name: "Homens", value: `R$ 0,00`, amount: 0 },
				{ name: "Mulheres", value: `R$ 0,00`, amount: 0 },
			]);
		}
	}, [ data ]);

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 12,
    }));

    function PieCenterLabel({ children }: { children: React.ReactNode }) {
        const { width, height, left, top  } = useDrawingArea();
        return (
            <StyledText x={left + width / 2} y={top + height / 2.5}>
                {children}
            </StyledText>
        );
    }

    if(!realData){
        return <></>
    }

    return (
        <PieChart
            width={matchesMaxMobile ? 365 : 400}
            height={matchesMaxMobile ? 365: 400}
            colors={['#2e96ff','#da00ff']} 
            tooltip={{
                trigger: 'item'
            }}
            series={[
                {
                    data: realData?.map((real) => {
                        return {
                            id: real.value,
                            label: real.name,
                            value: real.amount
                        }
                    }),
                    innerRadius: 70,
                    outerRadius: 100,
                    paddingAngle: 3,
                    cornerRadius: 5,
                    startAngle: -180,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                    valueFormatter: ({ id, value, label }) => id as unknown as string
                },
            ]}
        >
            {/* <PieCenterLabel>Center label</PieCenterLabel> */}
        </PieChart>

    )
}

export default Gender;