import gateway, { gatewayMAneger, gatewayDataApi, gatewayAuthDataApi } from "./gateway";

export const Remote = async (service: {
    urn: string;
    method: "post" | "put" | "delete" | "get" | "patch"
    params?: any;
    data?: any;
    headers?: any,
    api?: 'default' | 'manager' | 'data-api' | 'auth'
}) => {

    const { urn, method, params, data, headers, api } = service;

    const APISREMOTE = {
        'default': gateway,
        'manager': gatewayMAneger,
        'data-api': gatewayDataApi,
        'auth': gatewayAuthDataApi
    }

    const apiRemote = api ? APISREMOTE[api] : gateway

    try {
        const response = await apiRemote[method](urn, {
            ...(params && { params: params }),
            ...data ?? null,
        }, {
            ...(headers && { headers: headers })
        }, 
        )
        
        return { response: response.data, status: response?.status ?? response };
    } catch (reason: any) {
        if (reason?.response?.status >= 500) {
            console.error(reason)
        }

        if (reason.response) {
            const { data, status } = reason.response;
            data!.severity = 'error';
            return { response: data, status };;
        }
        return {
            statusCode: reason?.status,
            error: "Problemas ao se conectar com o serviço",
            severity: 'error'
        }
    }
}