import { Remote } from "service/remote";

export interface IIValidatorDataBanks {
    name?: string;
    cpf_cnpj?: string,
    bank_code?: string,
    agency?: string,
    account?: string,
    account_digit?: string,
    account_type?: "CONTA_CORRENTE" | "CONTA_POUPANCA" | "CONTA_PAGAMENTO" | "CONTA_FACIL" | "ENTIDADES_PUBLICAS",
    integration_id?: string,
    micro_deposit_value?: number,
    pix_description?: string,
    type?: string,
    bank_name?: string,
    pix_key?: string,
    pix_key_type?: "CPF" | "CHAVE_ALEATORIA" | "EMAIL" | "TELEFONE" | "CNPJ",
    pix_key_validation?: {
        cpf_cnpj: string
    },
}

const ValidateAccount = async (data: IIValidatorDataBanks) => {
    return await Remote({
        method: "post",
        urn: `online/validate/bank`,
        data
    })
}

export default ValidateAccount;