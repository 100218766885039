import { Grid, IconButton, Typography } from "@mui/material";
import { HiDownload, HiSearch } from "react-icons/hi";
import style from './style.module.css';
import { useState } from "react";
import axios from "axios";
import ModalUI from "components/@ui/modal";
import { saveAs } from 'file-saver'

export const VaptPostCard = ({
    description,
    download,
    image,
    index,
    keyName,
    scaleReverse: scale
}: {
    index: number,
    image: string,
    description: string,
    download?: boolean,
    keyName?: string,
    scaleReverse?: boolean

}) => {
    const [seeMore, setSeeMore] = useState(false);
    const [openModal, setOpenModal] = useState(false)

    const downloadPost = (file: string) => {
        let fileName = "CONTEÚDO VAPTPOST + AYUDE - " + file.split("bubble.io/")[1].split("/image")[0] + file.split("bubble.io/")[1].split("/image")[1]
        axios({
            url: file,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            saveAs(file, fileName)
            console.error('Erro ao baixar a imagem:', error);
        });
    }

    return (
        <>
            <div id={`${keyName ?? "post"}-${index}`} key={`${keyName ?? "post"}-${index}`} className={style.card} style={{
                height: seeMore ? '100%' : '379px',
                transition: 'all ease .2s',
                ...(scale && {
                    transform: 'scale(0.9)'
                })
            }}>
                <div className={style.bar}>
                    <div>
                        {download && <IconButton onClick={() => downloadPost(image)}>
                            <HiDownload fontSize={16} />
                        </IconButton>
                        }
                        <IconButton onClick={() => setOpenModal(true)}>
                            <HiSearch fontSize={16} />
                        </IconButton>
                    </div>
                </div>
                <img src={image} alt="ayude with ayude suggest" draggable={false} />
                <p className={seeMore ? style.description : style.shortDescription}>{description}</p>
                <button onClick={() => setSeeMore(!seeMore)}>Ver {seeMore ? "menos" : "mais"}</button>
            </div>

            <ModalUI open={openModal} handleClose={() => setOpenModal(false)}>
                <Grid p={4} container gap={2} justifyContent={"center"} direction={"column"} alignItems={"center"}>
                    <img src={image} alt="image" className={style.modalImage} />
                    <p className={style.modalDescription}>{description}</p>
                    <div className={style.modalContainerButtons}>
                        {download && <button className={style.modalButtonDownload} onClick={() => downloadPost(image)}>Download</button>}
                    </div>
                </Grid>
            </ModalUI>
        </>
    );
};
