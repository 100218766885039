import { Remote } from "service/remote";

export const Login = async (refresh_token: string) => {
    return await Remote({
        method: "patch",
        urn: `/renew`,
        api: 'auth',
        data: {
            refresh_token
        }
    })

}

export default Login;