import { Tooltip, SxProps, Theme, TooltipProps } from "@mui/material";
import { colors } from "theme";

const TooltipUI = ({
    children,
    title,
    sx,
    followCursor = false,
    color,
    placement
}: {
    children: JSX.Element;
    title: string;
    sx?: SxProps<Theme>,
    followCursor?: boolean,
    color?: string,
    placement?: any
}) => {
    return (
        <Tooltip
            placement={placement ?? "bottom"}
            title={title}
            slotProps={{
                tooltip: {
                    sx: { bgcolor: colors.grey200, color: color ?? colors.black, ...(sx && { sx }) }
                }
            }}
            followCursor={followCursor}>
            {children}
        </Tooltip>
    )
}

export default TooltipUI;