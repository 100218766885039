import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import ModalUI from "components/@ui/modal";
import { MdErrorOutline, MdOutlineUploadFile } from "react-icons/md";
import { colors, theme } from "theme";

import { DropzoneArea } from 'react-mui-dropzone'

import { useEffect, useState } from "react";
import { dataBR, formatCurrency } from "@utils/format";

import { PiCheckFat } from "react-icons/pi"
import { AgencyNumberInputMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom, KeyPixRandomMaskCustom } from "components/@ui/masked";

import { mask, unMask } from 'remask';

import contaBancaria from "assets/json/bancos.json"
import { schemaAccountSalesCounter } from "@utils/validator";
import Toast from "components/@ui/toast";
import { LoadingButton } from "@mui/lab";
import RemoteServices from "service";
import CommissionAlert from "components/commissionAlert";
import useGeneral from "context/general";
import { RequestCommissionProps } from "types/commission";
import { getStatusCommission } from "./historic";
import useAuth from "context/auth";
import { awaitTime } from "utils/awaitTime";
import { IIValidatorDataBanks } from "service/online/transfeera/validate";
import useMediasQuerys from "hooks/mediasQuery";

const keyPixTypeLabels: any = {
    'cellphone': 'Número de telefone',
    'key_random': 'Chave aleatória',
    'cpf': 'CPF',
    'cnpj': 'CNPJ',
    'email': 'E-mail',
    'pix': 'PIX',
    'ted': 'TED',
    'CC': 'Conta Corrente',
    'CP': 'Conta Poupança'
}


const masks = [
    '9999-9',
    '99999-9',
    '999999-9',
    '9999999-9',
    '99999999-9',
    '999999999-9',
    '9999999999-9',
    '99999999999-9',
];

const CommissionRequest = ({
    open,
    handleClose,
    getAccount,
    commissionValue,
    getProposals,
    getAvailableCommissions
}: {
    open: boolean;
    handleClose: () => void;
    getAccount: () => void;
    commissionValue: number;
    getProposals: () => void;
    getAvailableCommissions: () => void;
}) => {
    const {
        openDialogAccount,
        setOpenDialogAccount,
        redirectToRequest,
        setOpenDialogCommissionRequest,
        account,
        setAccount
    } = useGeneral()

    const {
        matchesMaxMobile
    } = useMediasQuerys()


    const [invoice, setInvoice] = useState<File[]>([]);
    const [statusInvoice, setStatusInvoice] = useState('');
    const [checked, setChecked] = useState(false)
    const [typePaymentCommission, setTypePaymentCommission] = useState('pix')
    const [typeKeyPix, setTypeKeyPix] = useState('');
    const [keyPix, setKeyPix] = useState('');
    const [accountType, setAccountType] = useState('');

    const [checkedInvoice, setCheckedInvoice] = useState(false)

    const [openModalNF, setOpenModalNF] = useState<boolean>(false)

    const [maskAccountNumberMask, setMaskAccountNumber] = useState('');
    const [bank, setBank] = useState({ name: '', code: '' });
    const [agencyNumber, setAgencyNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const [loading, setLoading] = useState(false);

    const requestPayment = async () => {
        const data = new FormData();
        data.append('invoice', invoice[0]);
        await RemoteServices.commissions.RequestCommission(data).then(({ data }) => {
            if (data?.id) {
                Toast({
                    type: 'success'
                }, "Comissão solicitada com sucesso!")
                getAvailableCommissions()
                handleClose()
                // getRequests({ page: pageIndex + 1, rows: rowsPerPage, status: 'pending' })
                // getAvailableCommissions()
                getProposals()
                return
            }
            Toast({
                type: 'error'
            }, data?.message ?? "Falha ao solicitar comissão")
        }).catch((err) => {
            Toast({
                type: 'error'
            }, err.response?.data?.message ?? "Falha ao solicitar comissão")
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <ModalUI
            open={open}
            handleClose={() => {
                handleClose()
                setStatusInvoice('');
                setInvoice([])
            }}
            sxPapper={{
                maxWidth: "45.375rem",
                width: "100%"
            }}
        // hiddenButtonClose
        >
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                flexDirection={"column"}
                textAlign="center"
                gap={2}
                width="100%"
            >
                <Grid width="100%" mt={matchesMaxMobile ? 3 : 1}>
                    <Typography
                        color={colors.black}
                        fontWeight={"bold"}
                        textTransform={"uppercase"}
                        textAlign={"start"}
                        variant={matchesMaxMobile ? "h6" : "h5"}>
                        Solicitar comissão
                    </Typography>
                </Grid>
                <Grid container justifyContent={"space-between"} gap={2}>
                    {!checkedInvoice ?
                        <Grid
                            p={2}
                            container
                            alignItems="center"
                            justifyContent={"space-between"}
                            width="100%"
                            px={3}
                            borderRadius={1}
                            bgcolor={colors.primary + 50}
                        >

                            <Typography textTransform={"uppercase"} color={colors.TextThird} fontWeight={"400"}>
                                COM o envio da nota fiscal
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                color={colors.TextThird}
                                textTransform={"uppercase"}
                                variant="h6">
                                {formatCurrency(
                                    commissionValue
                                )}
                            </Typography>
                        </Grid> :
                        <Grid
                            p={2}
                            container
                            alignItems="center"
                            justifyContent={"space-between"}
                            width="100%"
                            px={3}
                            borderRadius={1}
                            border={'1px solid ' + colors.primary}
                        >
                            <Typography textTransform={"uppercase"} color={colors.TextThird} fontWeight={"400"}>
                                SEM o envio da nota fiscal
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                color={colors.TextThird}
                                textTransform={"uppercase"}
                                variant="h6">
                                {formatCurrency(commissionValue - (commissionValue * 0.145))}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedInvoice}
                                onChange={(e) => {
                                    setCheckedInvoice(!checkedInvoice);
                                    setChecked(false)
                                    setInvoice([])
                                }}
                                color="primary"
                            />
                        }
                        label={
                            <Typography sx={{ textAlign: 'start', color: colors.black, fontWeight: '400' }}>
                                Prefiro não enviar nota fiscal
                            </Typography>
                        }
                    />
                </FormControl>
                {
                    !checkedInvoice ? invoice.length > 0 ?
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            borderRadius={2}
                            height={250}
                            width={'100%'}
                            className="succcess-import-att-content"
                            bgcolor={colors.primary}>
                            <PiCheckFat size={50} color={'white'} />
                        </Grid>
                        : <DropzoneArea
                            acceptedFiles={['image/*', 'application/pdf']}
                            filesLimit={1}
                            alertSnackbarProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            }}
                            maxFileSize={5000000}
                            Icon={() => <MdOutlineUploadFile color={colors.grey600} size={65} />}
                            dropzoneClass="drop-class-file-att"
                            dropzoneText="Arraste e solte o arquivo da nota fiscal aqui ou clique para selecionar."
                            getFileAddedMessage={(fileName) =>
                                `O arquivo ${fileName} foi adicionado com sucesso.`
                            }
                            getFileRemovedMessage={(fileName) =>
                                `O arquivo ${fileName} foi removido com sucesso.`
                            }
                            getDropRejectMessage={(rejectedFile) =>
                                `O arquivo ${rejectedFile.name} foi rejeitado. O formato do arquivo é inválido ou excede o tamanho máximo permitido de 5 MB`
                            }
                            getFileLimitExceedMessage={(filesLimit) =>
                                `Número máximo permitido de arquivo/s excedido. Somente ${filesLimit} arquivos são permitidos.`
                            }
                            onChange={(file) => {
                                if (statusInvoice) {
                                    setStatusInvoice('');
                                }
                                setInvoice(file);
                            }}
                        /> :
                        <>
                            <Grid width={"100%"}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    xs={12}
                                    sx={{ height: '9.375rem' }}
                                    bgcolor={colors.warning}>
                                    <MdErrorOutline color="white" size={60} />
                                </Grid>
                            </Grid>
                            <Typography
                                color={colors.black}
                                fontWeight={"bold"}
                                textTransform={"uppercase"}
                                variant="h4">
                                ATENÇÃO!
                            </Typography>
                        </>

                }
                {
                    !checkedInvoice &&
                    <Grid container justifyContent={"flex-end"} py={2}>
                        <Button
                            onClick={() => setOpenModalNF(true)}
                            variant="outlined">
                            (?) Como preencher a nota fiscal
                        </Button>
                    </Grid>
                }
                {checkedInvoice && <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => {
                                        setChecked(e.target.checked);
                                    }}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography sx={{ textAlign: 'start', color: colors.black, fontWeight: '400' }}>
                                    Concordo que ao não enviar a nota fiscal, irei receber apenas {formatCurrency(
                                        (commissionValue - (commissionValue * 0.145))
                                    )} (Valor já deduzido 14.5% do valor principal).
                                </Typography>
                            }
                        />
                    </FormControl>
                </Grid>}
                <Grid flexDirection={"column"} container xs={12} textAlign={"start"}>
                    <Typography sx={{ mb: 2 }} color={colors.black} fontWeight={"bold"}>
                        Método de pagamento da comissão
                    </Typography>
                    <Typography color={colors.black}>
                        Tipo: {keyPixTypeLabels[account?.type_payment]}
                    </Typography>

                    {
                        account?.type_payment === 'pix' ?
                            <>
                                <Typography color={colors.black}>
                                    Tipo de chave: {keyPixTypeLabels[account?.type_key_pix]}
                                </Typography>
                                <Typography color={colors.black}>
                                    Chave: {account?.key_pix}
                                </Typography>
                            </>
                            :
                            <>
                                <Typography color={colors.black}>Banco: {account?.bank?.code} - {account?.bank?.name}</Typography>
                                <Typography color={colors.black}>Tipo de conta: {keyPixTypeLabels[account?.type_account]}</Typography>
                                <Typography color={colors.black}>Conta: {account?.account}</Typography>
                                <Typography color={colors.black}>Agência: {account?.agency}</Typography>

                            </>
                    }

                    <Button
                        color="inherit"
                        sx={{
                            textDecoration: 'underline',
                            color: colors.black,
                            maxWidth: 200,
                            p: 0,
                            ml: -2.5
                        }}
                        onClick={() => {
                            setOpenDialogAccount(true)
                            setTypePaymentCommission(account?.type_payment)
                            if (account?.type_payment === 'pix') {
                                setTypeKeyPix(account?.type_key_pix)
                                setKeyPix(account?.key_pix)
                            } else {
                                setAccountType(account?.type_account)
                                setMaskAccountNumber(account?.account)
                                setBank(account?.bank)
                                setAgencyNumber(account?.agency)
                            }
                        }}
                    >
                        Alterar dados bancários
                    </Button>
                </Grid>
                <ChangePayment
                    accountNumber={accountNumber}
                    accountType={accountType}
                    bank={bank}
                    agencyNumber={agencyNumber}
                    getAccount={getAccount}
                    keyPix={keyPix}
                    maskAccountNumberMask={maskAccountNumberMask}
                    openDialogAccount={openDialogAccount}
                    setAccountNumber={setAccountNumber}
                    setAccountType={setAccountType}
                    setAgencyNumber={setAgencyNumber}
                    setBank={setBank}
                    setKeyPix={setKeyPix}
                    setMaskAccountNumber={setMaskAccountNumber}
                    setOpenDialogAccount={setOpenDialogAccount}
                    setTypeKeyPix={setTypeKeyPix}
                    setTypePaymentCommission={setTypePaymentCommission}
                    typePaymentCommission={typePaymentCommission}
                    typeKeyPix={typeKeyPix}
                    redirectToRequest={redirectToRequest}
                    setOpenDialogCommissionRequest={setOpenDialogCommissionRequest}
                />
                <Grid my={2} container justifyContent={"flex-end"}>
                    <LoadingButton
                        variant="contained"
                        disabled={(checkedInvoice && checked) || invoice.length === 1 ? false : true}
                        disableElevation
                        sx={{ p: 2, minWidth: 200 }}
                        onClick={requestPayment}
                    >
                        Solicitar comissão
                    </LoadingButton>
                </Grid>
                <CommissionAlert
                    open={openModalNF}
                    handleClose={() => setOpenModalNF(false)}
                    onClickAccept={() => {
                        setOpenModalNF(false)
                    }}
                />
            </Grid>
        </ModalUI>
    )
}

export default CommissionRequest;

export const ResendCommissionRequest = ({
    open,
    handleClose,
    request,
    getAccount,
}: {
    open: boolean;
    handleClose: () => void;
    request: RequestCommissionProps;
    getAccount: () => void;
}) => {
    const {
        openDialogAccount,
        setOpenDialogAccount,
        getRequestsReproved,
        redirectToRequest,
        setOpenDialogCommissionRequest,
        account
    } = useGeneral()
    const {
        matchesMaxMobile
    } = useMediasQuerys()
    const [invoice, setInvoice] = useState<File[]>([]);
    const [statusInvoice, setStatusInvoice] = useState('');
    const [checked, setChecked] = useState(false)
    const [typePaymentCommission, setTypePaymentCommission] = useState('pix')
    const [typeKeyPix, setTypeKeyPix] = useState('');
    const [keyPix, setKeyPix] = useState('');
    const [accountType, setAccountType] = useState('');

    const [checkedInvoice, setCheckedInvoice] = useState(false)

    const [openModalNF, setOpenModalNF] = useState<boolean>(false)

    const [maskAccountNumberMask, setMaskAccountNumber] = useState('');
    const [bank, setBank] = useState({ name: '', code: '' });
    const [agencyNumber, setAgencyNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const [loading, setLoading] = useState(false);

    const requestPayment = async () => {
        const data = new FormData();
        data.append('invoice', invoice[0]);
        await RemoteServices.commissions.ResendRequestCommission(request?.id as number, data).then(({ data }) => {
            if (data.status === 201) {
                Toast({
                    type: 'success'
                }, `Solicitação ${request?.id as number} reenviada com sucesso!`)
                getRequestsReproved()
                handleClose()
                setInvoice([])
                setStatusInvoice('')
                setChecked(false)
                setCheckedInvoice(false)
                return
            }
            Toast({
                type: 'error'
            }, data?.message ?? "Falha ao reenviar solicitação")
        }).catch((err) => {
            Toast({
                type: 'error'
            }, err.response?.data?.message ?? "Falha ao reenviar solicitação")
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => { console.log(request) }, [])



    return (
        <ModalUI
            open={open}
            handleClose={() => {
                handleClose()
                setStatusInvoice('');
                setInvoice([])
            }}
            sxPapper={{
                maxWidth: "60.375rem",
                width: "100%"
            }}
        >
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                flexDirection={"column"}
                gap={2}
            >
                <Grid width="100%" mt={matchesMaxMobile ? 3 : 1}>
                    <Typography
                        color={colors.black}
                        fontWeight={"bold"}
                        textTransform={"uppercase"}
                        textAlign={"start"}
                        variant={matchesMaxMobile ? "h6" : "h5"}>
                        Reenviar solicitação
                    </Typography>
                </Grid>

                <Grid container mt={4} mb={2}>
                    <Typography color={colors.black} variant="h6" textTransform={"uppercase"} fontWeight={"bold"}>
                        Detalhes da solicitação id: {request?.id ?? '0'}
                    </Typography>
                </Grid>
                <Grid container>
                    <table>
                        <tr>
                            <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Solicitado em {' '}
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {dataBR(request?.created_at ?? '00/00/0000')}
                                </Typography>
                            </td>
                            <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Descontado
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {formatCurrency(request?.discounted ?? 0)}
                                </Typography>
                            </td>
                            {!matchesMaxMobile && <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Nota fiscal
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">

                                    <Grid
                                        sx={{ whiteSpace: 'nowrap' }}
                                        maxWidth={500}
                                        overflow="hidden"
                                        textOverflow={"ellipsis"}
                                    >
                                        {
                                            request?.invoice_url ?
                                                <a href={request.invoice_url} target='_blank'>
                                                    {request.invoice_url}
                                                </a>
                                                :
                                                "Não enviado"
                                        }
                                    </Grid>

                                </Typography>
                            </td>}
                        </tr>
                        <tr>
                            <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Atualizado em
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {dataBR(request?.updated_at ?? '00/00/0000')}
                                </Typography>
                            </td>
                            <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Valor da solicitação
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    {formatCurrency(request?.commission_amount ?? 0)}
                                </Typography>
                            </td>
                            {!matchesMaxMobile && <td style={{ padding: 25 }}>
                                <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                    Status
                                </Typography>
                                <Typography color={colors.grey600} variant="subtitle1">
                                    <Grid
                                        container
                                        width={170}
                                        gap={1}
                                        borderRadius={4}
                                        bgcolor={getStatusCommission(request?.status ?? 'reproved').color + 15}
                                        color={getStatusCommission(request?.status ?? 'reproved').color}
                                        py={.5}
                                        p={1}
                                        textAlign="center"
                                    >
                                        {getStatusCommission(request?.status ?? 'reproved').Icon}
                                        <Typography sx={{ textTransform: 'none' }}>
                                            {getStatusCommission(request?.status ?? 'reproved').statuscommission}
                                        </Typography>
                                    </Grid>
                                </Typography>
                            </td>}
                        </tr>
                        {matchesMaxMobile &&
                            <tr>
                                <td style={{ padding: 25 }}>
                                    <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                        Status
                                    </Typography>
                                    <Typography color={colors.grey600} variant="subtitle1">
                                        <Grid
                                            container
                                            width={170}
                                            gap={1}
                                            borderRadius={4}
                                            bgcolor={getStatusCommission(request?.status ?? 'reproved').color + 15}
                                            color={getStatusCommission(request?.status ?? 'reproved').color}
                                            py={.5}
                                            p={1}
                                            textAlign="center"
                                        >
                                            {getStatusCommission(request?.status ?? 'reproved').Icon}
                                            <Typography sx={{ textTransform: 'none' }}>
                                                {getStatusCommission(request?.status ?? 'reproved').statuscommission}
                                            </Typography>
                                        </Grid>
                                    </Typography>
                                </td>
                                <td style={{ padding: 25 }}>
                                    <Typography color={colors.grey600} variant="subtitle1" fontWeight={"bold"}>
                                        Nota fiscal
                                    </Typography>
                                    <Typography color={colors.grey600} variant="subtitle1">

                                        <Grid
                                            sx={{ whiteSpace: 'nowrap' }}
                                            maxWidth={500}
                                            overflow="hidden"
                                            textOverflow={"ellipsis"}
                                        >
                                            {
                                                request?.invoice_url ?
                                                    <a href={request.invoice_url} target='_blank'>
                                                        {request.invoice_url}
                                                    </a>
                                                    :
                                                    "Não enviado"
                                            }
                                        </Grid>

                                    </Typography>
                                </td>
                            </tr>
                        }
                    </table>
                </Grid>
                <Grid container justifyContent={"space-between"} gap={2}>
                    {!checkedInvoice ?
                        <Grid
                            p={2}
                            container
                            alignItems="center"
                            justifyContent={"space-between"}
                            width="100%"
                            px={3}
                            borderRadius={1}
                            bgcolor={colors.primary + 50}
                        >

                            <Typography textTransform={"uppercase"} color={colors.TextThird} fontWeight={"400"}>
                                COM o envio da nota fiscal
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                color={colors.TextThird}
                                textTransform={"uppercase"}
                                variant="h6">
                                {formatCurrency(
                                    request?.commission_amount + request?.discounted
                                )}
                            </Typography>
                        </Grid> :
                        <Grid
                            p={2}
                            container
                            alignItems="center"
                            justifyContent={"space-between"}
                            width="100%"
                            px={3}
                            borderRadius={1}
                            border={'1px solid ' + colors.primary}
                        >
                            <Typography textTransform={"uppercase"} color={colors.TextThird} fontWeight={"400"}>
                                SEM o envio da nota fiscal
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                color={colors.TextThird}
                                textTransform={"uppercase"}
                                variant="h6">
                                {formatCurrency((request?.commission_amount + request?.discounted) - ((request?.commission_amount + request?.discounted) * 0.145))}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedInvoice}
                                onChange={(e) => {
                                    setCheckedInvoice(!checkedInvoice);
                                    setChecked(false)
                                    setInvoice([])
                                }}
                                color="primary"
                            />
                        }
                        label={
                            <Typography sx={{ textAlign: 'start', color: colors.black, fontWeight: '400' }}>
                                Prefiro não enviar nota fiscal
                            </Typography>
                        }
                    />
                </FormControl>
                {
                    !checkedInvoice ? invoice.length > 0 ?
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            borderRadius={2}
                            height={250}
                            width={'100%'}
                            className="succcess-import-att-content"
                            bgcolor={colors.primary}>
                            <PiCheckFat size={50} color={'white'} />
                        </Grid>
                        : <DropzoneArea
                            acceptedFiles={['image/*', 'application/pdf']}
                            filesLimit={1}
                            alertSnackbarProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            }}
                            maxFileSize={5000000}
                            Icon={() => <MdOutlineUploadFile color={colors.grey600} size={65} />}
                            dropzoneClass="drop-class-file-att"
                            dropzoneText="Arraste e solte o arquivo da nota fiscal aqui ou clique para selecionar."
                            getFileAddedMessage={(fileName) =>
                                `O arquivo ${fileName} foi adicionado com sucesso.`
                            }
                            getFileRemovedMessage={(fileName) =>
                                `O arquivo ${fileName} foi removido com sucesso.`
                            }
                            getDropRejectMessage={(rejectedFile) =>
                                `O arquivo ${rejectedFile.name} foi rejeitado. O formato do arquivo é inválido ou excede o tamanho máximo permitido de 5 MB`
                            }
                            getFileLimitExceedMessage={(filesLimit) =>
                                `Número máximo permitido de arquivo/s excedido. Somente ${filesLimit} arquivos são permitidos.`
                            }
                            onChange={(file) => {
                                if (statusInvoice) {
                                    setStatusInvoice('');
                                }
                                setInvoice(file);
                            }}
                        /> :
                        <>
                            <Grid width={"100%"}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    xs={12}
                                    sx={{ height: '9.375rem' }}
                                    bgcolor={colors.warning}>
                                    <MdErrorOutline color="white" size={60} />
                                </Grid>
                            </Grid>
                            <Typography
                                color={colors.black}
                                fontWeight={"bold"}
                                textTransform={"uppercase"}
                                variant="h4">
                                ATENÇÃO!
                            </Typography>
                        </>

                }
                {
                    !checkedInvoice &&
                    <Grid container justifyContent={"flex-end"} py={2}>
                        <Button
                            onClick={() => setOpenModalNF(true)}
                            variant="outlined">
                            (?) Como preencher a nota fiscal
                        </Button>
                    </Grid>
                }
                {checkedInvoice && <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => {
                                        setChecked(e.target.checked);
                                    }}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography sx={{ textAlign: 'start', color: colors.black, fontWeight: '400' }}>
                                    Concordo que ao não enviar a nota fiscal, irei receber apenas {
                                        formatCurrency((request?.commission_amount + request?.discounted) - ((request?.commission_amount + request?.discounted) * 0.145))} (Valor já deduzido 14.5% do valor principal).
                                </Typography>
                            }
                        />
                    </FormControl>
                </Grid>}
                <Grid flexDirection={"column"} container xs={12} textAlign={"start"}>
                    <Typography sx={{ mb: 2 }} color={colors.black} fontWeight={"bold"}>
                        Método de pagamento da comissão
                    </Typography>
                    <Typography color={colors.black}>
                        Tipo: {keyPixTypeLabels[account?.type_payment]}
                    </Typography>

                    {
                        account?.type_payment === 'pix' ?
                            <>
                                <Typography color={colors.black}>
                                    Tipo de chave: {keyPixTypeLabels[account?.type_key_pix]}
                                </Typography>
                                <Typography color={colors.black}>
                                    Chave: {account?.key_pix}
                                </Typography>
                            </>
                            :
                            <>
                                <Typography color={colors.black}>Banco: {account?.bank?.code} - {account?.bank?.name}</Typography>
                                <Typography color={colors.black}>Tipo de conta: {keyPixTypeLabels[account?.type_account]}</Typography>
                                <Typography color={colors.black}>Conta: {account?.account}</Typography>
                                <Typography color={colors.black}>Agência: {account?.agency}</Typography>

                            </>
                    }

                    <Button
                        color="inherit"
                        sx={{
                            textDecoration: 'underline',
                            color: colors.black,
                            maxWidth: 200,
                            p: 0,
                            ml: -2.5
                        }}
                        onClick={() => {
                            setOpenDialogAccount(true)
                            setTypePaymentCommission(account?.type_payment)
                            if (account?.type_payment === 'pix') {
                                setTypeKeyPix(account?.type_key_pix)
                                setKeyPix(account?.key_pix)
                            } else {
                                setAccountType(account?.type_account)
                                setMaskAccountNumber(account?.account)
                                setBank(account?.bank)
                                setAgencyNumber(account?.agency)
                            }
                        }}
                    >
                        Alterar dados bancários
                    </Button>
                </Grid>
                <ChangePayment
                    accountNumber={accountNumber}
                    accountType={accountType}
                    bank={bank}
                    agencyNumber={agencyNumber}
                    getAccount={getAccount}
                    keyPix={keyPix}
                    maskAccountNumberMask={maskAccountNumberMask}
                    openDialogAccount={openDialogAccount}
                    setAccountNumber={setAccountNumber}
                    setAccountType={setAccountType}
                    setAgencyNumber={setAgencyNumber}
                    setBank={setBank}
                    setKeyPix={setKeyPix}
                    setMaskAccountNumber={setMaskAccountNumber}
                    setOpenDialogAccount={setOpenDialogAccount}
                    setTypeKeyPix={setTypeKeyPix}
                    setTypePaymentCommission={setTypePaymentCommission}
                    typePaymentCommission={typePaymentCommission}
                    typeKeyPix={typeKeyPix}
                    redirectToRequest={redirectToRequest}
                    setOpenDialogCommissionRequest={setOpenDialogCommissionRequest}
                />
                <Grid mt={2} container justifyContent={"flex-start"}>
                    <Typography textAlign={"left"}>Está com alguma dúvida? Entre em contato com a <a href={`https://api.whatsapp.com/send/?phone=5511932588636&text=Olá,%20tenho%20dúvidas%20em%20relação%20a%20solicitação%20${request?.id}`} target="_blank">Tesouraria Ayude</a></Typography>
                </Grid>
                <Grid my={2} container justifyContent={"flex-end"}>
                    <LoadingButton
                        variant="contained"
                        disabled={(checkedInvoice && checked) || invoice.length === 1 ? false : true}
                        disableElevation
                        sx={{ p: 2, minWidth: 200 }}
                        onClick={requestPayment}
                    >
                        Solicitar comissão
                    </LoadingButton>
                </Grid>
                <CommissionAlert
                    open={openModalNF}
                    handleClose={() => setOpenModalNF(false)}
                    onClickAccept={() => {
                        setOpenModalNF(false)
                    }}
                />
            </Grid>
        </ModalUI>
    )
}


// async function aveAccount() {

export const ChangePayment = (props: {
    typeKeyPix: string;
    bank: {
        name: string, code: string
    };
    accountType: string;
    accountNumber: string;
    agencyNumber: string;
    typePaymentCommission: string;
    getAccount: () => void;
    setOpenDialogAccount(a: boolean): void;
    setAccountNumber(a: string): void;
    setMaskAccountNumber(a: string): void;
    openDialogAccount: boolean;
    setTypePaymentCommission(a: string): void;
    setKeyPix(a: string): void;
    keyPix: string;
    setTypeKeyPix(a: string): void;
    setBank(a: {
        name: string, code: string
    }): void;
    setAccountType(a: string): void;
    setAgencyNumber(a: string): void;
    maskAccountNumberMask: string;
    redirectToRequest: boolean,
    setOpenDialogCommissionRequest(a: boolean): void;
}) => {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const {
        setAccount,
        setRedirectToRequest,
        redirectToResendRequest,
        setRedirectToResendRequest,
        setOpenDialogResendRequest
    } = useGeneral()

    const {
        accountNumber,
        accountType,
        bank,
        typeKeyPix,
        agencyNumber,
        typePaymentCommission,
        getAccount,
        setOpenDialogAccount,
        setAccountNumber,
        setMaskAccountNumber,
        openDialogAccount,
        setTypePaymentCommission,
        keyPix,
        setKeyPix,
        setAccountType,
        setBank,
        setTypeKeyPix,
        maskAccountNumberMask,
        setAgencyNumber,
        redirectToRequest,
        setOpenDialogCommissionRequest,
    } = props;

    const { User, UserOperatorSales } = useAuth()


    const [loadingAccount, setLoadingAccount] = useState(false)
    const [statusTypeKeyPix, setStatusTypeKeyPix] = useState('')

    const [statusKeyPixCpf, setStatusKeyPixCpf] = useState('');
    const [keyPixCpf, setKeyPixCpf] = useState('');

    const [keyPixCnpj, setKeyPixCnpj] = useState('');
    const [statusKeyPixCnpj, setStatusKeyPixCnpj] = useState('');

    const [keyPixPhone, setKeyPixPhone] = useState('');
    const [statusKeyPixPhone, setStatusKeyPixPhone] = useState('');

    const [keyPixEmail, setKeyPixEmail] = useState('');
    const [statusKeyPixEmail, setStatusKeyPixEmail] = useState('');

    const [keyPixRandom, setKeyPixRandom] = useState('');
    const [statusKeyPixRandom, setStatusKeyPixRandom] = useState('');

    const [statusBank, setStatusBank] = useState('');

    const [statusAccountType, setStatusAccountType] = useState('');

    const [statusAgencyNumber, setStatusAgencyNumber] = useState('');

    const [agencyDigit, setAgencyDigit] = useState('');
    const [statusAgencyDigit, setStatusAgencyDigit] = useState('');

    const [statusAccountNumber, setStatusAccountNumber] = useState('');


    const [accountDigit, setAccountDigit] = useState('');
    const [statusAccountDigit, setStatusAccountDigit] = useState('');
    const [document, setDocument] = useState({
        document: "",
        name: "",
        type: ""
    });
    const saveAccount = () => {
        setLoadingAccount(true)
        schemaAccountSalesCounter
            .validate({
                typeKeyPix,
                keyPixCpf,
                keyPixCnpj,
                keyPixPhone,
                keyPixEmail,
                keyPixRandom,
                bank,
                accountType,
                agencyNumber,
                agencyDigit,
                accountNumber,
                accountDigit,
                typePaymentCommission,
                type_key_pix: undefined,
                key_pix: undefined,
                account: undefined,
                agency: undefined,
                type_account: undefined
            },
                { abortEarly: false }
            ).then(async () => {
                const data = {
                    type_payment: typePaymentCommission
                }
                if (typePaymentCommission === 'pix') {
                    // @ts-ignore
                    data.type_key_pix = typeKeyPix as unknown as undefined
                    // @ts-ignore
                    data.key_pix = keyPix as unknown as undefined
                } else {
                    // @ts-ignore
                    data.bank = bank as unknown as undefined
                    // @ts-ignore
                    data.account = accountNumber + '-' + accountDigit as unknown as undefined
                    // @ts-ignore
                    data.type_account = accountType as unknown as undefined
                    // @ts-ignore
                    data.agency = agencyNumber as unknown as undefined
                }
                let canContinue = false

                let body: IIValidatorDataBanks = {

                }
                let pixTypeTransfeera = {
                    'cellphone': 'TELEFONE',
                    'key_random': 'CHAVE_ALEATORIA',
                    'cpf': 'CPF',
                    'cnpj': 'CNPJ',
                    'email': 'EMAIL',
                }
                if (typePaymentCommission === "ted") {
                    body.account = accountNumber
                    body.account_digit = accountDigit
                    body.account_type = accountType === "CC" ? "CONTA_CORRENTE" : "CONTA_POUPANCA"
                    body.agency = agencyNumber
                    body.bank_code = bank.code
                    body.cpf_cnpj = document.document !== "" ? document.document : UserOperatorSales?.cnpj as string ?? UserOperatorSales?.responsible_cpf as string
                    body.name = document.name !== "" ? document.name : UserOperatorSales?.cnpj ? UserOperatorSales?.tradeName : UserOperatorSales?.responsible_name as string
                    body.pix_description = "Validação de conta bancária para pagamento Ayude"
                } else {
                    body.pix_key = typeKeyPix === "cellphone" || typeKeyPix === "cpf" || typeKeyPix === "cnpj" ? keyPix.replace(/\D/g, '') : keyPix
                    //@ts-ignore
                    body.pix_key_type = pixTypeTransfeera[typeKeyPix]
                    body.pix_key_validation = {
                        cpf_cnpj: document.document !== "" ? document.document : UserOperatorSales?.cnpj as string ?? UserOperatorSales?.responsible_cpf as string
                    }
                    body.pix_description = "Validação de conta bancária para pagamento Ayude"
                }

                await RemoteServices.transfeera.ValidateAccount(body as IIValidatorDataBanks).then(async ({ response }) => {
                    let id = response.id
                    let result = await consult(id)
                    canContinue = result.success
                    for (let _e of result.errors) {
                        Toast({
                            type: "error",
                            autoClose: 10000
                        }, _e.message)
                    }
                }).catch(() => Toast({
                    type: "error"
                }, "Erro ao salvar dados bancários. Por favor tente novamente"))


                if (canContinue) {
                    RemoteServices.commissions.getAccount(data).then(({ status }) => {
                        if (status === 200) {
                            setOpenDialogAccount(false)
                            Toast({
                                type: "success"
                            }, "Conta para comissões online salva!",)
                            setOpenDialogAccount(false)
                            if (redirectToRequest) {
                                setOpenDialogCommissionRequest(true)
                                RemoteServices.commissions.getAccount().then(({ response }) => {
                                    setAccount(response?.result?.account)
                                    setRedirectToRequest(false)
                                })
                            }
                            if (redirectToResendRequest) {
                                setOpenDialogResendRequest(true)
                                RemoteServices.commissions.getAccount().then(({ response }) => {
                                    setAccount(response?.result?.account)
                                    setRedirectToResendRequest(false)
                                })
                            }
                        } else {
                            Toast({
                                type: "error"
                            }, "Erro ao salvar dados bancários. Por favor tente novamente",)
                        }
                    }).catch((err) => {
                        Toast({
                            type: "error"
                        }, "Erro ao salvar dados bancários. Por favor tente novamente",)
                    }).finally(() => {
                        setLoadingAccount(false)
                    })
                } else {
                    setLoadingAccount(false)
                }
            }).catch((err) => {
                setLoadingAccount(false)
                handleValidationError(err);
                Toast(
                    {
                        type: 'warning',
                    }, 'Verifique os dados informados e tente novamente.'
                );
            })
    }

    const consult = async (id: string): Promise<{
        success: boolean,
        errors: Array<{
            errorCode: string,
            field: string,
            message: string,
        }>
    }> => {
        if (!id) {
            return {
                success: false,
                errors: [
                    {
                        message: "Ocorreu um erro ao validar conta bancária. Por favor, tente novamente.",
                        errorCode: "any",
                        field: "any"
                    }
                ]
            }
        }
        let valid
        let err
        let end = false
        while (!end) {
            await awaitTime(1)
            await RemoteServices.transfeera.ConsultAccount(id).then(({ response }) => {
                if (response.micro_deposit_status === "VALIDADO" && response.errors.length === 0 && response.valid === true) {
                    valid = true
                    end = true
                    err = []
                } else {
                    if (response.errors.length === 0) {
                        end = false
                    } else {
                        end = true
                        valid = false
                        err = response.errors
                    }
                }
            }).catch(err => console.log(err))
        }
        return {
            //@ts-ignore
            success: valid,
            //@ts-ignore
            errors: err
        }
    }

    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'bank.code':
                    setStatusBank(element.message);
                    break;
                case 'accountType':
                    setStatusAccountType(element.message);
                    break;
                case 'agencyNumber':
                    setStatusAgencyNumber(element.message);
                    break;
                case 'agencyDigit':
                    setStatusAgencyDigit(element.message);
                    break;
                case 'accountNumber':
                    setStatusAccountNumber(element.message);
                    break;
                case 'accountDigit':
                    setStatusAccountDigit(element.message);
                    break;
                case 'keyPixCpf':
                    setStatusKeyPixCpf(element.message);
                    break;
                case 'keyPixCnpj':
                    setStatusKeyPixCnpj(element.message);
                    break;
                case 'keyPixPhone':
                    setStatusKeyPixPhone(element.message);
                    break;
                case 'keyPixEmail':
                    setStatusKeyPixEmail(element.message);
                    break;
                case 'keyPixRandom':
                    setStatusKeyPixRandom(element.message);
                    break;
                case 'typeKeyPix':
                    setStatusTypeKeyPix(element.message);
                    break;
                default:
                    break;
            }
        });
    };

    function handleSetAccountNumber(event: any) {
        const initialValue = unMask(event.target.value);
        const lenghtInitialValue = initialValue.length;
        const maskValue = mask(initialValue, masks);

        setMaskAccountNumber(maskValue);

        const primaryPart = initialValue.substring(0, lenghtInitialValue - 1);
        const secondaryPart = initialValue.substring(lenghtInitialValue - 1);

        setAccountNumber(primaryPart);
        setAccountDigit(secondaryPart);
    }

    useEffect(() => {
        (async () => {
            if (openDialogAccount) {
                let user: any = {}
                await RemoteServices.auth.Me()
                    .then(async ({ response, status }) => {
                        if (status === 200) {
                            user = response?.data;
                        } else {
                            await RemoteServices.auth.MeGestor()
                                .then(({ response: _response, status: _status }) => {
                                    if (_status === 200) {
                                        user = _response;
                                    }
                                })
                        }
                    })
                    .catch((error) => {
                        Toast({
                            type: 'warning',
                        }, error.message ?? 'Não foi possível buscar dados do usuario!');
                    });

                if (user && User?.space === "pdv" || User?.space === "gestor") {
                    setDocument({
                        document: user?.owner?.type_pdv === "pj" ? user?.owner?.cnpj : user?.owner?.responsible_cpf,
                        name: user?.owner?.type_pdv === "pj" ? user?.owner?.company_name : user?.owner?.responsible_name,
                        type: user?.owner?.type_pdv === "pj" ? "CNPJ" : "CPF"
                    })
                }
            }
        })()
    }, [openDialogAccount])

    return (
        <Dialog
            PaperProps={{
                sx: {
                    borderRadius: matchesMaxMobile ? 0 : 10,
                    p: 2,
                    // minWidth: '80vh'
                }
            }}
            maxWidth="sm" fullWidth open={openDialogAccount} onClose={() => { }}
            fullScreen={matchesMaxMobile}>
            <DialogTitle>
                <Typography fontWeight={"bold"} sx={{ textTransform: 'uppercase' }} variant="h5">Pagamento de comissão</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} md={10} style={{ margin: '10px 0' }}>
                    <Typography>Cadastre os dados bancários vinculados ao<br /> {document.type !== "" ? document.type + ": " : UserOperatorSales?.cnpj ? "CNPJ: " : "CPF: "}<strong style={{
                        color: theme().palette.primary.main
                    }}>{document.document !== "" ? `${document.document}` : `${UserOperatorSales?.cnpj as string ?? UserOperatorSales?.responsible_cpf as string}`}</strong></Typography>
                    <Typography>Como deseja receber sua comissão?</Typography>
                </Grid>
                <Grid xs={12} md={12} sm={12} style={{ marginBottom: 20 }}>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={typePaymentCommission}
                            onChange={(event) => {
                                setTypePaymentCommission(event.target.value);
                            }}
                        >
                            <FormControlLabel value="pix" control={<Radio />} label="Pix" />
                            <FormControlLabel value="ted" control={<Radio />} label="TED" />
                        </RadioGroup>
                    </Grid>
                </Grid>
                {typePaymentCommission === "pix"
                    ?
                    <>
                        <Grid item xs={12} md={10} style={{ margin: '10px 0' }}>
                        </Grid>
                        <Grid xs={12} md={12} sm={12} style={{ marginBottom: 20 }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="standard-select-currency"
                                    select
                                    label="Tipo de chave pix."
                                    value={typeKeyPix}
                                    helperText={statusTypeKeyPix}
                                    error={!!statusTypeKeyPix}
                                    onChange={(event) => {
                                        if (statusTypeKeyPix !== "") {
                                            setStatusTypeKeyPix("")
                                        }
                                        setTypeKeyPix(event.target.value);
                                    }}
                                >
                                    {document.type === "CNPJ" &&
                                        <MenuItem key="true" value="cnpj">
                                            CNPJ
                                        </MenuItem>
                                    }
                                    {UserOperatorSales && UserOperatorSales?.cnpj !== null &&
                                        <MenuItem key="true" value="cnpj">
                                            CNPJ
                                        </MenuItem>
                                    }

                                    {UserOperatorSales && UserOperatorSales?.cnpj === null &&
                                        <MenuItem key="true" value="cpf">
                                            CPF
                                        </MenuItem>
                                    }
                                    {document.type === "CPF" &&
                                        <MenuItem key="true" value="cpf">
                                            CPF
                                        </MenuItem>
                                    }
                                    <MenuItem key="true" value="cellphone">
                                        Número de telefone
                                    </MenuItem>
                                    <MenuItem key="true" value="email">
                                        E-mail
                                    </MenuItem>
                                    <MenuItem key="true" value="key_random">
                                        Chave aleatória
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        {typeKeyPix === 'cpf' ? (
                            <Grid xs={12} md={12} sm={12}>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        error={!!statusKeyPixCpf}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="cpf">Chave pix - CPF</InputLabel>
                                        <OutlinedInput
                                            name="keyPixCpf"
                                            id="keyPixCpf"
                                            label="Chave pix - CPF"
                                            inputComponent={CPFMaskCustom}
                                            value={keyPix}
                                            onChange={(event) => {
                                                if (statusKeyPixCpf) {
                                                    setStatusKeyPixCpf('');
                                                }
                                                setKeyPixCpf(event.target.value);
                                                setKeyPix(event.target.value);
                                            }}
                                        />
                                        <FormHelperText>{statusKeyPixCpf}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : null}

                        {typeKeyPix === 'cnpj' ? (
                            <Grid xs={12} md={12} sm={12}>
                                <Grid item xs={12}>
                                    <FormControl
                                        error={!!statusKeyPixCnpj}
                                        fullWidth
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="keyPixCnpj">
                                            Chave pix - CNPJ
                                        </InputLabel>
                                        <OutlinedInput
                                            name="keyPixCnpj"
                                            id="keyPixCnpj"
                                            label="Chave pix - CNPJ"
                                            inputComponent={CNPJMaskCustom}
                                            value={keyPix}
                                            onChange={(event) => {
                                                if (statusKeyPixCnpj) {
                                                    setStatusKeyPixCnpj('');
                                                }
                                                setKeyPixCnpj(event.target.value);
                                                setKeyPix(event.target.value);
                                            }}
                                        />
                                        <FormHelperText>{statusKeyPixCnpj}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : null}

                        {typeKeyPix === 'cellphone' ? (
                            <Grid xs={12} md={12} sm={12}>
                                <Grid item xs={12} md={4} sm={6}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        error={!!statusKeyPixPhone}
                                    >
                                        <InputLabel htmlFor="cellphone">
                                            Chave Pix telefone
                                        </InputLabel>
                                        <OutlinedInput
                                            name="keyPixPhone"
                                            id="keyPixPhone"
                                            label="Chave Pix"
                                            inputComponent={CellphoneMaskCustom}
                                            value={keyPix}
                                            onChange={(event) => {
                                                if (statusKeyPixPhone) {
                                                    setStatusKeyPixPhone('');
                                                }
                                                setKeyPix(event.target.value);
                                                setKeyPixPhone(event.target.value);
                                            }}
                                            aria-describedby="component-error-cellphone"
                                        />
                                        <FormHelperText>{statusKeyPixPhone}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : null}

                        {typeKeyPix === 'email' ? (
                            <Grid xs={12} md={12} sm={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="keyPixEmail"
                                        fullWidth
                                        type="keyPixEmail"
                                        label="Chave pix - E-mail"
                                        variant="outlined"
                                        value={keyPix}
                                        error={!!statusKeyPixEmail}
                                        helperText={statusKeyPixEmail}
                                        onChange={(event) => {
                                            if (statusKeyPixEmail) {
                                                setStatusKeyPixEmail('');
                                            }
                                            setKeyPixEmail(event.target.value);
                                            setKeyPix(event.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ) : null}

                        {typeKeyPix === 'key_random' &&
                            <Grid xs={12} md={12} sm={12}>
                                <Grid item xs={12} md={8}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        error={!!statusKeyPixRandom}
                                    >
                                        <InputLabel htmlFor="keyPixRandom">
                                            Chave pix - Aleatória
                                        </InputLabel>
                                        <OutlinedInput
                                            name="keyPixRandom"
                                            id="keyPixRandom"
                                            label="Chave pix - Aleatória"
                                            inputComponent={KeyPixRandomMaskCustom}
                                            value={keyPix}
                                            onChange={(event) => {
                                                if (statusKeyPixRandom) {
                                                    setStatusKeyPixRandom('');
                                                }
                                                setKeyPixRandom(event.target.value);
                                                setKeyPix(event.target.value);
                                            }}
                                        />
                                        <FormHelperText>{statusKeyPixRandom}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        }
                    </>
                    :
                    <>
                        <Grid xs={12} md={12} sm={12}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            options={contaBancaria.banks}
                                            noOptionsText="Banco não encontrado"
                                            value={bank}
                                            onChange={(event, newValue) => {
                                                if (statusBank) {
                                                    setStatusBank('');
                                                }
                                                setBank(newValue);
                                            }}
                                            autoHighlight
                                            disableClearable
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(a: any, option) => (
                                                <Grid
                                                    color="inherit"
                                                    component={Button}
                                                    justifyContent={"flex-start"}
                                                    variant="text"
                                                    p={2}
                                                    container
                                                    onClick={() => {
                                                        if (statusBank) {
                                                            setStatusBank('');
                                                        }
                                                        setBank(option);
                                                    }}>
                                                    <span>{`${option?.code} - `}{option?.name}</span>

                                                </Grid>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="bank_name"
                                                    label="Banco"
                                                    variant="outlined"
                                                    error={!!statusBank}
                                                    helperText={statusBank}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: 15, marginTop: 10 }}
                                    >
                                        <TextField
                                            name="type_account"
                                            id="value-destination"
                                            fullWidth
                                            variant="outlined"
                                            select
                                            label="Tipo de conta"
                                            value={accountType}
                                            error={!!statusAccountType}
                                            helperText={statusAccountType}
                                            onChange={(e) => {
                                                if (statusAccountType) {
                                                    setStatusAccountType('');
                                                }
                                                setAccountType(e.target.value);
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                Selecione uma das opções abaixo
                                            </MenuItem>
                                            {contaBancaria.account_type.map((element) => (
                                                <MenuItem key={element.code} value={element.code}>
                                                    <span>{element.name}</span>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                <Grid container>
                                    <Grid width={"100%"}>
                                        <FormControl
                                            error={!!statusAgencyNumber}
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <InputLabel htmlFor="agencyNumber">
                                                Agência
                                            </InputLabel>
                                            <OutlinedInput
                                                name="agency_number"
                                                fullWidth
                                                id="agencyNumber"
                                                label="Agência"
                                                inputComponent={AgencyNumberInputMaskCustom}
                                                value={agencyNumber}
                                                onChange={(event) => {
                                                    if (statusAgencyNumber) {
                                                        setStatusAgencyNumber('');
                                                    }
                                                    setAgencyNumber(event.target.value);
                                                }}
                                            />
                                            <FormHelperText>
                                                {statusAgencyNumber}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            error={!!statusAccountNumber}
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <InputLabel htmlFor="accountNumber">
                                                Conta e Digito
                                            </InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                name="account_number"
                                                id="accountNumber"
                                                label="Conta"
                                                value={maskAccountNumberMask}
                                                onChange={(event) => {
                                                    if (statusAccountNumber) {
                                                        setStatusAccountNumber('');
                                                    }
                                                    handleSetAccountNumber(event);
                                                }}
                                            />
                                            <FormHelperText>
                                                {statusAccountNumber}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

            </DialogContent>
            <DialogActions sx={{
                flexDirection: matchesMaxMobile ? 'column' : 'row',
                alignItems: 'center',
                gap: 2
            }}>
                <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    sx={{ p: 2, minWidth: 200, textTransform: 'none' }}
                    onClick={() => {
                        setOpenDialogAccount(false)
                        if (redirectToRequest) setRedirectToRequest(false)
                        if (redirectToResendRequest) setOpenDialogResendRequest(false)
                    }}
                >Sair</Button>
                <LoadingButton
                    variant='contained'
                    color='primary'
                    loading={loadingAccount}
                    disableElevation
                    sx={{ p: 2, minWidth: 200, textTransform: 'none' }}
                    onClick={saveAccount}>Cadastrar</LoadingButton>
            </DialogActions>
        </Dialog>)
}