import React from 'react';

import { ToastOptions, toast, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToasComponentProps extends ToastOptions {
    ContentComponent?: JSX.Element| null
}

function Toast(props: ToasComponentProps, message: string) { 
    // @ts-ignore
    return toast(props.ContentComponent ? props.ContentComponent() : message, {
        theme: 'light',
        autoClose: props.autoClose ?? 3000,
        position:props.position ?? 'top-right',
        ...props
    })
}

export default Toast;