import { Grid, Typography, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Button, OutlinedInput, InputLabel, FormHelperText, Autocomplete, styled, Dialog, InputAdornment } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { CellphoneMaskCustom, CEPMaskCustom, CPFMaskCustom } from "components/@ui/masked";
import Breadcrumb from "components/breadcrumbs";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import RemoteServices from "service";
import { colors } from "theme";

import estadosCidades from 'assets/json/estados_cidades.json'
import { formatDate } from "@utils/format";
import { registerStep } from "@utils/validator";
import { ProposalProps } from "types/proposal";
import AntiFraude from "components/antiFraude";
import Toast from "components/@ui/toast";
import { LoadingButton } from "@mui/lab";
import ProposalDetails from "components/proposalDetais";
import useProposal from "context/proposal";
import useMediasQuerys from "hooks/mediasQuery";
import { WhatsApp } from "@mui/icons-material";

const PersonInfos = () => {

    const {
        matchesMaxMobile
    } = useMediasQuerys()


    const location = useLocation()
    const navigate = useNavigate()

    const { ClearProposal } = useProposal()

    const dateLastEighteenYears = moment()
    const dateLastOneHundredAndTenYears = moment()
        .subtract(110, 'years')
        .format(moment.HTML5_FMT.DATE);

    const dateLastTenYears = moment()
        .subtract(10, 'years')
        .format(moment.HTML5_FMT.DATE);

    const [disabledFields, setDisabledFields] = useState(false)

    const [openAntiFraudeDialog, setOpenAntiFraudeDialog] = useState(false)

    const [cities, setCities] = useState<string[]>([]);

    const [name, setName] = useState('')
    const [statusName, setStatusName] = useState('')

    const [birthday, setBirthday] = useState<Moment | null>(null)
    const [statusBirthday, setStatusBirthday] = useState('')

    const [email, setEmail] = useState('');
    const [statusEmail, setStatusEmail] = useState('')

    const [cellphone, setCellphone] = useState('')
    const [statusCellphone, setStatusCellphone] = useState('')

    const [clerkCellphone, setClerkCellphone] = useState('')
    const [statusClerkCellphone, setStatusClerkCellphone] = useState('')

    const [rg, setRg] = useState('')
    const [statusRg, setStatusRg] = useState('')

    const [issuingAgency, setIssuingAgency] = useState('')
    const [statusIssuingAgency, setStatusIssuingAgency] = useState('')

    const [issueDate, setIssueDate] = useState<Moment | null>(null)
    const [statusIssueDate, setStatusIssueDate] = useState('')

    const [address, setAddress] = useState('')
    const [statusAddress, setStatusAddress] = useState('')

    const [addressNumber, setAddressNumber] = useState('')
    const [statusAddressNumber, setStatusAddressNumber] = useState('')

    const [district, setDistrict] = useState('')
    const [statusDistrict, setStatusDistrict] = useState('')

    const [addressComplement, setAddressComplement] = useState('')

    const [CEP, setCEP] = useState('')
    const [statusCEP, setStatusCEP] = useState('')

    const [state, setState] = useState({ nome: '', sigla: '' })

    const [statusState, setStatusState] = useState('')

    const [city, setCity] = useState('')
    const [statusCity, setStatusCity] = useState('')

    const [CPF, setCPF] = useState('')
    const [statusCPF, setStatusCPF] = useState('');

    const [gender, setGender] = useState('');
    const [statusGender, setStatusGender] = useState('');

    const [disabledAddressInputs, setDisabledAddressInputs] = useState(false)

    const [loading, setLoading] = useState(false)

    const [proposalId, setProposalId] = useState('')

    const [activeStep, setActiveStep] = useState(0)


    const SolicitarPix = () => {
        RemoteServices.proposals.SolicitarPIX(proposalId)
            .then(() => {

            })
    }

    useEffect(() => {
        if (!location.state) {
            navigate('/')
        } else {
            // setOpenQuestions(true)
            const data = location.state
            if (data?.alreadyExist === true) {
                setName(data?.client.name)
                setCPF(data?.client.cpf)
                setBirthday(data?.client.birthday)
                setCellphone(data?.client.cellphone)
                setGender(data?.client.gender)
                setEmail(data?.client.email)
                setRg(data?.client.id_document)
                setIssuingAgency(data?.client.id_document_issuing_agency)
                setIssueDate(data?.client.id_document_issue_date)
                setCEP(data?.client.address.cep)
                const previusLocation = estadosCidades.find((item) => item.sigla === data?.client.address.state)
                setState({
                    nome: previusLocation?.nome ?? '',
                    sigla: data?.client.address.state,
                });
                setCities(previusLocation?.cidades ?? [])
                setCity(data?.client.address.city)
                setDistrict(data?.client.address.district)
                setAddressNumber(data?.client.address.number)
                setAddress(data?.client.address.street)
                setDisabledFields(true)
            } else {
                if (data?.sintegraError === false) {
                    setName(data?.client.name)
                    setCPF(data?.client.cpf)
                    setBirthday(data?.client.birthday)
                    setGender(data?.client.gender)
                    setDisabledFields(true)
                } else {
                    setCPF(data?.client.cpf)
                    setBirthday(data?.client.birthday)
                }
            }
        }
    }, [])


    const CreateProposal = async () => {
        setLoading(true)
        const data = {
            client: {
                name: name.trim(),
                email,
                cellphone: cellphone.replace(/\D/g, ''),
                cpf: CPF.replace(/\D/g, ''),
                id_document: rg,
                id_document_issuing_agency: issuingAgency,
                id_document_issue_date: moment(issueDate),
                gender,
                birthday: moment(birthday),
                address: {
                    cep: CEP.replace(/(\d{5})(\d)/, '$1-$2'),
                    state: state.sigla,
                    city,
                    district,
                    street: address,
                    number: addressNumber.toString(),
                }
            },
            credit_card_limit: location.state.credit_card_limit,
            link_id: location.state.link_id,
            net_amount: location.state.net_amount,
            parcels: location.state.parcels,
        }
        RemoteServices.proposals.CreateProposal(data as unknown as ProposalProps).then(({ response, status }) => {
            if (status > 300) {
                if (response?.point === "validation") {
                    handleValidationError(response.message)
                }
                else if (response?.point === "insight" || response?.point === "score") {
                    setOpenAntiFraudeDialog(true)
                } else if (response?.point === "Validação de cliente") {
                    Toast({
                        type: 'error',
                        autoClose: false
                    }, response?.message)
                } else {
                    Toast({
                        type: 'error',
                    }, response.message ?? "Falha ao criar proposta")
                }
                return
            }
            if (response?.id) {
                setProposalId(response?.id)
                setActiveStep(2)
                ClearProposal()
                navigate('/app/propostas', {
                    state: { proposalId: response?.id },
                })
            }
        })
            .catch(() => {
                Toast({
                    type: 'error',
                }, "Falha ao criar proposta")
            }).finally(() => setLoading(false))


    }


    const handleValidationError = (err: any) => {
        err.forEach((element: any) => {
            switch (element.path) {
                case 'name':
                    setStatusName(element.message);
                    break;
                case 'email':
                    setStatusEmail(element.message);
                    break;
                case 'cellphone':
                    setStatusCellphone(element.message);
                    break;
                case 'CPF':
                    setStatusCPF(element.message);
                    break;
                case 'birthday':
                    setStatusBirthday(element.message);
                    break;
                case 'id_document_issuing_agency':
                    setStatusIssuingAgency(element.message);
                    break;
                case 'id_document_issue_date':
                    setStatusIssueDate(element.message);
                    break;
                case 'gender':
                    setStatusGender(element.message);
                    break;
                case 'id_document':
                    setStatusRg(element.message);
                    break;
                case 'street':
                    setStatusAddress(element.message);
                    break;
                case 'addressNumber':
                    setStatusAddressNumber(element.message);
                    break;
                case 'district':
                    setStatusDistrict(element.message);
                    break;
                case 'cep':
                    setStatusCEP(element.message);
                    break;
                case 'city':
                    setStatusCity(element.message);
                    break;
                case 'state':
                    setStatusState(element.message);
                    break;
                case 'clerkCellphone':
                    setStatusClerkCellphone(element.message);
                    break;
                case 'number':
                    setStatusAddressNumber(element.message)
                    break;
                default:
                    break;
            }
        });
    };


    function getCep(cep: string) {
        const formatCep = cep.replace(/[^\d]/g, "")
        RemoteServices.external.GetCep({
            formatCep
        }).then(async ({ response }) => {
            if (response?.erro) {
                setDisabledAddressInputs(false)
                return
            }
            const state = estadosCidades.find((item) => item.sigla === response?.uf)
            setState({ nome: state?.nome ?? '', sigla: state?.sigla ?? '' })
            setCity(response?.localidade)
            setDisabledAddressInputs(true)

            if (statusState) setStatusState('')
            if (statusCity) setStatusCity('')
            if (statusDistrict) setStatusDistrict('')
            if (statusAddress) setStatusAddress('')

        }).catch(() => {
            setDisabledAddressInputs(false)
        })
    }

    const handleStates = (event: any, newValue: { sigla: string; nome: string; cidades: string[] }) => {
        if (statusState) {
            setStatusState('');
        }
        if (city) {
            setCity('');
        }
        estadosCidades.forEach((element) => {
            if (element.sigla === newValue.sigla) {
                setCities(newValue.cidades);
            }
        });

        if (newValue.sigla !== state.sigla) {
            setState({
                nome: newValue.nome,
                sigla: newValue.sigla,
            });
        }
    };


    return (
        <Grid p={4}>
            <Breadcrumb />
            <Grid p={1} mt={3}>
                <Typography variant="h4" fontWeight="bold" color={colors.black} textTransform={"uppercase"}>Informações pessoais do cliente</Typography>
                <Grid component="form" my={3} container gap={2} xs={12}>
                    <Grid container gap={2} xs={12}>
                        <Grid xs={12} md={5.5} lg={5.5}>
                            <TextField
                                disabled={disabledFields}
                                value={name}
                                error={!!statusName}
                                helperText={statusName}
                                onChange={(event) => {
                                    if (statusName) {
                                        setStatusName('');
                                    }
                                    setName(event.target.value);
                                }}
                                fullWidth
                                label="Nome completo"
                            />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={5.5}>
                            <TextField
                                fullWidth
                                type="email"
                                label="E-mail"
                                variant="outlined"
                                value={email}
                                error={!!statusEmail}
                                helperText={statusEmail}
                                onChange={(event) => {
                                    if (statusEmail) {
                                        setStatusEmail('');
                                    }
                                    setEmail(event.target.value);
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container gap={2} xs={12}>
                        <Grid xs={12} md={5.5} lg={5.5}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusCellphone}
                            >
                                <InputLabel htmlFor="WhatsApp">WhatsApp</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="WhatsApp"
                                    label="WhatsApp"
                                    inputComponent={CellphoneMaskCustom}
                                    value={cellphone}
                                    onChange={(event) => {
                                        if (statusCellphone) {
                                            setStatusCellphone('');
                                        }
                                        setCellphone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <WhatsApp color="success"/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{statusCellphone}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={5.5} lg={5.5}>
                            <DatePicker
                                label="Data de nascimento"
                                disabled
                                value={birthday ? moment(birthday) : null}
                                sx={{ width: '100%' }}
                                maxDate={dateLastEighteenYears}
                                minDate={moment(dateLastOneHundredAndTenYears)}
                                format='DD/MM/YYYY'
                                onChange={(date) => {
                                    if (statusBirthday) {
                                        setStatusBirthday('');
                                    }
                                    setBirthday(date);
                                }}
                                slotProps={{
                                    textField: {
                                        error: !!statusBirthday,
                                        helperText: statusBirthday
                                    }
                                }}
                            />

                        </Grid>
                    </Grid>
                    <FormControl error={!!statusGender}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={gender}
                            onChange={(event) => {
                                if (statusGender) {
                                    setStatusGender('');
                                }
                                setGender(event.target.value);
                            }}
                        >
                            <FormControlLabel value="F" control={<Radio size="medium" />} label="Feminino" />
                            <FormControlLabel value="M" control={<Radio size="medium" />} label="Masculino" />
                        </RadioGroup>
                        <FormHelperText>{statusGender}</FormHelperText>
                    </FormControl>
                    <Grid container gap={1} xs={12}>
                        <Grid xs={12} md={5.5} lg={2.75}>
                            <FormControl error={!!statusCPF} fullWidth variant="outlined">
                                <InputLabel htmlFor="clientCPF">CPF do cliente</InputLabel>
                                <OutlinedInput
                                    id="clientCPF"
                                    label="CPF do cliente"
                                    inputComponent={CPFMaskCustom}
                                    disabled
                                    value={CPF}
                                    onChange={(event) => {
                                        if (statusCPF) {
                                            setStatusCPF('');
                                        }
                                        setCPF(event.target.value);
                                    }}
                                    aria-describedby="component-error-cpf"
                                />
                                <FormHelperText id="component-error-cpf">
                                    {statusCPF}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={5.5} lg={2.75}>
                            <TextField
                                fullWidth
                                label="RG/CNH"
                                value={rg}
                                error={!!statusRg}
                                helperText={statusRg}
                                onChange={(event) => {
                                    if (statusRg) {
                                        setStatusRg('');
                                    }
                                    setRg(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={2.75}>
                            <TextField
                                fullWidth
                                label="Órgão expedidor"
                                value={issuingAgency}
                                error={!!statusIssuingAgency}
                                helperText={statusIssuingAgency}
                                onChange={(event) => {
                                    if (statusIssuingAgency) {
                                        setStatusIssuingAgency('');
                                    }
                                    setIssuingAgency(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={2.75}>
                            <DatePicker
                                label="Data de expedição"
                                value={issueDate ? moment(issueDate) : null}
                                sx={{ width: '100%' }}
                                maxDate={moment()}
                                minDate={moment(dateLastTenYears)}
                                format='DD/MM/YYYY'
                                onChange={(date) => {
                                    if (statusIssueDate) {
                                        setStatusIssueDate('');
                                    }
                                    setIssueDate(date);
                                }}
                                slotProps={{
                                    textField: {
                                        error: !!statusIssueDate,
                                        helperText: statusIssueDate
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container gap={1} xs={12}>
                        <Grid xs={12} md={5.5} lg={2.5}>
                            <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                <InputLabel htmlFor="cep">CEP</InputLabel>
                                <OutlinedInput
                                    id="cep"
                                    label="CEP"
                                    inputComponent={CEPMaskCustom}
                                    value={CEP}
                                    onChange={(event) => {
                                        if (statusCEP) {
                                            setStatusCEP('');
                                        }
                                        setCEP(event.target.value);
                                        if (event.target.value.replace(/[^\d]/g, "").length === 8) {
                                            getCep(event.target.value)
                                        }
                                    }}
                                    aria-describedby="component-error-cep"
                                />
                                <FormHelperText>{statusCEP}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={5.5} lg={1}>
                            <Autocomplete
                                fullWidth
                                // @ts-ignore
                                value={state}
                                options={estadosCidades}
                                noOptionsText="Estado não encontrado"
                                onChange={handleStates}
                                disableClearable
                                getOptionLabel={(option: { nome: string; sigla: string; cidades: string[]; }) => option.nome || ""}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Estado"
                                        variant="outlined"
                                        error={!!statusState}
                                        helperText={statusState}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={4.5}>
                            <Autocomplete
                                disabled={disabledAddressInputs}
                                fullWidth
                                options={cities}
                                noOptionsText="Cidade não encontrada"
                                value={city}
                                onChange={(event, newValue) => {
                                    if (statusCity) {
                                        setStatusCity('');
                                    }
                                    setCity(newValue);
                                }}
                                autoHighlight
                                disableClearable
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Cidade"
                                        variant="outlined"
                                        error={!!statusCity}
                                        helperText={statusCity}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={3}>
                            <TextField
                                value={district}
                                error={!!statusDistrict}
                                helperText={statusDistrict}
                                onChange={(event) => {
                                    if (statusDistrict) {
                                        setStatusDistrict('');
                                    }
                                    setDistrict(event.target.value);
                                }}
                                fullWidth
                                label="Bairro" />
                        </Grid>
                    </Grid>
                    <Grid container gap={1} xs={12}>
                        <Grid xs={12} md={5.5} lg={6}>
                            <TextField
                                value={address}
                                error={!!statusAddress}
                                helperText={statusAddress}
                                onChange={(event) => {
                                    if (statusAddress) {
                                        setStatusAddress('');
                                    }
                                    setAddress(event.target.value);
                                }}
                                fullWidth
                                label="Logradouro" />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={1.55}>
                            <TextField
                                type="number"
                                value={addressNumber}
                                error={!!statusAddressNumber}
                                helperText={statusAddressNumber}
                                onChange={(event) => {
                                    if (statusAddressNumber) {
                                        setStatusAddressNumber('');
                                    }
                                    setAddressNumber(event.target.value.replace(/\D+/g, ''));
                                }}
                                fullWidth
                                label="N°"
                            />
                        </Grid>
                        <Grid xs={12} md={5.5} lg={3.5}>
                            <TextField
                                helperText="Não obrigatório"
                                variant="outlined"
                                value={addressComplement}
                                onChange={(event) => setAddressComplement(event.target.value)}
                                fullWidth
                                label="Complemento" />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid xs={11.2} container justifyContent="flex-end">
                    <LoadingButton
                        loading={loading}
                        onClick={CreateProposal}
                        variant="contained"
                        disableElevation sx={{ p: 2, maxWidth: 240, width: '100%', justifySelf: "flex-end" }}>
                        Continuar
                    </LoadingButton>
                </Grid>
                {
                    !matchesMaxMobile &&
                    <Button
                        sx={{
                            p: 1,
                            width: 120,
                            textDecoration: 'underline',
                        }}
                        color="inherit"
                        onClick={() => navigate('/app/emprestar/tabela/', {
                            state: location.state
                        })}
                        variant="text">
                        Voltar
                    </Button>
                }

            </Grid>
            <AntiFraude
                onClickButton={() => {
                    setOpenAntiFraudeDialog(false)
                    navigate('/')
                }}
                openAntiFraudeDialog={openAntiFraudeDialog}
            />
        </Grid>
    )
}

export default PersonInfos;