/**
 * @param {JsonObject} theme 
 */
import { ThemeOptions } from '@mui/material/styles';

export default function themePalette(theme: any) {
    return {
        mode: "light",
        common: {
            black: theme.colors?.Paper
        },
        simples: {
            white: '#fff',
            black: '#000',
            bg: '#15133F',
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme?.colors?.secondaryLight,
            main: theme?.colors?.secondaryMain,
            dark: theme?.colors?.secondaryDark,
            200: theme?.colors?.secondary200,
            800: theme?.colors?.secondary800
        },
        third: {
            light: theme.colors?.thirdLight,
            main: theme.colors?.thirdMain,
            dark: theme.colors?.thirdDark,
            200: theme.colors?.third200,
            800: theme.colors?.third800
        },

        error: {
            light: theme.colors?.errorLight,
            errorLight40: theme.colors?.errorLight40,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            mainbg: theme.colors?.warningMainbg,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            mainbg: theme.colors?.successMainbg,
            dark: theme.colors?.successDark
        },
        // grey: {
        //     50: theme.colors?.grey50,
        //     100: theme.colors?.grey100,
        //     500: theme.darkTextSecondary,
        //     600: theme.heading,
        //     700: theme.darkTextPrimary,
        //     900: theme.textDark
        // },
        // dark: {
        //     light: theme.colors?.darkTextPrimary,
        //     main: theme.colors?.darkLevel1,
        //     dark: theme.colors?.darkLevel2,
        //     800: theme.colors?.darkBackground,
        //     900: theme.colors?.darkPaper
        // },
        // text: {
        //     primary: theme.darkTextPrimary,
        //     secondary: theme.darkTextSecondary,
        //     dark: theme.textDark,
        //     hint: theme.colors?.grey100
        // },
        // background: {
        //     paper: theme.darkPaper,
        //     default: theme.darkBackground,
        //     bg800: theme?.colors.darkBackground800
        // }
    } as ThemeOptions["palette"];
}