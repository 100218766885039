import React from 'react';
import MaskedInput from 'react-text-mask';

function CNPJMaskCustom(props: any) {
  const { inputRef, ...other } = props;
  return (
    //@ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref?.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

function CPFMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    //@ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

function KeyPixRandomMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref:any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        '-',
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
        /[a-z0-9]/,
      ]}
      guide={false}
    />
  );
}

function CellphoneMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

const CEPMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

function CEPMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={CEPMask}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AgencyNumberInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AgencyDigitInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AccountNumberInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function AccountDigitInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function POSMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        'N',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

const CNAEMask = [/\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/];

function CNAEInputMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    // @ts-ignore
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={CNAEMask}
      guide={false}
    />
  );
}

export {
  CNPJMaskCustom,
  CPFMaskCustom,
  CellphoneMaskCustom,
  CEPMaskCustom,
  KeyPixRandomMaskCustom,
  AgencyNumberInputMaskCustom,
  AgencyDigitInputMaskCustom,
  AccountNumberInputMaskCustom,
  AccountDigitInputMaskCustom,
  POSMaskCustom,
  CNAEInputMaskCustom,
  CNAEMask,
  CEPMask,
};
