import { createTheme, ThemeOptions } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale'
import componentStyleOverrides from './compStyleOverride';

import color from 'assets/scss/_themes.module.scss';
import { colorsTypes } from 'types';
import PoppinsFont from 'assets/fonts/Poppins-Regular.ttf';
import themePalette from './palette';
// @ts-ignore
export const colors: colorsTypes = color;

export const themeOption: any = {
    colors: colors,
    heading: colors.grey900,
    paper: colors.paper,
    backgroundDefault: colors.Background,
    darkTextPrimary: colors.grey700,
    darkTextSecondary: colors.grey500,
    textDark: colors.grey900,
    menuSelected: colors.secondary,
    menuSelectedBack: colors.secondaryLight,
    divider: colors.grey200
};


export const theme = () => {
    const themeOptions: ThemeOptions = {
        palette: themePalette(themeOption),
        typography: {
            fontFamily: 'Poppins, Arial',
        },
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            },

        },
        components: {
            MuiTableCell: {
                defaultProps: {
                    padding: 'none'
                }
            },
            MuiCssBaseline: {
                styleOverrides: `
                  @font-face {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsFont}) format('ttf');
                  }
                `,
            },
            MuiPopper: {
                defaultProps: {
                    sx: {
                        bgColor: colors.Background,
                        zIndex: 1011111
                    }
                }
            }
        }

    };
    const themes = createTheme(themeOptions, ptBR);
    //@ts-ignore
    themes.components = componentStyleOverrides(themeOption);
    return themes;
}