import storageLocales from "utils/storageLocales";
import { UserProps, UserProp } from "context/auth";

const USER_KEY = storageLocales.TCAuthOperator ?? '';
const USER_ID = storageLocales.TCtoken
const USER = storageLocales.TCUser

const UserStorage = {
  getUser() {
    if(localStorage.getItem(USER_KEY ?? '')){
      return JSON.parse(localStorage.getItem(USER_KEY ?? '') ?? '');
    }
    return null
  },

  saveUser(user: UserProps) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },

  getUserTc(): UserProp | null {
    if(localStorage.getItem(USER ?? '')){
      return JSON.parse(localStorage.getItem(USER ?? '') ?? '');
    }
    return null
  },
  
  saveUserTc(user: UserProp) {
    localStorage.setItem(USER, JSON.stringify(user));
  },
  saveToken(sub: string) {
    localStorage.setItem(USER_ID,sub);
  },
  removeUser() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(USER_ID);
  },

  saveUserId(sub: string) {
    localStorage.setItem(USER_ID,sub);
  },
  getUserId() {
    return localStorage.getItem(USER_ID);
  },

  saveAlreadySigned(status: string) {
    localStorage.setItem('alreadySigned', JSON.stringify(status));
  },
  
  getAlreadySigned() {
    return localStorage.getItem('alreadySigned');

  }
};

export default UserStorage;
