import { IconButton } from '@mui/material'
import style from './styles.module.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useState } from 'react'
import { saveAs } from 'file-saver'
import axios from 'axios'

const posts: {
    link: string,
    description: string
}[] = [
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+1.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+2.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+3.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+4.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+5.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+6.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+7.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+8.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+9.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+10.png"
        },
        {
            description: "” 🏥✨ Reduza Seu Plano de Saúde Atual em Até 20%! ✨🏥... ”",
            link: "https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/conteudo+vaptpost/AYUDE+11.png"
        },


    ]

export const VaptPostCarrousel = () => {
    const [scrollPosition, setScrollPosition] = useState(0)

    const scrollToElement = (index: number) => {
        setScrollPosition(index)
        const element = document.getElementById(`slide-${index}`)
        element?.scrollIntoView({
            behavior: 'smooth',
            block: "center",
            inline: "center"
        })
    }
    
    const download = (file: string) => {
        let fileName = "CONTEÚDO VAPTPOST + AYUDE - " + file.split("+vaptpost/")[1].replace("+", "-")
        axios({
            url: file,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            saveAs(file, fileName)
            console.error('Erro ao baixar a imagem:', error);
        });
    }

    return (
        <>
            <div className={style.container}>
                <div className={style.arrows}>
                    <IconButton onClick={() => {
                        if (scrollPosition > 0) {
                            scrollToElement(scrollPosition - 1)
                        } else {
                            scrollToElement(posts.length - 1)
                        }
                    }}>
                        <IoIosArrowBack color={'white'} fontSize={25} />
                    </IconButton>
                </div>
                <div id="slide" className={style.cardContainer}>
                    {posts.map((post, index) => {
                        return (
                            <div id={`slide-${index}`} className={style.card} style={{
                                ...(scrollPosition !== index && {
                                    transform: 'scale(.9)'
                                })
                            }}>
                                <img src={post.link} alt="ayude with ayude suggest" draggable={false} />
                                {/* <p>{post.description}</p> */}
                                <button onClick={() => download(post.link)}>Download</button>
                            </div>
                        )
                    })}
                </div>
                <div className={style.arrows}>
                    <IconButton onClick={() => {
                        if (scrollPosition < posts.length - 1) {
                            scrollToElement(scrollPosition + 1)
                        } else {
                            scrollToElement(0)
                        }
                    }}>
                        <IoIosArrowForward color={'white'} fontSize={25} />
                    </IconButton>
                </div>
            </div>
            <div className={style.dotsContainer}>
                {posts.map((_, index) => {
                    return (
                        <button className={index === scrollPosition ? style.dotSelected : style.dot} onClick={() => {
                            scrollToElement(index)
                        }}>
                            <a href={`#slide-${index}`}></a>
                        </button>
                    )
                })}
            </div>
        </>
    )

}