import { Grid, Typography, Skeleton } from "@mui/material";
import ButtonUI from "components/@ui/button";
import ModalUI from "components/@ui/modal"
import Toast from "components/@ui/toast";
import { useEffect, useState, useRef } from "react";
import { FiCheck } from "react-icons/fi";
import QRCode from "react-qr-code";
import { SalesTableProps } from "types/proposals";
import { saveAs } from 'file-saver'

import html2canvas from 'html2canvas';
import useMediasQuerys from "hooks/mediasQuery";

const CopyLink = ({
    link,
    getLink,
    newLink,
    saleTable,
    open,
    handleClose,
    showTable
}: {
    link: string;
    getLink: (table: string, copy: boolean) => void;
    newLink: string,
    saleTable: SalesTableProps | null,
    open: boolean,
    handleClose: () => void;
    showTable?: boolean
}) => {

    const { matchesMaxMobile } = useMediasQuerys()

    const [copy, setCopy] = useState<boolean | string>(false)

    const printRef = useRef(null)

    useEffect(() => {
        if (open && !newLink) {
            getLink(String(saleTable?.sale_table) ?? '', false)
            return
        }
    }, [open])

    return (
        <ModalUI
        sxPapper={{
            maxWidth: matchesMaxMobile ? '95vw' : '75vw'
        }}
            open={open}
            handleClose={() => {
                handleClose()
                setCopy(false)
            }}
        >
            <Grid container p={4} 
            minWidth={"45vw"}
            maxWidth={matchesMaxMobile ? '95vw' : '75vw'}
            flexDirection={"column"} alignItems="center" gap={4}>
                <Typography variant="body1">Você selecionou a TABELA {saleTable?.sale_table.split('on-')[1]}</Typography>
                <Typography variant="body1"><i>Obs.: Este Link/QR Code será gerado sem valor de empréstimo pré definido.</i></Typography>
                <Typography variant={showTable ? "h6" : "h4"} fontWeight={"bold"}>Copiar Link ou baixar QR Code</Typography>
                {
                    !newLink ?
                        <Skeleton
                            height={matchesMaxMobile ? 300 :500}
                            width={matchesMaxMobile ? 300 :500}
                        /> :
                        <div ref={printRef} style={{ padding: 10 }}>
                            <QRCode
                                size={280}
                                style={{ height: "auto", maxWidth: 400, width: "100%" }}
                                value={link}
                                viewBox={`0 0 256 256`} />
                        </div>
                }

                <Grid container gap={2} flexDirection={"column"} alignItems={"center"}>
                    <ButtonUI
                        onClick={() => {
                            if (!newLink) {
                                getLink(String(saleTable?.sale_table) ?? '', true)
                                return
                            }
                            navigator.clipboard.writeText(newLink)
                            setCopy(newLink)
                            if (!copy) {
                                Toast({
                                    type: 'success'
                                }, 'Link copiado!')
                            }
                        }}
                        sx={{ maxWidth: 400, width: "100%" }}
                        variant="outlined"
                        color={copy ? "success" : "primary"}>
                        {copy ? <FiCheck size={20} /> : 'Copiar Link'}
                    </ButtonUI>
                    <Typography variant="subtitle1">Ou</Typography>
                    <ButtonUI
                        onClick={async () => {
                            const element = printRef.current;
                            if (!printRef) {
                                return
                            }
                            if (newLink && element) {
                                const canvas = await html2canvas(element);

                                const data = canvas.toDataURL('image/jpg');
                                const currentlink = document.createElement('a');

                                if (typeof currentlink.download === 'string') {
                                    currentlink.href = data;
                                    currentlink.download = 'qr code link de auto empréstimo.jpg';
                                    document.body.appendChild(currentlink);
                                    currentlink.click();
                                    document.body.removeChild(currentlink);
                                } else {
                                    window.open(data);
                                }

                            }
                        }}
                        sx={{ maxWidth: 400, width: "100%" }}
                        variant="outlined"
                        color={"primary"}>
                        Baixar QR Code
                    </ButtonUI>

                </Grid>

            </Grid>
        </ModalUI>
    )
}

export default CopyLink;