import { Button, Grid, IconButton, TableCell, Tooltip, Typography } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import ProposalsTable from 'components/proposalsTable';
import { useEffect, useState } from 'react';
import RemoteServices from 'service';
import { ProposalRR } from 'types/proposal';
import ProposalDetails from 'components/proposalDetais';
import { useLocation } from 'react-router';
import LadingCards from 'components/lendingCards';
import Card from 'components/@ui/card';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { AiFillEye } from 'react-icons/ai';
import { dataBR, formatCurrency } from '@utils/format';
import { proposalCommission } from 'types/commission';
import useMediasQuerys from 'hooks/mediasQuery';
import { TablePagination } from '@material-ui/core';

export default function ProposalCommissions() {

    const {
        matchesMaxMobile
    } = useMediasQuerys()

    const [commissions, setcommissions] = useState<proposalCommission[] | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        setLoading(true)
        RemoteServices.commissions.ProposalsComnisssions()
            .then(({
                response
            }) => {
                if (response.length > 0) {
                    setcommissions(response)
                    setTotal(response.length)
                }
            }).finally(() => {
                setLoading(false)
            })
    }, [page, rowsPerPage])


    return (
        <Grid xs={12}>
            {
                matchesMaxMobile ?
                    !commissions ? 'Nenhum registro encontrado.' : commissions.map((row) => (
                        <Grid
                            p={1}
                            width="100%"
                            border={`1px solid ${colors.grey400}`}
                            my={2}
                            borderRadius={2}
                            bgcolor="white">
                            <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`}>
                                <Grid py={1} container>
                                    <Grid item xs={6} fontWeight="bold">
                                        Comissão:
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography color={colors.primary} fontWeight="bold">
                                            {formatCurrency(row.commission_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid pt={1} container fontSize={14} color={colors.grey600} direction="column">
                                <Grid pt={1} container fontSize={12} color={colors.grey600}>
                                    <Grid item xs={8} container flexDirection={"row"} gap={.5}>
                                        <Typography fontSize={12} fontWeight="bold" >
                                            Criada em:
                                        </Typography>
                                        {dataBR(row.created_at)}
                                    </Grid>
                                    <Grid item xs={4} container flexDirection={"row"} gap={.5}>
                                        <Typography fontSize={12} fontWeight="bold">
                                            ID:
                                        </Typography>
                                        {row.id}
                                    </Grid>
                                </Grid>
                                <Grid pt={1} container fontSize={12} color={colors.grey600}>
                                    <Grid item xs={6} container flexDirection={"row"} gap={.5}>
                                        <Typography fontSize={12} fontWeight="bold">
                                            Tabela:
                                        </Typography>
                                        {row.sale_table}
                                    </Grid>
                                    <Grid item xs={6} container flexDirection={"row"} gap={.5}>
                                        <Typography fontSize={12} fontWeight="bold" >
                                            Parcelas:
                                        </Typography>
                                        {row.parcels}x
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold">
                                        Valor da proposta:
                                    </Typography>
                                    {formatCurrency(row.net_amount ?? '0')}
                                </Grid>
                                <Grid item xs={6} container flexDirection={"row"} gap={.5}>
                                    <Typography fontSize={12} fontWeight="bold" >
                                        Valor do contrato:
                                    </Typography>
                                    {formatCurrency(row.gross_amount)}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                    :
                    <TableUI
                        page={page - 1}
                        onPageChange={(event, page) => setPage(page + 1)}
                        total={total}
                        loading={loading}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPage(Number(rows.target.value))
                        }}
                        HeaderComponent={
                            <CustomTableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="left">Criada em:</TableCell>
                                <TableCell align="left">Valor da proposta</TableCell>
                                <TableCell align="left">Valor do contrato</TableCell>
                                <TableCell align="left">Parcelas</TableCell>
                                <TableCell align="left">Tabela</TableCell>
                                <TableCell align="left">Comissão</TableCell>
                            </CustomTableRow>
                        }
                        RowComponent={
                            commissions && commissions.map((row) => (
                                <CustomTableRow type={'body'}>
                                    <TableCellCusttom title={row.id} />
                                    <TableCellCusttom title={dataBR(row.created_at)} />
                                    <TableCellCusttom title={formatCurrency(row.net_amount ?? '0')} />
                                    <TableCellCusttom title={formatCurrency(row.gross_amount)} />
                                    <TableCellCusttom title={`${row.parcels}x`} />
                                    <TableCellCusttom title={row.sale_table} />
                                    {
                                        row.commission_amount > 0 ? <TableCellCusttom title={formatCurrency(row.commission_amount)} /> :
                                            <TableCellCusttom title={formatCurrency(row.commission_amount)} negative tooltip={`CHARGEBACK`}/>
                                    }
                                </CustomTableRow>
                            ))
                        }
                    />
            }
            {matchesMaxMobile &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={total}
                    translate='yes'
                    labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                    labelRowsPerPage={`páginas:`}
                    rowsPerPage={rowsPerPage ?? 10}
                    page={page}
                    onPageChange={(event, page) => setPage(page + 1)}
                    onRowsPerPageChange={(rows) => {
                        setRowsPerPage(Number(rows.target.value))
                    }}
                />
            }
        </Grid >
    );
}