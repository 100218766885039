import Axios from "axios";
import storageLocales from "utils/storageLocales";
import URLSBases from "utils/urlBases";

export const PixAward = async (id: string | number, data: any) => {
    return Axios.post(URLSBases.apisTc + `/online/commissions/clerk/${id}`, data, {
        headers: {
            "Content-Type": "multipart/formdata",
            "Authorization": `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
        }
    })
}

export default PixAward;