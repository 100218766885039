
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { theme } from 'theme';
import Routes from 'routes';
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from 'context/auth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ProposalProvider } from 'context/proposal';
import { SnackProvider } from 'context/snack';
import { GeneralProvider } from 'context/general';  
import { ToastContainer } from "react-toastify"
import { ptBR } from '@mui/x-date-pickers/locales';
import moment from "moment"

moment.locale('pt-BR')

function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
        <ProposalProvider>
          <AuthProvider>
            <SnackProvider>
              <GeneralProvider>
                <ThemeProvider theme={theme()}>
                  <>
                    <CssBaseline enableColorScheme />
                    <Routes />
                    <ToastContainer />
                  </>
                </ThemeProvider>
              </GeneralProvider>
            </SnackProvider>
          </AuthProvider>
        </ProposalProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
