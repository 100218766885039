import IconEmprestimo from "assets/png/icons/EMPRESTAR.png"
import IconFiliais from "assets/png/icons/FILIAIS.png"
import IconSuporte from "assets/png/icons/SUPORTE.png"
import IconESTATÍSTICA from "assets/png/icons/ESTATÍSTICA.png"
import IconPUBLICIDADES from "assets/png/icons/PUBLICIDADES.png"
import IconCORRESPONDENTES from "assets/png/icons/CORRESPONDENTES.png"
import IconINTEGRAÇÃO from "assets/png/icons/INTEGRAÇÃO.png"
import IconPROPOSTAS from "assets/png/icons/PROPOSTAS.png"
import IconCOMISSÕES from "assets/png/icons/COMISSÕES.png"
import IconVIDEOS from "assets/png/icons/VIDEO.png"
import IconQRCODE from "assets/png/icons/QR CODE.png"
import IconGESTAO from "assets/png/icons/GESTÃO.png"
import IconVaptPost from "assets/vaptpost/Icon 3.png"


import IconAlert from "assets/svg/icon-alert.svg"

const Icons = {
    IconEmprestimo,
    IconFiliais,
    IconSuporte,
    IconAlert,
    IconESTATÍSTICA,
    IconPUBLICIDADES,
    IconCORRESPONDENTES,
    IconINTEGRAÇÃO,
    IconPROPOSTAS,
    IconCOMISSÕES,
    IconVIDEOS,
    IconQRCODE,
    IconGESTAO,
    IconVaptPost
}

export default Icons;