import { Remote } from "service/remote";

export const ListRequestCommission = async (data:{
    page?: number,
    limit?: number,
    status?: string
}) => {
    return await Remote({
        method: 'get',
        urn: `online/commissions/request`,
        params: data
    })

}

export default ListRequestCommission;