import React, { useState, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Tooltip,
    Typography,
    withStyles,
    Autocomplete
} from '@mui/material';

import contaBancaria from 'assets/json/bancos.json'
import { mask, unMask } from 'remask';
import ButtonUI from 'components/@ui/button';
import RemoteServices from 'service';
import Toast from 'components/@ui/toast';
import { schemaClerk } from '@utils/validator';
import { formatCurrency } from '@utils/format';
import { AgencyNumberInputMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom, KeyPixRandomMaskCustom } from 'components/@ui/masked';
import useMediasQuerys from 'hooks/mediasQuery';
import { IIValidatorDataBanks } from 'service/online/transfeera/validate';
import useAuth from 'context/auth';
import { awaitTime } from 'utils/awaitTime';

const masks = [
    '9999-9',
    '99999-9',
    '999999-9',
    '9999999-9',
    '99999999-9',
    '999999999-9',
    '9999999999-9',
    '99999999999-9',
];

function PixAccount({
    open,
    handleClose,
    account
}: {
    open: boolean;
    handleClose: (success?: boolean) => void;
    account: {
        cellphone: string,
        type_key_pix: string,
        key_pix: string,
    } | null
}) {
    const [loadingAccount, setLoadingAccount] = useState(false)
    const {
        matchesMaxMobile
    } = useMediasQuerys()
    const [typeKeyPix, setTypeKeyPix] = useState('');
    const [statusTypeKeyPix, setStatusTypeKeyPix] = useState('')
    const [keyPix, setKeyPix] = useState('');

    const [statusKeyPixCpf, setStatusKeyPixCpf] = useState('');
    const [keyPixCpf, setKeyPixCpf] = useState('');

    const [keyPixCnpj, setKeyPixCnpj] = useState('');
    const [statusKeyPixCnpj, setStatusKeyPixCnpj] = useState('');

    const [keyPixPhone, setKeyPixPhone] = useState('');
    const [statusKeyPixPhone, setStatusKeyPixPhone] = useState('');

    const [keyPixEmail, setKeyPixEmail] = useState('');
    const [statusKeyPixEmail, setStatusKeyPixEmail] = useState('');

    const [keyPixRandom, setKeyPixRandom] = useState('');
    const [statusKeyPixRandom, setStatusKeyPixRandom] = useState('');

    const [typePaymentCommission, setTypePaymentCommission] = useState('pix')
    const [clerkCellphone, setClerkCellphone] = useState('')
    const [statusClerkCellphone, setStatusClerkCellphone] = useState('')
    const {
        UserOperatorSales,
        User
    } = useAuth()

    useEffect(() => {
        if (account) {
            setClerkCellphone(account.cellphone)
            setKeyPix(account.key_pix)
            setTypeKeyPix(account.type_key_pix)
        }
    }, [account])

    async function validateAccount() {
        setLoadingAccount(true)
        schemaClerk
            .validate({
                typeKeyPix,
                keyPixCpf,
                keyPixCnpj,
                keyPixPhone,
                keyPixEmail,
                keyPixRandom,
                typePaymentCommission,
                clerkCellphone
            },
                { abortEarly: false }
            ).then(async () => {

                const data: any = {
                    type_key_pix: typeKeyPix,
                    key_pix: keyPix,
                    cellphone: clerkCellphone
                }
                let canContinue = false

                let body: IIValidatorDataBanks = {

                }
                let pixTypeTransfeera = {
                    'cellphone': 'TELEFONE',
                    'key_random': 'CHAVE_ALEATORIA',
                    'cpf': 'CPF',
                    'cnpj': 'CNPJ',
                    'email': 'EMAIL',
                }
                let cpf_cnpj = UserOperatorSales?.responsible_cpf as string
                if (User?.space === "pdv") {
                    await RemoteServices.auth.Me()
                    .then(({ response }) => {
                        const user = response?.data;
                        cpf_cnpj = user?.owner?.responsible_cpf
                    })
                }
                body.pix_key = typeKeyPix === "cellphone" || typeKeyPix === "cpf" || typeKeyPix === "cnpj" ? keyPix.replace(/\D/g, '') : keyPix
                //@ts-ignore
                body.pix_key_type = pixTypeTransfeera[typeKeyPix]
                body.pix_key_validation = {
                    cpf_cnpj: cpf_cnpj
                }
                body.pix_description = "Validação da chave pix para pagamento da GRATIFICAÇÃO POR ATENDIMENTO Ayude"


                await RemoteServices.transfeera.ValidateAccount(body as IIValidatorDataBanks).then(async ({ response }) => {
                    let id = response.id
                    let result = await consult(id)
                    canContinue = result.success
                    for (let _e of result.errors) {
                        Toast({
                            type: "error",
                            autoClose: 10000
                        }, _e.message)
                    }
                }).catch(() => Toast({
                    type: "error"
                }, "Erro ao salvar dados bancários. Por favor tente novamente"))

                if (canContinue) {
                    await RemoteServices.commissions.SaveAccountPIX(data).then(({ response, status }) => {
                        Toast({
                            type: "success"
                        }, "Conta PIX salva com sucesso!")
                        handleClose(true)
                    }).finally(() => {
                        setLoadingAccount(false)
                    })
                } else {
                    setLoadingAccount(false)
                }
            }).catch((err: any) => {
                setLoadingAccount(false)
                handleValidationError(err);
                Toast({
                    type: "warning"
                }, "Verifique os dados informados e tente novamente!")
            })
    }

    const consult = async (id: string): Promise<{
        success: boolean,
        errors: Array<{
            errorCode: string,
            field: string,
            message: string,
        }>
    }> => {
        if (!id) {
            return {
                success: false,
                errors: [
                    {
                        message: "Ocorreu um erro ao validar conta bancária. Por favor, tente novamente.",
                        errorCode: "any",
                        field: "any"
                    }
                ]
            }
        }
        let valid
        let err
        let end = false
        while (!end) {
            await awaitTime(1)
            await RemoteServices.transfeera.ConsultAccount(id).then(({ response }) => {
                if (response.micro_deposit_status === "VALIDADO" && response.errors.length === 0 && response.valid === true) {
                    valid = true
                    end = true
                    err = []
                } else {
                    if (response.errors.length === 0) {
                        end = false
                    } else {
                        end = true
                        valid = false
                        err = response.errors
                    }
                }
            }).catch(err => console.log(err))
        }
        return {
            //@ts-ignore
            success: valid,
            //@ts-ignore
            errors: err
        }
    }


    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'keyPixCpf':
                    setStatusKeyPixCpf(element.message);
                    break;
                case 'keyPixCnpj':
                    setStatusKeyPixCnpj(element.message);
                    break;
                case 'keyPixPhone':
                    setStatusKeyPixPhone(element.message);
                    break;
                case 'keyPixEmail':
                    setStatusKeyPixEmail(element.message);
                    break;
                case 'keyPixRandom':
                    setStatusKeyPixRandom(element.message);
                    break;
                case 'typeKeyPix':
                    setStatusTypeKeyPix(element.message);
                    break;
                case 'clerkCellphone':
                    setStatusClerkCellphone(element.message);
                    break;
                default:
                    break;
            }
        });
    };



    return (
        <Dialog open={open} maxWidth={"sm"} fullWidth fullScreen={matchesMaxMobile}>
            <DialogTitle style={{ textAlign: 'center' }}>Conta GRATIFICAÇÃO POR ATENDIMENTO</DialogTitle>
            <DialogContent>
                <Grid item xs={12} md={12} style={{ margin: '10px 0', textAlign: 'center' }}>
                    <Typography variant='subtitle1'>Validação da sua chave PIX para pagamentos da sua GRATIFICAÇÃO POR ATENDIMENTO.<br />Após a validação, todas as solicitações serão pagas através da chave cadastrada.</Typography>
                </Grid>

                <Grid item xs={12} md={10} style={{ margin: '10px 0' }}>
                    <FormLabel>Seu WhatsApp</FormLabel>
                </Grid>
                <FormControl
                    fullWidth
                    error={!!statusClerkCellphone}
                    variant="outlined"
                >
                    <OutlinedInput
                        id="operatorCellphone"
                        inputComponent={CellphoneMaskCustom}
                        value={clerkCellphone}
                        onChange={(event) => {
                            if (statusClerkCellphone) {
                                setStatusClerkCellphone('');
                            }
                            setClerkCellphone(event.target.value);
                        }}
                        aria-describedby="component-error-cellphone"
                    />
                    <FormHelperText id="component-error-cellphone">
                        {statusClerkCellphone}
                    </FormHelperText>
                </FormControl>
                <Grid item xs={12} md={10} style={{ margin: '10px 0' }}>
                    <FormLabel>Sua Chave PIX</FormLabel>
                </Grid>
                <Grid xs={12} md={12} sm={12} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            fullWidth
                            id="standard-select-currency"
                            select
                            label="Tipo de chave pix"
                            value={typeKeyPix}
                            helperText={statusTypeKeyPix}
                            error={!!statusTypeKeyPix}
                            onChange={(event) => {
                                if (statusTypeKeyPix !== "") {
                                    setStatusTypeKeyPix("")
                                }
                                setTypeKeyPix(event.target.value);
                            }}
                        >
                            <MenuItem key="true" value="cpf">
                                CPF
                            </MenuItem>
                            <MenuItem key="true" value="cnpj">
                                CNPJ
                            </MenuItem>
                            <MenuItem key="true" value="cellphone">
                                Número de telefone
                            </MenuItem>
                            <MenuItem key="true" value="email">
                                E-mail
                            </MenuItem>
                            <MenuItem key="true" value="key_random">
                                Chave aleatória
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {typeKeyPix === 'cpf' ? (
                    <Grid xs={12} md={12} sm={12}>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl
                                fullWidth
                                error={!!statusKeyPixCpf}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="cpf">Chave pix - CPF</InputLabel>
                                <OutlinedInput
                                    name="keyPixCpf"
                                    id="keyPixCpf"
                                    label="Chave pix - CPF"
                                    inputComponent={CPFMaskCustom}
                                    value={keyPix}
                                    onChange={(event) => {
                                        if (statusKeyPixCpf) {
                                            setStatusKeyPixCpf('');
                                        }
                                        setKeyPixCpf(event.target.value);
                                        setKeyPix(event.target.value);
                                    }}
                                />
                                <FormHelperText>{statusKeyPixCpf}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : null}

                {typeKeyPix === 'cnpj' ? (
                    <Grid xs={12} md={12} sm={12}>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl
                                error={!!statusKeyPixCnpj}
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel htmlFor="keyPixCnpj">
                                    Chave pix - CNPJ
                                </InputLabel>
                                <OutlinedInput
                                    name="keyPixCnpj"
                                    id="keyPixCnpj"
                                    label="Chave pix - CNPJ"
                                    inputComponent={CNPJMaskCustom}
                                    value={keyPix}
                                    onChange={(event) => {
                                        if (statusKeyPixCnpj) {
                                            setStatusKeyPixCnpj('');
                                        }
                                        setKeyPixCnpj(event.target.value);
                                        setKeyPix(event.target.value);
                                    }}
                                />
                                <FormHelperText>{statusKeyPixCnpj}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : null}

                {typeKeyPix === 'cellphone' ? (
                    <Grid xs={12} md={12} sm={12}>
                        <Grid item xs={12} md={4} sm={6}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusKeyPixPhone}
                            >
                                <InputLabel htmlFor="cellphone">
                                    Chave Pix telefone
                                </InputLabel>
                                <OutlinedInput
                                    name="keyPixPhone"
                                    id="keyPixPhone"
                                    label="Chave Pix"
                                    inputComponent={CellphoneMaskCustom}
                                    value={keyPix}
                                    onChange={(event) => {
                                        if (statusKeyPixPhone) {
                                            setStatusKeyPixPhone('');
                                        }
                                        setKeyPix(event.target.value);
                                        setKeyPixPhone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                />
                                <FormHelperText>{statusKeyPixPhone}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : null}

                {typeKeyPix === 'email' ? (
                    <Grid xs={12} md={12} sm={12}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="keyPixEmail"
                                fullWidth
                                type="keyPixEmail"
                                label="Chave pix - E-mail"
                                variant="outlined"
                                value={keyPix}
                                error={!!statusKeyPixEmail}
                                helperText={statusKeyPixEmail}
                                onChange={(event) => {
                                    if (statusKeyPixEmail) {
                                        setStatusKeyPixEmail('');
                                    }
                                    setKeyPixEmail(event.target.value);
                                    setKeyPix(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : null}

                {typeKeyPix === 'key_random' &&
                    <Grid xs={12} md={12} sm={12}>
                        <Grid item xs={12} md={8}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusKeyPixRandom}
                            >
                                <InputLabel htmlFor="keyPixRandom">
                                    Chave pix - Aleatória
                                </InputLabel>
                                <OutlinedInput
                                    name="keyPixRandom"
                                    id="keyPixRandom"
                                    label="Chave pix - Aleatória"
                                    inputComponent={KeyPixRandomMaskCustom}
                                    value={keyPix}
                                    onChange={(event) => {
                                        if (statusKeyPixRandom) {
                                            setStatusKeyPixRandom('');
                                        }
                                        setKeyPixRandom(event.target.value);
                                        setKeyPix(event.target.value);
                                    }}
                                />
                                <FormHelperText>{statusKeyPixRandom}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                gap: 2,
                flexDirection: matchesMaxMobile ? "column" : "row"
            }}>
                <ButtonUI
                    variant='outlined'
                    color='primary'
                    onClick={() => handleClose()}
                >Fechar</ButtonUI>
                <ButtonUI
                    loading={loadingAccount}
                    variant='contained'
                    color='primary'
                    onClick={validateAccount}>Validar</ButtonUI>
            </DialogActions>
        </Dialog>
    )
}

export default PixAccount;