import { Autocomplete, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, OutlinedInput, Radio, TextField, Typography } from "@mui/material"
import ButtonUI from "components/@ui/button"
import Toast from "components/@ui/toast"

import Breadcrumb from "components/breadcrumbs"
import useAuth, { SalesCounterProps } from "context/auth"
import { useEffect, useState } from "react"
import { RiAccountCircleFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import RemoteServices from "service"
import { colors } from "theme"

import estadosCidades from 'assets/json/estados_cidades.json'
import { CEPMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from "components/@ui/masked"
import { schemaFilialUpdate } from "@utils/validator"

import useMediasQuerys from "hooks/mediasQuery"
import URLSBases from "utils/urlBases"
import { awaitTime } from "utils/awaitTime"

const UpdateDataFilial
    = () => {

        const navigate = useNavigate()

        const {
            matchesMaxMobile
        } = useMediasQuerys()

        const {
            UserOperatorSales
        } = useAuth()

        const [id, setId] = useState<number | undefined>()

        const [loading, setLoading] = useState(false);
        const [loadingPage, setLoadingPage] = useState(true);

        const [tradeName, setTradeName] = useState('');
        const [statusTradeName, setStatusTradeName] = useState('');

        const [CPF, setCPF] = useState('');
        const [statusCPF, setStatusCPF] = useState('');

        const [responsibleName, setResponsibleName] = useState('');
        const [statusResponsibleName, setStatusResponsibleName] = useState('');

        const [cellphone, setCellphone] = useState('');
        const [statusCellphone, setStatusCellphone] = useState('');

        const [email, setEmail] = useState('');
        const [statusEmail, setStatusEmail] = useState('');

        const [address, setAddress] = useState('');
        const [statusAddress, setStatusAddress] = useState('');

        const [addressNumber, setAddressNumber] = useState('');
        const [statusAddressNumber, setStatusAddressNumber] = useState('');

        const [district, setDistrict] = useState('');
        const [statusDistrict, setStatusDistrict] = useState('');

        const [addressComplement, setAddressComplement] = useState('');
        const [statusAddressComplement, setStatusAddressComplement] = useState('');

        const [CEP, setCEP] = useState('');
        const [statusCEP, setStatusCEP] = useState('');

        const [state, setState] = useState({ nome: '', sigla: '' });
        const [statusState, setStatusState] = useState('');

        const [city, setCity] = useState('');
        const [statusCity, setStatusCity] = useState('');

        const [cities, setCities] = useState<string[]>([]);

        const [disabledByCEP, setDisabledByCEP] = useState(false)



        useEffect(() => {
            document.title = 'Ayude | Atualizar Cadastro';
        }, []);

        useEffect(() => {
            RemoteServices.auth.MeSale()
                .then(({ data }) => {
                    console.log(data?.data.sales_counter)
                    if (data?.data.sales_counter) handleSetUserData(data?.data.sales_counter)
                })
                .catch((error) => {
                    Toast({
                        type: 'warning',
                    }, error.message ?? 'Não foi possível buscar dados do usuario!');
                });
        }, []);


        function handleSetUserData(user: SalesCounterProps) {
            setId(user.id)
            setTradeName(user.trade_name);
            setResponsibleName(user.responsible_name);
            setCPF(user.responsible_cpf);
            setCellphone(user.cellphone);
            setEmail(user.email);
            setAddress(user.address);
            setAddressNumber(user.address_number);
            setDistrict(user.district);
            if (user?.address_complement) setAddressComplement(user.address_complement);
            setCEP(user.postal_code.replace(/[^\d]/g, "").replace(/^([\d]{5})\-*([\d]{3})/, "$1-$2"));

            estadosCidades?.forEach((element) => {
                if (element.sigla === user.state) {
                    setState({
                        nome: element.nome,
                        sigla: element.sigla,
                    });
                    setCities(element?.cidades ?? []);
                    setCity(user.city);
                }
            });

            setLoadingPage(false);
        }

        function getCep(cep: string) {
            const formatCep = cep.replace(/[^\d]/g, "")
            RemoteServices.external.GetCep({
                formatCep
            }).then(async ({ response }) => {
                if (!response?.erro) {
                    const state = estadosCidades.find((item) => item.sigla === response?.uf)

                    if (state && response?.localidade) {
                        setState({ nome: state?.nome ?? '', sigla: state?.sigla ?? '' })
                        setCity(response?.localidade)
                        setDisabledByCEP(true)
                    }
                    if (statusState) setStatusState('')
                    if (statusCity) setStatusCity('')
                    if (statusDistrict) setStatusDistrict('')
                    if (statusAddress) setStatusAddress('')
                } else {
                    setCity('')
                    setState({ nome: '', sigla: '' })
                    setDisabledByCEP(false)
                }
            })
        }


        const handleStates = (event: any, newValue: any) => {
            if (statusState) {
                setStatusState('');
            }
            if (city) {
                setCity('');
            }
            estadosCidades.forEach((element: any) => {
                if (element.sigla === newValue.sigla) {
                    setCities(newValue.cidades);
                }
            });

            setState({
                nome: newValue.nome,
                sigla: newValue.sigla,
            });
        };

        const updateFilial = () => {
            schemaFilialUpdate
                .validate(
                    {
                        tradeName,
                        responsibleName,
                        CPF,
                        email,
                        cellphone,
                        address,
                        addressNumber,
                        addressComplement: addressComplement || null,
                        district,
                        CEP,
                        city,
                        state,
                    },
                    { abortEarly: false },
                )
                .then(() => {
                    (async () => {
                        setLoading(true)
                        const data: any = {
                            trade_name: tradeName,
                            responsible_name: responsibleName,
                            responsible_cpf: CPF,
                            email,
                            cellphone,
                            address,
                            address_number: addressNumber,
                            city,
                            district,
                            state: state.sigla,
                            postal_code: CEP,
                        };


                        if (addressComplement) {
                            data.address_complement = addressComplement;
                        }

                        RemoteServices.filiais.SalesCounterUpdate({
                            id: id?.toString() as string,
                            data
                        })
                            .then(async ({ response, status }) => {

                                if (status > 200) {
                                    Toast({
                                        type: 'warning',
                                    }, response?.message ?? response?.error ?? 'Não foi possivel atualzar dados!');
                                } else {
                                    Toast({
                                        type: 'success',
                                    }, response?.message ?? 'Dados atualizados com sucesso');
                                    if (response?.data?.sales_counter?.blocked === true) {
                                        await awaitTime(2)
                                        window.location.replace(URLSBases.LOGIN)
                                    } else {
                                        navigate('/')
                                    }
                                }
                            })
                            .catch((error) => {
                                Toast({
                                    type: 'warning',
                                }, error?.message ?? 'Não foi possivel atualzar dados!');
                            }).finally(() => setLoading(false))
                    })();
                })
                .catch((err: any) => {
                    handleValidationError(err);
                    Toast(
                        {
                            type: 'warning',
                        },
                        'Não foi possível atualizar os dados do proprietário. Verifique os dados informados e tente novamente.'
                    );
                });
        };


        const handleValidationError = (err: any) => {
            console.log(err)
            err?.inner?.forEach((element: any) => {
                switch (element?.path) {
                    case 'tradeName':
                        setStatusTradeName(element.message);
                        break;
                    case 'CPF':
                        setStatusCPF(element.message);
                        break;
                    case 'cellphone':
                        setStatusCellphone(element.message);
                        break;
                    case 'email':
                        setStatusEmail(element.message);
                        break;
                    case 'responsibleName':
                        setStatusResponsibleName(element.message);
                        break;
                    case 'address':
                        setStatusAddress(element.message);
                        break;
                    case 'addressNumber':
                        setStatusAddressNumber(element.message);
                        break;
                    case 'addressComplement':
                        setStatusAddressComplement(element.message);
                        break;
                    case 'district':
                        setStatusDistrict(element.message);
                        break;
                    case 'CEP':
                        setStatusCEP(element.message);
                        break;
                    case 'city':
                        setStatusCity(element.message);
                        break;
                    case 'state.sigla':
                        setStatusState(element.message);
                        break;
                    default:
                        break;
                }
            });
        };


        return (
            <Grid p={2}>
                <Breadcrumb />
                <Grid p={2} mt={2}>
                    <Grid
                        container
                        gap={2}
                        py={2}
                    >
                        <RiAccountCircleFill size={30} />
                        <Typography
                            textTransform={"uppercase"}
                            color={colors.black}
                            variant="h5"
                            fontWeight={"bold"}>Meus dados</Typography>

                    </Grid>
                    <Grid mt={4}>
                        <Typography fontWeight="bold">Matriz: {UserOperatorSales?.owner_trade_name}</Typography>
                        <Typography fontWeight="bold" mt={2}>Informações do responsável</Typography>
                        <Grid component="form" my={3} container gap={2} xs={12}>
                            <Grid container gap={2} xs={12}>
                                <Grid xs={12} md={5.5} lg={5.5}>
                                    <TextField
                                        name="responsible_name"
                                        fullWidth
                                        label={"Nome do responsável"}
                                        variant="outlined"
                                        value={responsibleName}
                                        error={!!statusResponsibleName}
                                        helperText={statusResponsibleName}
                                        disabled
                                        onChange={(event) => {
                                            if (statusResponsibleName) {
                                                setStatusResponsibleName('');
                                            }
                                            setResponsibleName(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={5.5} lg={5.5}>
                                    <FormControl fullWidth error={!!statusCPF} variant="outlined">
                                        <InputLabel htmlFor="cpf">
                                            CPF do responsável
                                        </InputLabel>
                                        <OutlinedInput
                                            name="cpf"
                                            disabled
                                            id="cpf"
                                            label="CPF do responsável"
                                            inputComponent={CPFMaskCustom}
                                            value={CPF}
                                            onChange={(event) => {
                                                if (statusCPF) {
                                                    setStatusCPF('');
                                                }
                                                setCPF(event.target.value);
                                            }}
                                        />
                                        <FormHelperText>{statusCPF}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    error={!!statusCellphone}
                                >
                                    <InputLabel htmlFor="cellphone">Whatsapp</InputLabel>
                                    <OutlinedInput
                                        name="whatsapp"
                                        id="cellphone"
                                        label="Whatsapp"
                                        inputComponent={CellphoneMaskCustom}
                                        value={cellphone}
                                        onChange={(event) => {
                                            if (statusCellphone) {
                                                setStatusCellphone('');
                                            }
                                            setCellphone(event.target.value);
                                        }}
                                        aria-describedby="component-error-cellphone"
                                    />
                                    <FormHelperText>{statusCellphone}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <TextField
                                    fullWidth
                                    label="Nome fantasia"
                                    value={tradeName}
                                    error={!!statusTradeName}
                                    helperText={statusTradeName}
                                    onChange={(event) => {
                                        if (statusTradeName) {
                                            setStatusTradeName('');
                                        }
                                        setTradeName(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid mt={4}>
                        <Typography fontWeight="bold">Informações para acesso da conta</Typography>
                        <Grid component="form" my={3} container gap={2} xs={12}>
                            <Grid container gap={2} xs={12}>
                                <Grid xs={12} md={5.5} lg={5.5}>
                                    <TextField
                                        name="email"
                                        fullWidth
                                        type="email"
                                        label="E-mail"
                                        variant="outlined"
                                        value={email}
                                        error={!!statusEmail}
                                        helperText={statusEmail}
                                        onChange={(event) => {
                                            if (statusEmail) {
                                                setStatusEmail('');
                                            }
                                            setEmail(event.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid mt={4}>
                        <Typography fontWeight="bold">Endereço</Typography>
                        <Grid component="form" my={3} container gap={2} xs={12}>
                            <Grid container gap={2} xs={12}>
                                <Grid xs={12} md={3.5} lg={3.5}>
                                    <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                        <InputLabel htmlFor="cep">CEP</InputLabel>
                                        <OutlinedInput
                                            name="cep"
                                            id="cep"
                                            label="CEP"
                                            inputComponent={CEPMaskCustom}
                                            value={CEP}
                                            onChange={(event) => {
                                                if (statusCEP) {
                                                    setStatusCEP('');
                                                }
                                                setCEP(event.target.value);
                                                if (event.target.value.replace(/[^\d]/g, "").length === 8) {
                                                    getCep(event.target.value)
                                                }
                                            }}
                                            aria-describedby="component-error-cep"
                                        />
                                        <FormHelperText>{statusCEP}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={2} lg={2}>
                                    <Autocomplete
                                        fullWidth
                                        disabled={disabledByCEP}
                                        options={estadosCidades}
                                        noOptionsText="Estado não encontrado"
                                        value={state}
                                        onChange={handleStates}
                                        autoHighlight
                                        disableClearable
                                        getOptionLabel={(option) => option.nome}
                                        renderOption={(i: any, option: { sigla: string, nome: string }) => (
                                            <Grid
                                                color="inherit"
                                                component={Button}
                                                justifyContent={"flex-start"}
                                                variant="text"
                                                p={2}
                                                container
                                                onClick={() => handleStates('', option)}>
                                                <span>{option?.sigla ?? ''} - </span>
                                                {option?.nome ?? ''}
                                            </Grid>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                name="state"
                                                {...params}
                                                label="Estado"
                                                variant="outlined"
                                                error={!!statusState}
                                                helperText={statusState}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={3} lg={3}>
                                    <Autocomplete
                                        fullWidth
                                        disabled={disabledByCEP}
                                        options={cities}
                                        noOptionsText="Cidade não encontrada"
                                        value={city}
                                        onChange={(event, newValue) => {
                                            if (statusCity) {
                                                setStatusCity('');
                                            }
                                            setCity(newValue);
                                        }}
                                        autoHighlight
                                        disableClearable
                                        getOptionLabel={(option) => option}
                                        renderOption={(i: any, option: string) => (
                                            <Grid
                                                color="inherit"
                                                component={Button}
                                                justifyContent={"flex-start"}
                                                variant="text"
                                                p={2}
                                                container
                                                onClick={() => {
                                                    if (statusCity) {
                                                        setStatusCity('');
                                                    }
                                                    setCity(option);
                                                }}>
                                                <span>{option} </span>
                                            </Grid>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cidade"
                                                variant="outlined"
                                                error={!!statusCity}
                                                helperText={statusCity}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={2.3} lg={2.3}>
                                    <TextField
                                        name="district"
                                        fullWidth
                                        label="Bairro"
                                        variant="outlined"
                                        value={district}
                                        error={!!statusDistrict}
                                        helperText={statusDistrict}
                                        onChange={(event) => {
                                            if (statusDistrict) {
                                                setStatusDistrict('');
                                            }
                                            setDistrict(event.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid component="form" my={3} container gap={2} xs={12}>
                            <Grid container gap={2} xs={12}>
                                <Grid xs={12} md={5.9} lg={5.9}>
                                    <TextField
                                        name="address"
                                        fullWidth
                                        label="Logradouro"
                                        variant="outlined"
                                        value={address}
                                        error={!!statusAddress}
                                        helperText={statusAddress}
                                        onChange={(event) => {
                                            if (statusAddress) {
                                                setStatusAddress('');
                                            }
                                            setAddress(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={2} lg={2}>
                                    <TextField
                                        name="address_number"
                                        fullWidth
                                        label="N°"
                                        variant="outlined"
                                        value={addressNumber}
                                        error={!!statusAddressNumber}
                                        helperText={statusAddressNumber}
                                        onChange={(event) => {
                                            if (statusAddressNumber) {
                                                setStatusAddressNumber('');
                                            }
                                            setAddressNumber(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={3} lg={3}>
                                    <TextField
                                        name="address_complement"
                                        fullWidth
                                        label="Complemento"
                                        variant="outlined"
                                        value={addressComplement}
                                        error={!!statusAddressComplement}
                                        helperText={statusAddressComplement || 'Não obrigatório'}
                                        onChange={(event) => {
                                            if (statusAddressComplement) {
                                                setStatusAddressComplement('');
                                            }
                                            setAddressComplement(event.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={matchesMaxMobile ? 'center' : "flex-end"} gap={2}>
                            <ButtonUI
                                onClick={() => navigate('/')}
                                variant="contained">
                                Voltar
                            </ButtonUI>
                            <ButtonUI
                                loading={loading}
                                onClick={updateFilial}
                                variant="contained">
                                Atualizar cadastro
                            </ButtonUI>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

export default UpdateDataFilial
    ;