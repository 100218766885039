import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import {
    FiAlertTriangle
  } from "react-icons/fi"

const ValueInvalid = ({
    openAlertValueInvalid,
    onClose
}: {
    openAlertValueInvalid: boolean;
    onClose: () => void;
}) => {
    return (
        <Dialog open={openAlertValueInvalid} maxWidth="xs">
            <DialogTitle>
                <Grid style={{
                    display: 'flex',
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px'

                }}>
                    <FiAlertTriangle color="orange" />
                    <Typography style={{
                        fontWeight: 'bold'
                    }} variant='body1'>
                        SALDO INFORMADO INCORRETAMENTE
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1' style={{ textAlign: 'center', textTransform: 'uppercase', marginBottom: '20px' }}>
                    Verifique o saldo que está disponível para compras no cartão de crédito do cliente e digite corretamente <strong>(Inclusive os centavos)</strong>
                </Typography>
            </DialogContent>
            <DialogActions
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Button
                    onClick={onClose}
                    color="primary"
                    disableElevation
                    variant="contained"
                    sx={{ p: 2, minWidth: 120 }}
                >Entendi, digitarei o saldo correto</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ValueInvalid;