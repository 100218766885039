
export const Utils = {
    formatCellphone(cellphone: string) {
        cellphone.replace(/\D+/g, '')
        return cellphone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
    },

    onlyDigits(value: string) {
        return value.replace(/\D+/g, '')
    }
}