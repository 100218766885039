import { Remote } from "service/remote";

export const OwnerClient = async ({
    cpf
}: {
    cpf: string
}) => {
    return await Remote({
        method: "get",
        urn: `online/proposals/owner/client/${cpf}`
    })

}

export default OwnerClient;