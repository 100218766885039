import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import Typography from '@mui/material/Typography';
import Modal from 'components/@ui/modal';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { CPFMaskCustom } from 'components/@ui/masked';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RemoteServices from 'service';
import LoadingButton from '@mui/lab/LoadingButton';
import { transformValue } from '@utils/transformValue';
import { dataBR, formatCurrency, formatDate } from "@utils/format"
import { SalesTableProps } from 'types/proposals';
import useProposal from 'context/proposal';
import { useNavigate, useLocation } from 'react-router';
import { colors } from 'theme';
import AntiFraude from 'components/antiFraude';

import {
  FiAlertTriangle
} from "react-icons/fi"

import Taost from "components/@ui/toast"
import NoLimiteAmount from './noLimitAmount';
import UserStorage from 'service/storage/user';

const beinerror = false

export default function StartLend({
  open,
  handleClose,
  saleTable,
  typeSimulator,
  setSaleTable
}: {
  open: boolean,
  handleClose: () => void;
  saleTable: SalesTableProps | null;
  typeSimulator: string;
  setSaleTable(a: SalesTableProps): void
}) {

  const navigate = useNavigate()

  const { cardLimit, parcels, valueProposal } = useProposal();
  const { state } = useLocation()

  const [CPF, setCPF] = useState('');
  const [statusCPF, setStatusCPF] = useState('');
  const [birthday, setBirthday] = useState<Moment | null>(null);
  const [statusBirthday, setStatusBirthday] = useState('');
  const [loadownerClient, setLoadownerClient] = useState<boolean>(false)
  const [errorLimit, setErrorLimit] = useState<boolean>(false)
  const [openModalValurForDay, setOpenModalValurForDay] = useState(false)
  const [nextDate, setNextDate] = useState('')
  const [openAntiFraudeDialog, setOpenAntiFraudeDialog] = useState(false)

  const [notLimitavailableAmount, setNotLimitavailableAmount] = useState(false)
  const [amountAlreadyRequested, setAmountAlreadyRequested] = useState(0)
  const [availableAmount, setAvailableAmount] = useState(0)


  const [openAlertDeath, setOpenAlertDeath] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)

  const [openDialogProposalPending, setOpenDialogProposalPending] = useState(false)
  const [proposalPendingDetails, setProposalPendingDetails] = useState<{
    id: number,
    link: string,
    user: string,
    point: string
  }>()


  const dateLastEighteenYears = moment()
  const dateLastOneHundredAndTenYears = moment()
    .subtract(110, 'years')
    .format(moment.HTML5_FMT.DATE);

  const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + days)
    return date.toISOString()
  }

  const ownerClient = async () => {
    let canContinueProposal = true
    setLoadownerClient(true)
    RemoteServices.proposals.OwnerClient({ cpf: CPF })
      .then(async ({ response }) => {
        const param = {
          alreadyExist: response?.result ? true : false,
          client: {},
          parcels: 0,
          gross_amount: 0,
          credit_card_limit: 0,
          link_id: '',
          net_amount: 0,
          sintegraError: false
        }

        if (response?.result) {
          param.client = {
            ...response.result,
            birthday: new Date(new Date(formatDate(new Date(response.result?.birthday).toISOString())).setUTCHours(12)),
            id_document_issue_date: new Date(new Date(formatDate(new Date(response.result?.id_document_issue_date).toISOString())).setUTCHours(12))
          }
          await canContinue().then((r) => {
            const next = r?.response?.result
            setAmountAlreadyRequested(next?.amountAlreadyRequested)
            setAvailableAmount(next?.availableAmount)
            if (next?.availableAmount < (saleTable?.net_amount ?? 0)) {
              canContinueProposal = false
              setNotLimitavailableAmount(true)
              return
            }
            canContinueProposal = true
            if (next?.continue === false) {
              canContinueProposal = false
              if (next?.limit) {
                setErrorLimit(true)
              }
              else if (next?.amount) {
                setOpenModalValurForDay(true)
                return
              }
              else if (next?.block) {
                setOpenAntiFraudeDialog(true)
              }
              else if (next?.pendingProposal) {
                setProposalPendingDetails(next?.proposalDetails)
                setOpenDialogProposalPending(true)
              }
              return
            }
          })
        } else {
          let sintegraError = false
          let name: string = ''
          let gender: string = ''
          let error = false
          await RemoteServices.proposals.ConsultLocalize({
            cpf: CPF.replace(/[^\d]/g, ""),
            birthday: dataBR(new Date(birthday as unknown as string))
          })
            .then(({ response }) => {
              const data = response
              if (!data.result) {
                Taost({
                  type: 'error',
                }, data?.message?.resposta ?? "Não foi possível realizar a busca. Tente novamente mais tarde.")
                canContinueProposal = false
                error = true
              }

              if (data?.result?.error) {
                error = true
                if (data?.result?.point === "death") {
                  setOpenAlertDeath(true)
                } else if (data?.result?.point === "birthday") {
                  Taost({
                    type: 'error',
                  }, data?.result?.message ?? 'Error desconhecido')
                }
              } else {
                name = data?.result?.name
                gender = data?.result?.gender
              }
              const infos = {
                cpf: CPF,
                name: name,
                gender: gender,
                birthday: new Date(new Date(formatDate(new Date(birthday as unknown as string).toISOString())).setUTCHours(12)),
              }
              param.client = infos
              param.sintegraError = sintegraError
            })
            .catch((e) => {
              Taost({
                type: 'error',
              }, "Não foi possível realizar a busca. Tente novamente mais tarde.")
              canContinueProposal = false
              error = true
            })
          if (error) return
        }
        await RemoteServices.proposals.GenerateLink({
          saleTable: saleTable?.sale_table ?? ''
        })
          .then((r) => {
            const data = r.response
            param.link_id = data.id
            param.credit_card_limit = cardLimit ? transformValue(cardLimit) : state?.credit_card_limit
            if (typeSimulator === "perTable") {
              param.net_amount = saleTable?.net_amount ?? state?.net_amount
            } else {
              param.net_amount = transformValue(valueProposal ?? state?.net_amount)
            }
            param.parcels = Number(parcels ?? state.parcels)
            param.gross_amount = saleTable?.gross_amount ?? state.gross_amount
          })
        if (canContinueProposal) {
          navigate('/app/emprestar/tabela/proposta', { state: { ...param, saleTable: saleTable } })
        }
      })
      .finally(() => setLoadownerClient(false))
  }


  const canContinue = () => {
    const ct = RemoteServices.proposals.ConsultCpf({
      CPF: `${CPF.replace(/[^\d]/g, "")}?verifyValue=true&amount=${typeSimulator === "perValue" ? transformValue(String(saleTable?.net_amount ?? 0)) : (saleTable?.net_amount ?? 0)}`
    })
    return ct
  }

  if (beinerror) {
    return (
      <Modal open={open} handleClose={handleClose}>
        <Grid maxWidth={675} width="100%" container gap={2} justifyContent={"flex-end"} mt={5}>
          <Typography variant="subtitle2" fontWeight="bolder" textAlign={"center"} width="100%" color={colors.black} textTransform={"uppercase"}>
            Estamos passando por manutenção técnica
          </Typography>
          <Typography variant="subtitle2" fontWeight="bolder" textAlign={"center"} width="100%" color={colors.black} textTransform={"uppercase"}>
            Previsão para estabilidade em até 24 horas.
          </Typography>
        </Grid>
      </Modal>
    )

  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <Grid maxWidth={675} width="100%" container gap={5} justifyContent={"flex-end"} mt={5}>
        <Typography variant="h5" fontWeight="bolder" textAlign={"start"} width="100%" color={colors.black} textTransform={"uppercase"}>
          informações do cliente
        </Typography>
        <Grid container gap={2}>
          <FormControl
            fullWidth
            sx={{ maxWidth: 400 }}
            error={!!statusCPF}
            variant="outlined"
          >
            <InputLabel htmlFor="cpf">CPF do cliente</InputLabel>
            <OutlinedInput
              name="cpf"
              id="cpf"
              label="CPF do cliente"
              inputComponent={CPFMaskCustom}
              value={CPF}
              onChange={(e) => {
                if (statusCPF) {
                  setStatusCPF('');
                }
                setCPF(e.target.value)
              }}
            />
            <FormHelperText>{statusCPF}</FormHelperText>
          </FormControl>
          <DatePicker
            label="Data de nascimento"
            value={birthday ? moment(birthday) : null}
            sx={{ maxWidth: 250, width: '100%' }}
            maxDate={dateLastEighteenYears}

            format='DD/MM/YYYY'
            minDate={moment(dateLastOneHundredAndTenYears)}
            onChange={(date) => {
              if (statusBirthday) {
                setStatusBirthday('');
              }
              setBirthday(date);
            }}
          />
        </Grid>
        <LoadingButton
          sx={{ p: 2, maxWidth: 220, width: '100%' }}
          loading={loadownerClient}
          onClick={ownerClient}
          variant="contained"
          disabled={!CPF || !birthday}
          disableElevation>
          continuar
        </LoadingButton>
        <AntiFraude
          onClickButton={() => {
            setOpenAntiFraudeDialog(false)
            navigate('/')
          }}
          openAntiFraudeDialog={openAntiFraudeDialog}
        />
        <Dialog open={errorLimit} maxWidth={"sm"}>
          <DialogContent>
            {
              <Typography style={{ fontSize: '20px' }}>
                Este cliente já solicitou o valor máximo de empréstimo no mês, solicite que retorne no próximo mês.
              </Typography>
            }
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={() => {
                setOpenAntiFraudeDialog(false)
                setOpenDialog(false)
                setErrorLimit(false)
                navigate('/')
              }}
              sx={{ p: 2, minWidth: 120 }}
            >Entendi</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openModalValurForDay} maxWidth="sm">
          <DialogContent>
            <Typography align='center' gutterBottom variant='h6' style={{ marginBottom: '50px' }}>
              Esse cliente já recebeu o valor de {formatCurrency(transformValue(valueProposal ?? '0'))} hoje.
            </Typography>
            <Typography align='center' variant='body1' gutterBottom>
              Sugerimos que solicite um valor diferente para essa proposta.
            </Typography>
            <Typography align='center' variant='body1' gutterBottom>
              Motivo: Por segurança não é permitido solicitar valores iguais no mesmo dia.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalValurForDay(false)
                setOpenDialog(false)
                navigate('/')
              }}
              color="primary"
              variant="contained"
              disableElevation
              sx={{ p: 2, minWidth: 120 }}
            >Entendi</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialogProposalPending} maxWidth="sm">
          <DialogContent>
            <Typography align='center' gutterBottom variant='h6' style={{ marginBottom: '50px' }}>
              Este cliente já tem uma proposta aguardando ser finalizada.
            </Typography>

            <Typography align='center' variant='body1' gutterBottom>
              Conclua a proposta antes de criar uma nova.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (proposalPendingDetails?.user === UserStorage.getUserTc()?.sub) {
                  navigate('/app/propostas', {
                    state: { proposalId: proposalPendingDetails?.id },
                  })
                } else {
                  setOpenModalValurForDay(false)
                  setOpenDialog(false)
                  navigate('/')
                }
              }}
              color="primary"
              variant="contained"
              disableElevation
              sx={{ p: 2, minWidth: 120 }}
            >{proposalPendingDetails?.user === UserStorage.getUserTc()?.sub ? "Concluir proposta" : "Entendi"}</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAlertDeath} maxWidth="xs">
          <DialogTitle>
            <Grid style={{
              display: 'flex',
              flexDirection: "row",
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px'

            }}>
              <FiAlertTriangle color="orange" />
              <Typography style={{
                fontWeight: 'bold'
              }} variant='body1'>
                CPF INFORMADO COM ÓBITO REGISTRADO
              </Typography>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Typography variant='body1' style={{ textAlign: 'center', textTransform: 'uppercase', marginBottom: '20px' }}>
              Lamento informar que não é possível continuar devido ao óbito registrado no CPF informado.
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                setOpenAlertDeath(false)
                navigate('/')
              }}
              color="primary"
              disableElevation
              variant="contained"
              sx={{ p: 2, minWidth: 120 }}
            >Entendi</Button>
          </DialogActions>
        </Dialog>
        <NoLimiteAmount
          open={notLimitavailableAmount}
          saleTable={saleTable}
          setSaleTable={setSaleTable}
          amountAlreadyRequested={amountAlreadyRequested}
          availableAmount={availableAmount}
          handleClose={(sale) => {
            if (sale) {
              setSaleTable(sale)
              handleClose()
            }
            setNotLimitavailableAmount(false)
          }}
        />
      </Grid>
    </Modal>
  );
}