import { Grid } from "@mui/material"
import { formatCurrency, getLegibleDate } from '@utils/format';
import { transformValue } from '@utils/transformValue';
import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { colors } from 'theme';


export function ProposalsChart({ data }: { data: any }) {

    const [table, setTable] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    const toPercent = (decimal: number, fixed = 0) => `${formatCurrency(decimal)}`;


    useEffect(() => {
        const _dates: string[] = [];
        const _data: any[] = [];
        let _total = 0;
        for (let _d of data) {
            if (!_dates.includes(_d?.date)) {
                _dates.push(_d.date);
                const reduceredValue = data
                    .filter((a: any) => a.date === _d.date)
                    .map((a: any) => a.amount)
                    .reduce((a: any, b: any) => a + b);
                _data.push({
                    date: new Date(_d.date),
                    amount: reduceredValue,
                });
                _total += reduceredValue;
                console.log(total)
            }
        }
        if (_total > 0) {
            setTotal(_total)
            console.log(_total)
        }
        _data.sort((a, b) => a.date > b.date ? 1 : -1);
        setTable(_data);
        // console.log(total)
    }, [data])


    

const CustomTooltip = ({ active, payload, label }: { active: boolean | undefined, payload: any, label: string }) => {
    if (active && payload && payload.length) {
        return (
            <Grid 
            bgcolor={colors.primary + 20}
            p={2}
            border={'1px solid ' + colors.primary}
            onClick={() => console.log(label)} className="custom-tooltip">
                <p className="intro">{formatCurrency(payload[0].value)}</p>
                <p className="intro">{((payload[0].value / total) * 100).toFixed(2)}%</p>
            </Grid>
        );
    }

    return null;
};

    return (
        <ResponsiveContainer width={'100%'} height={400}>
            <AreaChart
                width={500}
                height={400}
                data={table}
                margin={{
                    top: 10,
                    right: 10,
                    left: 50,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    tickFormatter={(date: any) => getLegibleDate(date, true)}
                    dataKey="date" />
                <YAxis tickFormatter={toPercent} />
                {/* <YAxis
                    dataKey="amount"
                /> */}
                <Tooltip
                    label={'teste'}
                    content={({ active, payload, label,  }: any) =>
                        <CustomTooltip
                            active={active}
                            payload={payload}
                            label={label}
                        />
                    }
                />
                <Area
                    type="monotone" dataKey="amount" stroke={colors.primary} fill={colors.primary + 25} />
            </AreaChart>
        </ResponsiveContainer>
    )
}
