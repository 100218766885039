import Icons from "./icons";

import Lend from "pages/app/lend";
import SelectedTable from "components/selectedTable";
import { Outlet } from "react-router";
import PersonInfos from "components/startLend/personInformation";
import Proposals from "pages/app/proposals";
import Commissions from "pages/app/commissions";
import BranchCorrenpondent from "pages/app/BranchCorrespondent";
import NewFilial from "pages/app/BranchCorrespondent/newFilial";
import Statistics from "pages/app/statistics";
import Indicators from "pages/app/indicators";
import Indicator from "pages/app/indicators/indicato";
import Advertising from "pages/app/advertising";
import Support from "pages/app/support";
import Tutorial from "pages/app/Tutorial"
import Historic from "pages/app/commissions/historic";
import HistoricProposal from "pages/app/commissions/historic/proposals";
import LinkQrCode from "pages/app/qrcode"
import FilialAutoRegister from "pages/app/BranchCorrespondent/autoRegister";
import Gestor from "pages/app/gestor";
import NewGestor from "pages/app/gestor/new";
import DetailsGestor from "pages/app/gestor/details";
import EditFilial from "pages/app/BranchCorrespondent/edit";
import Remarketing from "pages/app/remarketing";
import { MdLeaderboard } from "react-icons/md";
import UserStorage from "service/storage/user";

const disabledCanIndicate = !UserStorage.getUser()?.canIndicate

const Navigations = {
    pdv: [
        {
            id: 0,
            title: 'Emprestar Balcão',
            hover: 'Simule e efetive a proposta de crédito para seu cliente em tempo real.',
            icon: () => <img src={Icons.IconEmprestimo} alt="icon" id="icon-nav" />,
            path: 'emprestar',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            component: <Outlet />,
            children: [
                {
                    path: '/app/emprestar/',
                    element: <Lend />
                },
                {
                    path: '/app/emprestar/tabela/',
                    element: <SelectedTable />,
                },
                {
                    path: '/app/emprestar/tabela/proposta',
                    element: <PersonInfos />,
                },
                {
                    path: '/app/emprestar/tutorial',
                    element: <Tutorial />
                },
            ]
        },
        {
            id: 1,
            title: 'propostas',
            hover: 'Acompanhe o status de todas as suas propostas.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconPROPOSTAS} alt="icon" id="icon-nav" />,
            path: 'propostas',
            component: <Proposals />
        },
        {
            id: 2,
            title: 'Painel de Dados',
            hover: 'Monitore os dados do seu negócio com precisão, acessando gráficos por período, tabelas de valores, geolocalização, e análises segmentadas por gênero e idade.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconESTATÍSTICA} alt="icon" id="icon-nav" />,
            path: 'painel',
            component: <Statistics />
        },
        {
            id: 3,
            title: 'Novo Usuário Filial ',
            hover: 'Expanda sua operação, crie acessos de novas filiais, personalize tabelas e comissões.',
            permissions: ['pdv', 'gestor'],
            icon: () => <img src={Icons.IconFiliais} alt="icon" id="icon-nav" />,
            path: 'filiais',
            component: <Outlet />,
            children: [
                {
                    path: '/app/filiais',
                    element: <BranchCorrenpondent />
                },
                {
                    path: '/app/filiais/nova-filial',
                    element: <NewFilial />,
                },
                {
                    path: '/app/filiais/editar-filial',
                    element: <EditFilial />,
                },
                {
                    path: '/app/filiais/auto-cadastro/:token',
                    element: <FilialAutoRegister />,
                }
            ]
        },
        {
            id: 4,
            title: 'Novo Usuário Matriz',
            hover: 'Crie acessos para sua matriz e monitore as permissões.',
            permissions: ['pdv'],
            icon: () => <img src={Icons.IconGESTAO} alt="icon" id="icon-nav" />,
            path: 'usuarios-matriz',
            component: <Outlet />,
            children: [
                {
                    path: '/app/usuarios-matriz',
                    element: <Gestor />
                },
                {
                    path: '/app/usuarios-matriz/novo-usuario-matriz',
                    element: <NewGestor />,
                },
                {
                    path: '/app/usuarios-matriz/detalhes/:gestorId',
                    element: <DetailsGestor />,
                },
            ]
        },
        {
            id: 5,
            title: 'Indique e Ganhe',
            hover: 'Cadastre novos Parceiros Matriz na Ayude através do seu link de indicação e receba comissões a cada proposta concluída pelos parceiros indicados.',
            permissions: ['pdv', 'gestor'],
            icon: () => <img src={Icons.IconCORRESPONDENTES} alt="icon" id="icon-nav" />,
            path: 'correspondentes',
            component: <Outlet />,
            children: [
                {
                    path: '/app/correspondentes',
                    element: <Indicators />,

                },
                {
                    path: '/app/correspondentes/:id',
                    element: <Indicator />
                }
            ],
            disabled: disabledCanIndicate
        },
        {
            id: 6,
            title: 'Conteúdos p/ Redes Sociais',
            hover: 'Turbine seu marketing, faça postagens para suas Redes Sociais e artes para impressões de propaganda usando nosso parceiro VaptPost.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconVaptPost} alt="icon" id="icon-nav" />,
            path: 'vaptpost',
            component: <Advertising />
        },
        {
            id: 7,
            title: 'Links/QR Code AutoContratação',
            hover: 'Crie links e QR codes com a taxa que desejar, permitindo que seus clientes contratem empréstimos sozinhos de onde estiverem.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconQRCODE} alt="icon" id="icon-nav" />,
            path: 'autoemprestimo',
            component: <LinkQrCode />
        },
        {
            id: 8,
            title: 'leads',
            hover: 'Base de leads gerados por ações com Links/QR Codes que não avançaram para propostas.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <MdLeaderboard color="gray" size={24} />,
            path: 'leads',
            component: <Remarketing />
        },
        {
            id: 9,
            title: 'comissões',
            hover: 'Acompanhe e solicite o saque de suas comissões.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconCOMISSÕES} alt="icon" id="icon-nav" />,
            path: 'comissoes',
            component: <Outlet />,
            children: [
                {
                    path: '/app/comissoes',
                    element: <Commissions />
                },
                {
                    path: '/app/comissoes/historico',
                    element: <Historic />
                },
                {
                    path: '/app/comissoes/historico/:proposal',
                    element: <HistoricProposal />
                },
            ]
        },
        {
            id: 10,
            title: 'PERGUNTAS FREQUENTES',
            hover: 'Veja todas as dúvidas frequentes, caso necessário entre em contato com o suporte.',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconSuporte} alt="icon" id="icon-nav" />,
            path: 'perguntas-frequentes',
            component: <Support />
        }
    ]
}

export default Navigations;