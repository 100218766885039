import { Remote } from "service/remote";
import { ProposalProps } from "types/proposal";

export const SolicitarPIX = async (id: string) => {
    return await Remote({
        method: "post",
        urn: "online/commissions/clerk/" + id
    })

}

export default SolicitarPIX;