import React, { createContext, useContext, useEffect, useState } from "react";

interface ContextProvider {
    cardLimit: string; 
    setCardLimit(a:string): void;
    parcels: null | number;
    setParcels(a:null | number): void;
    setValuesList(a: string[]): void;
    valuesList: string[];
    valueProposal: string;
    setValueProposal(a:string): void;
    setPreApproved(a:number): void;
    preApproved: number;
    saleTables: any[], 
    setSaleTables(a:any): void;
    setShowForm(a:boolean): void;
    showForm: boolean;
    typeSimulator: 'perTable' | 'perValue'
    setTypeSimulador(a:'perTable' | 'perValue'): void;
    ClearProposal: () => void;
}

export const ProposalManager = createContext<ContextProvider>({} as ContextProvider);
const useProposal = () => useContext(ProposalManager);

export const ProposalProvider = ({ children }: { children: JSX.Element }) => {

    const [cardLimit, setCardLimit] = useState<string>('');
    const [parcels, setParcels] = useState<null | number>(null);
    const [valuesList, setValuesList] = useState<string[]>([]);
    const [valueProposal, setValueProposal] = useState<string>('0');
    const [preApproved, setPreApproved] = useState<number>(0);
    const [saleTables, setSaleTables] = useState<any[]>([]);
    const [showForm, setShowForm] = useState(false)
    const [typeSimulator, setTypeSimulador] = useState<'perTable' | 'perValue'>('perTable')

    
    const ClearProposal = () => {
        setCardLimit('')
        setParcels(null)
        setValuesList([])
        setValueProposal('0')
        setPreApproved(0)
        setSaleTables([])
        setShowForm(false)
        setTypeSimulador('perTable')
    }
 

    return (
        <ProposalManager.Provider value={{
            cardLimit, 
            setCardLimit,
            parcels, 
            setParcels,
            valuesList, 
            setValuesList,
            valueProposal, 
            setValueProposal,
            preApproved, 
            setPreApproved,
            saleTables, 
            setSaleTables,
            showForm, 
            setShowForm,
            typeSimulator, 
            setTypeSimulador,
            ClearProposal
        }}>
            {children}
        </ProposalManager.Provider>
    )
}


export default useProposal;