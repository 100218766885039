import { Remote } from "service/remote";

const RedefinePassword = async (data: any) => {
    return await Remote({
        method: "put",
        urn: `/redefine-password`,
        api: 'auth',
        data
    })

}

export default RedefinePassword;