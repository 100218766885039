import { Remote } from "service/remote";

export const FilialAutoRegister = async ({
        data
}:
    {
        data: any
    }) => {
    return await Remote({
        method: "post",
        urn: "/online/branch",
        data
    })

}

export default FilialAutoRegister;