const lendingquota = [
    {
        value: 4,
        title: '4X'
    },
    {
        value: 6,
        title: '6X'
    },
    {
        value: 8,
        title: '8X'
    },
    {
        value: 10,
        title: '10X'
    },
    {
        value: 12,
        title: '12X'
    }
]

export default lendingquota;