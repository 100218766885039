
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import TooltipUI from 'components/@ui/Tooltip';
import { useState, useEffect, useRef } from 'react';
import { BiSkipNext } from 'react-icons/bi';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { RiPlayList2Fill } from 'react-icons/ri';
import YouTube, { YouTubeProps } from 'react-youtube';

interface Video {
    videoId: string;
    title: string;
    thumbnail: string;
}

const getPlaylistVideos = async (playlistId: string, apiKey: string): Promise<Video[]> => {
    const apiUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&key=${apiKey}`;
    const response = await fetch(apiUrl);
    const data = await response.json();

    return data.items.filter((item: any) => item?.snippet?.title !== "Private video").map((item: any) => ({
        videoId: item.snippet.resourceId.videoId,
        title: item.snippet.title,
        thumbnail: item.snippet.thumbnails.default?.url,
    }))
};

interface YouTubePlayerProps {
    playlistId: string;
    apiKey: string;
}

export const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ playlistId, apiKey }) => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);
    const [currentVideo, setCurrentVideo] = useState<Video | null>(null);

    const [playlistEl, setPlaylistEl] = useState<null | HTMLElement>(null);
    const openPlaylist = Boolean(playlistEl)

    const intervalRef = useRef<number | null>(null);


    useEffect(() => {
        const loadPlaylistVideos = async () => {
            const playlistVideos = await getPlaylistVideos(playlistId, apiKey);
            setVideos(playlistVideos);
            if (playlistVideos.length > 0) {
                setCurrentVideoId(playlistVideos[0].videoId);
            }
        };

        loadPlaylistVideos();
    }, [playlistId, apiKey]);


    useEffect(() => {
        if (currentVideoId) {
            setCurrentVideo(videos.find((item) => item.videoId === currentVideoId) as Video)
        } else {
            setCurrentVideo(null)
        }
    }, [currentVideoId])

    useEffect(() => {
        clearPlayer()
    }, []);

    const playNext = () => {
        clearPlayer()
        const currentIndex = videos.findIndex((vid) => vid.videoId === currentVideoId)
        const nextIndex = currentIndex + 1
        if (nextIndex < videos.length) {
            setCurrentVideoId(videos[nextIndex].videoId)
        }
    }

    const clearPlayer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

    const playBefore = () => {
        clearPlayer()
        const currentIndex = videos.findIndex((vid) => vid.videoId === currentVideoId)
        const nextIndex = currentIndex - 1
        if (nextIndex >= 0) {
            setCurrentVideoId(videos[nextIndex].videoId)
        }
    }

    const playerOptions: YouTubeProps['opts'] = {
        width: "100%",
        playerVars: {
            controls: 1,
            autoplay: 0
        },

    };

    return (
        <div>
            <Typography
                width={'100%'}
                fontWeight={500}
                textAlign={"left"}
                mb={2}
            >
                Assista às nossas aulas e aprenda tudo sobre a plataforma
            </Typography>
            <div>
                {currentVideoId && <YouTube videoId={currentVideoId} opts={playerOptions} onEnd={playNext} />}
            </div>

            <Grid
                container
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"nowrap"}
                alignItems={"center"}
                gap={1}
            >

                <Grid
                    item
                    display="flex"
                    justifyContent="flex-start"
                >
                    <TooltipUI title="Ver playlist">
                        <IconButton
                            id="button-playlist"
                            aria-controls={openPlaylist ? 'button-playlist' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openPlaylist ? 'true' : undefined}
                            onClick={(e) => setPlaylistEl(e.currentTarget)}
                        >
                            <RiPlayList2Fill color={"#606060"}/>
                        </IconButton>
                    </TooltipUI>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={playlistEl}
                        open={openPlaylist}
                        onClose={() => setPlaylistEl(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {videos.map((vid) => {
                            return (
                                <MenuItem
                                    onClick={() => {
                                        setCurrentVideoId(vid.videoId)
                                        setPlaylistEl(null)
                                    }}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        ... (currentVideoId === vid.videoId && {
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                        })
                                    }}
                                >
                                    <Grid
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                    >
                                        <img
                                            src={vid?.thumbnail}
                                            alt="thumb"
                                            style={{
                                                width: '70px',
                                                height: '60px'
                                            }}
                                        />
                                        <Typography>{vid?.title}</Typography>
                                    </Grid>
                                    <MdNavigateNext />
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Grid>

                <Grid
                    item
                    display="flex"
                    justifyContent="center"
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={1}
                >
                    <TooltipUI title='Vídeo anterior'>
                        <IconButton
                            onClick={playBefore}
                        >
                            <MdNavigateBefore color={"#606060"} size={30}/>
                        </IconButton>
                    </TooltipUI>

                    <img
                        src={currentVideo?.thumbnail}
                        alt="thumb"
                        style={{
                            width: '40px',
                            height: '30px'
                        }}
                    />

                    <TooltipUI title={currentVideo?.title ?? ""}>
                        <Typography
                            style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 2,
                                lineHeight: '1.2em',
                                maxHeight: '2.4em',
                            }}
                        >
                            {currentVideo?.title}
                        </Typography>
                    </TooltipUI>

                    <TooltipUI title='Próximo vídeo'>
                        <IconButton
                            onClick={playNext}
                        >
                            <MdNavigateNext color={"#606060"} size={30}/>
                        </IconButton>
                    </TooltipUI>
                </Grid>

            </Grid>
        </div >
    );
};