import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { CPFMaskCustom, CellphoneMaskCustom } from "components/@ui/masked";
import Breadcrumb from "components/breadcrumbs"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import RemoteServices from "service";
import { colors } from "theme";
import { schemaGestorCreate, schemaSalesCounterCreate } from "@utils/validator";
import Toast from "components/@ui/toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { WhatsApp } from "@mui/icons-material";
import { Utils } from "utils/formatter";
import Checkbox from '@mui/material/Checkbox';
import { awaitTime } from "utils/awaitTime";
import { IListBranchByGestor, INewGestor, TGestorPermission } from "./interfaces";
import TooltipUI from "components/@ui/Tooltip";

const NewGestor = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const [responsibleName, setResponsibleName] = useState('');
    const [statusResponsibleName, setStatusResponsibleName] = useState('');

    const [CPF, setCPF] = useState('');
    const [statusCPF, setStatusCPF] = useState('');

    const [cellphone, setCellphone] = useState('');
    const [statusCellphone, setStatusCellphone] = useState('');

    const [email, setEmail] = useState('');
    const [statusEmail, setStatusEmail] = useState('');

    const [password, setPassword] = useState('');
    const [statusPassword, setStatusPassword] = useState('');

    const [showForm, setShowForm] = useState(false)
    const [loadingCPF, setLoadingCPF] = useState(false)

    const [permissions, setPermissions] = useState<{
        readCommission: boolean,
        requestCommission: boolean,
        updateOwnerData: boolean,
        createSalesCounter: boolean,
        updateSalesCounterCommission: boolean,
        updateSalesCounterTables: boolean,
        readCommisionOnGenerateProposal: boolean,
        generateProposal: boolean,
        readLeads: boolean
    }>({
        readCommission: false,
        requestCommission: false,
        updateOwnerData: false,
        createSalesCounter: false,
        updateSalesCounterCommission: false,
        updateSalesCounterTables: false,
        generateProposal: false,
        readCommisionOnGenerateProposal: false,
        readLeads: false
    })

    const [listBranch, setlistBranch] = useState<Array<IListBranchByGestor>>([])

    const [permittedBranches, setPermittedBranches] = useState<Array<number> | null>([])

    const [orderBy, setOrderBy] = useState<"created_at" | "state" | "id">("id")
    const [ordering, setOrdering] = useState<"desc" | "asc">("asc")

    const [loadingOrder, setLoadingOrder] = useState(true)

    const [stateByUser, setStateByUser] = useState<{
        id: number,
        state: string
    }[]>([])

    const [creationByUser, setCriationByUser] = useState<{
        id: number,
        created_at: string
    }[]>([])

    const [searchName, setSearchName] = useState('')
    const [branchFilter, setBranchFilter] = useState<"all" | "with_gestor" | "without_gestor">('all')

    const permissionsList = [
        {
            name: "readCommission",
            label: "Ver comissão"
        },
        {
            name: "requestCommission",
            label: "Permissão financeira"
        },
        {
            name: "updateOwnerData",
            label: "Atualizar Meus dados"
        },
        {
            name: "createSalesCounter",
            label: "Criar Filiais"
        },
        {
            name: "updateSalesCounterCommission",
            label: "Editar comissão das Filiais"
        },
        {
            name: "updateSalesCounterTables",
            label: "Editar tabelas das Filiais"
        },
        {
            name: "generateProposal",
            label: "Gerar proposta"
        },
        {
            name: "readCommisionOnGenerateProposal",
            label: "Ver comissão ao gerar proposta"
        },
        {
            name: "readLeads",
            label: "Visualizar Leads"
        },
    ]

    const months: any = {
        0: "Janeiro",
        1: "Fevereiro",
        2: "Março",
        3: "Abril",
        4: "Maio",
        5: "Junho",
        6: "Julho",
        7: "Agosto",
        8: "Setembro",
        9: "Outubro",
        10: "Novembro",
        11: "Dezembro"
    }

    useEffect(() => {
        setNewOrder()
    }, [searchName])

    useEffect(() => {
        getSalesCounters()
    }, [])

    const submitForm = async () => {
        setLoading(true);
        schemaGestorCreate
            .validate(
                {
                    name: responsibleName,
                    cpf: CPF,
                    phone: cellphone,
                    email,
                    password,
                },
                { abortEarly: false },
            )
            .then(() => {
                (async () => {
                    const data: INewGestor = {
                        name: responsibleName,
                        cpf: Utils.onlyDigits(CPF),
                        phone: Utils.onlyDigits(cellphone),
                        email,
                        password,
                        permissions: {
                            targets: permittedBranches,
                            permissions: (() => {
                                let arr: string[] = []
                                for (let _permission of Object.keys(permissions)) {
                                    if (permissions[_permission as TGestorPermission]) {
                                        arr.push(_permission)
                                    }
                                }
                                return arr
                            })() as TGestorPermission[]
                        }
                    };

                    RemoteServices.gestor.newGestor(data)
                        .then(({ response, status }) => {
                            if (status > 201) {
                                Toast({
                                    type: 'error',
                                }, response?.message ?? response?.errors[0] ?? 'Não foi possível criar novo usuário Matriz. Verifique os dados e tente novamente!');
                                return
                            }
                            Toast({
                                type: 'success',
                            }, response?.message ?? 'Gestor criado com successo!');
                            navigate('/app/usuarios-matriz')
                        })
                        .catch((error) => {
                            Toast({
                                type: 'warning',
                            }, error?.errors[0] ?? error?.message ?? 'Não foi possível criar novo usuário Matriz. Verifique os dados e tente novamente!');
                        })
                        .finally(() => setLoading(false))
                })();
            })
            .catch((error) => {
                setLoading(false);
                handleValidationError(error);
                Toast(
                    {
                        type: 'warning',
                    }, 'Não foi possível criar novo usuário Matriz. Verifique os dados e tente novamente.',
                );
            });
    };


    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'responsibleName':
                    setStatusResponsibleName(element.message);
                    break;
                case 'CPF':
                    setStatusCPF(element.message);
                    break;
                case 'cellphone':
                    setStatusCellphone(element.message);
                    break;
                case 'email':
                    setStatusEmail(element.message);
                    break;
                case 'password':
                    setStatusPassword(element.message);
                    break;

                default:
                    break;
            }
        });
    };

    const consultCPF = async (cpf: string) => {
        setLoadingCPF(true)
        await RemoteServices.proposals.ConsultLocalizeWithoutBirthday({
            cpf
        }).then((res) => {
            if (res.response.result) {
                let result = res.response.result
                setResponsibleName(result.name)
                setShowForm(true)
            } else {
                setStatusCPF(res.response.message.resposta)
                setShowForm(false)
            }
        }).finally(() => setLoadingCPF(false))
    }

    const getSalesCounters = async () => {
        await RemoteServices.filiais.List()
            .then(async ({ response }) => {
                if (response?.data) {
                    await RemoteServices.filiais.List({
                        limit: response?.meta?.total_items,
                        page: 1
                    }).then(({ response }) => {
                        if (response?.data) {
                            let arr = []
                            for (let _d of response?.data) {
                                arr.push({
                                    id: _d.sales_counter.id,
                                    responsible_name: _d.sales_counter.responsible_name,
                                    state: _d.sales_counter.state,
                                    created_at: _d.sales_counter.created_at,
                                    gestors: _d.sales_counter.gestors
                                })
                            }
                            setlistBranch(arr.sort((a, b) => a.id - b.id))
                            setNewOrder()
                            setLoadingOrder(false)
                        } else {
                            return getSalesCounters()
                        }
                    })
                }
            }).finally(() => setLoading(false))
    }

    function renderDate(date: Date): string {
        let [month, year] = [new Date(date).getUTCMonth(), new Date(date).getFullYear()]
        return `${months[month]} de ${year}`
    }

    const setNewOrder = async (order_by: string = orderBy, order: string = ordering) => {
        setLoadingOrder(true)

        let distincStates: string[] = []
        let distinctDates: string[] = []

        let filteredBranchs = renderListFiltered(listBranch, order_by, order)

        for (let _lb of filteredBranchs) {
            if (!distincStates.includes(_lb.state)) distincStates.push(_lb.state)

            if (!distinctDates.includes(renderDate(_lb.created_at))) distinctDates.push(renderDate(_lb.created_at))
        }

        let firstsUserByState: {
            id: number,
            state: string
        }[] = []

        let firstsUserByDate: {
            created_at: string,
            id: number
        }[] = []

        for (let _ds of distincStates) {
            firstsUserByState.push({
                id: filteredBranchs.find((item) => item.state === _ds)?.id as number,
                state: _ds
            })
        }
        for (let _dd of distinctDates) {
            firstsUserByDate.push({
                id: filteredBranchs.find((item) => renderDate(item.created_at) === _dd)?.id as number,
                created_at: _dd
            })
        }

        setCriationByUser(firstsUserByDate)
        setStateByUser(firstsUserByState)

        setLoadingOrder(false)

    }

    const setBranchsByState = async (state: string, set: boolean = true) => {
        const branches = listBranch.filter((item) => item.state === state)
        for (let _b of branches) {
            if (set) {
                setPermittedBranches(prev => [...prev as Array<any>, _b.id])
            } else {
                setPermittedBranches(prev => [... (prev as Array<any>).filter((i) => i !== _b.id)])
            }
        }
    }

    const setBranchsByDate = async (date: string, set: boolean = true) => {
        const branches = listBranch.filter((item) => renderDate(item.created_at) === date)
        for (let _b of branches) {
            if (set) {
                setPermittedBranches(prev => [...prev as Array<any>, _b.id])
            } else {
                setPermittedBranches(prev => [... (prev as Array<any>).filter((i) => i !== _b.id)])
            }
        }
    }

    function renderListFiltered(list: IListBranchByGestor[], order_by: string = orderBy, order: string = ordering): IListBranchByGestor[] {
        list.sort((a: any, b: any) => {
            if (order_by === "id") {
                return (order === "asc" ? a : b)[order_by] - (order === "asc" ? b : a)[order_by]
            }
            else if (order_by === "created_at") {
                //@ts-ignore
                return (new Date((order === "asc" ? a : b)[order_by]) - new Date((order === "asc" ? b : a)[order_by]))
            } else {
                return ((order === "asc" ? b : a)[order_by] as string).localeCompare(((order === "asc" ? a : b)[order_by] as string))
            }
        })

        if (searchName !== "") {
            list = list.filter((branch) => branch.responsible_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(searchName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")))
        }

        if (branchFilter !== "all") {
            if (branchFilter === "with_gestor") {
                list = list.filter((branch) => branch.gestors?.length > 0)
            } else {
                list = list.filter((branch) => branch.gestors === null)
            }
        }

        return list
    }

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid px={1} py={2} mt={2}>
                <Typography
                    textTransform={"uppercase"}
                    color={colors.black}
                    variant="h5"
                    fontWeight={"bold"}>Novo Usuário Matriz - Cadastro</Typography>
                <Grid container xs={12} mt={4} gap={2} maxWidth={1280}>
                    <Grid container xs={12} gap={2}>
                        <Grid container md={5} lg={5} xs={12}>
                            <FormControl fullWidth error={!!statusCPF} variant="outlined">
                                <InputLabel htmlFor="counterCPF">
                                    CPF do responsável
                                </InputLabel>
                                <OutlinedInput
                                    disabled={showForm}
                                    id="counterCPF"
                                    label="CPF do responsável"
                                    inputComponent={CPFMaskCustom}
                                    value={CPF}
                                    onChange={(event) => {
                                        if (statusCPF) {
                                            setStatusCPF('');
                                        }
                                        setCPF(event.target.value);
                                        if (event.target.value.length === 14) {
                                            consultCPF(event.target.value)
                                        }
                                    }}
                                    aria-describedby="component-error-cpf"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {loadingCPF && <CircularProgress size={24} />}
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-cpf">
                                    {statusCPF}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container md={5} lg={5} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label="Nome do responsável"
                                variant="outlined"
                                value={responsibleName}
                                error={!!statusResponsibleName}
                                helperText={statusResponsibleName}
                                onChange={(event) => {
                                    if (statusResponsibleName) {
                                        setStatusResponsibleName('');
                                    }
                                    setResponsibleName(event.target.value);
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container md={5} lg={5} xs={12}>
                            <FormControl
                                fullWidth
                                error={!!statusCellphone}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="operatorCellphone">
                                    Whatsapp
                                </InputLabel>
                                <OutlinedInput
                                    disabled={!showForm}
                                    id="operatorCellphone"
                                    label="Whatsapp"
                                    inputComponent={CellphoneMaskCustom}
                                    value={cellphone}
                                    onChange={(event) => {
                                        if (statusCellphone) {
                                            setStatusCellphone('');
                                        }
                                        setCellphone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <WhatsApp color="success" />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-cellphone">
                                    {statusCellphone}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>


                    <Typography variant="h5" color={colors.black}>DADOS PARA ACESSO</Typography>
                    <Grid container xs={12} gap={2}>
                        <Grid item md={5} lg={5} xs={12}>
                            <TextField
                                disabled={!showForm}
                                fullWidth
                                type="email"
                                label="E-mail"
                                variant="outlined"
                                value={email}
                                error={!!statusEmail}
                                helperText={statusEmail}
                                onChange={(event) => {
                                    if (statusEmail) {
                                        setStatusEmail('');
                                    }
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item md={5} lg={5} xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusPassword}
                            >
                                <InputLabel htmlFor="password">Senha</InputLabel>
                                <OutlinedInput
                                    disabled={!showForm}
                                    id="password"
                                    autoComplete="off"
                                    label="Senha"
                                    value={password}
                                    onChange={(e) => {
                                        if (statusPassword) {
                                            setStatusPassword('');
                                        }
                                        setPassword(e.target.value);
                                    }}
                                    type={passwordIsVisible ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setPasswordIsVisible(!passwordIsVisible);
                                                }}
                                                edge="end"
                                            >
                                                {passwordIsVisible ? (
                                                    <FiEye />
                                                ) : (
                                                    <FiEyeOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{statusPassword}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Typography variant="h5" color={colors.black}>PERMISSÕES PARA O NOVO USUÁRIO MATRIZ</Typography>
                    <Grid container xs={12} gap={2}>
                        <FormGroup sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            {
                                permissionsList?.map((item) => {
                                    return (
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={(permissions as any)[item.name]}
                                                onChange={(e) => {
                                                    setPermissions(prev => {
                                                        return {
                                                            ...prev,
                                                            [item.name]: e.target.checked
                                                        }
                                                    })
                                                }} />}
                                            label={item.label} />
                                    )
                                })
                            }
                        </FormGroup>
                    </Grid>
                    <Typography variant="h5" color={colors.black}>ATRIBUIR FILIAIS PARA O NOVO USUÁRIO MATRIZ</Typography>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} gap={2}>
                            <Grid item width={300}>
                                <FormControl fullWidth>
                                    <InputLabel id="search-by-name">Pesquisar por nome</InputLabel>
                                    <OutlinedInput
                                        id="search-by-name"
                                        autoComplete="off"
                                        placeholder="Digite o nome do responsável"
                                        label="Pesquisar por nome"
                                        value={searchName}
                                        onChange={(e) => {
                                            setSearchName(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item width={300}>
                                <FormControl fullWidth>
                                    <InputLabel id="branchs">Mostrar</InputLabel>
                                    <Select
                                        labelId="branchs"
                                        value={branchFilter}
                                        label="Mostrar"
                                        onChange={(e) => {
                                            setBranchFilter(e.target.value as any)
                                            setNewOrder()
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={'all'}>Todas as Filiais</MenuItem>
                                        <MenuItem value={'with_gestor'}>Filiais com usuários matriz atrubuídos</MenuItem>
                                        <MenuItem value={'without_gestor'}>Filiais sem usuários matriz</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item width={300}>
                                <FormControl fullWidth>
                                    <InputLabel id="order-by">Ordenar por</InputLabel>
                                    <Select
                                        labelId="order-by"
                                        value={orderBy}
                                        label="Ordenar por"
                                        onChange={(e) => {
                                            setOrderBy(e.target.value as any)
                                            setNewOrder(e.target.value)
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={'id'}>ID</MenuItem>
                                        <MenuItem value={'created_at'}>Data de criação</MenuItem>
                                        <MenuItem value={'state'}>Estado</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item width={300}>
                                <FormControl fullWidth>
                                    <InputLabel id="ordering">Ordenação</InputLabel>
                                    <Select
                                        labelId="ordering"
                                        value={ordering}
                                        label="Ordenação"
                                        onChange={(e) => {
                                            setOrdering(e.target.value as any)
                                            setNewOrder(orderBy, e.target.value)
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={'desc'}>Decrescente</MenuItem>
                                        <MenuItem value={'asc'}>Crescente</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormGroup sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                        }}>
                            {"todas".includes(searchName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) && <FormControlLabel
                                control={<Checkbox
                                    checked={permittedBranches === null}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setPermittedBranches(null)
                                        } else {
                                            setPermittedBranches([])
                                        }
                                    }} />}
                                label={"Todas"} />}
                            {"matriz".includes(searchName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) && <FormControlLabel
                                control={<Checkbox
                                    disabled={permittedBranches === null}
                                    checked={permittedBranches?.includes(0) || permittedBranches === null}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setPermittedBranches(prev => [...prev as Array<any>, 0])
                                        } else {
                                            setPermittedBranches(prev => [... (prev as Array<any>).filter((i) => i !== 0)])
                                        }

                                    }} />}
                                label={"Matriz"} />}
                            {
                                loadingOrder ?
                                    <Grid item xs={12}>
                                        <br />
                                        <CircularProgress size={24} />
                                    </Grid>
                                    : renderListFiltered(listBranch).map((item) => {
                                        return (
                                            <>
                                                {orderBy === "state" && stateByUser.length > 0 && stateByUser.find((i) => i.id === item.id) &&
                                                    <Grid item xs={12} md={12} lg={12} onClick={() => {
                                                        let includesAll = true
                                                        const branches = renderListFiltered(listBranch).filter((i) => i.state === item.state)

                                                        for (let _b of branches) {

                                                            if (!permittedBranches?.includes(_b.id)) {
                                                                includesAll = false
                                                            }
                                                        }
                                                        setBranchsByState(stateByUser.find((i) => i.id === item.id)?.state as string, !includesAll)
                                                    }} style={{
                                                        cursor: 'pointer'
                                                    }}>
                                                        <TooltipUI followCursor title={`Atribuir todas as filiais do estado ${stateByUser.find((i) => i.id === item.id)?.state}`}>
                                                            <Typography variant="h6" color={permittedBranches === null ? colors.grey400 : colors.black}>{stateByUser.find((i) => i.id === item.id)?.state}</Typography>
                                                        </TooltipUI>
                                                    </Grid>
                                                }
                                                {orderBy === "created_at" && creationByUser.length > 0 && creationByUser.find((i) => i.id === item.id) &&
                                                    <Grid item xs={12} md={12} lg={12}
                                                        onClick={() => {
                                                            let includesAll = true
                                                            const branches = renderListFiltered(listBranch).filter((i) => renderDate(new Date(i.created_at)) === renderDate(new Date(item.created_at)))
                                                            for (let _b of branches) {
                                                                if (!permittedBranches?.includes(_b.id)) {
                                                                    includesAll = false
                                                                }
                                                            }
                                                            setBranchsByDate(creationByUser.find((i) => i.id === item.id)?.created_at as string, !includesAll)
                                                        }} style={{
                                                            cursor: 'pointer'
                                                        }}>
                                                        <TooltipUI followCursor title={`Atribuir todas as filiais criadas em ${creationByUser.find((i) => i.id === item.id)?.created_at}`}>
                                                            <Typography variant="h6" color={permittedBranches === null ? colors.grey400 : colors.black}>{creationByUser.find((i) => i.id === item.id)?.created_at}</Typography>
                                                        </TooltipUI>

                                                    </Grid>
                                                }
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        disabled={permittedBranches === null}
                                                        checked={permittedBranches?.includes(item?.id) || permittedBranches === null}
                                                        onChange={(e) => {
                                                            console.log(e.target.checked)
                                                            if (e.target.checked) {
                                                                setPermittedBranches(prev => [...prev as Array<any>, item.id])
                                                            } else {
                                                                setPermittedBranches(prev => [... (prev as Array<any>).filter((i) => i !== item.id)])
                                                            }

                                                        }} />}
                                                    label={item?.id + " - " + item?.responsible_name?.toUpperCase() + (item.gestors?.length > 0 ? (" (" + item.gestors?.map((gestor, index) => `${index === 0 ? "" : " "}${gestor.name}`) + ")") : "")} />
                                            </>
                                        )
                                    })
                            }
                        </FormGroup>
                    </Grid>
                    <Grid xs={12} md={10.15} lg={10.15} mt={2} container justifyContent="flex-end">
                        <LoadingButton
                            loading={loading}
                            disabled={loading || !showForm}
                            onClick={submitForm}
                            variant="contained"
                            disableElevation
                            sx={{ p: 2, maxWidth: 240, width: '100%', justifySelf: "flex-end" }}>
                            Cadastrar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewGestor;