import { Grid, Skeleton } from "@mui/material";
import useAuth from "context/auth";

const LoadingSkeletonStatiscs = () => {
    const {
        User
    } = useAuth()
    return (
        <Grid container flexDirection={"column"} gap={2}>
            <Grid container gap={2}>
                <Skeleton height={200} width={'100%'} sx={{ maxWidth: 350 }} />
                <Skeleton height={200} width={'100%'} sx={{ maxWidth: 350 }} />
                {User?.space === "pdv" && <Skeleton height={200} width={'100%'} sx={{ maxWidth: 350 }} />}
            </Grid>
            <Skeleton height={450} width={'100%'} />
            <Grid container gap={2}>
                <Skeleton height={600} width={'100%'} sx={{ maxWidth: 600 }} />
                <Skeleton height={600} width={'100%'} sx={{ maxWidth: 600 }} />
            </Grid>
            <Grid container gap={2}>
                <Skeleton height={600} width={'100%'} sx={{ maxWidth: 600 }} />
                <Skeleton height={600} width={'100%'} sx={{ maxWidth: 600 }} />
                <Skeleton height={600} width={'100%'} sx={{ maxWidth: 600 }} />
            </Grid>
        </Grid>
    )
}
export default LoadingSkeletonStatiscs;