import { Remote } from "service/remote";

type StatusPedingProps = "preValidationCard" | "face_id" | "transfeera" | "signature" | "Todas"

export const Proposals = async ({
    id,
    page,
    limit,
    owner_typing,
    status,
    end_data,
    start_data,
    user_id
}: {
    id?: number;
    page?: number;
    limit?: number;
    owner_typing?: boolean;
    status?: "aproved" | "preValidationCard" | "face_id" | "failed" | "transfeera" | "signature" | 'pending' | 'Todas' | StatusPedingProps;
    start_data?: string,
    end_data?: string,
    user_id?: string
}) => {
    return await Remote({
        method: "get",
        urn: `online/proposals/report` + (id ? `/search/${id}` : ''),
        params: {
            page,
            limit,
            owner_typing,
            ...( status && status !== 'Todas' && { status}),
            ... (start_data && {start_data}),
            ... (end_data && {end_data}),
            ... (user_id && { user_id}),
        }
    })

}

export default Proposals;