import { Remote } from "service/remote";

export const ListIndicators = async (data?:{
    page?: number,
    limit?: number,
    id?: number
}) => {
    return await Remote({
        method: "get",
        urn: `online/indicator${data?.id ? ('/' + data.id) : ''}`,
        params: data
    })

}

export default ListIndicators;