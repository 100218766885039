import { Remote } from "service/remote";

export const GenerateLink = async ({
    saleTable
}: {
    saleTable: string;
}) => {
    return await Remote({
        method: "post",
        urn: `online/links`,
        data: {
            saleTable: saleTable
        }
    })

}

export default GenerateLink;