import { Remote } from "service/remote";

export const CommissionTaxUpdate = async (data:any) => {
    return await Remote({
        method: "patch",
        urn: `online/commissions/tax`,
        data
    })

}

export default CommissionTaxUpdate;