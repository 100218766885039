import { useState, useEffect } from "react"
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material"
import { formatCurrency } from "@utils/format"
import Card from "components/@ui/card"
import RemoteServices from "service"
import TooltipUI from "components/@ui/Tooltip"
import { useNavigate } from "react-router"
import useMediasQuerys from "hooks/mediasQuery"
import useAuth from "context/auth"

export default function LadingCardsLayoutTwo({
    onClickCommissionRequest
}: {
    onClickCommissionRequest: () => void
}) {
    const {
        defaultUser,
        User
    } = useAuth()
    const navigate = useNavigate()

    const [availableCommission, setAvailableCommission] = useState<number>(0)
    const [totalBorrowed, setTotalBorrowed] = useState<number>(0)
    const [pendingProposals, setPendingProposals] = useState<number>(0)

    const matchsWrap = !useMediaQuery('(min-width:600px)')

    const { matchesMaxMobile } = useMediasQuerys()

    const getAvailableCommissions = () => {
        RemoteServices.commissions.Available()
            .then(({
                response
            }) => {
                if (response?.amount) {
                    setAvailableCommission(response.amount)
                }
            })
    }

    const getProposalAnalitics = () => {
        RemoteServices.proposals.ProposalBI()
            .then(({
                response
            }) => {
                if (response?.total_borrowed) {
                    setTotalBorrowed(response?.total_borrowed?.month)
                }
                if (response?.pending_proposals) {
                    setPendingProposals(response?.pending_proposals?.month)
                }
            })
    }

    useEffect(() => {
        getAvailableCommissions()
        getProposalAnalitics()
    }, [])

    return (
        <Grid
            container
            gap={3}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            flexWrap={matchsWrap ? "wrap" : "nowrap"}
        >
            <Box
                sx={{
                    maxWidth: 300,
                    minWidth: 150,
                    width: '100%',
                    height: 145,
                    border: '2px solid #4C9FDB',
                    borderRadius: '31px',
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: 0,
                    backgroundColor: '#4C9FD826'
                }}>
                <Typography fontSize={22} fontWeight={"bold"}>{formatCurrency(totalBorrowed)}</Typography> <br />
                <Typography fontSize={14} sx={{ mt: -3 }}>Total emprestado no mês</Typography>
            </Box>

            <Box
                sx={{
                    maxWidth: 300,
                    minWidth: 150,
                    width: '100%',
                    height: 145,
                    border: '2px solid #B6C931',
                    borderRadius: '31px',
                    p: 3,
                    pt: 5.5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: 0,
                    backgroundColor: '#AEB77126'
                }}>
                <Typography fontSize={22} fontWeight={"bold"} color={availableCommission < 0 ? 'red' : 'inherit'}>{formatCurrency(availableCommission)}</Typography>
                <Typography fontSize={14}>Comissões a receber <br />
                    <TooltipUI title={availableCommission === 0 ? 'Não há comissões para receber' : ''}>
                        <div>
                            {(User?.space !== "gestor" || defaultUser?.permissions?.permissions.includes("requestCommission")) &&
                                <Button
                                    disabled={availableCommission <= 0}
                                    onClick={onClickCommissionRequest}
                                    variant="text"
                                    color="inherit"
                                    sx={{ p: 0, fontSize: '10px' }}>
                                    (Solicitar comissão agora)
                                </Button>
                            }
                        </div>
                    </TooltipUI>
                </Typography>
            </Box>

            <Box
                sx={{
                    maxWidth: 300,
                    minWidth: 150,
                    width: '100%',
                    height: 145,
                    border: '2px solid #CFA327',
                    borderRadius: '31px',
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: 0,
                    backgroundColor: '#CFA32726',
                    cursor: 'pointer'
                }}
                onClick={() => navigate('/app/propostas?status=pending')}
            >
                <Typography variant="h5" fontWeight={"bold"}>{pendingProposals}</Typography>
                <Typography variant="subtitle1">Propostas Pendentes</Typography>
            </Box>
        </Grid>
    )
}