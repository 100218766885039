import { Remote } from "service/remote";

export const ConsultLocalizeWithoutBirthday = async (query: {
    cpf?: string;
    cnpj?: string
}) => {
    console.log(query)
    return await Remote({
        method: "get",
        urn: `online/proposals/consult-localize/cadastro`,
        params: query
    })

}

export default ConsultLocalizeWithoutBirthday;