import { Remote } from "service/remote";

export const Update = async (data: any, id: number) => {
    return await Remote({
        method: "put",
        urn: `/owners/${id}`,
        api: 'manager',
        data
    })

}

export default Update;