import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import TabPanel from 'components/@ui/tab';
import lendingquota from 'utils/lendingquota';
import { colors } from 'theme';
import TableLending from 'components/tableLending';
import { SalesTableProps } from 'types/proposals';
import useProposal from 'context/proposal';
import { moneyMask } from '@utils/moneymask';
import SalesTableSkeleton from 'components/@ui/skeleton/SaleTables';
import CopyLink from 'components/selectedTable/copyLink';
import RemoteServices from 'service';
import Toast from 'components/@ui/toast';
import { transformValue } from '@utils/transformValue';

const SimulatorMaxValue = ({
    value,
    showTablesSimulator,
    saleTables,
    parcels,
    changeParcel,
    LoadingSaleTables
}: {
    value: number,
    showTablesSimulator: boolean,
    saleTables: SalesTableProps[],
    parcels: number | null,
    LoadingSaleTables: boolean;
    changeParcel: (a: number) => void;

}) => {

    const {
        valuesList,
        setValueProposal,
        valueProposal,
    } = useProposal();

    const [valueProposalError, setValueProposalError] = useState<string>('')
    const [valueProp, setvalueProp] = useState<string>('')

    const [newLink, setNewLink] = useState('')

    const [openLinkCopy, setOpenLinkCopy] = useState(false)
    const [saleTable, setSaleTable] = useState<SalesTableProps | null>(null)

    const ValidatePropInput = () => {
        if (!valuesList.includes(moneyMask(valueProp).replace(' ', ' '))) {
            if (transformValue(valueProp) < transformValue(valuesList[1])) {
                setValueProposalError('O valor mínimo para empréstimo é de R$ 300,00')
                return false
            }
            if (transformValue(valueProp) > transformValue(valuesList[valuesList.length -1])) {
                setValueProposalError('O valor máximo para empréstimo é de R$ 5.000,00')
                return false
            }
            setValueProposalError('O valor inserido não é válido. Insira um valor divisível por 10. exemplo: R$ 550')
            return false
        }
        setValueProposalError('')
        return true
    }

    useEffect(() => {
        if (valueProposal && valueProposal !== '0') {
            setvalueProp(valueProposal)
        }
    }, [valueProposal])

    async function getLink(table: string, copy: boolean) {
        RemoteServices.proposals.GenerateLink({
            saleTable: String(table)
        }).then(({ response }) => {
            if (response?.id) {
                setNewLink(`https://emprestimo.ayude.com.br/${response.id}`)
                if (copy) {
                    navigator.clipboard.writeText(`https://emprestimo.ayude.com.br/${response.id}`)
                    Toast({
                        type: 'success'
                    }, 'Link copiado!')
                }
            }
        }).catch(() => {
            Toast({
                type: 'error'
            }, 'Falha ao gerar link')
        }).finally(() => { })
    }

    return (
        <Grid container flexDirection={"column"} gap={2}>
            <Grid
                container
                xs={12}
                gap={3}
                alignItems={"flex-end"}
                p={3}
                border={'1px solid ' + colors.grey300}
                bgcolor={colors.Background}
                borderRadius={"4px"}
                justifyContent={"flex-start"}
            >
                {
                    value === 1 &&
                    <Grid xs={12} md={5} lg={5}>
                        <TextField
                            fullWidth
                            value={moneyMask(valueProp)}
                            onChange={(e) => setvalueProp(e.target.value)}
                            error={!!valueProposalError}
                            inputProps={{
                                sx: {
                                    color: (valueProp && valueProp !== 'R$ 0,0') ? colors.black : colors.grey500
                                }
                            }}
                            helperText={valueProposalError}
                            label="Digite o valor desejado pelo cliente"
                            onBlur={(r) => {
                                if (ValidatePropInput()) {
                                    setValueProposal(r.target.value)
                                }
                                // setValueProposal(r.target.value)
                            }}
                        />
                    </Grid>
                }
                <Grid container gap={2} xs={12} md={value === 1 ? 5 : 12} lg={value === 1 ? 5 : 12}>
                    <Typography minWidth={"100%"} fontWeight="400" variant="h6" textTransform="uppercase">
                        Selecione a quantidade de parcelas para simular o empréstimo
                    </Typography>
                    {
                        lendingquota.map((_quota) => (
                            <Button
                                disabled={value === 1 && (!!valueProposalError || valueProposal === '0')}
                                color="primary"
                                key={_quota.value}
                                onClick={() => changeParcel(_quota.value)}
                                sx={{
                                    p: 2,
                                    backgroundColor: (parcels ? (parcels === _quota.value ? colors.primary : colors.paper) : colors.paper),
                                    color: (parcels ? (parcels === _quota.value ? colors.paper : colors.primary) : colors.primary),
                                    borderColor: (value === 1 && (!!valueProposalError || valueProposal === '0') ? 'transparent' : colors.primary),
                                    borderWidth: '1px',
                                    borderStyle: 'solid'

                                }}
                                disableElevation
                                variant="contained">{_quota.title}</Button>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid>
                {
                    LoadingSaleTables && saleTables.length === 0 && <SalesTableSkeleton />
                }
            </Grid>
            {
                showTablesSimulator && saleTables.length > 0 &&
                <Grid mt={3}>
                    <Typography fontWeight="500" variant="h4" textTransform="uppercase" id="tables-on">Tabelas</Typography>
                    <Typography fontWeight="400" variant="subtitle2" sx={{ textDecoration: 'underline', maxWidth: '70%' }}>
                    Seleciona a tabela desejada, copie o Link ou baixe o QR Code de autoempréstimo para divulgar em seu site, WhatsApp e outras redes sociais.
                    </Typography>
                    <Grid container gap={2} mt={5} alignItems={"center"} justifyContent="center">
                        {
                            saleTables?.sort((a, b) =>
                                Number(a.sale_table.replace('on-', '')) - Number(b.sale_table.replace('on-', ''))
                            )?.filter((_s) => Number(_s.sale_table.split('on-')[1]) < 15)?.map((sale_table) => (
                                <TableLending
                                    onClick={() => {
                                        setOpenLinkCopy(true)
                                        setNewLink('')
                                        setSaleTable(sale_table)
                                    }}
                                    key={sale_table.id}
                                    sx={{ mt: 5 }}
                                    color={
                                        Number(sale_table.sale_table.replace('on-', '')) <= 3 ?
                                            colors.greenTable : (Number(sale_table.sale_table.replace('on-', '')) <= 6
                                                ? colors.blueTable : (Number(sale_table.sale_table.replace('on-', '')) <= 9
                                                ? colors.yellowTable : (Number(sale_table.sale_table.replace('on-', '')) <= 12
                                                ? colors.orangeTable : colors.redTable)))
                                    }
                                    item={sale_table} />
                            ))
                        }

                    </Grid>
                </Grid>
            }
            <CopyLink
                getLink={getLink}
                open={openLinkCopy}
                handleClose={() => setOpenLinkCopy(false)}
                link={newLink}
                newLink={newLink}
                saleTable={saleTable}
                showTable
            />
        </Grid>
    )
}



const QRCodeSimulator = ({
    showTablesSimulator,
    saleTables,
    parcels,
    changeParcel,
    LoadingSaleTables,
}: {
    showTablesSimulator: boolean,
    saleTables: SalesTableProps[]
    parcels: number | null,
    LoadingSaleTables: boolean;
    changeParcel: (a: number) => void;
}) => {

    const Simulator = () => <SimulatorMaxValue
        value={1}
        LoadingSaleTables={LoadingSaleTables}
        showTablesSimulator={showTablesSimulator}
        saleTables={saleTables}
        parcels={parcels}
        changeParcel={changeParcel} />

    return (
        <Box sx={{ width: '100%' }} id="parcels">
            <TabPanel value={1} index={1}>
                <Simulator />
            </TabPanel>
        </Box>
    );
}

export default QRCodeSimulator;