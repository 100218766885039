import { Remote } from "service/remote";

export const CommissionlBI = async () => {
    return await Remote({
        method: "get",
        urn: `online/commissions/bi`
    })

}

export default CommissionlBI;