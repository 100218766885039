import { Remote } from "service/remote";

export const Simulator = async ({
    net_amount,
    parcels
}: {
    net_amount: number,
    parcels: number
}) => {
    return await Remote({
        method: "get",
        urn: "online/proposals/simulator",
        data: {net_amount, parcels}
    })

}

export default Simulator;