import { useState, useEffect, useRef, ReactNode } from "react"

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"

import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"

import Logo from "components/@ui/logo"

import { colors } from "theme"
import Navigations from "utils/navigations"
import { Badge, BadgeProps, Container, Drawer, IconButton, Menu, MenuItem, MenuProps, Typography, alpha, keyframes, styled, useMediaQuery } from "@mui/material"

import { RiAccountCircleFill, RiLockPasswordFill } from "react-icons/ri"
import { FaBell, FaPowerOff, FaBars, FaWhatsapp } from "react-icons/fa"
import { MdAccountBalance } from "react-icons/md"
import { FiArrowLeft, FiBell } from "react-icons/fi";
import CommissionRequest, { ChangePayment, ResendCommissionRequest } from "pages/app/commissions/request"
import RemoteServices from "service"
import UserStorage from "service/storage/user"
import useAuth from "context/auth"
import ChangePassword from "components/changePassword"
import URLSBases from "utils/urlBases"
import useGeneral from "context/general"
import Chat from "components/chat"
import { formatCurrency, formatDateWithTime } from "@utils/format"
import { RequestCommissionProps } from "types/commission"
import useMediasQuerys from "hooks/mediasQuery"
import { DraggableButton } from 'react-draggable-button';
import PixAccount from "components/proposalDetais/pixAccount"
import { awaitTime } from "utils/awaitTime"
import TooltipUI from "components/@ui/Tooltip"

const baseUrl = [
    'emprestar',
    'autoemprestimo',
    'propostas',
    'comissoes',
    'filiais',
    'correspondentes',
    'painel-de-dados',
    'vaptpost',
    'leads',
    'suporte'
]

const PrivateLayout = () => {
    const {
        User
    } = useAuth()

    const navigator = useNavigate()

    const containerRef = useRef<HTMLDivElement>(null)
    const [sideMenuMobileOpen, setSideMenuMobileOpen] = useState(false);

    const { matchesMaxMobile } = useMediasQuerys()

    const { pathname } = useLocation()

    const { chatOpen, setChatOpen } = useGeneral()

    const [duplicate, setDuplicate] = useState(false)

    const pulseAnimation = keyframes`
        0% {
        transform: scale(1);
        }
        50% {
        transform: scale(1.2);
        }
        100% {
        transform: scale(1);
        }
    `

    return (
        <Grid ref={containerRef} height={"100%"} container overflow={"hidden"} position="relative">
            {
                matchesMaxMobile &&
                <Grid
                    position="fixed"
                    container
                    bgcolor="white"
                    justifyContent="space-between"
                    boxShadow={'rgba(0, 0, 0, 0.35) 0px 5px 15px'}
                    p={2}
                    sx={{ borderEndEndRadius: 16, borderEndStartRadius: 16, zIndex: 55 }}
                    alignItems="center">
                    <div>
                        <Grid container alignItems={"center"} gap={1}>
                            {
                                // @ts-ignore
                                !baseUrl.includes(pathname?.match(/([^\/]*)\/*$/)[1]) &&
                                <IconButton onClick={() => navigator(-1)}>
                                    <FiArrowLeft />
                                </IconButton>
                            }

                            <Typography fontWeight={"bold"} textTransform='capitalize'>
                                {/* @ts-ignore */}
                                {decodeURIComponent(pathname && pathname?.match(/([^\/]*)\/*$/)[1]?.replaceAll('-', ' ').replaceAll('_', '/'))}
                            </Typography>
                        </Grid>
                    </div>
                    <Grid>
                        <IconButton onClick={() => setSideMenuMobileOpen(true)} id="btn-menu-open">
                            <FaBars color={colors.primary} />
                        </IconButton>
                    </Grid>
                </Grid>
            }
            <Drawer
                anchor={'right'}
                open={sideMenuMobileOpen}
                onClose={() => setSideMenuMobileOpen(false)}
            >
                <Grid className="menu-side-bar">
                    <MenuSideBar onClickItemNav={() => setSideMenuMobileOpen(false)} />
                </Grid>
            </Drawer>
            {
                !matchesMaxMobile &&
                <Grid className="menu-side-bar">
                    <MenuSideBar />
                </Grid>
            }
            <Grid
                overflow="auto"
                height={"100vh"}
                mt={matchesMaxMobile ? 10 : 0}
                width={matchesMaxMobile ? "100%" : 'calc(100% - 250px)'}>
                <Outlet />
            </Grid>
            {User?.space !== "gestor" && <DraggableButton
                containerRef={containerRef}
                device='auto'
                backgroundColor='transparent'
                height={50}
                width={50}
                borderRadius={25}
                initialBottom={50}
                initialRight={50}
                borderMargin={40}
                animationTimig={2}
                resizeTaxOnMove={0.1}
                zIndex={10}
                children={<Grid position="absolute">
                    <Grid sx={{ position: 'relative', display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography textAlign={"center"} color={"red"}>Suporte <br />Atendimento</Typography>
                        <IconButton sx={{
                            maxWidth: 260,
                            width: '100%',
                            p: 2,
                            animation: `${pulseAnimation} 2s ease infinite`
                        }}>
                            <FaWhatsapp size={50} color="#25D366" />
                        </IconButton>
                    </Grid>
                </Grid >}
                onClick={(async () => {
                    //esse timing não faz sentido, mas se tirar vai executar essa função 2 vezes. Pq? só Deus sabe
                    await awaitTime(0.5)
                    window.open("https://wa.me/+5512935019799", "_blank")
                })}
                pressTimeToClick={0.4}
            />}
        </Grid>
    )
}

export default PrivateLayout;


const MenuSideBar = ({
    onClickItemNav
}: {
    onClickItemNav?: () => void;
}) => {

    const MyUser = UserStorage.getUserTc()

    const {
        UserOperator,
        User,
        UserOperatorSales,
        defaultUser
    } = useAuth()

    const navigate = useNavigate()

    const {
        logout
    } = useAuth()

    const {
        openDialogAccount,
        setOpenDialogAccount,
        notifications,
        redirectToRequest,
        setOpenDialogCommissionRequest,
        account,
        setAccount,
        setRedirectToResendRequest,
        setOpenDialogResendRequest,
        openDialogResendRequest
    } = useGeneral()

    const [typePaymentCommission, setTypePaymentCommission] = useState('pix')
    const [typeKeyPix, setTypeKeyPix] = useState('');
    const [keyPix, setKeyPix] = useState('');
    const [accountType, setAccountType] = useState('');

    const [maskAccountNumberMask, setMaskAccountNumber] = useState('');
    const [bank, setBank] = useState({ name: '', code: '' });
    const [agencyNumber, setAgencyNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const { pathname } = useLocation()

    const [changePass, setChangePass] = useState(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [anchorElNotification, setAnchorElNotification] = useState<null | HTMLElement>(null);
    const openNotification = Boolean(anchorElNotification);

    const [notificationSelected, setNotificationSelected] = useState<RequestCommissionProps | null>(null)

    const [pixAccount, setPixAccount] = useState(null)
    const [openModalPixAccount, setOpenModalPixAccount] = useState(false)

    const [menus, setMenus] = useState<ReactNode[]>([])

    useEffect(() => {
        (() => {
            if (defaultUser) {
                if (!MyUser) {
                    window.location.replace(URLSBases.LOGIN)
                }
                let array: ReactNode[] = []
                Navigations.pdv.forEach((nav) => {
                    if (nav.path === "comissoes") {
                        if (!defaultUser.permissions?.permissions.includes("readCommission")) {
                            nav.permissions = nav.permissions.filter((item) => item !== "gestor")
                        }
                    }
                    if (nav.path === "leads") {
                        if (!defaultUser.permissions?.permissions.includes("readLeads")) {
                            nav.permissions = nav.permissions.filter((item) => item !== "gestor")
                        }
                    }
                    if (nav.permissions?.includes(MyUser?.space ?? '')) {
                        array.push((
                            <Button
                                onClick={() => onClickItemNav ? onClickItemNav() : {}}

                                component={Link as unknown as any}
                                to={'app/' + nav.path}
                                key={nav.id}
                                sx={{
                                    maxWidth: 200,
                                    gap: 2,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    justifyContent: 'flex-start !important',
                                    ...(pathname.includes(nav.path) &&
                                    {
                                        filter: 'invert(48%) sepia(13%) saturate(4000%) hue-rotate(165deg) brightness(95%) contrast(80%)'
                                    }),
                                    ":hover": {
                                        color: pathname.includes(nav.path) ? 'inherit' : colors.primary,
                                        '#icon-nav': {
                                            filter: pathname.includes(nav.path) ? "none" : 'invert(48%) sepia(13%) saturate(4000%) hue-rotate(165deg) brightness(95%) contrast(80%)'
                                        }
                                    }
                                }}
                                fullWidth
                                color={"inherit"}
                                variant={"text"}>
                                {nav.icon()}
                                {nav.title}
                            </Button>

                        ))
                    }
                })
                setMenus(array)
            }
        })()
    }, [defaultUser])


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNotification(event.currentTarget);
    };
    const handleCloseNotification = () => {
        setAnchorElNotification(null);
    };


    const getAccount = async (open: boolean = true) => {
        await RemoteServices.commissions.getAccount().then(({ response }) => {
            if (response?.result?.account === null) {
                setOpenDialogAccount(true)
                setRedirectToResendRequest(true)
            } else {
                setAccount(response?.result?.account)
                setTypePaymentCommission(response?.result?.account?.type_payment)
                if (response?.result?.account.type_payment === "pix") {
                    setKeyPix(response?.result?.account?.key_pix)
                    setTypeKeyPix(response?.result?.account?.type_key_pix)
                } else {
                    setMaskAccountNumber(response?.result?.account.account)
                    setAgencyNumber(response?.result?.account.agency)
                    setBank(response?.result?.account.bank)
                    setAccountType(response?.result?.account.type_account)
                }
                setOpenDialogResendRequest(true)
                if (open) setOpenDialogAccount(true)
            }
        })
    }

    const _getAccount = async () => {
        await RemoteServices.commissions.getAccount().then(({ response }) => {
            if (response?.result?.account !== null) {
                setAccount(response?.result?.account)
                setTypePaymentCommission(response?.result?.account?.type_payment)
                if (response?.result?.account.type_payment === "pix") {
                    setKeyPix(response?.result?.account?.key_pix)
                    setTypeKeyPix(response?.result?.account?.type_key_pix)
                } else {
                    setMaskAccountNumber(response?.result?.account.account)
                    setAgencyNumber(response?.result?.account.agency)
                    setBank(response?.result?.account.bank)
                    setAccountType(response?.result?.account.type_account)
                }
            }
        }).finally(() => {
            setOpenDialogAccount(true)

        })
    }

    const getPixAccount = async () => {
        await RemoteServices.commissions.GetAccountPIX().then(({ response }) => {
            if (response.result !== null) {
                setPixAccount(response.result)
            }
        }).finally(() => {
            setOpenModalPixAccount(true)
        })
    }

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    return (
        <Grid
            xs={12}
            container
            border={"1px solid" + colors.grey300}
            p={2}
            height={"100vh"}
            sx={{
                overflowY: "auto",
            }}
            justifyContent={"space-between"}
            bgcolor={colors.Background}>
            <Grid
                xs={12}
                container
                flexDirection={"column"}
                gap={2}
            >
                <Grid px={1} py={2}>
                    <Link to={"/app/emprestar"}>
                        <Logo />
                    </Link>
                </Grid>

                {defaultUser && Navigations.pdv.map((nav) => {
                    if (nav.path === "comissoes") {
                        if (!defaultUser.permissions?.permissions.includes("readCommission")) {
                            nav.permissions = nav.permissions.filter((item) => item !== "gestor")
                        }
                    }
                    if (nav.permissions?.includes(MyUser?.space ?? '') && !nav?.disabled) {
                        return (
                            <TooltipUI title={nav.hover} placement={"right"}>
                                <Button
                                    onClick={() => onClickItemNav ? onClickItemNav() : {}}

                                    component={Link as unknown as any}
                                    to={'app/' + nav.path}
                                    key={nav.id}
                                    sx={{
                                        maxWidth: 200,
                                        gap: 2,
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        justifyContent: 'flex-start !important',
                                        ...(pathname.includes(nav.path) &&
                                        {
                                            filter: 'invert(48%) sepia(13%) saturate(4000%) hue-rotate(165deg) brightness(95%) contrast(80%)'
                                        }),
                                        ":hover": {
                                            color: pathname.includes(nav.path) ? 'inherit' : colors.primary,
                                            '#icon-nav': {
                                                filter: pathname.includes(nav.path) ? "none" : 'invert(48%) sepia(13%) saturate(4000%) hue-rotate(165deg) brightness(95%) contrast(80%)'
                                            }
                                        }
                                    }}
                                    fullWidth
                                    color={"inherit"}
                                    variant={"text"}>
                                    {nav.icon()}
                                    {nav.title}
                                </Button>
                            </TooltipUI>

                        )
                    }
                })}
            </Grid>
            <Grid
                xs={12}
                container
                flexDirection={"column"}
                gap={2}
            >
                <Grid sx={{
                    overflow: 'hidden',
                }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{defaultUser?.responsibleName ?? 'Nome do usuário'}</Typography>
                    <Typography sx={{
                        width: '230px',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>{defaultUser?.email ?? 'mail@email.com'}</Typography>
                    <Typography sx={{ fontStyle: 'italic' }}>Acesso: {User?.space === 'pdv' ? 'Matriz' : User?.space === 'sales_counter' ? 'Filial' : 'Gestor'}</Typography>
                </Grid>
                <Grid
                    container
                    alignItems={"center"}
                    gap={1}
                    component={Button}
                    color="inherit"
                    justifyContent={"flex-start"}
                    px={0}
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    aria-expanded={open ? 'true' : undefined}
                >
                    <RiAccountCircleFill size={25} color={colors.grey500} />
                    <Typography sx={{ fontWeight: 'bold' }} >MEUS DADOS</Typography>
                </Grid>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {User?.space !== "gestor" &&
                        <MenuItem onClick={() => {
                            navigate('/app/meus-dados')
                            handleClose()
                        }}>
                            <Grid p={1} container gap={2} sx={{ ':hover': { color: colors.primary } }}>
                                <RiAccountCircleFill size={25} />
                                Meus dados
                            </Grid>
                        </MenuItem>}
                    {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("updateOwnerData") &&
                        <MenuItem onClick={() => {
                            navigate('/app/meus-dados')
                            handleClose()
                        }}>
                            <Grid p={1} container gap={2} sx={{ ':hover': { color: colors.primary } }}>
                                <RiAccountCircleFill size={25} />
                                Meus dados
                            </Grid>
                        </MenuItem>}
                    <MenuItem onClick={() => {
                        handleClose()
                        setChangePass(true)
                    }}>
                        <Grid p={1} container gap={2} sx={{ ':hover': { color: colors.primary } }}>
                            <RiLockPasswordFill size={25} />
                            Alterar senha
                        </Grid>
                    </MenuItem>
                    {User?.space !== "gestor" &&
                        <MenuItem onClick={async () => {
                            _getAccount()
                            handleClose()
                        }}>
                            <Grid p={1} container gap={2} sx={{ ':hover': { color: colors.primary } }}>
                                <MdAccountBalance size={25} />
                                Alterar conta bancária de comissão
                            </Grid>
                        </MenuItem>}
                    {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("requestCommission") &&
                        <MenuItem onClick={async () => {
                            _getAccount()
                            handleClose()
                        }}>
                            <Grid p={1} container gap={2} sx={{ ':hover': { color: colors.primary } }}>
                                <MdAccountBalance size={25} />
                                Alterar conta bancária de comissão
                            </Grid>
                        </MenuItem>}
                    {User?.space === "sales_counter" &&
                        <MenuItem onClick={async () => {
                            getPixAccount()
                            handleClose()
                        }}>
                            <Grid p={1} container gap={2} sx={{ ':hover': { color: colors.primary } }}>
                                <MdAccountBalance size={25} />
                                Alterar chave GRATIFICAÇÃO POR ATENDIMENTO
                            </Grid>
                        </MenuItem>}
                </StyledMenu>

                {User?.space !== "gestor" && <Grid
                    container
                    alignItems={"center"}
                    gap={1}
                    component={Button}
                    color="inherit"
                    justifyContent={"flex-start"}
                    px={0}
                    id="demo-customized-button"
                    aria-controls={openNotification ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClickNotification}
                    aria-expanded={openNotification ? 'true' : undefined}
                >
                    <StyledBadge badgeContent={notifications.length} color="error" max={9}>
                        <FaBell size={25} color={colors.grey500} />
                    </StyledBadge>
                    <Typography sx={{ fontWeight: 'bold', marginLeft: notifications.length > 0 ? "8px" : "0px" }} >NOTIFICAÇÕES</Typography>
                </Grid>}

                {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("requestCommission") &&
                    <Grid
                        container
                        alignItems={"center"}
                        gap={1}
                        component={Button}
                        color="inherit"
                        justifyContent={"flex-start"}
                        px={0}
                        id="demo-customized-button"
                        aria-controls={openNotification ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        onClick={handleClickNotification}
                        aria-expanded={openNotification ? 'true' : undefined}
                    >
                        <StyledBadge badgeContent={notifications.length} color="error" max={9}>
                            <FaBell size={25} color={colors.grey500} />
                        </StyledBadge>
                        <Typography sx={{ fontWeight: 'bold', marginLeft: notifications.length > 0 ? "8px" : "0px" }} >NOTIFICAÇÕES</Typography>
                    </Grid>}

                <StyledMenu
                    id="notification"
                    MenuListProps={{
                        'aria-labelledby': 'notification',
                    }}
                    anchorEl={anchorElNotification}
                    open={openNotification}
                    onClose={handleCloseNotification}
                >
                    {notifications.map((item) => {
                        return (
                            <MenuItem onClick={async () => {
                                setAnchorElNotification(null)
                                setNotificationSelected(item)
                                await getAccount(false)
                            }}>
                                <Container >
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        flexWrap: 'nowrap',
                                        gap: 10,
                                        px: 2,
                                        py: 2
                                    }}>
                                        <Typography variant="body1" fontWeight={"bold"} color={"red"} sx={{ textDecoration: 'underline' }}>Solicitação de comissão reprovada</Typography>
                                        <Typography variant="body1" fontWeight={"bold"}>{formatDateWithTime(item.updated_at ?? '')}</Typography>
                                    </Grid>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        flexWrap: 'nowrap',
                                        gap: 10,
                                        px: 2,
                                        py: 2
                                    }}>
                                        <Typography variant="body1">
                                            <strong>ID</strong> {item.id}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Valor</strong> {formatCurrency(item.commission_amount)}
                                        </Typography>
                                    </Grid>
                                </Container>
                            </MenuItem>
                        )
                    })}
                </StyledMenu>

                <Grid
                    container
                    alignItems={"center"}
                    gap={1}
                    component={Button}
                    color="inherit"
                    justifyContent={"flex-start"}
                    px={0}
                    onClick={logout}
                >
                    <FaPowerOff size={25} color={colors.grey500} />
                    <Typography sx={{ fontWeight: 'bold' }}>SAIR</Typography>
                </Grid>
                <ChangePassword
                    open={changePass}
                    handleClose={() => {
                        setChangePass(false)
                    }}
                />
                <PixAccount
                    open={openModalPixAccount}
                    account={pixAccount}
                    handleClose={() => {
                        setOpenModalPixAccount(false)
                    }}
                />
                <ChangePayment
                    accountNumber={accountNumber}
                    accountType={accountType}
                    bank={bank}
                    agencyNumber={agencyNumber}
                    getAccount={getAccount}
                    keyPix={keyPix}
                    maskAccountNumberMask={maskAccountNumberMask}
                    openDialogAccount={openDialogAccount}
                    setAccountNumber={setAccountNumber}
                    setAccountType={setAccountType}
                    setAgencyNumber={setAgencyNumber}
                    setBank={setBank}
                    setKeyPix={setKeyPix}
                    setMaskAccountNumber={setMaskAccountNumber}
                    setOpenDialogAccount={setOpenDialogAccount}
                    setTypeKeyPix={setTypeKeyPix}
                    setTypePaymentCommission={setTypePaymentCommission}
                    typePaymentCommission={typePaymentCommission}
                    typeKeyPix={typeKeyPix}
                    redirectToRequest={redirectToRequest}
                    setOpenDialogCommissionRequest={setOpenDialogCommissionRequest}
                />
                <ResendCommissionRequest
                    open={openDialogResendRequest}
                    handleClose={() => setOpenDialogResendRequest(false)}
                    request={notificationSelected as RequestCommissionProps}
                    getAccount={getAccount}
                />
            </Grid>
        </Grid>
    )
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        '& :hover': {
            color: theme.palette.primary
        },
        '& .MuiMenu-list': {
            padding: '4px 0',
            marginTop: 2,
            marginBottom: 2,
        },
        '& .MuiMenuItem-root': {
            marginTop: 2,
            marginBottom: 2,
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            }
        },
    },
}));