import PrivateLayout from "layout/Private"
import Lend from "pages/app/lend"
import UpdateDataMatriz from "pages/app/updateData/matriz"
import UpdateDataFilial from "pages/app/updateData/filial"
import { Navigate, RouteObject, useRoutes } from "react-router"
import Login from "pages/auth/login"
import Navigations from "utils/navigations"
import UserStorage from "service/storage/user"
import useAuth from "context/auth"
import FilialAutoRegister from "pages/app/BranchCorrespondent/autoRegister"

const MyUser = UserStorage.getUserTc()

const Routes = () => {

    const {
        User
    } = useAuth()

    return useRoutes(

        User ? [
            {
                path: '/authenticate',
                element: <Login />
            },
            {
                path: '/',
                ... (!window.location.href.includes('auto-cadastro') && {
                    element: <PrivateLayout />
                }),
                children: [
                    {
                        path: '/',
                        element: <Navigate to={"/app/emprestar"} />
                    },
                    {
                        path: '/*',
                        element: <Navigate to={"/app/emprestar"} />
                    },
                    {
                        path: '/app',
                        children: [
                            {
                                path: '/app',
                                element: <Navigate to={"/app/emprestar"} />
                            },
                            {
                                path: '/app/meus-dados',
                                element: User?.space === 'sales_counter' ? <UpdateDataFilial /> : <UpdateDataMatriz />
                            },
                            ...(!User?.space ?
                                [{ path: '/app/emprestar', element: <Lend /> }]
                                : NavigationsPermitted())
                        ]
                    }
                ]
            }] : [
            {
                path: '/authenticate',
                element: <Login />
            },
            {
                path: '/app/filiais/auto-cadastro/:token',
                element: <FilialAutoRegister />,
            }
        ])
}

const NavigationsPermitted = () => {
    const {
        User,
        defaultUser
    } = useAuth()
    let Paths: RouteObject[] = []

    function setPath(navi: {
        id: number;
        title: string;
        icon: () => JSX.Element;
        path: string;
        permissions: string[];
        component: JSX.Element;
        children: {
            path: string;
            element: JSX.Element;
        }[];
    } | any) {
        Paths.push({
            path: navi.path,
            element: navi.component,
            ...(navi.children && { children: navi.children })
        })
    }

    Navigations.pdv.map((navi) => {

        if (navi.permissions?.includes(MyUser?.space ?? '') && !navi?.disabled) {
            if (navi.path === "comissoes") {
                if (User?.space === "gestor") {
                    if (defaultUser?.permissions?.permissions.includes('readCommission')) {
                        setPath(navi)
                    }
                } else {
                    setPath(navi)
                }
            } else {
                setPath(navi)
            }
        }
    })
    return Paths
}

export default Routes;