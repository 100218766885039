import { Autocomplete, Box, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, OutlinedInput, Radio, TextField, Typography } from "@mui/material"
import ButtonUI from "components/@ui/button"
import Toast from "components/@ui/toast"

import Breadcrumb from "components/breadcrumbs"
import { useEffect, useState } from "react"
import { RiAccountCircleFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import RemoteServices from "service"
import { colors } from "theme"

import estadosCidades from 'assets/json/estados_cidades.json'
import { CEPMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from "components/@ui/masked"
import { schemaOwnerStep2 } from "@utils/validator"

import MinutaDocument from "assets/documents/MINUTA - Contrato Prestação de Serviços - Completo - V3.pdf"
import useMediasQuerys from "hooks/mediasQuery"
import { awaitTime } from "utils/awaitTime"
import URLSBases from "utils/urlBases"

const UpdateDataMatriz = () => {

    const navigate = useNavigate()

    const {
        matchesMaxMobile
    } = useMediasQuerys()
    const [userData, setUserData] = useState({
        owner: {
            type_payment_commission: '',
            type_pdv: '',
            cnpj: null,
            id: 0
        },
        account: {
            type_account: '',
            agency_number: '',
            account_number: '',
            account_digit: '',
            type_key_pix: '',
            key_pix: '',
            agency_digit: '',
            bank_name: '',
            bank_code: '',
        },
    });

    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);

    const [simpleNational, setSimpleNational] = useState(true);

    const [typePaymentCommission, setTypePaymentCommission] = useState('');

    const [typeKeyPix, setTypeKeyPix] = useState('');
    const [keyPix, setKeyPix] = useState('');

    const [keyPixCpf, setKeyPixCpf] = useState('');
    const [statusKeyPixCpf, setStatusKeyPixCpf] = useState('');

    const [keyPixCnpj, setKeyPixCnpj] = useState('');
    const [statusKeyPixCnpj, setStatusKeyPixCnpj] = useState('');

    const [keyPixPhone, setKeyPixPhone] = useState('');
    const [statusKeyPixPhone, setStatusKeyPixPhone] = useState('');

    const [keyPixEmail, setKeyPixEmail] = useState('');
    const [statusKeyPixEmail, setStatusKeyPixEmail] = useState('');

    const [keyPixRandom, setKeyPixRandom] = useState('');
    const [statusKeyPixRandom, setStatusKeyPixRandom] = useState('');

    const [isIssueNote, setIsIssueNote] = useState(false);

    const [typePdv, setTypePdv] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [statusCompanyName, setStatusCompanyName] = useState('');

    const [tradeName, setTradeName] = useState('');
    const [statusTradeName, setStatusTradeName] = useState('');

    const [CNPJ, setCNPJ] = useState('');
    const [statusCNPJ, setStatusCNPJ] = useState('');

    const [CPF, setCPF] = useState('');
    const [statusCPF, setStatusCPF] = useState('');

    const [responsibleName, setResponsibleName] = useState('');
    const [statusResponsibleName, setStatusResponsibleName] = useState('');

    const [cellphone, setCellphone] = useState('');
    const [statusCellphone, setStatusCellphone] = useState('');

    const [email, setEmail] = useState('');
    const [statusEmail, setStatusEmail] = useState('');

    const [address, setAddress] = useState('');
    const [statusAddress, setStatusAddress] = useState('');

    const [addressNumber, setAddressNumber] = useState('');
    const [statusAddressNumber, setStatusAddressNumber] = useState('');

    const [district, setDistrict] = useState('');
    const [statusDistrict, setStatusDistrict] = useState('');

    const [addressComplement, setAddressComplement] = useState('');
    const [statusAddressComplement, setStatusAddressComplement] = useState('');

    const [CEP, setCEP] = useState('');
    const [statusCEP, setStatusCEP] = useState('');

    const [state, setState] = useState({ nome: '', sigla: '' });
    const [statusState, setStatusState] = useState('');

    const [city, setCity] = useState('');
    const [statusCity, setStatusCity] = useState('');

    const [cities, setCities] = useState<string[]>([]);

    const [bank, setBank] = useState({ name: '', code: '' });
    const [statusBank, setStatusBank] = useState('');

    const [accountType, setAccountType] = useState('');
    const [statusAccountType, setStatusAccountType] = useState('');

    const [agencyNumber, setAgencyNumber] = useState('');
    const [statusAgencyNumber, setStatusAgencyNumber] = useState('');

    const [agencyDigit, setAgencyDigit] = useState('');
    const [statusAgencyDigit, setStatusAgencyDigit] = useState('');

    const [accountNumber, setAccountNumber] = useState('');
    const [statusAccountNumber, setStatusAccountNumber] = useState('');

    const [maskAccountNumberMask, setMaskAccountNumber] = useState('');

    const [accountDigit, setAccountDigit] = useState('');
    const [statusAccountDigit, setStatusAccountDigit] = useState('');


    const [fieldUpdateCNPJ, setFfieldUpdateCNPJ] = useState(false);

    const [contractLink, setContractLink] = useState('')


    useEffect(() => {
        document.title = 'Ayude | Atualizar Cadastro';
    }, []);

    useEffect(() => {
        RemoteServices.auth.Me()
            .then(({ response, status }) => {
                if (status === 200) {
                    const user = response?.data;
                    if (user) {
                        setUserData(user);
                        if (user?.account?.type_key_pix) {
                            setTypeKeyPix(user?.account?.type_key_pix);
                        }
                        handleSetUserData(user);
                    }
                } else {
                    RemoteServices.auth.MeGestor().then(({ response: _response, status: _status }) => {
                        console.log(_response)
                        if (_status === 200) {
                            const user = _response;
                            if (user) {
                                setUserData(user);
                                if (user?.account?.type_key_pix) {
                                    setTypeKeyPix(user?.account?.type_key_pix);
                                }
                                handleSetUserData(user);
                            }
                        } else {
                            Toast({
                                type: 'warning',
                            }, response?.message ?? response?.error ?? _response?.message ?? _response?.error ?? 'Não foi possível buscar dados do usuario!')
                        }
                    })
                }
            })
            .catch((error) => {
                Toast({
                    type: 'warning',
                }, error?.response?.data?.message ?? error?.response?.data?.error ?? 'Não foi possível buscar dados do usuario!');
            });
    }, []);


    function handleSetUserData(user: any) {
        if (user?.owner.type_pdv === null || user?.owner.type_pdv === 'pj') {
            setTypePdv('pj');
        } else {
            setTypePdv('pf');
        }

        if (user?.owner.link_contract) {
            setContractLink(user?.owner.link_contract)
        }

        if (user?.owner.cnpj) {
            setCompanyName(user?.owner.company_name);

            setCNPJ(user?.owner.cnpj);
            if (user?.owner.is_issue_note === 'true') {
                setIsIssueNote(true);
            } else {
                setIsIssueNote(false);
            }

            setSimpleNational(user?.owner.is_simple_national);
        }

        setTradeName(user?.owner?.trade_name);
        setResponsibleName(user?.owner?.responsible_name);
        setCPF(user?.owner?.responsible_cpf);
        setCellphone(user?.owner?.cellphone);
        setEmail(user?.owner?.email);
        setAddress(user?.owner?.address);
        setAddressNumber(user?.owner?.address_number);
        setDistrict(user?.owner?.district);
        setAddressComplement(user?.owner?.address_complement);
        setCEP(user?.owner?.postal_code.replace(/[^\d]/g, "").replace(/^([\d]{5})\-*([\d]{3})/, "$1-$2"));

        estadosCidades?.forEach((element) => {
            if (element.sigla === user?.owner?.state) {
                setState({
                    nome: element.nome,
                    sigla: element.sigla,
                });
                setCities(element?.cidades ?? []);
                setCity(user?.owner?.city);
            }
        });

        setLoadingPage(false);
        if (
            user?.owner?.type_payment_commission === null ||
            user?.owner?.type_payment_commission === 'ted'
        ) {
            setTypePaymentCommission('ted');
            setAccountType(user?.account?.type_account);
            setAgencyNumber(user?.account?.agency_number);
            setAgencyDigit(user?.account?.agency_digit);
            setAccountNumber(user?.account?.account_number);
            setAccountDigit(user?.account?.account_digit);
            setMaskAccountNumber(
                user?.account?.account_number.concat('-', user?.account?.account_digit),
            );
            setBank({ name: user?.account?.bank_name, code: user?.account?.bank_code });
        }
        if (user?.owner?.type_payment_commission === 'pix') {
            setTypePaymentCommission('pix');
            setKeyPix(user?.account?.key_pix);

            if (user?.account?.type_key_pix === 'cpf') {
                setKeyPixCpf(user?.account?.key_pix);
                return;
            }
            if (user?.account?.type_key_pix === 'cnpj') {
                setKeyPixCnpj(user?.account?.key_pix);
                return;
            }
            if (user?.account?.type_key_pix === 'email') {
                setKeyPixEmail(user?.account?.key_pix);
                return;
            }
            if (user?.account?.type_key_pix === 'phone') {
                setKeyPixPhone(user?.account?.key_pix);
                return;
            }
            if (user?.account?.type_key_pix === 'key_random') {
                setKeyPixRandom(user?.account?.key_pix);
            }
        }
    }


    useEffect(() => {
        if (
            typePaymentCommission === 'ted' &&
            userData?.account?.type_account != null
        ) {
            setTypeKeyPix('');
            setAccountType(userData?.account?.type_account);
            setAgencyNumber(userData?.account?.agency_number);
            setAgencyDigit(userData?.account?.agency_digit);
            setAccountNumber(userData?.account?.account_number);
            setAccountDigit(userData?.account?.account_digit);
            setMaskAccountNumber(
                userData?.account?.account_number.concat(
                    '-',
                    userData?.account?.account_digit,
                ),
            );
            setBank({
                name: userData?.account?.bank_name,
                code: userData?.account?.bank_code,
            });
        }
        if (typePaymentCommission === 'pix') {
            if (userData?.account?.type_key_pix) {
                setTypeKeyPix(userData?.account?.type_key_pix);
                setKeyPix(userData?.account?.key_pix);
            } else {
                setTypeKeyPix('cpf');
            }

            if (userData?.account?.type_key_pix === 'cpf' && typeKeyPix === 'cpf') {
                setKeyPixCpf(userData?.account?.key_pix);
                setStatusKeyPixCpf('');
            }
            if (userData?.account?.type_key_pix === 'cnpj' && typeKeyPix === 'cnpj') {
                setKeyPixCnpj(userData?.account?.key_pix);
                setStatusKeyPixCnpj('');
            }
            if (userData?.account?.type_key_pix === 'email' && typeKeyPix === 'email') {
                setKeyPixEmail(userData?.account?.key_pix);
                setStatusKeyPixEmail('');
            }
            if (userData?.account?.type_key_pix === 'phone' && typeKeyPix === 'phone') {
                setKeyPixPhone(userData?.account?.key_pix);
                setStatusKeyPixPhone('');
            }
            if (
                userData?.account?.type_key_pix === 'key_random' &&
                typeKeyPix === 'key_random'
            ) {
                setKeyPixRandom(userData?.account?.key_pix);
                setStatusKeyPixRandom('');
            }
        }
    }, [typePaymentCommission]);



    const handleStates = (event: any, newValue: any) => {
        if (statusState) {
            setStatusState('');
        }
        if (city) {
            setCity('');
        }
        estadosCidades.forEach((element: any) => {
            if (element.sigla === newValue.sigla) {
                setCities(newValue.cidades);
            }
        });

        setState({
            nome: newValue.nome,
            sigla: newValue.sigla,
        });
    };

    const handleStep2 = () => {
        schemaOwnerStep2
            .validate(
                {
                    typePdv,
                    companyName,
                    tradeName,
                    responsibleName,
                    CNPJ,
                    CPF,
                    email,
                    cellphone,
                    typePaymentCommission,
                    typeKeyPix,
                    keyPixCpf,
                    keyPixCnpj,
                    keyPixPhone,
                    keyPixEmail,
                    keyPixRandom,
                    address,
                    addressNumber,
                    addressComplement: addressComplement || null,
                    district,
                    CEP,
                    city,
                    state,
                    bank,
                    accountType,
                    agencyNumber,
                    agencyDigit,
                    accountNumber,
                    accountDigit,
                },
                { abortEarly: false },
            )
            .then(() => {
                (async () => {
                    setLoading(true)
                    const data: any = {
                        type_pdv: typePdv,
                        company_name: companyName,
                        trade_name: tradeName,
                        responsible_name: responsibleName,
                        responsible_cpf: CPF,
                        email,
                        cnpj: CNPJ,
                        cellphone,
                        is_simple_national: simpleNational ?? true,
                        is_issue_note: isIssueNote,
                        type_payment_commission: typePaymentCommission,
                        type_key_pix: typeKeyPix,
                        key_pix: keyPix,
                        bank_name: bank.name,
                        bank_code: bank.code,
                        account_number: accountNumber,
                        account_digit: accountDigit,
                        agency_number: agencyNumber,
                        type_account: accountType,
                        address,
                        address_number: addressNumber,
                        city,
                        district,
                        state: state.sigla,
                        postal_code: CEP,
                    };

                    if (agencyDigit?.length) {
                        data.agency_digit = agencyDigit;
                    }

                    if (addressComplement) {
                        data.address_complement = addressComplement;
                    }

                    if (typePdv === 'pf') {
                        delete data.company_name;
                        delete data.cnpj;
                        delete data.is_issue_note;
                        delete data.is_simple_national;
                    }

                    if (typePaymentCommission === 'pix') {
                        delete data.bank_name;
                        delete data.bank_code;
                        delete data.type_account;
                        delete data.agency_number;
                        delete data.agency_digit;
                        delete data.account_number;
                        delete data.account_digit;
                    }
                    if (typePaymentCommission === 'ted') {
                        delete data.keyPixCnpj;
                        delete data.keyPixCpf;
                        delete data.keyPixEmail;
                        delete data.keyPixPhone;
                        delete data.keyPixRandom;
                        delete data.type_key_pix;
                        delete data.key_pix;
                    }
                    console.log(data, userData?.owner?.id, userData?.owner)
                    RemoteServices.auth.Update(data, userData?.owner?.id)
                        .then(async ({ response, status }) => {
                            if (status > 200) {
                                Toast({
                                    type: 'warning',
                                }, response?.message ?? response?.error ?? 'Não foi possivel atualzar dados!');
                            } else {
                                Toast({
                                    type: 'success',
                                }, response?.message ?? 'Dados atualizados com sucesso');
                                if (response?.data?.owner?.blocked === true) {
                                    await awaitTime(2)
                                    window.location.replace(URLSBases.LOGIN)
                                } else {
                                    navigate('/')
                                }
                            }
                        })
                        .catch((error) => {
                            Toast({
                                type: 'warning',
                            }, error?.message ?? 'Não foi possivel atualzar dados!');
                        }).finally(() => setLoading(false))
                })();
            })
            .catch((err: any) => {
                handleValidationError(err);
                Toast(
                    {
                        type: 'warning',
                    },
                    'Não foi possível atualizar os dados do proprietário. Verifique os dados informados e tente novamente.'
                );
            });
    };


    const handleValidationError = (err: any) => {
        console.log(err)
        err?.inner?.forEach((element: any) => {
            switch (element?.path) {
                case 'companyName':
                    setStatusCompanyName(element.message);
                    break;
                case 'tradeName':
                    setStatusTradeName(element.message);
                    break;
                case 'CNPJ':
                    setStatusCNPJ(element.message);
                    break;
                case 'CPF':
                    setStatusCPF(element.message);
                    break;
                case 'cellphone':
                    setStatusCellphone(element.message);
                    break;
                case 'email':
                    setStatusEmail(element.message);
                    break;
                case 'responsibleName':
                    setStatusResponsibleName(element.message);
                    break;
                case 'address':
                    setStatusAddress(element.message);
                    break;
                case 'addressNumber':
                    setStatusAddressNumber(element.message);
                    break;
                case 'addressComplement':
                    setStatusAddressComplement(element.message);
                    break;
                case 'district':
                    setStatusDistrict(element.message);
                    break;
                case 'CEP':
                    setStatusCEP(element.message);
                    break;
                case 'city':
                    setStatusCity(element.message);
                    break;
                case 'state.sigla':
                    setStatusState(element.message);
                    break;
                case 'bank.code':
                    setStatusBank(element.message);
                    break;
                case 'accountType':
                    setStatusAccountType(element.message);
                    break;
                case 'agencyNumber':
                    setStatusAgencyNumber(element.message);
                    break;
                case 'agencyDigit':
                    setStatusAgencyDigit(element.message);
                    break;
                case 'accountNumber':
                    setStatusAccountNumber(element.message);
                    break;
                case 'accountDigit':
                    setStatusAccountDigit(element.message);
                    break;
                case 'keyPixCpf':
                    setStatusKeyPixCpf(element.message);
                    break;
                case 'keyPixCnpj':
                    setStatusKeyPixCnpj(element.message);
                    break;
                case 'keyPixPhone':
                    setStatusKeyPixPhone(element.message);
                    break;
                case 'keyPixEmail':
                    setStatusKeyPixEmail(element.message);
                    break;
                case 'keyPixRandom':
                    setStatusKeyPixRandom(element.message);
                    break;

                default:
                    break;
            }
        });
    };


    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                >
                    <RiAccountCircleFill size={30} />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>Meus dados</Typography>

                </Grid>
                <Grid mt={4}>
                    <Typography fontWeight="bold">Informações da empresa</Typography>
                    <Grid component="form" my={3} container gap={2} xs={12}>
                        <Grid container gap={2} xs={12}>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label="Tipo de cadastro"
                                    value={typePdv === "pj" ? "Pessoa Jurídica" : "Pessoa Física"}
                                />
                            </Grid>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label="Nome fantasia"
                                    value={tradeName}
                                    error={!!statusTradeName}
                                    helperText={statusTradeName}
                                    onChange={(event) => {
                                        if (statusTradeName) {
                                            setStatusTradeName('');
                                        }
                                        setTradeName(event.target.value);
                                    }}
                                />
                            </Grid>
                            {typePdv === 'pj' &&
                                <>
                                    <Grid xs={12} md={5.5} lg={5.5}>
                                        <TextField
                                            name="company_name"
                                            fullWidth
                                            disabled
                                            label="Razão social"
                                            variant="outlined"
                                            value={companyName}
                                            error={!!statusCompanyName}
                                            helperText={statusCompanyName}
                                            onChange={(event) => {
                                                if (statusCompanyName) {
                                                    setStatusCompanyName('');
                                                }
                                                setCompanyName(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={5.5} lg={5.5}>
                                        <FormControl
                                            error={!!statusCNPJ}
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <InputLabel htmlFor="cnpj">CNPJ</InputLabel>
                                            <OutlinedInput
                                                name="cnpj"
                                                id="cnpj"
                                                label="CNPJ"
                                                disabled
                                                // disabled={!fieldUpdateCNPJ}
                                                inputComponent={CNPJMaskCustom}
                                                value={CNPJ}
                                                onChange={(event) => {
                                                    if (statusCNPJ) {
                                                        setStatusCNPJ('');
                                                    }
                                                    setCNPJ(event.target.value);
                                                }}
                                            />
                                            <FormHelperText>{statusCNPJ}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={4}>
                    <Typography fontWeight="bold">Informações do responsável</Typography>
                    <Grid component="form" my={3} container gap={2} xs={12}>
                        <Grid container gap={2} xs={12}>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <TextField
                                    name="responsible_name"
                                    fullWidth
                                    label={
                                        typePdv === 'pj' ? 'Nome do responsável' : 'Nome completo'
                                    }
                                    variant="outlined"
                                    value={responsibleName}
                                    error={!!statusResponsibleName}
                                    helperText={statusResponsibleName}
                                    disabled
                                    onChange={(event) => {
                                        if (statusResponsibleName) {
                                            setStatusResponsibleName('');
                                        }
                                        setResponsibleName(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <FormControl fullWidth error={!!statusCPF} variant="outlined">
                                    <InputLabel htmlFor="cpf">
                                        {typePdv === 'pj' ? 'CPF do responsável' : 'CPF'}
                                    </InputLabel>
                                    <OutlinedInput
                                        name="cpf"
                                        disabled
                                        id="cpf"
                                        label="CPF do responsável"
                                        inputComponent={CPFMaskCustom}
                                        value={CPF}
                                        onChange={(event) => {
                                            if (statusCPF) {
                                                setStatusCPF('');
                                            }
                                            setCPF(event.target.value);
                                        }}
                                    />
                                    <FormHelperText>{statusCPF}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={5.5} lg={5.5}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusCellphone}
                            >
                                <InputLabel htmlFor="cellphone">Whatsapp</InputLabel>
                                <OutlinedInput
                                    name="whatsapp"
                                    id="cellphone"
                                    label="Whatsapp"
                                    inputComponent={CellphoneMaskCustom}
                                    value={cellphone}
                                    onChange={(event) => {
                                        if (statusCellphone) {
                                            setStatusCellphone('');
                                        }
                                        setCellphone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                />
                                <FormHelperText>{statusCellphone}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={4}>
                    <Typography fontWeight="bold">Informações para acesso da conta</Typography>
                    <Grid component="form" my={3} container gap={2} xs={12}>
                        <Grid container gap={2} xs={12}>
                            <Grid xs={12} md={5.5} lg={5.5}>
                                <TextField
                                    name="email"
                                    fullWidth
                                    type="email"
                                    label="E-mail"
                                    variant="outlined"
                                    value={email}
                                    error={!!statusEmail}
                                    helperText={statusEmail}
                                    onChange={(event) => {
                                        if (statusEmail) {
                                            setStatusEmail('');
                                        }
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={4}>
                    <Typography fontWeight="bold">Endereço</Typography>
                    <Grid component="form" my={3} container gap={2} xs={12}>
                        <Grid container gap={2} xs={12}>
                            <Grid xs={12} md={3.5} lg={3.5}>
                                <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                    <InputLabel htmlFor="cep">CEP</InputLabel>
                                    <OutlinedInput
                                        name="cep"
                                        id="cep"
                                        label="CEP"
                                        inputComponent={CEPMaskCustom}
                                        value={CEP}
                                        onChange={(event) => {
                                            if (statusCEP) {
                                                setStatusCEP('');
                                            }
                                            setCEP(event.target.value);
                                        }}
                                        aria-describedby="component-error-cep"
                                    />
                                    <FormHelperText>{statusCEP}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={2} lg={2}>
                                <Autocomplete
                                    fullWidth
                                    options={estadosCidades}
                                    noOptionsText="Estado não encontrado"
                                    value={state}
                                    onChange={handleStates}
                                    autoHighlight
                                    disableClearable
                                    getOptionLabel={(option) => option.nome}
                                    renderOption={(i: any, option: { sigla: string, nome: string }) => (
                                        <Grid
                                            color="inherit"
                                            component={Button}
                                            justifyContent={"flex-start"}
                                            variant="text"
                                            p={2}
                                            container
                                            onClick={() => handleStates('', option)}>
                                            <span>{option?.sigla ?? ''} - </span>
                                            {option?.nome ?? ''}
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            name="state"
                                            {...params}
                                            label="Estado"
                                            variant="outlined"
                                            error={!!statusState}
                                            helperText={statusState}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} md={3} lg={3}>
                                <Autocomplete
                                    fullWidth
                                    options={cities}
                                    noOptionsText="Cidade não encontrada"
                                    value={city}
                                    onChange={(event, newValue) => {
                                        if (statusCity) {
                                            setStatusCity('');
                                        }
                                        setCity(newValue);
                                    }}
                                    autoHighlight
                                    disableClearable
                                    getOptionLabel={(option) => option}
                                    renderOption={(i: any, option: string) => (
                                        <Grid
                                            color="inherit"
                                            component={Button}
                                            justifyContent={"flex-start"}
                                            variant="text"
                                            p={2}
                                            container
                                            onClick={() => {
                                                if (statusCity) {
                                                    setStatusCity('');
                                                }
                                                setCity(option);
                                            }}>
                                            <span>{option} </span>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cidade"
                                            variant="outlined"
                                            error={!!statusCity}
                                            helperText={statusCity}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} md={2.3} lg={2.3}>
                                <TextField
                                    name="district"
                                    fullWidth
                                    label="Bairro"
                                    variant="outlined"
                                    value={district}
                                    error={!!statusDistrict}
                                    helperText={statusDistrict}
                                    onChange={(event) => {
                                        if (statusDistrict) {
                                            setStatusDistrict('');
                                        }
                                        setDistrict(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid component="form" my={3} container gap={2} xs={12}>
                        <Grid container gap={2} xs={12}>
                            <Grid xs={12} md={5.9} lg={5.9}>
                                <TextField
                                    name="address"
                                    fullWidth
                                    label="Logradouro"
                                    variant="outlined"
                                    value={address}
                                    error={!!statusAddress}
                                    helperText={statusAddress}
                                    onChange={(event) => {
                                        if (statusAddress) {
                                            setStatusAddress('');
                                        }
                                        setAddress(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={2} lg={2}>
                                <TextField
                                    name="address_number"
                                    fullWidth
                                    label="N°"
                                    variant="outlined"
                                    value={addressNumber}
                                    error={!!statusAddressNumber}
                                    helperText={statusAddressNumber}
                                    onChange={(event) => {
                                        if (statusAddressNumber) {
                                            setStatusAddressNumber('');
                                        }
                                        setAddressNumber(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} md={3} lg={3}>
                                <TextField
                                    name="address_complement"
                                    fullWidth
                                    label="Complemento"
                                    variant="outlined"
                                    value={addressComplement}
                                    error={!!statusAddressComplement}
                                    helperText={statusAddressComplement || 'Não obrigatório'}
                                    onChange={(event) => {
                                        if (statusAddressComplement) {
                                            setStatusAddressComplement('');
                                        }
                                        setAddressComplement(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Button
                            component="a"
                            href={MinutaDocument}
                            target="_blank"
                            variant="text">
                            MINUTA - Contrato Prestação de Serviços
                        </Button>
                    </Grid>
                    <Grid container justifyContent={matchesMaxMobile ? 'center' : "flex-end"} gap={2}>
                        <ButtonUI
                            onClick={() => navigate('/')}
                            variant="contained">
                            Voltar
                        </ButtonUI>
                        <ButtonUI
                            loading={loading}
                            onClick={handleStep2}
                            variant="contained">
                            Atualizar cadastro
                        </ButtonUI>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UpdateDataMatriz;