import { Box, Button, Grid, Typography } from "@mui/material"
import ModalUI from "components/@ui/modal";
import useMediasQuerys from "hooks/mediasQuery";
import { MdErrorOutline } from "react-icons/md";
import { colors } from "theme";

const CommissionAlert = ({
    open,
    handleClose,
    onClickAccept
}: {
    open: boolean;
    handleClose: () => void;
    onClickAccept: () => void;
}) => {

    const {
        matchesMaxMobile
    } = useMediasQuerys()


    return (
        <ModalUI
            open={open}
            handleClose={handleClose}
            sxPapper={{
                p: 0
            }}
        // hiddenButtonClose
        >
            <Grid width={matchesMaxMobile ? '100%' : '41.875rem'}>
                {/* <Grid width={"100%"} mt={10}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        xs={12}
                        sx={{ height: '9.375rem' }}
                        bgcolor={colors.warning}>
                        <MdErrorOutline color="white" size={60} />
                    </Grid>
                </Grid> */}
                <Grid
                    p={8}
                    container
                    alignItems="center"
                    justifyContent="center"
                    gap={4}
                    flexDirection={"column"}>
                    <Box sx={{ textAlign: 'center', }}>
                        <Typography
                            color={colors.black}
                            fontWeight={"bold"}
                            textTransform={"uppercase"}
                            variant="h5">
                            Como preencher sua nota fiscal
                        </Typography>
                    </Box>
                    <Typography
                        color={colors.black}
                        fontWeight={"bold"}
                        sx={{ mt: 1 }}
                    >
                        Dados para Emissão da Nota Fiscal
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            color={colors.black}
                            fontWeight={"400"}
                        >
                            CNPJ: 45.881.530/0001-24
                            <br /> Inscrição Municipal: 789100748
                            <br /> Razão social: NACIONAL SOLUÇÕES EM PAGAMENTOS LTDA
                            <br /> Endereço:  ESTRADA DR. ALTINO BONDENSAN, 500 - EUGÊNIO DE MELO,
                            CENTRAL 01 - SALA - 208 D
                            <br /> CEP: 12247-016 Município: SÃO JOSÉ DOS CAMPOS UF: SP
                        </Typography>
                    </Box>
                    <Grid container gap={2}>
                        <Button
                            component="a"
                            fullWidth={matchesMaxMobile}
                            target="_blank"
                            href="https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/MODELO+DE+EMISS%C3%83O+DE+NF+AYUDE+SJC+-+OPT+PELO+SIMPLES.pdf"
                            variant="outlined"
                            sx={{
                                flexDirection: 'column',
                                p: 2,
                                px: 4,
                                maxWidth: 280
                            }}
                        >
                            <Typography>Modelo de Nota Fiscal <br /></Typography>
                            <Typography fontWeight="bold">Optante pelo Simples</Typography>
                        </Button>
                        <Button
                            component="a"
                            fullWidth={matchesMaxMobile}
                            target="_blank"
                            href="https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/MODELO+DE+EMISS%C3%83O+DE+NF+AYUDE+SJC+-+N%C3%83O+OPT+PELO+SIMPLES.pdf"
                            variant="outlined"
                            sx={{
                                flexDirection: 'column',
                                p: 2,
                                px: 4,
                                maxWidth: 280
                            }}
                        >
                            <Typography>Modelo de Nota Fiscal<br /></Typography>
                            <Typography fontWeight="bold">Não Optante pelo Simples</Typography>
                        </Button>
                    </Grid>
                    {/* <Button
                        disableElevation
                        variant="contained"
                        sx={{
                            p: 2,
                            minWidth: 220,
                            textTransform: 'none'
                        }}
                        onClick={onClickAccept}
                    >Solicitar comissão</Button> */}
                </Grid>
            </Grid>
        </ModalUI>
    )
}

export default CommissionAlert;