import { Remote } from "service/remote";

export const SalesTableAdmin = async ({userid}:{userid: number }) => {
    return await Remote({
        method: "get",
        urn: "/sales-counters/" + userid,
        api: 'manager'
    })

}

export default SalesTableAdmin;