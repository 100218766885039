import axios, { InternalAxiosRequestConfig } from "axios";
import storageLocales from "utils/storageLocales";
import URLSBases from "utils/urlBases";


const api = axios.create({
    baseURL: URLSBases.utils
});


async function intercepter(config: InternalAxiosRequestConfig<any>) {
    const token = localStorage.getItem(storageLocales.TCtoken);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}

api.interceptors.request.use(intercepter);

export const MeSale = async () => {
    return api.get('/sales-counters/me')
} 
