import { AlertColor } from "@mui/material"
import { createContext, useState } from "react"
import { awaitTime } from "utils/awaitTime"


interface ContextProps {
	open: boolean,
	setOpen: (value: boolean) => void,
	message: string,
	severity: AlertColor,
	showSnackbar: (props: {
		severity: AlertColor,
		message: string
	}) => void
}

export const contextSnack = createContext({} as ContextProps)

export const SnackProvider = ({ children }: {
	children: JSX.Element
}) => {
	const [severity, setSeverity] = useState<AlertColor>("success")
	const [open, setOpen] = useState<boolean>(false)
	const [message, setMessage] = useState<string>("")

	const showSnackbar = async (props: {
		severity: AlertColor,
		message: string
	}) => {
		setOpen(false)
		await awaitTime(0.3)
		setMessage(props.message)
		setSeverity(props.severity)
		setOpen(true)
	}
	
	return (
		<contextSnack.Provider
			value={{
				open,
				setOpen,
				message,
				severity,
				showSnackbar
			}}
		>
			{children}
		</contextSnack.Provider>
	)
}