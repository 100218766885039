import { Remote } from "service/remote";

export const TablesOWner = async () => {
    return await Remote({
        method: "get",
        urn: "online/proposals/owner/tax"
    })

}

export default TablesOWner;