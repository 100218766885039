import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material"
import { ErrorOutline } from '@mui/icons-material';
import useMediasQuerys from "hooks/mediasQuery";

const AntiFraude = ({
    openAntiFraudeDialog,
    onClickButton
}: {
    openAntiFraudeDialog: boolean;
    onClickButton: () => void;
}) => {
    const { matchesMaxMobile } = useMediasQuerys()
    return (
        <Dialog open={openAntiFraudeDialog} maxWidth={"md"} scroll="body">
            <DialogContent>
                <Grid item xs={12} sx={{
                    backgroundColor: '#F5B000',
                    minWidth: '100%',
                    height: '151px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    m: '20px 0px 30px 0px',
                }}>
                    <ErrorOutline sx={{
                        color: 'white',
                        width: '60px',
                        height: '60px'
                    }} />
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: `0px ${matchesMaxMobile ? "0px" : "80px"}`,
                    textAlign: 'center',
                    gap: '5px'
                }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: '10px' }}>INFORMATIVO</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: '10px' }}>SISTEMA DE ANÁLISE CADASTRAL CLEARSALE</Typography>
                    <Typography>Não foi possível darmos continuidade em sua proposta devido a inconsistências cadastrais</Typography>
                    <Typography>Os dados cadastrais inseridos não foram aprovados na validação cadastral clearsale</Typography>

                    <Typography sx={{ fontWeight: 'bold', mt: '10px' }}>Prováveis motivos para recusa:</Typography>
                    <ul style={{ textAlign: 'left' }}>
                        <li><Typography>Número de telefone recente ou cadastrado em outro CPF;</Typography></li>
                        <li><Typography>Email criado recentemente ou sem atividades recentes;
                        </Typography></li>
                        <li><Typography>Endereço errado ou mudou-se recentemente;
                        </Typography></li>
                        <li><Typography>Documentos emitidos ou extraviados recentemente;
                        </Typography></li>
                        <li><Typography>Email, telefone ou CPF envolvidos em fraudes ou em outras ocorrências</Typography></li>
                    </ul>
                    <Typography style={{ color: 'gray' }} variant="subtitle2">Sugerimos que identifique acima a inconsistência cadastral, atualize os dados e após 24 horas envie uma nova proposta.</Typography>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ p: 2, minWidth: 125 }}
                    variant='contained'
                    color='primary'
                    disableElevation
                    onClick={onClickButton}
                >Entendi</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AntiFraude