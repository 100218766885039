import { Grid } from "@mui/material"
import { formatCurrency, getLegibleDate } from '@utils/format';
import { transformValue } from '@utils/transformValue';
import React, { useEffect, useState } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';

import { colors } from 'theme';


function Parcels({
    data
}: {
    data: { parcels: number, netAmount: number }[]
}) {

    const [realData, setData] = useState<{ name: string, value: string, amount: number }[] | null>(null);

    useEffect(() => {
		if (data?.length > 0) {
			const x4 = data.filter(a => a.parcels === 4).map(a => a.netAmount);
			const x6 = data.filter(a => a.parcels === 6).map(a => a.netAmount);
			const x8 = data.filter(a => a.parcels === 8).map(a => a.netAmount);
			const x10 = data.filter(a => a.parcels === 10).map(a => a.netAmount);
			const x12 = data.filter(a => a.parcels === 12).map(a => a.netAmount);
			setData([
				{ name: "x4", value: `R$ ${x4.length > 0 ? x4.reduce((a, b) => a+b) : 0}`, amount: x4.length },
				{ name: "x6", value: `R$ ${x6.length > 0 ? x6.reduce((a, b) => a+b) : 0}`, amount: x6.length },
				{ name: "x8", value: `R$ ${x8.length > 0 ? x8.reduce((a, b) => a+b) : 0}`, amount: x8.length },
				{ name: "x10", value: `R$ ${x10.length > 0 ? x10.reduce((a, b) => a+b) : 0}`, amount: x10.length },
				{ name: "x12", value: `R$ ${x12.length > 0 ? x12.reduce((a, b) => a+b) : 0}`, amount: x12.length },
			]);
		} else {}
	}, [ data ]);

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 12,
    }));

    function PieCenterLabel({ children }: { children: React.ReactNode }) {
        const { width, height, left, top  } = useDrawingArea();
        return (
            <StyledText x={left + width / 2} y={top + height / 2.5}>
                {children}
            </StyledText>
        );
    }

    if(!realData){
        return <></>
    }

    return (
        <PieChart
            width={400}
            height={400}
            tooltip={{
                trigger: 'item'
            }}
            series={[
                {
                    data: realData?.map((real) => {
                        return {
                            id: real.value,
                            label: real.name,
                            value: real.amount
                        }
                    }),
                    innerRadius: 70,
                    outerRadius: 100,
                    paddingAngle: 3,
                    cornerRadius: 5,
                    startAngle: -180,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                    valueFormatter: ({ id, value, label }) => id as unknown as string
                },
            ]}
        >
            {/* <PieCenterLabel>Center label</PieCenterLabel> */}
        </PieChart>

    )
}

export default Parcels;