import  Axios  from "axios";
import storageLocales from "utils/storageLocales";
import URLSBases from "utils/urlBases";

export const RequestCommission = async (data:any) => {
    return Axios.post(URLSBases.apisTc + "/online/commissions/request", data, {
        headers: {
            "Content-Type": "multipart/formdata",
            "Authorization": `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
        }
    })

}

export const ResendRequestCommission = async (id: number, data:any) => {
    return Axios.put(URLSBases.apisTc + "/online/commissions/request/" + id, data, {
        headers: {
            "Content-Type": "multipart/formdata",
            "Authorization": `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
        }
    })

}

export default RequestCommission;