import { Remote } from "service/remote";

export const ConsultToken = async (token: string) => {
    return await Remote({
        method: "get",
        urn: "/online/branch/token/" + token,
    })

}

export default ConsultToken;