import { Remote } from "service/remote";

export const ProposalBI = async (userId?:string) => {
    return await Remote({
        method: "get",
        urn: `online/proposals/bi`
    })

}

export default ProposalBI;